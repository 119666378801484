import { AmountInwordsPipe } from './../amount-inwords.pipe';
import { Router } from '@angular/router';
import { AuthService, Balances, CurrencyRate } from './../Services/auth.service';
import { CommonService } from './../Services/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { Guid } from "guid-typescript";
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { EmailSecOTPComponent } from '../email-sec-otp/email-sec-otp.component';

@Component({
  selector: 'app-conversion-application-form',
  templateUrl: './conversion-application-form.component.html',
  styleUrls: ['./conversion-application-form.component.scss']
})
export class ConversionApplicationFormComponent implements OnInit {

  constructor(
    public currency: DecimalPipe,
    public auth: AuthService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public commonP: CommonService,
    public errorKey: ErrorMessageKeyService,
    private _router: Router,
    private AmountInwordsPipe: AmountInwordsPipe
  ) {
  }
  selectedAccontfromAccount;
  selectedAcconttoAccount;
  toAccountList: Balances[] = [];
  appliedRate: string;
  fromCurrency;
  amount: number;
  AccountBalance: string;
  errorbalance: boolean = false;

  ValueChange() {
    this.appliedRate = "";
    this.selectedAcconttoAccount = undefined;
    this.fromCurrency = this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).currency;
    if(this.fromCurrency != 'PKR' && this.fromCurrency != 'USD'){
      this.toAccountList = this.auth.AllBalance.filter(x => x.currency == 'PKR' || x.currency == 'USD');
    }
    else{
      this.toAccountList = this.auth.AllBalance.filter(x => x.currency != this.fromCurrency);
    }
    
    this.AccountBalance = this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).balance;
  }

  async Continue() {
    await this.commonP.presentLoading();
    let _amount = this.currency.transform(this.amount, "1.2-2").replace(/[^0-9.]/g, "");;
    this.auth.ConversionForm(this.auth.data.onBoardingAccount.applicationId, Guid.create().toString(), this.fromCurrency, this.selectedAccontfromAccount, this.toCurrency, this.selectedAcconttoAccount, this.rateToDisplay, _amount, this.AmountInwordsPipe.transform(this.amount), this.auth.accessToken).then(async (data: any) => {
      if (data?.response?.code == 1) {
        await this.commonP.hideLoading();
        this.translate.get(["Success", "RequestSubmit", "OK"]).subscribe((data: any) => {
          this.commonP.showSuccessAlert(data["Success"], data["RequestSubmit"], data["OK"], this._router).then((data) => {
            this._router.navigateByUrl("/CurrencyExchangeRate");
          });
        })
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert("", "", this._router, data);
      }
    })
  }

  AmountChange() {
    this.amount = Math.floor(this.amount);
    var balance = Number(this.AccountBalance);

    if (this.amount > balance) {
      this.errorbalance = true;
    }
    else {
      this.errorbalance = false;
    }
  }

  toCurrency: string;
  rateToDisplay

  ValueChange1() {

    this.appliedRate = "";
    this.toCurrency = this.toAccountList.find(x => x.accountNumber == this.selectedAcconttoAccount).currency;

    if (this.toCurrency == this.fromCurrency) {
      this.appliedRate = "1";
      this.rateToDisplay = "1";
    } else {
      if (this.AllRate) {
        let a = this.AllRate.find(x => x.ccY_Sale == this.fromCurrency && x.ccY_Buy == this.toCurrency);
        if (a) {
          this.appliedRate = a.rateBuy;
          this.rateToDisplay = a.rateBuy;
        } else {
          a = this.AllRate.find(x => x.ccY_Buy == this.fromCurrency && x.ccY_Sale == this.toCurrency);
          if (a) {
            this.appliedRate = (1 / Number(a.rateSale)).toFixed(4) + "";
            this.rateToDisplay = a.rateSale;
          }
        }
      }
    }

  }

  agree: boolean = false;
  changeAgree(event) {
    if (event.srcElement.checked) {
      this.agree = true;
    }
    else {
      this.agree = false;

    }
  }

  isValid() {
    if (!this.errorbalance) {
      if (this.agree) {
        if (this.fromCurrency && this.selectedAccontfromAccount && this.selectedAcconttoAccount && this.toCurrency && this.amount) {
          return false;
        }
        else {
          return true;
        }
      } else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  AllRate: CurrencyRate[] = [];
  async ngOnInit(): Promise<void> {
    setTimeout(async () => {
      this.commonP.presentLoading();
      this.auth.accessToken = await this.auth.Get('token');
      this.auth.data = JSON.parse(await this.auth.Get("User"));
      this.auth.BalanceInquiry(Guid.create().toString(), this.auth.accessToken).then((data: any) => {

        if (data?.response?.code == 1) {
          this.auth.AllBalance = data.response.content;

          this.auth.GetAllRate(this.auth.accessToken, Guid.create().toString()).then((data: any) => {
            if (data?.response?.code == 1) {
              this.commonP.hideLoading();
              this.AllRate = data.response.content.allRate;
            } 
            else {
              this.commonP.ParseErrorAlert("", "", this._router, data);
            }
          });
        }
        else {
          this.commonP.ParseErrorAlert('', '', this._router, data);
        }
      })
    }, 3000);
  }
}
