import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../Services/common.service';

@Component({
  selector: 'app-header5',
  templateUrl: './header5.component.html',
  styleUrls: ['./header5.component.scss']
})
export class Header5Component implements OnInit {

  constructor(
    private commonP: CommonService,
    private _router: Router
  ) { }

  ngOnInit(): void {
  }

  LogOut() {
    this.commonP.showLogoutAlert(this._router).then((data) => {
      if (data == "Logout") {
        this.commonP.presentLoading();
        this.commonP.hideLoading();
        this.commonP.clear();
        this._router.navigateByUrl('/login');
        this.commonP.hideLoading();
      }
    });
  }

  backToDashboard(){
    this._router.navigateByUrl('/CurrencyExchangeRate');
  }

  CurrencyExchangeRate() {
    this._router.navigateByUrl('/CurrencyExchangeRate');
  }
}
