import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss']
})
export class FabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  labelLink(e){
    e.preventDefault();
    window.open("http://m.me/DIBPakistan", '_blank');
  }

}
