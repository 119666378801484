import { AuthService } from './Services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class Auth2Guard implements CanActivate {
  constructor(
    public auth: AuthService,
    public _router: Router
  ) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let a = await this.auth.Get("token");
    let _b = await this.auth.Get("User");
    if (_b) {
      
      let b = JSON.parse(_b);

      if (a) {
        
        if (b?.onBoardingAccount?.accountNumber) {
          return true;
        } else {
          this._router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
          return false;
        }

      } else {
        
        this._router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
        return false;
      }
    } else {
      
      this._router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
      return false;
    }




  }

  

}

