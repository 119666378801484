<app-header></app-header>

<div class="container login-component mt-15">
  <div class="ng-row">
    <div class="ng-col hide-on-mob" col-2>
      <div class="left-img"></div>
    </div>
    <div class="ng-col pt-20 p-l-r-sm-30" col-6 col-sm-12>

      <div class="ng-row height-100" justify-content-center align-content-center>
        <div class="ng-col" col-10 col-sm-12>
          <div class="logo mt-15">
            <ng-container *ngIf="IsResident == '0'">
              <img src="../assets/images/logo-roshan.png" width="120" alt="">
            </ng-container>
          </div>
          <div class="font-24 color-2f5 font-600 mt-10">
            <ng-container *ngIf="IsResident == '0'">{{"WelcometoDIBRoshan" | translate}}</ng-container>
            <ng-container *ngIf="IsResident == '1'">{{"WelcometoDIBDigital" | translate}}</ng-container>
          </div>

          <div class="font-16 color-2f5 font-400 mt-5">
            {{"PleaseLogintocomplete" | translate}}
          </div>

          <form [formGroup]="registerForm" autocomplete="off">

            <div class="form-group mt-20" *ngIf="IsResident == '0'">
              <label>{{"Email" | translate}}</label>
              <input type="email" onCopy="return false" onDrag="return false" onDrop="return false"
                onPaste="return false" [(ngModel)]="_email" autocomplete="new-email" formControlName="email"
                placeholder="Enter email">

              <ng-container item-content *ngFor="let validation of errorKey.validation_messages.email">
                <span class="error-label"
                  *ngIf="registerForm.controls.email.hasError(validation.type) &&(registerForm.controls.email.touched ||submitAttempt)">
                  {{ getMessage(validation.key,'email') }}
                </span>
              </ng-container>
            </div>

            <div class="form-group mt-20" *ngIf="IsResident == '1'">
              <label>{{"Username" | translate}}</label>
              <input type="text" value="" autocomplete="new-username" formControlName="username"
                placeholder="Enter username" maxlength="15" minlength="6">
              <ng-container item-content *ngFor="let validation of errorKey.validation_messages.username">
                <span class="error-label"
                  *ngIf="registerForm.controls.username.hasError(validation.type) && (registerForm.controls.username.touched || submitAttempt)">
                  {{ getMessage(validation.key,'username') }}
                  <br>
                </span>
              </ng-container>
            </div>

            <div class="form-group mt-30 password-eye">
              <label>{{"Password" | translate}}</label>
              <input type="text" onCopy="return false" onDrag="return false" onDrop="return false"
                onPaste="return false" #forpasswordstyle style="-webkit-text-security: disc;"
                autocomplete="new-password" [(ngModel)]="_password" formControlName="password"
                placeholder="Enter password">
              <i class="icon-eye" #eyeIcon (click)="showPassword()"></i>
              <ng-container item-content *ngFor="let validation of errorKey.validation_messages.password">
                <span class="error-label"
                  *ngIf="registerForm.controls.password.hasError(validation.type) &&(registerForm.controls.password.touched ||submitAttempt)">
                  {{ getMessage(validation.key,'password') }}
                </span>
              </ng-container>
            </div>


            <div class="mt-30 text-align-center">
              <recaptcha class="display-inline-block" [size]="'normal'" formControlName="recaptcha" [hide]="false"
                (captchaExpired)="onCaptchaExpired()" (captchaResponse)="onCaptchaResponse($event)">
              </recaptcha>
            </div>
          </form>

          <div class="font-14 color-931 font-600 mt-15">
            <span class="link" [routerLink]="['/change-password']">
              {{"ForgetPassword" | translate}} ?
            </span>
          </div>

          <div class="form-group mt-30 mb-30">
            <button class="btn-dft" [disabled]="isFormValid()" (click)="Login()">{{"Login" | translate}}</button>
          </div>
        </div>
      </div>
    </div>


    <div class="ng-col not-apply" col-4 col-sm-12>

      <div class="board-text p-l-r-sm-30">
        <div class="board-text-head">
          <div>
            <div class="font-14 color-354 font-500">
              {{this.title}}
            </div>
            <div class="font-12 color-718 font-400 mt-10">
              {{this.text}}
            </div>
          </div>
        </div>

        <div class="board-text-body">
          <div class="ng-row" align-item-center *ngFor="let data of this.steps">
            <div class="ng-col" col-auto>
              <img [src]="data.listIcon">
            </div>
            <div class="ng-col p-l-r" col-9>
              <div class="font-12 color-354 font-400">
                {{data.listText}}
              </div>
            </div>
          </div>
        </div>

        <div class="board-text-foot">
          <div class="form-group">
            <button class="btn-dft-white" (click)="Signupfunc()">{{"SignUp" | translate}}</button>
            <!-- <button class="btn-dft mt-15" [routerLink]="['/pension/InvestmentDetail']">{{"Investment Details" | translate}}</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-fab></app-fab>

<!-- <div class="custom-modal min-width" #modal1>
  <div class=" custom-modal-overlay" (click)="hidePopup()"></div>
  <div class="custom-modal-canvas">
    <div class="custom-modal-close">
      <i class="icon-close-black" (click)="hidePopup()"></i>
    </div>

    <div class="font-24 font-600 color-354 line-h-26 text-align-center mt-25">
      {{ "CaptchaVerification" | translate }}
    </div>

    <div class="ng-row mt-46" justify-content-center>
      <div class="ng-col" col-7 col-sm-12>

        <botdetect-captcha help-link-enabled="false" captchaStyleName="CaptchaNameField"></botdetect-captcha>
        <div class="form-group mt-20">
          <input placeholder="Please enter code" id="CaptchaInputField" name="CaptchaInputField" ngModel
            #CaptchaInputField="ngModel" type="text" style="text-transform: none;">
        </div>

      </div>
    </div>

    <div class="ng-row mt-42 mb-25" justify-content-center>
      <div class="ng-col" col-7 col-sm-12 col-md-6>
        <button class="btn-dft" [disabled]='isvalid()' (click)="Login1()">{{"Verify" | translate}}</button>
      </div>
    </div>
  </div>
</div> -->

<div class="custom-modal full-width full-height" #modal>
  <div class="custom-modal-overlay"></div>
  <div class="custom-modal-canvas">
    <div class="custom-modal-close">

    </div>
    <div class="custom-modal-canvas-scroller">

      <div class="ng-row mt-32" justify-content-center>
        <div class="ng-col" col-10 col-sm-12>
          <i class="icon-survey"></i>
          <div class="font-24 font-600 color-2f5 line-h-26 mt-25">
            {{ "SurveytoAssess" | translate }}
          </div>
          <div class="font-14 font-500 color-354 line-h-26 mt-25">
            {{ "StateBankofPakistanSBP" | translate }}
          </div>
        </div>
      </div>

      <div class="ng-row mt-42 mb-25" justify-content-center>
        <div class="ng-col" col-5 col-sm-12 col-md-6>
          <button class="btn-dft" (click)="showRoute()">{{"TakeaSurvey" | translate}}</button>
        </div>
      </div>

    </div>
  </div>
</div>