<app-header2></app-header2>

<div class="container mt-46" [ngClass]="this.sideBarTextShow == true ? 'dashboard-component':''">
  <div class="ng-row">
    <div class="ng-col bg-2F5 pd" col-12>
      <div class="font-24 color-white font-300 pd">
        {{"Welcometo" | translate}} <strong>{{"DIBRoshan" | translate}}</strong><span *ngIf="this.auth.data">,
          {{this.auth.data.fullname}}</span>
      </div>
    </div>

    <div class="ng-col bg-eee pd" #box1 col-md-5 col-sm-12>
      <div class="font-14 color-354 font-700 pd inside-link">
        {{"Toavailinternet" | translate}} <strong class="label-link color-931"
          (click)="labelLink2($event)"><u>{{"clickhere" | translate}}</u></strong>
      </div>
      <div class="ng-row pd" col-space>

        <div class="ng-col mb-25" col-12>
          <div class="font-14 color-354 font-700">
            {{"FormRequest" | translate}}
          </div>
        </div>

        <div class="ng-col" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"RoshanApnaGhar" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="showPopup3()">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>

        <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"RoshanQurbani" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('QurbaniForm')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>

      



        <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"IslamicNayaPakistanCertificatePurchase" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('INPC')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>



        <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"LinkAccountRequest" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('LAR')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>

        <div class="ng-col mt-15" col-12 (click)="showPopup2()">
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"DIBRoshanApniCarApplication" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="showPopup2()">

              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>

        <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"RoshanSamaajiKhidmat" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('Roshan')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>

        <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"ConversionRequest" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('CR')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>

        <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"RDAADCServicesForm" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('RDAADC')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>

        <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"LocalTran" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('LT')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>


        <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"InternationalTran" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('IT')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>

        <!-- <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"RemittanceApplicationForm" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('RAF')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div> -->

        <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"NayaPakistanEncashmentForm" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('NPE')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>

        <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"RDAStockMarketInvestmentForm" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('RDAStock')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>

        <div class="ng-col mt-15" col-12>
          <div class="ng-row br-12 bg-white pd height-100" justify-content-between align-item-center>
            <div class="ng-col" col-7>
              <div class="font-12 color-354 font-400">
                {{"CDCTransferAmountForm" | translate}}
              </div>
            </div>
            <div class="ng-col" col-auto (click)="ForRouteNavigationWithLoader('CDC')">
              <i class="icon-arrow-white"></i>
            </div>
          </div>
        </div>





      </div>
    </div>

    <div class="ng-col bg-white pd" #box2 col-md-7 col-sm-12>


      <div class="font-16 color-2f5 font-700 pd">
        {{"AccountBalance" | translate}}
      </div>

      <div class="pd currency-table-parent">
        <div class="currency-table">
          <div class="currency-table-head bg-F6F pd">
            <div class="ng-row">
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  IBAN
                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{"Amount" | translate}}
                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{"Currency" | translate}}
                </div>
              </div>
            </div>
          </div>
          <div class="currency-table-body p-l-r">
            <div class="ng-row border-bottom pt-20 pb-15 currency-table-item" align-item-center
              *ngFor="let data of this.auth.AllBalance">
              <div class="ng-col" col-4>

                <div class="font-12 color-354 font-400 text-align-center">
                  {{data.iban}}
                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{data.balance | formatmoneypipe : 2}}
                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{data.currency}}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="font-16 color-2f5 font-700 pd">
        {{"FxConversionRatesFCYtoPKR" | translate}}
      </div>

      <div class="pd currency-table-parent">
        <div class="currency-table">
          <div class="currency-table-head bg-F6F pd">
            <div class="ng-row">
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{"Currency" | translate}}
                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{"DIBBuy" | translate}}
                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{"DIBSell" | translate}}
                </div>
              </div>
            </div>
          </div>
          <div class="currency-table-body p-l-r">
            <div *ngFor="let data of this.pkrlist" class="ng-row border-bottom pt-20 pb-15 currency-table-item"
              align-item-center>
              <div class="ng-col" col-4>

                <div class="font-12 color-354 font-400 text-align-center">
                  {{data.ccY_Sale}}
                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{data.rateBuy}}
                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{data.rateSale}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="font-16 color-2f5 font-700 pd">
        {{"FxConversionRatesFCYtoUSD" | translate}}
      </div>

      <div class="pd currency-table-parent">
        <div class="currency-table">
          <div class="currency-table-head bg-F6F pd">
            <div class="ng-row">
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{"Currency"| translate}}
                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{"DIBBuy"| translate}}

                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{"DIBSell"| translate}}

                </div>
              </div>
            </div>
          </div>
          <div class="currency-table-body p-l-r">
            <div *ngFor="let data of this.usdlist" class="ng-row border-bottom pt-20 pb-15 currency-table-item"
              align-item-center>
              <div class="ng-col" col-4>

                <div class="font-12 color-354 font-400 text-align-center">
                  {{data.ccY_Sale}}
                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{data.rateBuy}}
                </div>
              </div>
              <div class="ng-col" col-4>
                <div class="font-14 color-354 font-400 text-align-center">
                  {{data.rateSale}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="font-16 color-2f5 font-700 pd">
        {{"ConversionCalculator" | translate}}
      </div>

      <div class="ng-row pd">
        <div class="ng-col" col-12 *ngIf='!this.FromValueSelected'>
          <div class="form-group">
            <label>{{"FromCurrency" | translate}}</label>
            <select [(ngModel)]="this.SelectFromValue" class="custom-select" (change)="ChangeEvent()">
              <option [ngValue]="null" disabled selected hidden>{{"PleaseSelect" | translate}}</option>
              <option [ngValue]='a' *ngFor="let a of this.RateFromArrayNew[0]">{{a}}</option>
            </select>
          </div>
        </div>
        <div class="ng-col" col-12 *ngIf="this.FromValueSelected">
          <div class="ng-row" col-space>
            <div class="ng-col" col-6>
              <div class="form-group">
                <label>{{"FromCurrency" | translate}}</label>
                <select [(ngModel)]="this.SelectFromValue" class="custom-select" (change)="ChangeEvent()">
                  <option [ngValue]="null" disabled selected hidden>{{"PleaseSelect" | translate}}</option>
                  <option [ngValue]='a' *ngFor="let a of this.RateFromArrayNew[0]">{{a}}</option>
                </select>
              </div>
            </div>
            <div class="ng-col" col-6 *ngIf="this.FromValueSelected">
              <div class="form-group">
                <label>{{"ToCurrency" | translate}}</label>
                <select [(ngModel)]="this.SelectToValue" class="custom-select" (change)="ChangeEvent1()">
                  <option value="undefined" disabled selected hidden>{{"PleaseSelect" | translate}}</option>
                  <option [ngValue]='a.to' *ngFor="let a of this.newlist">{{a.to}}</option>
                </select>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="font-14 color-2f5 font-700 pd">
        {{"ConversionRate" | translate}} : <strong class="color-931 font-700">{{this.SelectToValue}}
          {{this.rateForDisplay}}</strong>
      </div>

      <div class="form-group pd">
        <label>{{"Amount" | translate}}</label>
        <input type="number" placeholder="Please enter" [(ngModel)]="this.Amount" (change)="AmountChange()">
      </div>

      <div class="form-group pd">
        <button class="btn-dft" [disabled]="Validation()" (click)="Conversion()">
          {{"Convert" | translate}}
        </button>
      </div>

      <div class="form-group pd" *ngIf='this.formvalid'>
        <label class="no-mark">{{"ConvertedAmount" | translate}}</label>
        <input type="number" [value]="this.convertedrate" placeholder="Converted amount here .." readonly>
      </div>

    </div>

    <div class="ng-col bg-eee pd" col-3 col-md-5 col-sm-12 *ngIf="this.sideBarTextShow == true"
      id="dashbord-sidebar-content"></div>
  </div>
</div>

<div class="container not-apply mt-46 mb-46">
  <div class="ng-row text-align-center">
    <div class="ng-col" col-12>
      <div class="font-14 color-354 font-400">
        {{"Reachusat" | translate}} <a class="color-354 font-700 alink"
          href="tel:+92-21-111-786-342">+92-21-111-786-342</a> {{"oremailusat" | translate}} <a
          class="alink font-700 color-354" href="mailto:dibrda.inquiry@dibpak.com">dibrda.inquiry@dibpak.com</a>.
      </div>

      <div class="font-14 color-354 font-400 mt-5">
        {{"Foranycomplaints" | translate}} <a class="alink font-700 color-354"
          href="mailto:dibrda.complaint@dibpak.com">dibrda.complaint@dibpak.com</a>.
      </div>

      <div class="font-24 color-354 font-600 mt-15">
        {{"Thankyouforchoosingus" | translate}}
      </div>

      <div class="font-14 color-354 font-400">
        {{"DubaiIslamicBankPakistanLimited2" | translate}}
      </div>
      <div class="font-14 color-354 font-700">
        {{"TheBetterWaytoBank" | translate}}
      </div>
    </div>
  </div>
</div>


<app-fab></app-fab>

<div class="custom-modal full-width full-height" #modal2>
  <div class="custom-modal-overlay" (click)="hidePopup2()"></div>
  <div class="custom-modal-canvas height-100 p-0">
    <div class="fixed-header">
      <div class="ng-row pt-25" justify-content-center>
        <div class="ng-col" col-11 col-sm-11>
          <div class="custom-modal-close">
            <i class="icon-close-black" (click)="hidePopup2()"></i>
          </div>

          <img src="assets/images/roshan-apni-car.svg" alt="" width="117">

          <div class="font-24 font-600 color-2f5 line-h-26 mt-20">
            {{"RoshanDigitalApni" | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="fixed-content">
      <div class="ng-row" justify-content-center>
        <div class="ng-col" col-11 col-sm-11>
          <div class="dot-text-list">
            <ul>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Financingfacility" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Tenoroption" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Maximumvehicle" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Minimumage" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Preferredpricing" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Maximumagelimit" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Iftheapplicant" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Bothsdatacheck" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Beneficiaryismandatory" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Thefacilityis" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Incaseof" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"IncaseofForeign" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"MonthlyRentals" | translate}}
                </div>
              </li>
              <li>
                <div class="font-14 font-400 color-2d3 line-h-26 opacity-08">
                  {{"Tocheckwhat" | translate}} <span class="color-6F2 link"><u>{{"ViewCharges" | translate}}</u></span>
                </div>
              </li>
            </ul>
          </div>

        </div>
      </div>

      <div class="mt-25">
        <accordion accordionIndex="1" [accordionHeading]="this.PPricingtitle">
          <div class="ng-row pt-20 pb-20" align-item-center>
            <div class="ng-col text-align-left" col-4>
              <div class="font-12 color-718">
                {{"Rate" | translate}}
              </div>
            </div>
            <div class="ng-col text-align-center" col-4>
              <div class="font-12 color-718">
                {{"Lien Product" | translate}}
              </div>
            </div>
            <div class="ng-col text-align-right" col-4>
              <div class="font-12 color-718">
                {{"Non-Lien Product" | translate}}
              </div>
            </div>
          </div>
          <div class="ng-row pt-20 pb-20 border-top border-color-e1e" align-item-center
            *ngFor="let R of this.PPricingRate">
            <div class="ng-col text-align-left" col-4>
              <div class="font-12 color-354">
                {{R.rate}}
              </div>
            </div>
            <div class="ng-col text-align-center" col-4>
              <div class="font-12 color-354">
                {{R.lienProduct}}
              </div>
            </div>
            <div class="ng-col text-align-right" col-4>
              <div class="font-12 color-354">
                {{R.non_LienProduct}}
              </div>
            </div>
          </div>

        </accordion>

        <accordion accordionIndex="2" accordionHeading="{{this.Benchmarktitle}}">
          <div class="ng-row pt-20 pb-20" align-item-center *ngFor="let B of this.BenchmarkRate">
            <div class="ng-col text-align-left" col-12>
              <div class="font-15 font-600 color-354">
                {{B.subHeading}}
              </div>
              <div class="ng-row">
                <div class="ng-col text-align-left" col-4>
                  <div class="font-12 color-718">
                    {{"Tenor" | translate}}
                  </div>
                </div>
                <div class="ng-col text-align-center" col-4>
                  <div class="font-12 color-718">

                  </div>
                </div>
                <div class="ng-col text-align-right" col-4>
                  <div class="font-12 color-718">
                    {{"Percentage" | translate}}
                  </div>
                </div>
              </div>
            </div>
            <div class="ng-col border-top border-color-e1e" *ngFor="let D of B.details" col-12>
              <div class="ng-row pt-20 pb-20 " align-item-center>
                <div class="ng-col text-align-left" col-4>
                  <div class="font-12 color-354">
                    {{D.tenor}}
                  </div>
                </div>
                <div class="ng-col text-align-center" col-4>
                  <div class="font-12 color-354">
                  </div>
                </div>
                <div class="ng-col text-align-right" col-4>
                  <div class="font-12 color-354">
                    {{D.percentage}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </accordion>

        <accordion accordionIndex="3" accordionHeading="{{this.deliverTimetitle}}">
          <div class="ng-row pt-20 pb-20" align-item-center *ngFor="let DT of this.deliverTime">
            <div class="ng-col text-align-left" col-12>

              <div class="font-15 font-600 color-354">
                {{DT.title}}
                <div class="font-12 pt-10 pb-10 color-354">
                  {{DT.details[0].sub_Head_Text}}
                </div>
              </div>

              <div class="ng-row">
                <div class="ng-col text-align-left" col-4>
                  <div class="font-12 color-718">
                    {{"Variants" | translate}}
                  </div>
                </div>
                <div class="ng-col text-align-center" col-4>
                  <div class="font-12 color-718">

                  </div>
                </div>
                <div class="ng-col text-align-right" col-4>
                  <div class="font-12 color-718">
                    {{"DeliveryTime" | translate}}
                  </div>
                </div>
              </div>
            </div>
            <div class="ng-col border-top border-color-e1e" *ngFor="let T of DT.details" col-12>
              <div class="ng-row pt-20 pb-20 " align-item-center>
                <div class="ng-col text-align-left" col-4>
                  <div class="font-12 color-354">
                    {{T.variants}}
                  </div>
                </div>
                <div class="ng-col text-align-center" col-4>
                  <div class="font-12 color-354">
                  </div>
                </div>
                <div class="ng-col text-align-right" col-4>
                  <div class="font-12 color-354">
                    {{T.deliveryTime}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </accordion>
      </div>

      <div class="ng-row mt-20 pt-25" justify-content-center>
        <div class="ng-col" col-11 col-sm-11>
          <div class="font-12 color-718">
            {{"Notes" | translate}}:
          </div>

          <div class="dot-text-list font-12 color-718">
            <ol>
              <li>
                {{"SBPFloorwill" | translate}}
              </li>
              <li>
                {{"impliestherelevant" | translate}}
              </li>
              <li>
                {{"Customerwillbe" | translate}}
              </li>
              <li>
                {{"Allbanksto" | translate}}
              </li>
              <li>
                {{"Incaseofany" | translate}}
              </li>
              <li>
                {{"Wewillreview" | translate}}
              </li>
            </ol>
          </div>

        </div>
      </div>

      <div class="ng-row mt-20 pt-25" justify-content-center>
        <div class="ng-col" col-5 col-sm-11>
          <button class="btn-dft" (click)="ForRouteNavigationWithLoader('AutoFinance')">
            {{"CalculateMyLoan" | translate}}
          </button>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="custom-modal full-width full-height" #modal3>
  <div class="custom-modal-overlay" (click)="hidePopup3()"></div>
  <div class="custom-modal-canvas height-100 p-0">
    <div class="fixed-header">
      <div class="ng-row pt-25" justify-content-center>
        <div class="ng-col" col-11 col-sm-11>
          <div class="custom-modal-close">
            <i class="icon-close-black" (click)="hidePopup3()"></i>
          </div>

          <img src="assets/images/roshan-apna-ghar-logo.png" alt="" width="117">

          <div class="font-24 font-600 color-2f5 line-h-26 mt-20">
            {{"Roshan Digital Apna Ghar" | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="fixed-content">
      <div class="ng-row mt-10" justify-content-center>
        <div class="ng-col" col-11 col-sm-11>
          <accordion accordionIndex="1" accordionHeading="Product Features">
            <div class="dot-text-list">
              <ul>
                <li>
                  Financing being offered for Home Purchase, Home Constructions and Home Renovation
                </li>
                <li>
                  Finance to value up to 85% will be considered
                </li>
                <li>
                  Flexibility to make partial pre-payments
                </li>
                <li>
                  Discounted processing fee
                </li>
                <li>
                  Maximum age limit of the applicant at the time of maturity should not exceed 65 years
                </li>
                <li>
                  3 types of Pricing for Financing (Variable, Fix Rate or GMSS Pricing)
                </li>
              </ul>
            </div>
          </accordion>

          <accordion accordionIndex="2" accordionHeading="Variable Rate Pricing">
            <div class="dot-text-list">
              <ul>
                <li>
                  With lien 1 year KIBOR + 1.5%
                </li>
                <li>
                  Without lien 1 year KIBOR
                </li>

              </ul>
              <!-- <div class="mt-15">Pricing to be revised on yearly basis</div> -->

            </div>
          </accordion>

          <accordion accordionIndex="3" accordionHeading="Fix Rate Pricing">
            <div class="dot-text-list">
              <ul>
                <li>
                  With lien 5 year PKRV
                </li>
                <li>
                  Without lien 5 year PKRV 9.4 + 1.50%
                </li>
              </ul>
              <!-- <div class="mt-15">{{"RatesRevised"|translate}}</div> -->
            </div>

          </accordion>

          <accordion accordionIndex="4" accordionHeading="Goverment Market/Profit Subsidy Scheme (GMSS) Pricing">
            <div class="ng-row border-bottom border-color-5E0 pb-15" col-space>
              <div class="ng-col" col-6>
                <div class="font-400 font-14 color-718">
                  Size of Unit
                </div>
                <div class="mt-25 font-400 font-14 color-354">
                  Home up to 125 sq yds (5 Marla) with maximum covered area of 850 sq ft
                  and for flat/apartment with maximum covered area of 850 sq ft.
                </div>
              </div>
              <div class="ng-col" col-6>
                <div class="font-400 font-14 color-718">
                  Subsidized Profit Rate
                </div>
                <div class="dot-text-list">
                  <ul class="font-400 font-14 color-354">
                    <li>
                      First 5 years = 3%
                    </li>
                    <li>
                      Next 5 years = 5%
                    </li>
                    <li>
                      For period exceeding
                      10 years = 1 year KIBOR + 2.5%
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="ng-row border-bottom border-color-5E0 pb-15" col-space>
              <div class="ng-col" col-6>
                <div class="font-400 font-14 color-354 mt-25">
                  House up to 125 sq yds (Marla) and for
                  flat/apartment with maximum covered
                  area of 1,250 sq ft.
                </div>
              </div>
              <div class="ng-col" col-6>
                <div class="dot-text-list">
                  <ul class="font-400 font-14 color-354">
                    <li>
                      First 5 years = 5%
                    </li>
                    <li>
                      Next 5 years = 7%
                    </li>
                    <li>
                      For period exceeding
                      10 years = 1 year KIBOR + 4%
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="ng-row" col-space>
              <div class="ng-col" col-6>
                <div class="font-400 font-14 color-354 mt-25">
                  House up to 250 sq yds (10 Marla) and
                  for for flat/apartment with maximum
                  covered area of 2,000 sq ft.
                </div>
              </div>
              <div class="ng-col" col-6>
                <div class="dot-text-list">
                  <ul class="font-400 font-14 color-354">
                    <li>
                      First 5 years = 7%
                    </li>
                    <li>
                      Next 5 years = 9%
                    </li>
                    <li>
                      For period exceeding
                      10 years = 1 year KIBOR + 4%
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </accordion>
        </div>
      </div>
    </div>


    <div class="ng-row mt-20 mb-20 pt-25" justify-content-center>
      <div class="ng-col" col-5 col-sm-11>
        <button class="btn-dft" (click)="showPopup4()">
          {{"Continue" | translate}}
        </button>
      </div>
    </div>

  </div>
</div>

<div class="custom-modal" #modal4>
  <div class="custom-modal-overlay" (click)="hidePopup4()"></div>
  <div class="custom-modal-canvas">
    <div class="fixed-header">
      <div class="ng-row pt-25" justify-content-center>
        <div class="ng-col" col-11 col-sm-11>
          <div class="custom-modal-close">
            <i class="icon-close-black" (click)="hidePopup4()"></i>
          </div>

          <img src="assets/images/roshan-apna-ghar-logo.png" alt="" width="117">

          <div class="font-24 font-600 color-2f5 line-h-26 mt-20">
            {{"Type of Transaction" | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="fixed-content">
      <div class="ng-row" justify-content-center>
        <div class="ng-col" col-11 col-sm-11>
          <div class="form-group mt-30">
            <div class="ng-row">
              <div class="ng-col mb-10" col-12>
                <div class="font-16 font-600 color-2d3">
                  {{"Pleaseselectyourtypeoftransaction" | translate}}
                </div>
              </div>
              <div class="ng-col" col-4 col-md-5>
                <div class="custom-checkbox">
                  <input type="radio" value="" id="label-1" name="group1" (change)="radioTypeOfTransaction('own')">
                  <label for="label-1" class="color-black pt-5 pl-35">
                    {{"OwnFunds" | translate}}
                  </label>
                </div>
              </div>
              <div class="ng-col pl-15" col-auto col-md-7>
                <div class="custom-checkbox">
                  <input type="radio" value="" id="label-2" name="group1" (change)="radioTypeOfTransaction('bank')">
                  <label for="label-2" class="color-black pt-5 pl-35">
                    {{"Financingfrombank" | translate}}
                  </label>
                </div>
              </div>
            </div>
          </div>


          <div class="ng-row mt-20 pt-25" justify-content-center>
            <div class="ng-col" col-6 col-sm-12>
              <div class="form-group mb-20">
                <button class="btn-dft mt-15" [disabled]="Transactionbuttonvalid()"
                  (click)="ForRouteNavigationWithLoader(this.typeOfTransactionStatus)">
                  {{"Continue" | translate}}
                </button>
              </div>
            </div>
          </div>



        </div>
      </div>

    </div>
  </div>
</div>