import { Component, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './../Services/common.service';
import { PasswordValidation } from './../Validators/PasswordValidators';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { AuthService } from './../Services/auth.service';
import { ConfigService } from '../Services/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { KycService } from './../Services/kyc.service';
import { Country, CountriesService } from './../Services/countries.service';
import { Guid } from "guid-typescript";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  cardmaask: string = "XXXXX-XXXXXXX-X";
  submitAttempt: boolean = false;
  cardPlaceHolder: string = "XXXXX-XXXXXXX-X";
  registerForm: FormGroup;
  id: Guid;
  uniqueId: string;
  resendOptbtnShow: boolean = false;
  counter: string;
  current: string;
  max: string;
  timeoutHandle;
  timerexpire: string;
  public verify: string = '';

  constructor(
    public translate: TranslateService,
    private kyc: KycService,
    private commonP: CommonService,
    public errorKey: ErrorMessageKeyService,
    public formBuilder: FormBuilder,
    public countries: CountriesService,
    private auth: AuthService,
    private config: ConfigService,
    private _router: Router,

    public Renderer2: Renderer2,
  ) {

    this.registerForm = this.formBuilder.group({
      IDNumber: ["", Validators.compose([Validators.required, Validators.minLength(13)])],
    })
  }

  ngOnInit(): void {
    this.counter = "1";
    this.current = "1";
    this.max = "3";
    this.startTime();
  }

  isFormValid() {
    if (!this.registerForm.valid) {
      return true;
    } else {
      false;
    }
  }

  startTime() {
    var str = "0:59";

    var array = str.split(":");
    this.countdown(parseInt(array[0]), parseInt(array[1]));
  }

  getMessage(key, control) {

    let error = "";

    this.translate.get([key]).subscribe(data => {

      error = data[key];
    })
    return error;
  }

  countdown(minutes, seconds) {
    this.timeoutHandle;
    var that = this;
    function tick() {




      that.timerexpire = minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
      seconds--;
      if (seconds >= 0) {
        that.timeoutHandle = setTimeout(tick, 1000);
      } else {
        if (minutes >= 1) {

          setTimeout(function () {
            that.countdown(minutes - 1, 59);
          }, 1000);
        }
      }

      if (that.timerexpire == "0:00") {

        that.resendOptbtnShow = true;
      }
    }
    tick();
  }

  clearAll() {
    this.verify = "";
  }

  Next() {
    this.id = Guid.create();
    this.uniqueId = this.id.toString();

    var a = this.registerForm.get('IDNumber').value;
    this.commonP.presentLoading();

    this.auth.SendForgotPasswordOTP(a, this.uniqueId).then(async (data: any) => {
      if (data?.response?.code == 1) {
        this.commonP.hideLoading();
        this.commonP.Set('MobileNumber', data.response.content.mobilenumber);
        this.commonP.Set('Idnumber', a);
        this.commonP.Set('Email', data.response.content.email);
        this.commonP.Set('Auth',this.uniqueId);
      
      //  let bb=JSON.parse(auth);
        this._router.navigateByUrl("/verify-change-password-otp");

      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert('', '', this._router, data);
      }
    })
  }

}
