import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, TemplateRef, Renderer2 } from '@angular/core';
import * as _ from 'lodash';
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { CommonService } from '../../Services/common.service'
import { Router } from '@angular/router';
import { ConfigService } from '../../Services/config.service';
import { AuthService } from '../../Services/auth.service';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  imageError: string = null;
  cameraactive: boolean = false;
  cardImageBase64: string;
  image64: string[];
  imagebase64: string;
  isImageSaved: boolean;
  localstream;
  imageviewer: boolean;
  AttachImage: boolean;
  PdfSaved: boolean;
  AttachedPDFLink: string;
  pdfsrc = '';
  id : string;
  AllowedArr;

  @Input() Heading: string;
  @Input() CameraViewer: boolean = true;
  @Input() AsterikShow: boolean = false;
  @Input() Text: string = "UploadJPEG";
  @Input() ObjectName: string;
  @Input() PDFName: string;
  @Input() IsSupportComponent: boolean = false;
  @Input() closeButton: boolean = false;
  @Input() AuthToken: string;
  @Input() Link : string = null;
  @Input() description : string = null;
  @Input() IsResponseContainExtension : boolean = false;
  
  @ViewChild("modal") Modal: ElementRef;
  @ViewChild("imageid") imagetag: ElementRef;
  @ViewChild("imageid2") imagetag2: ElementRef;
  @ViewChild("modal2") Modal2: ElementRef;
  @ViewChild("modal3") Modal3: ElementRef;
  @ViewChild('pdfviewer') pdf: ElementRef;
  @ViewChild('takeinput', { static: false }) InputVar: ElementRef;
  @ViewChild('takeinput2', { static: false }) InputVar2: ElementRef;
  @ViewChild('viewlink', { static: false }) viewlink: ElementRef;

  @Output() closeButtonFunction = new EventEmitter<any>();
  @Output() callParentFunction = new EventEmitter<any>();
  @Output() callAnotherFunction = new EventEmitter<any>();
  @Output() UploadDone =new EventEmitter<boolean>();

  constructor(
    private renderer: Renderer2,
    private common: CommonService,
    private router: Router,
    private config: ConfigService,
    private auth : AuthService
  ) { 
    this.id = Guid.create().toString();
  }

  async ngOnInit(){
    debugger
   this.config.AllowedTypeArray = await this.common.Get('AllowedDocs');
    this.AllowedArr  =  "pdf,jpeg,png,jpg,9j"; // this.config.AllowedTypeArray.toUpperCase().split(',');
    this.AllowedArr = this.AllowedArr.split(',')
    
    var classes = document.getElementsByClassName('ng2-pdf-viewer-container');
    for (var i = 0; i < classes.length; i++) {
      classes[i].setAttribute('style', 'display :block; height: 0px; width: 0px');
    }

    if(this.ObjectName == "Headpic" || this.ObjectName == "Sign"){
      
      // this.config.AllowedTypeArray = this.config.AllowedTypeArray.split(',').filter(x => x.toUpperCase() == "PNG" || x.toUpperCase() == 'JPEG' || x.toUpperCase() == 'JPG')[0];

    }
  }

  closeClick() {
    this.closeButtonFunction.emit();
  }

  viewButtonLink(link) {
    window.open(this.AttachedPDFLink, '_blank');
  }

  fileChangeEvent(fileInput: any) {
    console.log(fileInput.target,"1213113 target");
    
    this.common.presentLoading();
    if (fileInput.target.files && fileInput.target.files[0]) {
      
      // Size Filter Bytes
      const max_size = 5000000;
      const allowed_types = this.AllowedArr;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError = 'You have exceeded 5 MB. Please upload a valid format page.';
        this.common.hideLoading();
        return false;
      }
      console.log(!_.includes(allowed_types, fileInput.target.files[0].type.split('/')[1].toString()),"if condition");
      
      if (!_.includes(allowed_types, fileInput.target.files[0].type.split('/')[1].toString())) {
        debugger
        this.imageError = 'Only PDF, JPEG and PNG formats are allowed.';
        this.common.hideLoading();
        return false;
      }

      const reader = new FileReader();
      reader.onload = async (e: any) => {
        debugger
        this.pdfsrc = e.target.result;
        let selectedfile = fileInput.target.files;
        let array = e.target.result.split(',');
        let extension = array[0].split(';')[0].split('/')[1].toString();
        console.log(array[0],"array0");
        
        if (array[0] == "data:application/pdf;base64") {
          
          let url = URL.createObjectURL(selectedfile[0]);
          setTimeout(() => {

            if (!this.pdferror) {

              this.auth.saveTempimage2(this.AuthToken, array[1], extension).then((data:any) => {
                
                if(data?.response?.code == 1){

                  this.imagebase64 = array[1];
                  this.PdfSaved = true;
                  this.AttachedPDFLink = url;
                  this.UploadDone.emit(true);
                  this.common.hideLoading();

                  if(this.IsResponseContainExtension){
                    this.callParentFunction.emit([data.response.content.id, extension]);
                  }
                  else{
                    this.callParentFunction.emit(data.response.content.id);
                  }
                }
                else{
                  this.common.hideLoading();
                  this.common.ParseErrorAlert("","",this.router,data);
                }
              })
            }
            else {

              this.InputVar.nativeElement.value = "";
              this.cardImageBase64 = null;
              this.isImageSaved = false;
              this.PdfSaved = false;
              this.imagebase64 = null;
              this.imagebase64 = undefined;
              this.common.showErrorAlert("Sorry", "Corrupted File", "OK", this.router);
              this.common.hideLoading();
            }
          }, 1000);
        }
        else if (array[0] == "data:image/png;base64" || array[0] == "data:image/jpeg;base64" || array[0] == "data:image/jpg;base64") {

          let image = new Image();
          image.src = e.target.result;

          image.onload = load => {

            this.auth.saveTempimage2(this.AuthToken, array[1], extension).then((data: any) => {
              
              if (data?.response?.code == 1) {
     
                this.imagebase64 = array[1];
                this.isImageSaved = true;
                this.AttachImage = true;
                this.renderer.setAttribute(this.imagetag.nativeElement, 'src', array[0] + ',' + array[1]);
                this.UploadDone.emit(true);
                this.common.hideLoading();
                if (this.IsResponseContainExtension) {
                  this.callParentFunction.emit([data.response.content.id, extension]);
                }
                else {
                  this.callParentFunction.emit(data.response.content.id);
                }
              }
              else {
                this.common.hideLoading();
                this.common.ParseErrorAlert("", "", this.router, data);
              }
            })
          }
          image.onerror = error => {

            this.InputVar.nativeElement.value = "";
            this.cardImageBase64 = null;
            this.isImageSaved = false;
            this.PdfSaved = false;
            this.imagebase64 = null;
            this.imagebase64 = undefined;
            this.common.showErrorAlert("Sorry", "Corrupted File", "OK", this.router);
            this.common.hideLoading();
          }
        }
        else{
          this.common.hideLoading();
          this.common.showErrorAlert("Sorry","Only PDF, JPEG and PNG formats are allowed","OK",this.router);
        }
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
    else{
      console.log("12335543545 else");
      
    }
  }

  pdferror: boolean;
  err() {
    this.pdferror = true;
  }

  loaded() {
    this.pdferror = false;
  }

  openButtonLink(link) {
    window.open(link, '_blank');
  }

  removeImage() {
    this.InputVar.nativeElement.value = "";
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.PdfSaved = false;
    this.imagebase64 = null;
    this.renderer.removeClass(this.Modal.nativeElement, "active");
    this.callAnotherFunction.emit();
  }

  removeImage3() {
    this.renderer.setAttribute(this.imagetag2.nativeElement, 'src', "");
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.PdfSaved = false;
    this.imagebase64 = null;
    this.renderer.removeClass(this.Modal3.nativeElement, "active");
    this.callAnotherFunction.emit();
  }

  removePdf(data) {
    this.InputVar.nativeElement.value = "";
    this.imagebase64 = null;
    this.PdfSaved = false;
    this.callAnotherFunction.emit(data);
  }

  hidePopup() {
    this.renderer.removeClass(this.Modal.nativeElement, "active");
  }

  hidePopup3() {
    this.renderer.removeClass(this.Modal3.nativeElement, "active");
    this.cameraactive = false;
    this.localstream.getTracks()[0].stop();
  }

  showPopup() {
    this.renderer.addClass(this.Modal.nativeElement, "active");
  }

  showPopup3() {
    if (this.AttachImage) {
      this.renderer.addClass(this.Modal.nativeElement, "active");

    } else {
      this.renderer.addClass(this.Modal3.nativeElement, "active");

    }
  }

  showPopup1() {
    this.cameraactive = true;
    this.checkCamera();
  }

  checkCamera() {
    var that = this;
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(function (stream) {
        that.localstream = stream;
        /* use the stream */

        that.cameraactive = true;
        that.renderer.addClass(that.Modal2.nativeElement, "active");
      })
      .catch(function (err) {
        /* handle the error */

        that.common.ParseErrorAlert('Sorry', 'OK', that.router, 'Your device does not support this feature');
        that.cameraactive = false;
      });
  }

  hidePopup1() {
    this.renderer.removeClass(this.Modal2.nativeElement, "active");
    this.cameraactive = false;
    this.localstream.getTracks()[0].stop();
  }

  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  triggerSnapshot(): void {
    this.trigger.next();
    this.imageviewer = true;
  }

  handleImage(webcamImage: WebcamImage): void {
    console.info('Saved webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.renderer.setAttribute(this.imagetag2.nativeElement, 'src', this.webcamImage.imageAsDataUrl);
    this.isImageSaved = true;
    this.AttachImage = false;
    this.callParentFunction.emit(this.webcamImage.imageAsBase64);
    this.hidePopup1();
  }

  public get triggerObservable(): Observable<void> {
    if (this.cameraactive) {
      return this.trigger.asObservable();
    }
  }

}
