import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageKeyService {

  constructor() { }


  validation_messages = {
    explanation: [
      { type: 'required', key: "explanationReq" },
      { type: 'minlength', key: "minexplanation" },
      { type: 'maxlength', key: "maxexplanation" },

    ],
    taxNumber: [
      { type: 'required', key: "taxReq" },
      { type: 'minlength', key: "mintaxnumber" },
      { type: 'maxlength', key: "maxtaxnumber" },
      { type: 'pattern', key: "taxpattern" },

    ],
    postalCode: [

      { type: 'required', key: "postalCodeReq" },

      { type: 'minlength', key: "postalCodeminlength" },
      { type: 'maxlength', key: "postalCodeMaxLength" },


    ],
    monthlyIncome: [

      { type: 'required', key: "monthlyIncomeReq" },

      { type: 'minlength', key: "monthlyIncomeminlength" },
      { type: 'maxlength', key: "monthlyIncomeMaxLength" },


    ],
    typeofaccount: [
      {
        type: 'required', key: 'tofrequired'
      }
    ],
    POA: [
      {
        type: 'required', key: 'poarequired'
      }
    ],
    EMCTR: [
      {
        type: 'required', key: 'emctrrequired'
      }
    ],
    EMCTURN: [
      {
        type: 'required', key: 'emcturnreq'
      }
    ],
    SOF: [
      {
        type: 'required', key: 'sofrequired'
      }
    ],
    Occup: [
      {
        type: 'required', key: 'Occupreq'
      }
    ],
    MonthIncome: [
      {
        type: 'required', key: 'monthincomereq'
      }
    ],
    Province: [
      {
        type: 'required', key: 'provincereq'
      }
    ],

    natureofbusiness: [
      {
        type: 'required', key: 'natofrequired'
      },
      { type: 'pattern', key: "ProfessionPatter" },

    ],
    Buildingname: [
      {
        type: 'required', key: 'Buildingnamereq'
      },
      // { type: 'pattern', key: "ProfessionPatter" },

    ],
    natureofaccount: [
      {
        type: 'required', key: 'nofrequired'
      }
    ],
    TokenAmount: [
      {
        type: 'required', key: 'TokenAmountReq'
      },
      {
        type: 'pattern', key: 'Numbersallow'
      }
    ],
    Industry: [
      {
        type: 'required', key: 'Industryreq'
      }
    ],
    Acctitle: [
      {
        type: 'required', key: 'AcctitleReq'
      },
      { type: 'minlength', key: "Acctitleminlength" },
      { type: 'maxlength', key: "AcctitleMaxLength" },
      { type: 'pattern', key: "Acctitlepattern" },
    ],
    companyName: [

      { type: 'required', key: "companyNameReq" },
      { type: 'pattern', key: "Companynamepattern" },
      { type: 'minlength', key: "companyNameminlength" },
      { type: 'maxlength', key: "companyNameMaxLength" },


    ],
    Namefield: [
      { type: 'required', key: "NameReq" },
      { type: 'pattern', key: 'Alphabetsallow' }
    ],
    addressLine1: [
      { type: 'required', key: "addressLine1Req" },
      { type: 'minlength', key: "addressLine1minlength" },
      { type: 'maxlength', key: "addressLine1MaxLength" },
    ],
    Propertyaddress: [
      { type: 'required', key: "PropertyaddressReq" },
      { type: 'minlength', key: "addressLine1minlength" },
      { type: 'maxlength', key: "addressLine1MaxLength" },
    ],
    SocietyArea: [
      { type: 'required', key: "SocietyAreaReq" },

    ],
    CoveredArea: [
      { type: 'required', key: "CoveredAreaReq" },
      { type: 'pattern', key: 'Numbersallow' }


    ],
    addressLine2: [
      { type: 'required', key: "addressLine1Req" },
      { type: 'minlength', key: "addressLine2minlength" },
      { type: 'maxlength', key: "addressLine2MaxLength" },
    ],
    rating: [
      { type: 'required', key: 'ratingreq' }
    ],
    otp: [
      { type: 'required', key: "OtpRequired" },
      { type: 'minlength', key: "Otpminlength" },
    ],
    email: [
      { type: 'required', key: "EmailRequired" },
      { type: 'pattern', key: "EmailPattern" },
      { type: 'maxlength', key: "EmailMaxLength" },
    ],
    username: [
      { type: 'required', key: "usernameRequired" },
      { type: 'pattern', key: "usernamePattern" },
      { type: 'maxlength', key: "usernameMaxLength" },
      { type: 'minlength', key: "usernameMinLength" },
    ],
    newemail: [

      { type: 'required', key: "EmailRequired" },
      { type: 'pattern', key: "EmailPattern" },
      { type: 'maxlength', key: "EmailMaxLength" },

    ],
    address: [
      { type: 'pattern', key: "addressPatter" },
      { type: 'required', key: "AddressRequired" },
      { type: 'maxlength', key: "addressmaxlength" },
      { type: 'minlength', key: "addressminlength" },
    ],
    completeaddress: [
      { type: 'pattern', key: "completeaddressPatter" },
      { type: 'required', key: "completeaddressRequired" },
      { type: 'maxlength', key: "completeaddressmaxlength" },

    ],
    driverAddress: [
      { type: 'pattern', key: "driverAddressPatter" },
      { type: 'required', key: "AddressRequired" },
      { type: 'maxlength', key: "completeaddressmaxlength" },
    ],
    CityOfBirth: [
      { type: 'required', key: 'cobreq' },
      { type: 'pattern', key: "CobPattern" },
    ],
    LandlineNumber: [
      { type: 'required', key: 'LandlineNumberRequired' },
      { type: 'pattern', key: "LandlineNumberPattern" },
    ],
    RelationPurposeRemitter: [
      { type: 'required', key: 'RelationPurposeRemitterReq' }
    ],
    NamePurposeRemitter: [
      { type: 'required', key: 'NamePurposeRemitterrReq' }
    ],
    IncomeCategory: [
      { type: 'required', key: 'Incomereq' }
    ],
    TypeOfCompany: [
      { type: 'required', key: 'tocreq' }
    ],
    TypeOfBusiness: [
      { type: 'required', key: 'tobreq' }
    ],
    BusinessName: [
      { type: 'required', key: 'bnamereq' },
      { type: 'pattern', key: "ProfessionPatter" },

    ],
    AddLine1: [
      { type: 'required', key: 'Addline1Req' }
    ],
    OfficeAddLine1: [
      { type: 'required', key: 'officeAddline1Req' }
    ],
    OfficeNumber: [
      { type: 'required', key: 'officeAddline1Req' },
      { type: 'pattern', key: "PhoneNumPattern" }

    ],
    PhoneNumPrevEmp: [
      { type: 'required', key: 'PhoneNumReq' },
      { type: 'pattern', key: "PhoneNumPattern" }
    ],
    NameOfPreEmp: [
      { type: 'required', key: 'Nameofprevreq' },
      { type: 'pattern', key: "Nameofprevpattern" },
      { type: 'minlength', key: "minlength" }
    ],
    Desgination: [
      { type: 'required', key: 'designationreq' },
      { type: 'pattern', key: "designationPattern" }
    ],
    city: [
      { type: 'required', key: "CityRequired" },
      { type: 'pattern', key: "CityPattern" },
    ],
    Nominee: [
      { type: 'required', key: "NomineeRequired" },
    ],
    RequiredMessage: [
      { type: 'required', key: "RequiredMessage" },
      { type: 'pattern', key: "ProfessionPatter" },

    ],
    COApplicantNumber: [
      { type: 'required', key: "COApplicantNumberRequired" },
      { type: 'pattern', key: "PhoneNumPattern" }

    ],
    Grade: [
      { type: 'required', key: "GradeRequired" },
      { type: 'pattern', key: "GradePattern" },
    ],
    ResAddress1: [
      { type: 'required', key: "ResAddress1Required" },
    ],
    ResAddress2: [
      { type: 'required', key: "ResAddress2Required" },
    ],
    PerAddress1: [
      { type: 'required', key: "PerAddress1Required" },
    ],
    PerAddress2: [
      { type: 'required', key: "PerAddress2Required" },
    ],
    IDIssuance: [
      { type: 'required', key: "DOERequired" },
    ],
    branch: [
      { type: 'required', key: "branchRequired" },
    ],
    State: [
      { type: 'required', key: "StateRequired" },
      { type: 'pattern', key: "StatePattern" },
    ],
    country: [
      { type: 'required', key: "CountryRequired" },
    ],
    termsCondition: [
      { type: 'required', key: "TermsConditions" }
    ],
    employeerName: [

      { type: 'required', key: "EmployeerNameRequired" },
      { type: 'pattern', key: "EmployeerNamePatter" },
      { type: 'minlength', key: "Employeerminlength" },
      { type: 'maxlength', key: "Employeermaxlength" },

    ],
    profession: [

      { type: 'required', key: "ProfessionRequired" },
      { type: 'pattern', key: "ProfessionPatter" },
    ],
    passportexpiry: [
      { type: 'required', key: "passportexpiry" },
    ],
    PassportName: [
      { type: 'required', key: "PassportName" },
    ],
    PassportNumber: [
      { type: 'required', key: "Passnmbrrequired" },
      { type: 'maxlength', key: "Passnmbrlength" },
      { type: 'minlength', key: "Passnmbrlengthmin" },
    ],
    DobYearSelect: [
      { type: 'required', key: 'dobyearreq' },
    ],
    DobMonthSelect: [
      { type: 'required', key: 'dobmonthreq' },
    ],
    DobdaySelect: [
      { type: 'required', key: 'dobdayreq' },
    ],
    DoeYearSelect: [
      { type: 'required', key: 'doeyearreq' },
    ],
    DoeMonthSelect: [
      { type: 'required', key: 'doemonthreq' },
    ],
    DoedaySelect: [
      { type: 'required', key: 'doedayreq' },
    ],
    issueYearSelect: [
      { type: 'required', key: 'issueyearreq' },
    ],
    DurationYear: [
      { type: 'required', key: 'durayearreq' },
    ],
    DurationMonth: [
      { type: 'required', key: 'duramonthreq' },
    ],
    issueMonthSelect: [
      { type: 'required', key: 'issuemonthreq' },
    ],
    issuedaySelect: [
      { type: 'required', key: 'issuedayreq' },
    ],
    pepexpose: [
      { type: 'required', key: "pepreq" },
    ],
    Frequency: [
      { type: 'required', key: "freqReq" },
    ],
    PepRelative: [
      { type: 'required', key: "peprelreq" },
    ],
    MailAddress1: [
      { type: 'required', key: "Mailingline1req" },
    ],
    MailAddress2: [
      { type: 'required', key: "Mailingline2req" },
    ],
    CurntAddress: [
      { type: 'required', key: "Currentreq" },
    ],
    SpouseName: [
      { type: 'required', key: "SpouseNamereq" }
    ],
    Father_Name: [
      { type: 'required', key: "fatherNamereq" }, {
        type: 'pattern', key: 'fathernamepattern',
      },
      {
        type: 'minlength', key: 'fathernamemin'
      }
    ],
    Addressdiffer: [
      { type: 'required', key: "Addrdifferreq" }
    ],
    AddressdCoApplicant: [
      { type: 'required', key: "AddressdCoApplicantreq" }
    ],
    Remittance: [
      { type: 'required', key: "Remittancereq" }
    ],
    Mother_M_name: [
      { type: 'required', key: "M_M_Name" },
      { type: 'pattern', key: 'Alphabetsallow' }
    ],
    CountryofIssue: [
      { type: 'required', key: "CountryofIssue" },
    ],
    Nationality: [
      { type: 'required', key: "Nationreq" },
    ],
    BenName: [
      { type: 'required', key: "BenNamereq" },
      { type: 'minlength', key: "BenNameMinlength" },
      { type: 'pattern', key: 'BenNamePattern' }
    ],
    CNICPassport: [
      { type: 'required', key: "CNICPassportreq" },
      { type: 'minlength', key: "CNICPassportMinlength" },
    ],
    BenBankAddress: [
      { type: 'required', key: "BenBankAddressreq" },
      { type: 'minlength', key: "BenbankAddressMinlength" },
    ],
    BeneAddress: [
      { type: 'required', key: "BeneAddressNamereq" },
      { type: 'minlength', key: "BeneAddressMinlength" },
    ],
    BenContact: [
      { type: 'required', key: "BenContactreq" },
{ type: 'minlength', key: "BenContactMinlength" },
      { type: 'pattern', key: 'Numbersallow' }
    ],
    DrawnCity: [
      { type: 'required', key: "DrawnCityrequired" },
      { type: 'minlength', key: "DrawnCityMinlength" },
    ],
    BeneIdentityType: [
      { type: 'required', key: "BeneIdentityTypereq" },
    ],
    CustomerIdentityType: [
      { type: 'required', key: "CustomerIdentityTypereq" },
    ],
    BeneContact : [
      { type: 'required', key: "BeneContactreq" },
      { type: 'minlength', key: "BeneContactMinlength" },
      { type: 'pattern', key: 'Numbersallow' }
    ],
    CustomerIdentityNo: [
      { type: 'required', key: "CustomerIdentityNoreq" },
      { type: 'minlength', key: "CustomerIdentityNoMinlength" },
      { type: 'pattern', key: 'Numbersallow' }
    ],
    BeneIdentityNo: [
      { type: 'required', key: "BeneIdentityNoreq" },
      { type: 'minlength', key: "BeneIdentityNoMinlength" },
      { type: 'pattern', key: 'Numbersallow' }
    ],
    BenBnkCode: [{ type: 'pattern', key: 'Numbersallow' }],
    BenAccount: [
      { type: 'required', key: "BenAccountReq" },
      { type: 'minlength', key: "BenAccountMinlength" },
    ],
    BankName: [
      { type: 'required', key: "BankNamerequired" },
      { type: 'minlength', key: "BankNameMinlength" },
      { type: 'pattern', key: 'BankNamePattern' }
    ],
    SwiftCode: [
      { type: 'required', key: "SwiftCoderequired" },
      { type: 'minlength', key: "SwiftCodeMinlength" },
      { type: 'pattern', key: 'Numbersallow' }
    ],
    Relationben: [
      { type: 'required', key: "Relationbenrequired" },
      { type: 'minlength', key: "RelationbenMinlength" },
    ],
    InterSwiftCode: [
      { type: 'minlength', key: "InterSwiftCodeMinlength" },
    ],
    Name: [
      { type: 'required', key: 'Nameaspercnicreq' },
      { type: 'pattern', key: 'Alphabetsallow' },
      { type: 'minlength', key: "minlength" }
    ],
    BenTitle: [
      { type: 'required', key: 'BenTitlereq' },
      { type: 'pattern', key: 'Alphabetsallow' },
      { type: 'minlength', key: "minlength" }
    ],
    NameofFundProvider: [
      { type: 'required', key: 'NameofFundReq' }
    ],
    RelationwithAccount: [
      { type: 'required', key: 'RelationwithAccReq' }
    ],
    Cnic: [
      { type: 'required', key: 'cnicreq' },
      { type: 'pattern', key: 'Numbersallow' },
      { type: 'minlength', key: "Idnumber13digits" }
    ],
    projectName:[
      {type:'required',key:'projectnamereq'}
    ],
    ResAddline1: [
      { type: 'required', key: 'ResAddline1req' }
    ],
    SpecifyIncome: [
      { type: 'required', key: 'SpecifyIncomeReq' }
    ],
    ResNearlandmark: [
      { type: 'required', key: 'ResNearlandmarkreq' }
    ],
    OldCnic: [
      { type: 'pattern', key: 'Numbersallow' }
    ],
    ResPhoneNum: [
      { type: 'required', key: 'ResPhoneNumreq' },
      { type: 'pattern', key: 'MobilePattern' }
    ],
    Relationship: [
      { type: 'required', key: 'Relationshipreq' },
      { type: 'pattern', key: 'Alphabetsallow' }
    ],
    InterBankNAme: [
      { type: 'minlength', key: "InterBankNAmeMinlength" },
      { type: 'pattern', key: 'InterBankNamePattern' }
    ],
    BeneCorressBankNAme: [
      { type: 'minlength', key: "BeneCorressBankNAmeMinlength" },
      { type: 'pattern', key: 'BeneCorressBankNAmePattern' }
    ],
    BeneCorsBankAddress: [
      { type: 'minlength', key: "BeneCorsBankAddressMinlength" },
      { type: 'pattern', key: 'BeneCorsBankAddressPattern' }
    ],
    Amountinfigures: [
      { type: 'required', key: 'AmountReq' },
      { type: 'pattern', key: 'Numbersallow' }
    ],
    InterBankAddress: [
      { type: 'minlength', key: "InterBankAddressMinlength" },
      { type: 'pattern', key: 'InterBankAddressPattern' }
    ],
    AuthorizeName: [
      { type: 'pattern', key: 'AuthorizeNamePattern' },
      { type: 'minlength', key: "AuthorizeNameMinlength" },
    ],
    AuthorizeCnic: [
      { type: 'minlength', key: "AuthorizeCnicMinlength" },
    ],
    RemittancePurpose: [
      { type: 'required', key: "RemittanceRequired" },
      { type: 'pattern', key: 'RemittancePattern' },
      { type: 'minlength', key: 'RemittanceMinlength' }
    ],
    RemittanceOtherPurpose: [
      { type: 'required', key: "RemittanceotherRequired" },
      { type: 'pattern', key: 'RemittanceotherPattern' },
    ],
    NameOnCard: [
      { type: 'required', key: "NameOnCardReq" },
      { type: 'minlength', key: 'NameOnCardMinlength' },
      { type: 'pattern', key: 'NameOnCardPattern' }
    ],
    maritalstatus: [
      { type: 'required', key: 'msreq' }
    ],
    empstatus: [
      { type: 'required', key: 'empreq' }
    ],
    emptype: [
      { type: 'required', key: 'emptype' },
      { type: 'pattern', key: 'Companynamepattern' }

    ],
    PreviousEmp: [
      { type: 'pattern', key: 'Companynamepattern' }
    ],
    onlynumber: [
      { type: 'pattern', key: 'Numbersallow' }
    ],
    AnotherPrevEmp: [
      { type: 'required', key: 'AnotherPrevEmpreq' },
      { type: 'pattern', key: 'Companynamepattern' }

    ],
    empLen: [
      { type: 'required', key: 'empLen' }
    ],
    NoOfChild: [
      { type: 'required', key: 'noOfchildreq' },
      { type: 'pattern', key: 'noOfchildpattern' }
    ],
    EduQual: [
      { type: 'required', key: 'EduQualReq' },
      { type: 'pattern', key: "ProfessionPatter" },

    ],
    MonthlyRent: [
      { type: 'required', key: 'monthlyreq' }
    ],
    ResType: [
      { type: 'required', key: 'restypereq' }
    ],
    PreMatureCertNo: [
      { type: 'required', key: "CertfReq" },
      { type: 'minlength', key: 'CertfMinlength' },
      { type: 'pattern', key: 'CertfPattern' }
    ],
    whatido: [

      { type: 'required', key: "Required" },


    ],
    annualIncome: [

      { type: 'required', key: "AnnulaIncomeRequired" },


    ]

    , mobilenumber: [

      { type: 'required', key: "MobileRequired" },
      { type: 'pattern', key: "MobilePattern" },
      { type: 'minlength', key: "MobileMin" },
      { type: 'maxlength', key: "MobileMax" },
    ],
    newmobile: [

      { type: 'required', key: "MobileRequired" },
      { type: 'pattern', key: "MobilePattern" },
      { type: 'minlength', key: "MobileMin" },
      { type: 'maxlength', key: "MobileMax" },
    ],
    currentmobilenumber: [

      { type: 'required', key: "MobileRequired" },
      { type: 'pattern', key: "MobilePattern" },
      { type: 'minlength', key: "MobileMin" },
      { type: 'maxlength', key: "MobileMax" },
    ],
    manufacture: [

      { type: 'required', key: "manufactureReq" },
      { type: 'minlength', key: "minlength" }
    ],
    Manufacture: [

      { type: 'required', key: "ManufactureReq" },
    ],
    Tenure: [

      { type: 'required', key: "TenureReq" },
    ],
    vehicleclass: [

      { type: 'required', key: "vehicleclassReq" },
      { type: 'minlength', key: "minlength" }
    ],
    vehiclePrice: [

      { type: 'required', key: "vehicleclassReq" },
      { type: 'pattern', key: "Numbersallow" },
      { type: 'minlength', key: "minlength" }
    ],
    desiredfinance: [

      { type: 'required', key: "desiredfinanceReq" },
      { type: 'pattern', key: "Numbersallow" },
      { type: 'minlength', key: "minlength" }
    ],
    desiredfinanceperiod: [

      { type: 'required', key: "desiredfinanceperiodreq" }
    ],
    Charity: [
      { type: 'required', key: 'CharityReq' }
    ],
    yearofmanufacture: [

      { type: 'required', key: "yearofmanufactureReq" },
      { type: 'pattern', key: "Numbersallow" }
    ],
    model: [

      { type: 'required', key: "modelReq" },
      { type: 'minlength', key: "minlength" }
    ],
    car: [
      { type: 'required', key: "carReq" },
    ],
    product: [
      { type: 'required', key: "productReq" },
    ],
    amount: [
      { type: 'required', key: "AmountRequired" },
      { type: 'pattern', key: 'Numbersallow' }

    ],
    Netamount: [
      { type: 'required', key: "NetAmountRequired" },
      { type: 'pattern', key: 'Numbersallow' }
    ],
    OtherSourceincom: [
      { type: 'required', key: "OtherSourceincomReq" },
    ],
    password: [
      { type: 'required', key: "PasswordRequired" },
      { type: 'pattern', key: "PasswordPattern" },

    ]
    , inputiban: [
      { type: 'required', key: "Required" },
      { type: 'maxlength', key: "20MaxLenght" },
      { type: 'minlength', key: "12 Max Lenght" },
    ],
    description: [
      { type: 'required', key: "DescriptionRequired" },
      { type: 'maxlength', key: "DescriptionMaxLenght" },
      { type: 'minlength', key: "DescriptionMinLenght" },

    ],

    startDate: [
      { type: 'required', key: "StartDateRequired" },

    ],
    numberOfTransaction: [
      { type: 'required', key: "numberOfTransferRequired" },

    ],
    frequency: [
      { type: 'required', key: "FrequencyRequired" },

    ],
    numberOfTransfer: [
      { type: 'required', key: "numberOfTransferRequired" },

    ],
    nickName: [
      { type: 'required', key: "nickNameRequired" },
      { type: 'maxlength', key: "nickNameMax" },
      { type: 'minlength', key: "nickNameMin" },

    ],
    FirstName: [
      {
        type: 'required', key: 'firstnamerequired',
      }, {
        type: 'pattern', key: 'firstnamepattern',
      },
      {
        type: 'minlength', key: 'firstnamemin'
      },
      {
        type: 'maxlength', key: 'firstnamemax'
      }
    ],
    MiddleName: [
      {
        type: 'required', key: 'middlenamerequired'
      }, {
        type: 'pattern', key: 'middlenamepattern',
      }
    ],
    LastName: [
      {
        type: 'required', key: 'lastnamerequired'
      },
      {
        type: 'minlength', key: 'lastnamemin'
      },
      {
        type: 'maxlength', key: 'lastnamemax'
      },
      {
        type: 'pattern', key: 'lastnamepattern',
      }
    ],
    Gender: [
      {
        type: 'required', key: 'genderrequired'
      }
    ],
    DateofBirth: [
      {
        type: 'required', key: 'DOBrequired'
      }
    ],
    IDExpiry: [
      {
        type: 'required', key: 'idexpirereq'
      }
    ],
    iban: [
      { type: 'required', key: "IbanRequired" },
      { type: 'maxlength', key: "IbanMax" },
      { type: 'minlength', key: "IbanMin" },

    ],
    accountNo: [
      { type: 'required', key: "accNoRequired" },
      { type: 'maxlength', key: "accNoMax" },
      { type: 'minlength', key: "accNoMin" },
      { type: 'pattern', key: "accNoPattern" },

    ],
    cardnumber: [
      { type: 'required', key: "Cardnumberrequired" },
      { type: 'maxlength', key: "Cardnumber13digits" },
      { type: 'minlength', key: "Cardnumber13digits" },
      { type: 'pattern', key: "cardnumberPattern" },
    ],
    IDNumber: [
      { type: 'required', key: "Idnumberrequired" },
      { type: 'minlength', key: "Idnumber13digits" },
    ],
    expirymonth: [
      { type: 'required', key: "expirymonthrequired" },
    ],
    expiryyear: [
      { type: 'required', key: "expiryyearrequired" },
    ],
    cvvnumber: [
      { type: 'required', key: "Cvv" },
      { type: 'maxlength', key: "Cardnumber3digits" },
      { type: 'minlength', key: "Cardnumber3digits" },
    ],
    budget: [

      { type: 'required', key: "budgetRequired" },
      { type: 'minlength', key: "budgetminlength" },
      { type: 'maxlength', key: "budgetMaxLength" },
      { type: 'pattern', key: "budgetPattern" },
    ],
    comment: [

      { type: 'required', key: "commentRequired" },
      { type: 'maxlength', key: "commentMaxLength" },

    ],
    response: [
      { type: 'required', key: "ResponseReq" },
    ],
    ConfirmTerms: [
      { type: 'required', key: "termreq" },
    ],
    bankname: [
      { type: 'required', key: "banknamereq" },
      { type: 'minlength', key: "banknamemin" },
      { type: 'pattern', key: "banknamePattern" }
    ],
    amountbudget: [

      { type: 'required', key: "amountbudgetRequired" },
      { type: 'maxlength', key: "amountbudgetMaxLength" },
      { type: 'minlength', key: "amountbudgetminlength" },
      { type: 'pattern', key: "amountbudgetPattern" },
    ],
    ibanvalid: [
      { type: 'required', key: "ibanRequired" },
      { type: 'maxlength', key: "ibanMaxLength" },
      { type: 'pattern', key: "Invalidiban" },
    ],
    newbeneficiary: [
      { type: 'required', key: "newbeneficiaryRequired" },
      { type: 'maxlength', key: "newbeneficiaryMaxLength" },
    ],
    startdate: [
      { type: 'required', key: "startdateRequired" },

    ],
    enddate: [
      { type: 'required', key: "enddateRequired" },

    ],
    minamount: [
      { type: 'required', key: "minamountRequired" },
      { type: 'maxlength', key: "minamountlength" },
    ],
    maxamount: [
      { type: 'required', key: "maxamountRequired" },
      { type: 'maxlength', key: "maxamountlength" },
    ],
    categroy: [
      { type: 'required', key: "categroyRequired" },

    ],
    text: [
      { type: 'required', key: "textRequired" },

    ], types: [
      { type: 'required', key: "typeRequired" },

    ], fullName: [
      { type: 'required', key: "fullNameRequired" },

    ], benificiaryadderss: [
      { type: 'required', key: "benificiaryadderss" },
    ], valiBranchName: [
      { type: 'required', key: "BranchNameIsRequired" }
    ], valiDate: [
      { type: 'required', key: "DateIsRequired" },
      { type: 'pattern', key: "PleaseEnterCorrectDate" }
    ],
    segmentType: [
      {
        type: 'required', key: "segmentreq"
      },
      { type: 'pattern', key: 'Alphabetsallow' }
    ],
    Carvariant: [
      {
        type: 'required', key: "carvarireq"
      },
    ],
    CustomerContribution: [
      {
        type: 'required', key: "custconreq"
      },
    ],
    totalyear: [
      {
        type: 'required', key: "totalyearreq"
      },
    ],
    totalmonth: [
      {
        type: 'required', key: "totalmonthreq"
      },
    ],
    NoOfEmp: [
      {
        type: 'required', key: "noofempreq"
      },
      { type: 'pattern', key: 'Numbersallow' }

    ],
    entitype: [
      {
        type: 'required', key: "entitypereq"
      },
      { type: 'pattern', key: 'Numbersallow' }
    ],
    Share: [
      {
        type: 'required', key: "sharereq"
      },
      { type: 'pattern', key: 'Numbersallow' }

    ],
    Turnover: [
      {
        type: 'required', key: "Turnoverreq"
      },
      { type: 'pattern', key: 'Numbersallow' }

    ],
    Propertyype: [
      {
        type: 'required', key: "Propertypereq"
      },
    ],
    PropertySize: [
      {
        type: 'required', key: "Propertysizereq"
      },
      { type: 'pattern', key: 'Numbersallow' }

    ],
    officepropert: [
      {
        type: 'required', key: "officepropertreq"
      },
    ],
    Rent: [
      {
        type: 'required', key: "Rentreq"
      },
      { type: 'pattern', key: 'Numbersallow' }

    ],
    BookingSeg: [
      {
        type: 'required', key: "bookingreq"
      },
    ],
    Takaful: [
      {
        type: 'required', key: "takafulreq"
      },
    ],
    ExpectedAmount:[
      {
        type:'required',key:"RequiredMessage",

      },
      {
        type: 'pattern', key: 'Numbersallow'
      },
      { type: 'minlength', key: "minAmount" },
      { type: 'maxlength', key: "maxAmount" },
    ],
    RetirementAge:[
      {
        type:'required',key:"RequiredMessage",

      },
    ],
    MonthlySaving: [
      { type: 'required', key: 'monthlysaving' }
    ],

    InvestmentObjective:[
      { type: 'required', key: 'investmentObjreq' }
    ],
    KnowledgeofInvestment:[
      { type: 'required', key: 'knowledgeofinvest' }
    ],
    InvestmentHorizon:[
      { type: 'required', key: 'investmenthorizon' }
    ],
   Nextofkin:[
    {type:'required',key:'nextofkinrequired'},
    {
      type: 'pattern', key: 'Numbersallow'
    },
    
   ],
   FullName:[
    {type:'required',key:'fullnameVpsrequired'},
    { type: 'pattern', key: 'Alphabetsallow' }
    
   ],
   CnicPassport:[
    {type:'required',key:'cnic/passport'},    
   ],
   RelationShip:[
    {type:'required',key:'relation'}    
   ],
   ContactNumber:[
    {type:'required',key:'contactnumber'},
    { type: 'pattern', key: 'MobilePattern' }


   ],
   AddressVps:[
    {type:'required',key:'AddressVps'},

   ]

  }
}
