import { Component, Input, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from './../Services/auth.service';
import { CommonService } from './../Services/common.service';
import { ConfigService } from '../Services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { KycService } from './../Services/kyc.service';
import { CountriesService, Country } from '../Services/countries.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocationStrategy } from '@angular/common';
import { Guid } from 'guid-typescript';
import { ValidationError } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-verify-change-password-otp',
  templateUrl: './verify-change-password-otp.component.html',
  styleUrls: ['./verify-change-password-otp.component.scss']
})
export class VerifyChangePasswordOtpComponent implements OnInit {

  counter: string;
  current: string;
  max: string;
  submitAttempt: boolean = false;
  registerForm: FormGroup;
  numbertext: string;
  timeoutHandle;
  timerexpire: string;
  public verify: string = '';
  id: Guid;
  uniqueId: string;
  loginResponse;
  resendOptbtnShow: boolean = false;
  code: string;
  idnumber;

  constructor(
    private kyc: KycService,
    private country: CountriesService,
    public errorKey: ErrorMessageKeyService,
    private translate: TranslateService,
    private commonP: CommonService,
    private auth: AuthService,
    private config: ConfigService,
    private route: ActivatedRoute,
    private _router: Router,
    private formBuilder: FormBuilder,
    private location: LocationStrategy,
    public Renderer2: Renderer2,
    public countries: CountriesService,

  ) {
    this.registerForm = this.formBuilder.group({
      otp: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
    })
  }

  email: string
  async ngOnInit(): Promise<void> {
    this.startTime();
    this.commonP.Set("Auth" , "");
    this.counter = "2";
    this.current = "2";
    this.max = "3";


    let a = await this.commonP.Get('MobileNumber');
    let d = await this.commonP.Get('Idnumber');

    let em = await this.commonP.Get('Email');


    if (a) {
      this.numbertext = a;
    }
    if (d) {
      this.idnumber = d;
    }

    if (em) {
      this.email = em;
    }
  }


  startTime() {

    var str = "0:59";

    var array = str.split(":");
    this.countdown(parseInt(array[0]), parseInt(array[1]));
  }

  getMessage(key, control) {

    let error = "";

    this.translate.get([key]).subscribe(data => {

      error = data[key];
    })
    return error;
  }


  isFormValid() {
    if (!this.registerForm.valid) {
      return true;
    } else {
      false;
    }
  }

  clearAll() {
    this.verify = "";
  }

  countdown(minutes, seconds) {
    this.timeoutHandle;
    var that = this;
    function tick() {




      that.timerexpire = minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
      seconds--;
      if (seconds >= 0) {

        that.timeoutHandle = setTimeout(tick, 1000);
      } else {
        if (minutes >= 1) {

          setTimeout(function () {
            that.countdown(minutes - 1, 59);
          }, 1000);
        }
      }

      if (that.timerexpire == "0:00") {


        that.resendOptbtnShow = true;
      }
    }
    tick();


  }

  clear() {
    this.code = "";
    this.registerForm.reset();
  }

  SendAgain() {
    debugger
    this.id = Guid.create();
    this.uniqueId = this.id.toString();

    this.commonP.presentLoading();

    this.auth.SendForgotPasswordOTP(this.idnumber, this.uniqueId).then((data: any) => {
      if (data?.response?.code == 1) {
        debugger
        this.commonP.hideLoading();
        this.translate.get(["Success", "Ok", "ResendSuccess"]).subscribe((data) => {
          this.commonP.showSuccessAlert(data["Success"], data["ResendSuccess"], data["Ok"], this._router);
          this.startTime();
          this.resendOptbtnShow = false;
          this.clear();

        })
      }
      else {
        this.commonP.hideLoading();
        this.commonP.showErrorAlert("Error", data?.response?.message, "Ok",this._router);
        this.commonP.ParseErrorAlert("", "", this._router, data);
      }
    })
  }

  Next() {

    this.id = Guid.create();
    this.uniqueId = this.id.toString();

    var a = this.registerForm.get('otp').value;
    this.commonP.presentLoading();

    this.commonP.Set('Auth',this.uniqueId);
    this.auth.VerifyForgotPasswordOTP(this.idnumber, this.uniqueId, a).then((data: any) => {
      if (data?.response?.code == 1) {
        this.commonP.hideLoading();
        this.commonP.Set('Random', a);
        this._router.navigateByUrl('/reset-password');

      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert('', '', this._router, data);

      }
    })
  }

}
