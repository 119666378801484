import { Injectable } from '@angular/core';
import { HttpServiceService } from './http-service.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class RemittanceTrackerService {
  public RemittanceTrackerUrl: string = "Auth/RemittanceTracker";
  constructor(private http: HttpServiceService,private config: ConfigService,) { }
  public RemittanceTracker(body) {
    debugger
    console.log('RemittanceTrackerObject',body)
    return new Promise((resolve, reject) => {
      
      this.http.postToOpenApi(this.config.server + this.RemittanceTrackerUrl, body).subscribe((data: any) => {
        if (data?.response?.code == 1) {
        }
        resolve(data);
        console.log('RemittanceTracker',data);
      },
        Error => {
          resolve(Error);

        console.log('RemittanceTrackerError',Error);

        })
    });
  }
}
