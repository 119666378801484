import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from "guid-typescript";
import { AuthService } from '../Services/auth.service';
import { EFormServiceService } from '../Services/eform-service.service';
import { AmountInwordsPipe } from '../amount-inwords.pipe';
import { CommonService } from './../Services/common.service';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';

@Component({
  selector: 'app-qurbani-form',
  templateUrl: './qurbani-form.component.html',
  styleUrls: ['./qurbani-form.component.scss']
})
export class QurbaniFormComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private commonP: CommonService,
    public errorKey: ErrorMessageKeyService,
    public formBuilder: FormBuilder,
    private _router: Router,
    public auth: AuthService,
    public Eform: EFormServiceService,
    private AmountInwordsPipe: AmountInwordsPipe,
  ) { }

  selectedAccontfromAccount;
  AccountBalance;
  appliedRate;
  amount;
  selectedCurrency;
  QurbaniRatesChart;
  QurbaniTypes;
  QurbaniServType;
  QurbaniServPro;
  SelectedQurbaniType;
  allQurbaniTypes;
  SelectedSP;
  SelectedST;
  AnimalQuantity;
  QurbaniDay;
  dropdownServiceTypeData:any = [];
  async ngOnInit(): Promise<void> {

    this.auth.accessToken = await this.auth.Get('token');
    this.auth.data = JSON.parse(await this.auth.Get("User"));

    this.auth.BalanceInquiry(Guid.create().toString(), this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {
        this.auth.AllBalance = data.response.content;

        this.Eform.GetQurbaniFields(this.auth.accessToken).then((data: any) => {
          if (data?.response?.code == 1) {
            debugger
            this.allQurbaniTypes = data.response.content.qurbaniType;
            this.QurbaniTypes = data.response.content.qurbaniType.filter(x => x.forChart == true);
            this.QurbaniServType = data.response.content.qurbaniServiceType;
            //this.QurbaniServPro =  data.response.content.qurbaniServProvider;
            
            this.commonP.hideLoading();

          }
          else {
            this.commonP.hideLoading();
            this.commonP.ParseErrorAlert('', '', this._router, data);
          }
        })
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert('', '', this._router, data);
      }
    })
  }

  QurbaniRates;
  QurbaniTypeSelection() {
    debugger
    this.commonP.presentLoading();
    this.Eform.GetQurbaniRates(this.SelectedQurbaniType, this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {
        console.log('QurbaniRatesData',data);
        
         this.QurbaniRates = data.response.content.rates.result;
         console.log("datahere",this.QurbaniRates);
         
         this.QurbaniRatesChart = data.response.content.rates.result;
         this.QurbaniServPro = data.response.content.rates.result;

        // this.QurbaniRatesChart.forEach(element => {
        //   element.name = element.name.toString().toUpperCase();
        //   element.rateLists.forEach(element => {
        //     element.typeWise.forEach(element => {
        //       element.data.map(x => x.qtid = this.allQurbaniTypes.find(v => v.qtId == x.qtid).name);
        //     });
        //   });
        // });

        this.commonP.hideLoading();
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert('', '', this._router, data);
      }
    })
  }

  AllRate;

  ValueChange() {
    this.commonP.presentLoading()
    this.AccountBalance = this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).balance;
    this.selectedCurrency = this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).currency;

    this.auth.GetAllRate(this.auth.accessToken, Guid.create().toString()).then((data: any) => {
      if (data?.response?.code == 1) {
        this.commonP.hideLoading();
        this.AllRate = data.response.content.allRate;

        this.currencylogic();
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert("", "", this._router, data)
      }
    });

    this.SelectedSP = undefined;
    this.selectedST = undefined;
    this.SelectedQurbaniType = undefined;
    this.Branch = undefined;
    this.DonationType = undefined;
    this.City = undefined;
    this.otherContact = undefined;
    this.otherName = undefined;
    this.OtherAddress = undefined;
  }

  rateToDisplay;
  convertedRate;

  currencylogic() {
debugger
    this.appliedRate = "";

    if (this.selectedCurrency == "PKR") {
      this.appliedRate = "1";
      this.rateToDisplay = "1";
      if (this.amount) {
        this.convertedRate = Number(this.amount.toString().replace(/[^0-9.]/g, ""));
      }
    }
    else {
      if (this.AllRate) {

        let a = this.AllRate.find(x => x.ccY_Sale == this.selectedCurrency && x.ccY_Buy == "PKR");
        if (a) {
          this.appliedRate = a.rateBuy;
          this.rateToDisplay = a.rateBuy;
          if (this.amount) {
            var _amount = this.amount.toString().replace(/[^0-9.]/g, "")
            if (_amount) {
              this.convertedRate = (Number(_amount) / Number(a.rateBuy)).toFixed(2);
            }
          }
        }
        else {
          a = this.AllRate.find(x => x.ccY_Buy == this.selectedCurrency && x.ccY_Sale == "PKR");
          if (a) {
            this.appliedRate = (1 / Number(a.rateSale)).toFixed(4) + "";
            this.rateToDisplay = a.rateSale;
            if (this.amount) {
              var _amount = this.amount.toString().replace(/[^0-9.]/g, "")
              if (_amount) {
                this.convertedRate = (Number(_amount) / Number(a.rateSale)).toFixed(2);
              }
            }
          }
        }
      }
    }
  }

  DonationType;
  selectedST;
  City;
  otherContact;
  otherName;
  OtherAddress;
  Branch;
  QurbaniConfig;
  servicetypelist: string[] = [];
  SpOutlets;
  CityList: string[] = [];

  change() {
    this.DonationType = undefined;
    this.selectedST = undefined;
    this.City = undefined;
    this.otherContact = undefined;
    this.otherName = undefined;
    this.OtherAddress = undefined;
    this.Branch = undefined;
    this.servicetypelist = [];
    this.QurbaniServType=[]
    debugger
    this.dropdownServiceTypeData = this.QurbaniRates.filter(sa => sa.spid === this.SelectedSP)[0];
    // this.commonP.presentLoading();
    // this.Eform.GetQurbaniConfig(this.SelectedSP, this.auth.accessToken).then((data: any) => {
    //   if (data?.response?.code == 1) {
    //     this.QurbaniConfig = data.response.content;
    //     this.QurbaniConfig.forEach(element => {
    //       element.data.forEach(element => {
    //         this.servicetypelist.push(element.st);
    //       });
    //     });

    //     if (this.SelectedSP == 1101) {
    //       this.Eform.GetSpOutletsList(this.auth.accessToken).then((data: any) => {
    //         if (data?.response?.code == 1) {

    //           this.SpOutlets = data.response.content;
    //           this.SpOutlets.forEach(element => {
    //             element.data.forEach(element => {
    //               this.CityList.push(element.city)
    //             });
    //           });
    //         }
    //         else {
    //           this.commonP.hideLoading();
    //           this.commonP.ParseErrorAlert("", "", this._router, data);
    //         }
    //       })
    //     }
    //     this.commonP.hideLoading();
    //   }
    //   else {
    //     this.commonP.hideLoading();
    //     this.commonP.ParseErrorAlert("", "", this._router, data);
    //   }
    // })
  }
  agree;
  NumberOfShare;
  Varity;
  BranchList;

  STChange() {
    debugger
    this.City = undefined;
    this.Branch = undefined;
    this.OutletAddress = undefined;

    if (this.SelectedSP != 1104 && this.SelectedSP != 1105 && this.SelectedSP != 1101) {
      console.log('kashif',this.QurbaniRates);
      console.log('kashif',this.QurbaniServPro);
      let find=this.QurbaniServPro.find(v => v.spid == this.SelectedSP);
      var a = this.QurbaniRates.filter(x => x.SelectedSP.toUpperCase() == find?.selectedST.toUpperCase());
      var qurbaniname = this.allQurbaniTypes.find(v => v.qtId == this.SelectedQurbaniType).serviceProvider;
      var servicetypeid = this.QurbaniServType.find(f => f.serviceProvider.toUpperCase() == this.selectedST.toUpperCase()).stId;
      a.forEach(element => {
        debugger;
        element.rateLists.forEach(element1 => {
          debugger
          var collect = element1.typeWise.find(c => c.name.toUpperCase() == this.selectedST.toUpperCase());
          var rateelement = collect.data.find(c =>
            c.qtid.toUpperCase() == qurbaniname.toUpperCase() &&
            c.stid == servicetypeid);
          if (rateelement) {
            this.amount = rateelement.rate;

            this.currencylogic();

            if (Number(this.convertedRate) > Number(this.AccountBalance)) {
              this.commonP.showErrorAlert("Sorry", "You have an insufficient balance", "OK", this._router);
            }
          }
        });
      });
    }
  }

  NumberofsharesArray: number[] = [];
  TotalAmount;

  NumberOfShareChange() {

    this.NumberofsharesArray = [];
    var namelist = this.NamesOfSharinglist;

    for (var i = 1; i <= this.NumberOfShare; i++) {
      this.NumberofsharesArray.push(i);
      this.NamesOfSharinglist.splice(this.NumberOfShare);

    }

    var _amout;
    if (this.amount) {
      _amout = this.amount.toString().replace(/[^0-9.]/g, "");
    }
    this.amount = Number(_amout) * this.NumberOfShare;
    this.currencylogic();
    if (Number(this.convertedRate) > Number(this.AccountBalance)) {
      this.commonP.showErrorAlert("Sorry", "You have an insufficient balance", "OK", this._router);
    }
  }

  NamesOfSharinglist: string[] = [];
  ShareNames(e, index) {
    if (this.NamesOfSharinglist[index]) {
      this.NamesOfSharinglist[index] = e.target.value;
    }
    else {
      this.NamesOfSharinglist.push(e.target.value)
    }
  }

  cityselect() {
    if (this.City) {
      this.BranchList = this.SpOutlets[0].data.filter(x => x.city == this.City)[0].data;
    }

    if (this.SelectedSP == 1101) {
      var a = this.QurbaniRates.filter(x => x.name.toUpperCase() == this.QurbaniServPro.find(v => v.spid == this.SelectedSP).serviceProvider.toUpperCase());
      var qurbaniname = this.allQurbaniTypes.find(v => v.qtId == this.SelectedQurbaniType).name;
      var servicetypeid = this.QurbaniServType.find(f => f.name.toUpperCase() == this.selectedST.toUpperCase()).stId;
      a.forEach(element => {
        element.rateLists.forEach(element1 => {
          var collect = element1.typeWise.find(c => c.name.toUpperCase() == this.selectedST.toUpperCase());
          var rateelement = collect.data.find(c =>
            c.qtid.toUpperCase() == qurbaniname.toUpperCase() &&
            c.stid == servicetypeid
            && c.label == this.City);
          if (rateelement) {
            this.amount = rateelement.rate;

            this.currencylogic();

            if (Number(this.convertedRate) > Number(this.AccountBalance)) {
              this.commonP.showErrorAlert("Sorry", "You have an insufficient balance", "OK", this._router);
            }
          }
        });
      });
    }
  }

  varitySelect() {

    if (this.SelectedSP == 1104 || this.SelectedSP == 1105) {
      var a = this.QurbaniRates.filter(x => x.name.toUpperCase() == this.QurbaniServPro.find(v => v.spid == this.SelectedSP).serviceProvider.toUpperCase());
      var servicetypeid = this.QurbaniServType.find(f => f.name.toUpperCase() == this.selectedST.toUpperCase()).stId;

      var qurbaniname = this.allQurbaniTypes.find(v => v.qtId == this.SelectedQurbaniType).name;

      var thisqurbaniname = qurbaniname + " " + this.Varity;

      a.forEach(element => {

        element.rateLists.forEach(element1 => {
          var collect = element1.typeWise.find(c => c.name.toUpperCase() == this.selectedST.toUpperCase());
          var rateelement = collect.data.find(c =>
            c.qtid.toUpperCase() == thisqurbaniname.toUpperCase() &&
            c.stid == servicetypeid);
          if (rateelement) {

            this.amount = rateelement.rate;

            this.currencylogic();

            if (Number(this.convertedRate) > Number(this.AccountBalance)) {
              this.commonP.showErrorAlert("Sorry", "You have an insufficient balance", "OK", this._router);
            }
          }
        });
      });
    }
  }

  MailAddressDiffer = false;
  changeStatus(e) {
    var check = e.detail.checked;
    if (check == true) {
      this.MailAddressDiffer = true
    } else {
      this.MailAddressDiffer = false
    }
  }

  OutletAddress;
  branchselect() {

    if (this.Branch) {
      this.OutletAddress = this.BranchList.filter(x => x.location == this.Branch)[0].address
    }
  }

  DelMailAddress;
  listisfullcount = 0;
  anotherlistisfullcount = 0;

  isformvalid() {
    if (this.AccountBalance && this.SelectedQurbaniType && this.SelectedSP && this.selectedST) {
      if (this.SelectedQurbaniType == 501) {
        this.listisfullcount = 0;
        if (this.NumberOfShare) {
          this.NamesOfSharinglist.forEach(element => {
            if (element.length > 0) {
              this.listisfullcount++;
            }
          });
          if (this.NamesOfSharinglist.length == 0) {
            return true
          }
          else if (this.listisfullcount == this.NumberOfShare) {
            if (this.SelectedSP == 1101) {
              if (this.City) {
                if (this.selectedST.toUpperCase() == "CHARITY") {
                  if (this.DonationType.toUpperCase() == "SELF") {
                    return false
                  }
                  else if (this.DonationType.toUpperCase() == "OTHER") {
                    if (this.otherName && this.otherContact && this.OtherAddress) {
                      return false
                    }
                    else {
                      return true
                    }
                  }
                  else {
                    return true
                  }
                }
                else if (this.selectedST.toUpperCase() == "COLLECTION") {
                  if (this.Branch && this.OutletAddress) {
                    return false
                  }
                  else {
                    return true
                  }
                }
                else if (this.selectedST.toUpperCase() == "DELIVERY") {
                  if (this.MailAddressDiffer) {
                    if (this.DelMailAddress) {
                      return false
                    }
                    else {
                      return true
                    }
                  }
                  else {
                    return true
                  }
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else if (this.SelectedSP == 1102) {
              if (this.selectedST.toUpperCase() == "CHARITY") {
                if (this.DonationType.toUpperCase() == "SELF") {
                  return false
                }
                 if (this.DonationType.toUpperCase() == "OTHER") {
                  if (this.otherName && this.otherContact && this.OtherAddress) {
                    return false
                  }
                  else {
                    return true
                  }
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else if (this.SelectedSP == 1103) {
              if (this.agree && this.QurbaniDay) {
                if (this.selectedST.toUpperCase() == "CHARITY") {
                  if (this.DonationType.toUpperCase() == "SELF") {
                    return false
                  }
                  else if (this.DonationType.toUpperCase() == "OTHER") {
                    if (this.otherName && this.otherContact && this.OtherAddress) {
                      return false
                    }
                    else {
                      return true
                    }
                  }
                  else {
                    return true
                  }
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else if (this.SelectedSP == 1103 ||this.SelectedSP == 1104 || this.SelectedSP == 1105) {
              if (this.Varity) {
                if (this.selectedST.toUpperCase() == "CHARITY") {
                  if (this.DonationType.toUpperCase() == "SELF") {
                    return false
                  }
                  else if (this.DonationType.toUpperCase() == "OTHER") {
                    if (this.otherName && this.otherContact && this.OtherAddress) {
                      return false
                    }
                    else {
                      return true
                    }
                  }
                  else {
                    return true
                  }
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else {
              return false
            }
          }
          else {
            return true
          }
        }
        else {
          return true
        }
      }
      else if (this.SelectedQurbaniType == 101) {
        this.anotherlistisfullcount = 0;
        this.NamesOfSharinglist.forEach(element => {
          if (element.length > 0) {
            this.anotherlistisfullcount++;
          }
        });
        if (this.NamesOfSharinglist.length == 0) {
          return true
        }
        else if (this.anotherlistisfullcount == 7) {
          if (this.SelectedSP == 1101) {
            if (this.City) {
              if (this.selectedST.toUpperCase() == "CHARITY") {
                if (this.DonationType.toUpperCase() == "SELF") {
                  return false
                }
                else if (this.DonationType.toUpperCase() == "OTHER") {
                  if (this.otherName && this.otherContact && this.OtherAddress) {
                    return false
                  }
                  else {
                    return true
                  }
                }
                else {
                  return true
                }
              }
              else if (this.selectedST.toUpperCase() == "COLLECTION") {
                if (this.Branch && this.OutletAddress) {
                  return false
                }
                else {
                  return true
                }
              }
              else if (this.selectedST.toUpperCase() == "DELIVERY") {
                if (this.MailAddressDiffer) {
                  if (this.DelMailAddress) {
                    return false
                  }
                  else {
                    return true
                  }
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else {
              return true
            }
          }
          else if (this.SelectedSP == 1102) {
            if (this.selectedST.toUpperCase() == "CHARITY") {
              if (this.DonationType.toUpperCase() == "SELF") {
                return false
              }
              else if (this.DonationType.toUpperCase() == "OTHER") {
                if (this.otherName && this.otherContact && this.OtherAddress) {
                  return false
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else {
              return true
            }
          }
          else if (this.SelectedSP == 1103) {
            if (this.agree && this.QurbaniDay) {
              if (this.selectedST.toUpperCase() == "CHARITY") {
                if (this.DonationType.toUpperCase() == "SELF") {
                  return false
                }
                else if (this.DonationType.toUpperCase() == "OTHER") {
                  if (this.otherName && this.otherContact && this.OtherAddress) {
                    return false
                  }
                  else {
                    return true
                  }
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else {
              return true
            }
          }
          else if (this.SelectedSP == 1104 || this.SelectedSP == 1105) {
            if (this.Varity) {
              if (this.selectedST.toUpperCase() == "CHARITY") {
                if (this.DonationType.toUpperCase() == "SELF") {
                  return false
                }
                else if (this.DonationType.toUpperCase() == "OTHER") {
                  if (this.otherName && this.otherContact && this.OtherAddress) {
                    return false
                  }
                  else {
                    return true
                  }
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else {
              return true
            }
          }
          else {
            return false
          }
        }
        else {
          return true
        }
      }
      else {
        if (this.SelectedSP == 1101 ) {
          if (this.City) {
            if (this.selectedST.toUpperCase() == "CHARITY") {
              if (this.DonationType.toUpperCase() == "SELF") {
                return false
              }
              else if (this.DonationType.toUpperCase() == "OTHER") {
                if (this.otherName && this.otherContact && this.OtherAddress) {
                  return false
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else if (this.selectedST.toUpperCase() == "COLLECTION") {
              if (this.Branch && this.OutletAddress) {
                return false
              }
              else {
                return true
              }
            }
            else if (this.selectedST.toUpperCase() == "DELIVERY") {
              if (this.MailAddressDiffer) {
                if (this.DelMailAddress) {
                  return false
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else {
              return true
            }
          }
          else {
            return true
          }
        }
        else if (this.SelectedSP == 1102) {
          if (this.selectedST.toUpperCase() == "CHARITY") {
            if (this.DonationType.toUpperCase() == "SELF") {
              return false
            }
            else if (this.DonationType.toUpperCase() == "OTHER") {
              if (this.otherName && this.otherContact && this.OtherAddress) {
                return false
              }
              else {
                return true
              }
            }
            else {
              return true
            }
          }
          else {
            return true
          }
        }
        else if (this.SelectedSP == 1103) {
          if (this.agree && this.QurbaniDay) {
            if (this.selectedST.toUpperCase() == "CHARITY") {
              if (this.DonationType.toUpperCase() == "SELF") {
                return false
              }
              else if (this.DonationType.toUpperCase() == "OTHER") {
                if (this.otherName && this.otherContact && this.OtherAddress) {
                  return false
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else {
              return true
            }
          }
          else {
            return true
          }
        }
        else if (this.SelectedSP == 1104) {
          if (this.Varity) {
            if (this.selectedST.toUpperCase() == "CHARITY") {
              if (this.DonationType.toUpperCase() == "SELF") {
                return false
              }
              else if (this.DonationType.toUpperCase() == "OTHER") {
                if (this.otherName && this.otherContact && this.OtherAddress) {
                  return false
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else {
              return true
            }
          }
          else {
            return true
          }
        }
        else if (this.SelectedSP == 1105) {
          if (this.Varity && this.AnimalQuantity) {
            if (this.selectedST.toUpperCase() == "CHARITY") {
              if (this.DonationType.toUpperCase() == "SELF") {
                return false
              }
              else if (this.DonationType.toUpperCase() == "OTHER") {
                if (this.otherName && this.otherContact && this.OtherAddress) {
                  return false
                }
                else {
                  return true
                }
              }
              else {
                return true
              }
            }
            else {
              return true
            }
          }
          else {
            return true
          }
        }
        else {
          return false
        }
      }
    }
    else {
      return true
    }
  }

  labelLink1(e) {
    e.preventDefault();

    window.open("../../../assets/Saylani-WaqalatNama.pdf", '_blank');
  }

  AnimalQuantityChange() {
    this.amount = Number(this.amount.toString().replace(/[^0-9.]/g, "")) * this.AnimalQuantity
  }

  saveinfo() {
    debugger
    var object = this.Eform.savequrbanirequestmodel;
    console.log(this.Eform.savequrbanirequestmodel,"saveinfo");

    debugger
    // object.debitAccount = Number(this.amount.toString().replace(/[^0-9.]/g, "")),
    //   object.AmountInWords = this.AmountInwordsPipe.transform(this.amount).toString(),
      object.ChannelType = "Web",
      object.CollectAddress = (this.SelectedSP == 1101) ? (this.OutletAddress ? this.OutletAddress : "") : "",
      object.CollectCity = (this.SelectedSP == 1101) ? (this.City ? this.City : "") : "",
      object.CollectLocation = (this.SelectedSP == 1101) ? (this.Branch ? this.Branch : "") : "",
      object.CreditCurr = "PKR",
      object.DebitAccount = this.selectedAccontfromAccount,
      object.DebitCurr = this.selectedCurrency,
      object.DelMailAddress = (this.SelectedSP == 1101) ? (this.DelMailAddress ? this.DelMailAddress : "") : "",
      object.DonationAddress = (this.DonationType.toUpperCase() == "OTHER") ? (this.OtherAddress ? this.OtherAddress : "") : "",
      object.DonationContact = (this.DonationType.toUpperCase() == "OTHER") ? (this.otherContact ? this.otherContact : "") : "",
      object.DonationPerson = (this.DonationType.toUpperCase() == "OTHER") ? (this.otherName ? this.otherName : "") : "",
      object.DonationType = this.DonationType,
      object.ExRate = Number(this.appliedRate),
      object.LocalEq = Number(this.convertedRate) > 0 ? Number(this.convertedRate) : 0,
      object.QTName = this.allQurbaniTypes.find(v => v.qtId == this.SelectedQurbaniType).name,
      object.QurbaniDay = (this.SelectedSP == 1103) ? (this.QurbaniDay ? this.QurbaniDay : "") : "",
      object.QurbaniShare = (this.SelectedQurbaniType == 102) ? (this.NumberOfShare ? Number(this.NumberOfShare) : 0) : 0;
      object.SPName=(this.SelectedSP)
    if (this.SelectedQurbaniType == 501 || this.SelectedQurbaniType == 502) {
      this.NamesOfSharinglist.forEach(element => {
        if (object.QurbaniShareNames) {
          object.QurbaniShareNames = object.QurbaniShareNames + "," + element
        }
        else {
          object.QurbaniShareNames = element
        }
      });
    }

    object.AnimalQuantity = this.AnimalQuantity ? Number(this.AnimalQuantity) : 0;
    object.SPName = this.QurbaniServPro.find(v => v.spid == this.SelectedSP).serviceProvider,
      object.STName = this.selectedST,
      object.UserId = this.auth.data.userId;
  }


  forward() {
    debugger
    this.commonP.presentLoading();
    this.saveinfo();

    this.Eform.SaveQurbaniRequest(this.Eform.savequrbanirequestmodel, this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {
        this.commonP.hideLoading();
        this.commonP.Set('QurbaniRefId', data.response.content.referenceId);
        this._router.navigateByUrl('/QurbaniFormSuccess');
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert("", "", this._router, data)
      }
    })
  }
}
