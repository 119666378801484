<app-header3></app-header3>

<div class="container mt-46">
  <div class="ng-row">
    <div class="ng-col" col-3 col-sm-12>
    </div>
    <div class="ng-col pt-30 pd-sm-30" col-7 col-sm-12>

      <div class="ng-row pb-30">
        <div class="ng-col">
          <div class="font-30 color-2f5 font-600 mt-5">
            {{"RDAADCServicesForm" | translate}}
          </div>

          <form [formGroup]="RdaAdcServiceForm">

            <div class="form-group mt-30">
              <label>{{"BasicAccountNumber" | translate}}</label>
              <select class="custom-select" (change)="ValueChange()" [(ngModel)]="selectedAccont"
                formControlName="BasicAccNo">
                <option disabled hidden value="undefined" selected>Please select</option>
                <option *ngFor="let data of this.auth.AllBalance" [ngValue]="data.accountNumber">{{data.accountNumber}}
                </option>
              </select>
              <ng-container item-content *ngFor="let validation of errorKey.validation_messages.accountNo">
                <span class="error-label"
                  *ngIf="RdaAdcServiceForm.controls.BasicAccNo.hasError(validation.type) &&(RdaAdcServiceForm.controls.BasicAccNo.touched ||submitAttempt)">
                  {{ getMessage(validation.key,'accountNo') }}
                </span>
              </ng-container>
            </div>


            <ng-container *ngIf="selectedAccont">
              <ng-container *ngIf="this.PkrAccount">
                <div class="font-20 color-354 font-600 mt-30">
                  {{"CardInformation" | translate}}
                </div>

                <div class="form-group mt-20">
                  <label class="no-mark">{{"Nametoappearoncard" | translate}}</label>
                  <input type="text" placeholder="Enter Name" maxlength="26" minlength="3" (change)="NameChange()"
                    formControlName="NameOnCard">
                  <div class="hint-text mt-10">
                    <ul>
                      <li>{{'HintName'| translate}}</li>
                    </ul>
                  </div>
                  <ng-container item-content *ngFor="let validation of errorKey.validation_messages.NameOnCard">
                    <span class="error-label"
                      *ngIf="RdaAdcServiceForm.controls.NameOnCard.hasError(validation.type) &&(RdaAdcServiceForm.controls.NameOnCard.touched ||submitAttempt)">
                      {{ getMessage(validation.key,'NameOnCard') }}
                    </span>
                  </ng-container>
                </div>

                <div class="form-group mt-30">
                  <div class="ng-row">
                    <div class="ng-col mb-15" col-12>
                      <div class="font-16 font-600 color-2d3">{{"DebitCardOptions" | translate}} </div>


                    </div>
                    <div class="ng-col" col-auto>
                      <div class="custom-checkbox">
                        <input type="radio" value="UPISilver" id="label-1" formControlName="DebitCard">
                        <label for="label-1" class="color-black pt-5 pl-35">
                          {{"UPISilver" | translate}}
                        </label>
                      </div>
                    </div>
                    <div class="ng-col pl-15" col-auto>
                      <div class="custom-checkbox">
                        <input type="radio" value="UPIGold" id="label-3" formControlName="DebitCard">
                        <label for="label-3" class="color-black pt-5 pl-35">
                          {{"UPIGold" | translate}}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="form-group mt-42 custom-checkbox">
                  <div class="ng-row">
                    <div class="ng-col" col-auto>
                      <input type="checkbox" (change)="changeAgree($event)" value="" id="label-0001">
                      <label for="label-0001" class="pt-0 line-h-20">
                        {{"Iconsenttordaadc" | translate}}
                        <span class="label-link color-6F2" (click)="labelLink2($event)"> {{"English" |
                          translate}}</span>
                        <span class="label-link color-6F2" (click)="labelLink1($event)"> {{"Urdu" | translate}}</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group mt-20 custom-checkbox">
                  <div class="ng-row">
                    <div class="ng-col" col-auto>
                      <input type="checkbox" (change)="changeAgree1($event)" value="" id="label-0002">
                      <label for="label-0002" class="pt-0 line-h-20">
                        {{"Iconsenttordaadc2" | translate}}
                      </label>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container>
                <div class="form-group mt-30">
                  <div class="ng-row">
                    <div class="ng-col mb-15" col-12>
                      <div class="font-20 font-600 color-2d3">{{"ChequeBookLeaves" | translate}} </div>


                    </div>
                    <div class="ng-col" col-auto>
                      <div class="custom-checkbox">
                        <input type="radio" value="25 Leaves" id="label-001" formControlName="ChequeBookLeaves">
                        <label for="label-001" class="color-black pt-5 pl-35">
                          25 Leaves
                        </label>
                      </div>
                    </div>
                    <div class="ng-col pl-15" col-auto>
                      <div class="custom-checkbox">
                        <input type="radio" value="50 Leaves" id="label-002" formControlName="ChequeBookLeaves">
                        <label for="label-002" class="color-black pt-5 pl-35">
                          50 Leaves
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="form-group mt-20 custom-checkbox">
                  <div class="ng-row">
                    <div class="ng-col" col-auto>
                      <input type="checkbox" (change)="changeAgree2($event)" value="" id="label-0003">
                      <label for="label-0003" class="pt-0 line-h-20">
                        {{"Iconsenttordaadc3" | translate}}
                        <span class="label-link color-6F2" (click)="labelLink3($event)">{{"TermsConditionsRdaAdc" |
                          translate}}</span>
                      </label>
                    </div>
                  </div>
                </div> -->
              </ng-container>

              <div class="form-group mt-30">
                <div class="ng-row">
                  <div class="ng-col mb-15" col-12>
                    <div class="font-16 font-600 color-2d3">{{"ReciveCourierText" | translate}}</div>


                  </div>
                  <div class="ng-col" col-12>
                    <div class="custom-checkbox">
                      <input type="radio" value="Mailing Address" id="label-01" formControlName="RecieveCourier"
                        (change)='CourierOptionSelected()'>
                      <label for="label-01" class="color-black pt-5 pl-35">
                        {{"MailingAddressCourier" | translate}}
                      </label>
                    </div>
                  </div>
                  <div class="ng-col" col-12>
                    <div class="custom-checkbox">
                      <input type="radio" value="Branch For Self Pickup" id="label-02" formControlName="RecieveCourier"
                        (change)='CourierOptionSelected()'>
                      <label for="label-02" class="color-black pt-5 pl-35">
                        {{"SelectedBranch" | translate}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group mt-30" *ngIf='this.BranchOptionvisible'>
                <label>{{"BranchCity" | translate}}</label>
                <select formControlName="City" [(ngModel)]="this.CityNull" (change)="CitySelectChangeMethod()">
                  <option hidden [ngValue]="null" disabled selected>Please select</option>
                  <option [ngValue]="a.cityId" *ngFor="let a of this.CityList">{{a.cityName}}</option>
                </select>
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.city">
                  <span class="error-label"
                    *ngIf="RdaAdcServiceForm.controls.City.hasError(validation.type) &&(RdaAdcServiceForm.controls.City.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'city') }}
                  </span>
                </ng-container>
              </div>

              <div class="form-group mt-30" *ngIf="this.cityselected">
                <label>{{"BankBranch" | translate}}</label>
                <select formControlName="Branch">
                  <option hidden [ngValue]="null" disabled selected>Please select</option>
                  <option [ngValue]="a.id" *ngFor="let a of this.BranchList">{{a.branchName}}</option>
                </select>
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.branch">
                  <span class="error-label"
                    *ngIf="RdaAdcServiceForm.controls.Branch.hasError(validation.type) &&(RdaAdcServiceForm.controls.Branch.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'branch') }}
                  </span>
                </ng-container>
              </div>

            </ng-container>


          </form>



          <div class="form-group mt-30">
            <button class="btn-dft" [disabled]='isFormValid()' (click)="FormPost()">
              {{"Continue" | translate}}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-fab></app-fab>