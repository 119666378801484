import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { CommonService } from 'src/app/Services/common.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-qurbani-form-success',
  templateUrl: './qurbani-form-success.component.html',
  styleUrls: ['./qurbani-form-success.component.scss']
})
export class QurbaniFormSuccessComponent implements OnInit {

  constructor(
    private common: CommonService,
    private datepipe: DatePipe

  ) { }
  refeid;
  TodayDate;

  async ngOnInit(): Promise<void> {
    this.refeid = await this.common.Get('QurbaniRefId');
    this.TodayDate = this.datepipe.transform(moment().format().toString());
    this.common.hideLoading();
  }
}
