<app-header3></app-header3>

<div class="container mt-46">
  <div class="ng-row" justify-content-center>
    <div class="ng-col pt-30 pd-sm-30" col-8 col-sm-12 col-md-11>

      <div class="ng-row pb-30">
        <div class="ng-col">
          <div class="font-30 color-2f5 font-600 mt-5">
            {{"ConversionApplicationForm" | translate}}
          </div>


          <div class="form-group mt-30">
            <label>{{"DebitingAccount" | translate}}</label>
            <select class="custom-select" (change)="ValueChange()" [(ngModel)]="selectedAccontfromAccount">
              <option hidden [ngValue]="undefined" disabled selected>Please Select</option>
              <option *ngFor="let data of this.auth.AllBalance" [ngValue]="data.accountNumber">{{data.accountNumber}}
              </option>
            </select>
          </div>

          <div class="font-16 color-354 font-700 mt-30" *ngIf="selectedAccontfromAccount">
            {{"AccountBalance" | translate}}
          </div>

          <div class="font-14 color-931 font-700 mt-5" *ngIf="selectedAccontfromAccount">
            {{fromCurrency}}: {{AccountBalance}}
          </div>


          <div class="form-group mt-20" *ngIf="this.selectedAccontfromAccount">
            <label>{{"AmountinFigures" | translate}}</label>
            <input type="number" pattern="[0-9]*" (change)="AmountChange()" [(ngModel)]="amount"
              placeholder="Please enter">
          </div>

          <div class="form-group mt-20" *ngIf="this.amount">
            <label class="no-mark">{{"AmountinWords" | translate}}</label>
            <label class="no-mark">{{amount| amountInwords }}{{fromCurrency}}</label>
            <span class="error-label" *ngIf="this.errorbalance">
              Insuficient amount to be request
            </span>

          </div>
          <div class="form-group mt-30" *ngIf="this.selectedAccontfromAccount">
            <label>{{"CreditingAccountNumber" | translate}}</label>

            <select class="custom-select" (change)="ValueChange1()" [(ngModel)]="selectedAcconttoAccount">
              <option hidden [ngValue]="undefined" disabled selected>Please Select</option>
              <option *ngFor="let data of this.toAccountList" [ngValue]="data.accountNumber">{{data.accountNumber}}
              </option>

            </select>
          </div>

          <div class="font-16 color-354 font-700 mt-30" *ngIf="toCurrency">
            {{"ConversionRate" | translate}}
          </div>

          <div class="font-14 color-931 font-700 mt-5" *ngIf="toCurrency && this.selectedAcconttoAccount">
            {{toCurrency}}: {{rateToDisplay}}
          </div>

          <div class="font-16 color-354 font-700 mt-30">
            {{"Disclaimer" | translate}}
          </div>

          <div class="font-14 color-354 font-600 mt-10">
            <span class="color-red">*</span> {{"ALLCONVERSIONS" | translate}}
          </div>

          <div class="font-14 color-354 font-600 mt-10">
            <span class="color-red">*</span> {{"REQUESTSRECEIVED" | translate}}
          </div>

          <div class="form-group mt-42 custom-checkbox">
            <div class="ng-row">
              <div class="ng-col" col-10>
                <input type="checkbox" (change)="changeAgree($event)" value="" id="label-0001">
                <label for="label-0001" class="pt-0 line-h-20">
                  {{"Iconsentto2" | translate}}
                </label>
              </div>
            </div>
          </div>

          <div class="form-group mt-30">
            <button class="btn-dft" (click)="Continue()" [disabled]="isValid()">
              {{"Continue" | translate}}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-fab></app-fab>