<div class="custom-accordion" [ngClass]="this.accordionStatus == this.auth.obj.variablee ? 'active':''">
    <div class="ng-row" justify-content-center>
        <div class="ng-col" col-11 col-sm-11>
          <div class="custom-accordion-heading" (click)="showAccordion(this.accordionIndex)">
            <div class="font-16 font-600 color-354">
              {{this.accordionHeading | translate}}
            </div>
          </div>
          <div class="custom-accordion-content">
            <ng-content></ng-content>
          </div>
        </div>
    </div>
</div>