import { Injectable } from '@angular/core';
import { HttpServiceService } from './http-service.service';
import { ConfigService } from './config.service';
import { AuthService } from "./auth.service";

export interface ScenarioOrder {
  type: string;
  status: boolean;
  error: string;
  isOPtional: boolean;
}

export interface ComboV1Values {
  UniqueId: string;
  Type: string;
}

@Injectable({
  providedIn: 'root'
})

export class KycService {

   public GetKycStatuURL: string = "kyc/GetStatusV6";

  public SaveAddressURL: string = "ResidentialInformation/ResidentialInformation";
  public SaveAccountKycURL: string = "Kyc/AccountKYCInfo";
  public SaveDocs: string = "AdditionalDocs/SaveAdditionalDocs";
  public SaveFatcaURL: string = "FatcaCrs/FatcaCrs";
  public GetComboValuesURL: string = "ResidentialInformation/GetComboValues";
  public GetComboValuesV2URL: string = "ResidentialInformation/GetComboValuesV2";
  public GetComboValuesV1URL: string = "ResidentialInformation/GetComboValuesV1";
  public SpecificFieldTypeValueURL: string = "ResidentialInformation/SpecificFieldTypeValue";
  public GetCountryCityValuesV1URL: string = "ResidentialInformation/GetCountryCityValuesV1";
  public SaveTransactionURL: string = "AgentQuestionair/AgentQuestionairResponse";
  public CreateAccountURL: string = "LinkAccount/OpenAccount";
  public videoToken: string = "";
  public videoSession: string = "";
  ISPassportskip: boolean = true;
  public ScenarioOrder: ScenarioOrder[] = [];
  count: number = 0;
  totalCount: number = 0;
  isExpireFlow: boolean = false;
  expireMsg: string = "";


  constructor(
    private http: HttpServiceService,
    private ConfigService: ConfigService,
    private auth: AuthService,
  ) { }

  public V1Valueobject: ComboV1Values = {
    Type: "",
    UniqueId: '',
  }

  public OpenAccount(token, object) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.ConfigService.server + this.CreateAccountURL, object, token).subscribe((data) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveAddressInfo(token, object) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.ConfigService.server + this.SaveAddressURL, object, token).subscribe((data) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveAdditioanlDocs(token, object) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.ConfigService.server + this.SaveDocs, object, token).subscribe((data) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveTransaction(token, object) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.ConfigService.server + this.SaveTransactionURL, object, token).subscribe((data) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveFatca(token, object) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.ConfigService.server + this.SaveFatcaURL, object, token).subscribe((data) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveAccountKyc(token, object) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.ConfigService.server + this.SaveAccountKycURL, object, token).subscribe((data) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }


  public GetComboValues(token, productId = 1) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.ConfigService.server + this.GetComboValuesURL, { ProductId: productId }, token).subscribe((data) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public GetComboValuesV1() {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.ConfigService.server + this.GetComboValuesV1URL, this.V1Valueobject, this.auth.accessToken).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SpecificFieldTypeValueMethod() {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.ConfigService.server + this.SpecificFieldTypeValueURL, this.V1Valueobject, this.auth.accessToken).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public GetComboValuesV2() {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.ConfigService.server + this.GetComboValuesV2URL, this.V1Valueobject, this.auth.accessToken).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public GetCountryCityValuesV1() {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.ConfigService.server + this.GetCountryCityValuesV1URL, this.V1Valueobject, this.auth.accessToken).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }



  public GetKycStatus(token, productId = 1) {

    return new Promise((resolve, reject) => {
      
      this.http.postToCloseApi(
        this.ConfigService.server + this.GetKycStatuURL, { ProductId: productId }, token).subscribe((data) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

                this.ScenarioOrder = data.response.content.data;
                this.totalCount = this.ScenarioOrder.length;

              }
            }
          }
          resolve(data);
          console.log(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }


  getScreen(numbers) {
    
    
    let result = "";
    let bool = false;
    this.isExpireFlow = false;
    this.expireMsg = "";
    while (!bool) {
      if (this.count < this.totalCount) {
        let screen = this.ScenarioOrder[this.count];
        if (screen.type == "PassportNumberC" || screen.type == "PassportNumberG") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            if (screen.isOPtional) {
              this.ISPassportskip = true;
            }
            else {
              this.ISPassportskip = false;
            }
            bool = true;
            result = "PassportNumberPage";
          }
        }
        else if (screen.type == "Passport" || screen.type == "PassportG") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            if (screen.isOPtional) {
              this.ISPassportskip = true;
            }
            else {
              this.ISPassportskip = false;
            }
            if (screen.error) {
              this.isExpireFlow = true;

              this.expireMsg = "PassportExpireMessage"
            }
            bool = true;
            result = "/register/PassportDetails";
          }
        }
        else if (screen.type == "ForiegnIDCard") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            if (screen.error) {
              this.isExpireFlow = true;

              this.expireMsg = "FIDExpireMessage"
            }
            bool = true;
            result = "/scan-foreign-id";
          }
        }
        else if (screen.type == "IDCard") {
          if (screen.status) {
            this.count = this.count + 1;

          }
          else {
            if (screen.error) {
              this.isExpireFlow = true;
              this.expireMsg = "IdExpireMessage"
            }
            bool = true;
            result = "/register/PakistanNationalityDetails";
          }
        }
        else if (screen.type == "ProductSelection") {

          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/register/AccountType";
          }

        }
        else if (screen.type == "PrimaryGuardianIdCard") {

          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/register/PakistanNationalityDetails";
          }

        }



        else if (screen.type == "AdditionalDocs") {

          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/register/SupportingDocuments";
          }

        }
        else if (screen.type == "AccountKyc") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/register/AdditionalDetails";

          }
        }
        else if (screen.type == "AdditioanalInfo") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/register/TellUsMoreAboutYourself1";
          }
        }
        else if (screen.type == "ForiegnBankDetails") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/register/YourForeignBankDetails";
          }
        }
        //joint start
        else if (screen.type == "PersonalDetails") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/SecondaryRegistration/PersonalDetails";
          }
        }
        else if (screen.type == "SecGuardianIdCard") {

          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/SecondaryRegistration/IdentityCardDetails";
          }
        }

        else if (screen.type == "PassportDetailsSecondary") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/SecondaryRegistration/PassportDetails";
          }
        }
        else if (screen.type == "TellUsMoreAboutYourselfSecondary") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/SecondaryRegistration/TellUsAboutYourself";
          }
        }
        else if (screen.type == "AdditionalDetailsSecondary") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/SecondaryRegistration/AdditionalDetails";
          }
        }
        else if (screen.type == "YourForeignBankDetailsSecondary") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/SecondaryRegistration/ForeignBankDetails";
          }
        }
        else if (screen.type == "SupportingDocumentsSecondary") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/SecondaryRegistration/SupportingDocuments";
          }
        }
        else if (screen.type == "CrsFatcaDeclarationSecondary") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/SecondaryRegistration/FatcaDeclaration";
          }
        }
        //end
        else if (screen.type == "VideoCall" || screen.type == "VideoCallG") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "/register/SubmitSuccess";
          }
        }
        else if(screen.type.trim().toLowerCase() == "UserDiscrepancy".trim().toLowerCase()){
          if(screen.status){
            this.count = this.count + 1;
          }
          else{
            bool = true;
            result = "/register/appdiscrepancypreviewscreen"
          }
        }
        // else if(screen.type.trim().toLowerCase() == "userdiscrepancy".trim()){
        //    if(screen.status){
        //     this.count = this.count + 1;
        //    }
        //    else{
        //     bool = true;
        //     result = "/register/app-discrepancy-screen"
        //    }
        // }
        else if (screen.type == "FatcaCrs") {
          if (screen.status) {
            this.count = this.count + 1;
          } else {

            bool = true;
            result = "/register/CrsFatcaDeclaration";
          }
        }
        else if (screen.type == "BankingTransaction") {
          if (screen.status) {
            this.count = this.count + 1;
          } else {

            bool = true;
            result = "/register/SubmitSuccess";
          }
        }
        else if (screen.type == "AccountCreation") {
          if (screen.status) {
            this.count = this.count + 1;
          } else {
            bool = true;
            result = "/register/SubmitSuccess";
          }
        }
        else if (screen.type == "IAgree") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "TermandconditionPage";
          }
        }
        else if (screen.type == "Cong") {
          if (screen.status) {
            this.count = this.count + 1;
          }
          else {
            bool = true;
            result = "ConactivedPage";
          }
        }
        else if (screen.type == "MobileOTP") {
          if (screen.status) {
            this.count = this.count + 1;
          } else {
            bool = true;
            result = "VerifyCustomer";
          }
        }
        else if (screen.type == "Dashboard") {
          if (screen.status) {
            this.count = this.count + 1;
          } else {
            bool = true;
            result = "Dashborad12Page";
          }
        }
        else {
          bool = true;
          result = "Dashborad12Page";
        }

      }
      else {
        bool = true;
        result = "Dashborad12Page";
      }
    }
    return result;
  }
}
