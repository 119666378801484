<header>
    <div class="ng-row height-100" align-item-center justify-content-between>
        <div class="ng-col hide-on-mob" col-3 col-md-1></div>

        <div class="ng-col" col-6 col-md-7 col-sm-12>
            <div class="ng-row" align-item-center justify-content-center>
                <div class="ng-col text-align-center p-l-r" col-auto col-sm-7>
                    <ng-container *ngIf="IsResident == '0'">
                        <img src="../assets/images/logo.png" alt="" width="180" class="mt-10">
                    </ng-container>
                    <ng-container *ngIf="IsResident == '1'">
                        <img src="../assets/images/new-logo-roshan.png" alt="" class="mt-10" width="100">
                    </ng-container>
                </div>
                <div class="ng-col text-align-center" col-auto col-sm-5>
                    <ng-container *ngIf="IsResident == '0'">
                        <img src="../assets/images/logo-roshan.png" alt="" class="mt-10" width="100">
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="ng-col p-l-r text-align-right not-apply" col-3 col-md-4 col-sm-12>
            <div class="ng-row" align-item-center justify-content-right>
                <div class="ng-col not-apply" col-auto>
                    <div class="link color-2f5 font-700" (click)="RemittanceTracker()">
                        {{"RemittanceTracker" | translate}}
                    </div>
                </div>
                <div class="ng-col not-apply p-l-r" col-auto>
                    <a href="mailto:Dibrda.inquiry@dibpak.com"><i class="icon-information"></i></a>
                </div>
            </div>
        </div>
    </div>
</header>