import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, CurrencyRate } from './../Services/auth.service';
import { CommonService } from './../Services/common.service';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { Guid } from "guid-typescript";
import { EFormServiceService } from '../Services/eform-service.service';

@Component({
  selector: 'app-rdastock-market-investment-request',
  templateUrl: './rdastock-market-investment-request.component.html',
  styleUrls: ['./rdastock-market-investment-request.component.scss']
})
export class RdastockMarketInvestmentRequestComponent implements OnInit {

  agree: boolean = false;
  agree1: boolean = false;
  SelectedAccountNumber = undefined;
  BrokerSelected = undefined;
  brokerlist;

  constructor(
    public auth: AuthService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public commonP: CommonService,
    public errorKey: ErrorMessageKeyService,
    private _router: Router,
    private eform: EFormServiceService
  ) { }

  async ngOnInit(): Promise<void> {
    setTimeout(async () => {
      this.commonP.presentLoading();
      this.auth.accessToken = await this.auth.Get('token');
      this.auth.data = JSON.parse(await this.auth.Get("User"));

      if (this.auth.data.IsSpentDays || this.auth.data.isForeignTaxPayed) {
        this._router.navigateByUrl("/CurrencyExchangeRate")
      }

      this.auth.BalanceInquiry(Guid.create().toString(), this.auth.accessToken).then((data: any) => {

        if (data?.response?.code == 1) {
          this.auth.AllBalance = data.response.content.filter(x => x.currency == 'PKR');

          this.auth.FetchBrokerListMethod(Guid.create().toString()).then((data: any) => {
            if (data?.response?.code == 1) {
              this.commonP.hideLoading();
              this.brokerlist = data.response.content;
            }
            else {
              this.commonP.hideLoading();
              this.commonP.ParseErrorAlert("", "", this._router, data);
            }
          })
        }
        else {
          this.commonP.hideLoading();
          this.commonP.ParseErrorAlert('', '', this._router, data);
        }
      })
    }, 3000);
  }

  changeAgree(event) {
    if (event.srcElement.checked) {
      this.agree = true;
    }
    else {
      this.agree = false;
    }
  }

  changeAgree1(event) {
    if (event.srcElement.checked) {
      this.agree1 = true;
    }
    else {
      this.agree1 = false;
    }
  }

  isvalid() {
    if (this.agree && this.agree1) {
      if (this.SelectedAccountNumber && this.BrokerSelected) {
        return false;
      }
      return true;
    }
    else {
      return true;
    }
  }

  ValueChange() {
    var object = this.eform.RDACapitalObject;
    object.AccountNumber = (this.SelectedAccountNumber == undefined) ? "" : this.SelectedAccountNumber;
    object.Broker = (this.BrokerSelected == undefined) ? "" : this.BrokerSelected,
      object.ApplicationId = (this.auth.data.onBoardingAccount.applicationId == undefined) ? "" : this.auth.data.onBoardingAccount.applicationId;
  }

  FormPost() {
    this.commonP.presentLoading();
    this.eform.SaveRDACapitalMarReq(this.eform.RDACapitalObject, this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {
        this.commonP.hideLoading();
        this.translate.get(["Success", "RDAStockMarketSuccess", "OK"]).subscribe((data: any) => {
          this.commonP.showSuccessAlert(data["Success"], data["RDAStockMarketSuccess"], data["OK"], this._router).then((data) => {
            this._router.navigateByUrl("/CurrencyExchangeRate");
          });
        })
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert("", "", this._router, data);
      }
    })
  }

  labelLink(e) {
    e.preventDefault();
    this._router.navigateByUrl('/LinkAccountOpeningForm')
  }

  labelLink1(e) {
    e.preventDefault();
    window.open("../../../assets/Terms-and-Conditions-for-NRVA-Holder.pdf", '_blank');
  }

}
