<app-header3></app-header3>

<div class="container mt-46">
  <div class="ng-row" justify-content-center>
    <div class="ng-col pt-30 pd-sm-30" col-8 col-sm-12 col-md-11>

      <div class="ng-row pb-30">
        <div class="ng-col">
          <div class="font-30 color-2f5 font-600 mt-5">
            {{"LinkAccountOpeningForm" | translate}}
          </div>

          <div class="form-group mt-30">
            <label>{{"BasicAccountNumber" | translate}}</label>
            <select class="custom-select" (change)="ValueChange()" [(ngModel)]="selectedAccont">
              <option disabled hidden value="undefined" selected>Please select</option>
              <option *ngFor="let data of this.auth.AllBalance" [ngValue]="data.accountNumber">{{data.accountNumber}}
              </option>
            </select>
          </div>

          <div class="font-14 color-354 font-400 mt-30 inside-link">
            {{"Referencetomy" | translate}} <strong class="label-link color-931"
              (click)="labelLink($event)">{{"termsconditions" | translate}}</strong>.
          </div>

          <div class="form-group mt-30">
            <label>{{"ProductType" | translate}}</label>
            <select class="custom-select" (change)="ProductChange()" [(ngModel)]="SelectedProduct">
              <option disabled hidden value="undefined" selected>Please select</option>
              <option value="NRP">NRP Rupee Value Account</option>
              <option value="FCV">Foreign Currency Value Account</option>
            </select>
          </div>

          <div *ngIf="this.SelectedProduct" class="form-group mt-30">
            <div class="ng-row">
              <div class="ng-col mb-15" col-12>
                <div class="font-16 font-600 color-2d3">{{"Selectthecurrency" | translate}}</div>
              </div>
              <ng-container *ngFor="let data of this.productstoshow ; let i = index">
                <div class="ng-col" col-auto col-sm-4 *ngIf="i == 0">
                  <div class="custom-checkbox">
                    <input type="checkbox" value="{{data}}" id="{{data}}" (change)="changeSelection2($event,data,i)"
                      name="checkboxGroup">
                    <label for="{{data}}" class="color-black pt-5 pl-35">
                      {{data}}
                    </label>
                  </div>
                </div>
                <div class="ng-col pl-15" col-auto col-sm-4 *ngIf="i > 0">
                  <div class="custom-checkbox">
                    <input type="checkbox" value="{{data}}" id="{{data}}" (change)="changeSelection2($event,data,i)"
                      name="checkboxGroup">
                    <label for="{{data}}" class="color-black pt-5 pl-35">
                      {{data}}
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div *ngIf="SelectedProduct" class="form-group mt-30">
            <div *ngFor="let a of this.productSelection; let i = index">


              <ng-container>
                <div [ngClass]="i == 0 ? 'ng-row' : 'ng-row mt-15'">
                  <div class="ng-col mb-15" col-12>
                    <div class="font-16 font-600 color-2d3">{{a.currencyId}}</div>
                  </div>
                </div>
              </ng-container>

              <div class="ng-row">
                <ng-container *ngIf="a.saving == true && a.current == true">
                  <div class="ng-col" col-auto>
                    <div class="font-16 font-600 color-2d3">{{"TypeofAccount" | translate}} </div>
                  </div>
                  <div class="ng-col pl-15" col-auto>
                    <div class="custom-checkbox">
                      <input type="radio" id="TOAlabel-{{i}}" value="current" (change)="changeType2(a.currencyId,i)"
                        name="radioGroup{{i}}">
                      <label for="TOAlabel-{{i}}" class="color-black pt-5 pl-35">
                        {{"Current" | translate}}
                      </label>
                    </div>
                  </div>
                  <div class="ng-col pl-15" col-auto>
                    <div class="custom-checkbox">
                      <input type="radio" id="TOAlabel2-{{i}}" value="saving" (change)="changeType2(a.currencyId,i)"
                        name="radioGroup{{i}}">
                      <label for="TOAlabel2-{{i}}" class="color-black pt-5 pl-35">
                        {{"Saving" | translate}}
                      </label>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="a.saving == false || a.current == false">
                  <div class="ng-col" col-auto>
                    <div class="font-16 font-600 color-2d3">{{"TypeofAccount" | translate}} </div>
                  </div>
                  <div class="ng-col pl-15" col-auto>
                    <div class="custom-checkbox">
                      <ng-container *ngIf="a.saving == true && a.current == false">
                        <input type="radio" id="TOAlabel-{{i}}" value="saving" (change)="changeType2(a.currencyId,i)"
                          name="radioGroup{{i}}">
                        <label for="TOAlabel-{{i}}" class="color-black pt-5 pl-35">
                          {{"Saving" | translate}}
                        </label>
                      </ng-container>
                      <ng-container *ngIf="a.saving == false && a.current == true">
                        <input type="radio" id="TOAlabel-{{i}}" value="current" (change)="changeType2(a.currencyId,i)"
                          name="radioGroup{{i}}">
                        <label for="TOAlabel-{{i}}" class="color-black pt-5 pl-35">
                          {{"Current" | translate}}
                        </label>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div class="ng-row">
                <ng-container>
                  <div class="ng-col" col-auto>
                    <div class="font-16 font-600 color-2d3">{{"NatureofAccount" | translate}} </div>
                  </div>
                  <div class="ng-col pl-15" col-auto>
                    <div class="custom-checkbox">
                      <input type="radio" id="NOAlabel-{{i}}" value="Joint" (change)="changeNature(a,i)"
                        name="NOAradioGroup{{i}}">
                      <label for="NOAlabel-{{i}}" class="color-black pt-5 pl-35">
                        {{"Joint" | translate}}
                      </label>
                    </div>
                  </div>
                  <div class="ng-col pl-15" col-auto>
                    <div class="custom-checkbox">
                      <input type="radio" id="NOAlabel2-{{i}}" value="Individual" (change)="changeNature(a,i)"
                        name="NOAradioGroup{{i}}">
                      <label for="NOAlabel2-{{i}}" class="color-black pt-5 pl-35">
                        {{"Individual" | translate}}
                      </label>
                    </div>
                  </div>
                </ng-container>
              </div>

            </div>
          </div>

          <div class="form-group mt-42 custom-checkbox">
            <div class="ng-row">
              <div class="ng-col" col-10>
                <input type="checkbox" (change)="changeAgree($event)" value="" id="label-0001">
                <label for="label-0001" class="pt-0 line-h-20">
                  {{"Iconsentto" | translate}}
                </label>
              </div>
            </div>
          </div>

          <div class="form-group mt-30">
            <button class="btn-dft" [disabled]="isValid()" (click)="Continue()">
              {{"Continue" | translate}}
            </button>
          </div>

        </div>
      </div>
    </div>

    <app-fab></app-fab>