import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { HttpServiceService } from '../Services/http-service.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  alert;
  loading;
  logOutAlert;
  ipAddress: string;
  platform: string = "w";
  longitude: string;
  latitiude: string;
  Form : FormGroup;


  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private loader: NgxSpinnerService,
    private storagemap: StorageMap,
    public translate: TranslateService,
    public http: HttpServiceService,
    public https: HttpClient
  ) {
    this.http.getlocationservice().then(resp => {
      this.longitude = JSON.stringify(resp.lng);
      this.latitiude = JSON.stringify(resp.lat);
    })
  }


  public AssignFieldsEmptyString(Form: FormGroup , ...args) {
    this.presentLoading();
    args[0].forEach(element => {
      let formfield = Form.controls[element];
      formfield.setValue('');
      formfield.markAsUntouched();
      formfield.updateValueAndValidity();
    });
    this.hideLoading();
  }

  public MarkFieldsMandatory(Form: FormGroup, ...args) {
    this.presentLoading();
    args[0].forEach(element => {
      let FormField = Form.controls[element];
      FormField.markAsUntouched();
      FormField.setValidators([Validators.required]);
      FormField.updateValueAndValidity();
    });
    this.hideLoading();
  }

  public MarkFieldsNonMandatory(Form : FormGroup , ...args) {
    this.presentLoading();
    args[0].forEach(element => {
      let FormField = Form.controls[element]
      FormField.setValue('');
      FormField.clearAsyncValidators();
      FormField.clearValidators();
      FormField.markAsUntouched();
      FormField.updateValueAndValidity();
    });
    this.hideLoading();
  }

  public GetIpAddress() {
    return this.https.get("https://api.ipify.org/?format=json");
  }

  Get(key: string): Promise<any> {
    
    return new Promise((resolve) => {
      this.storagemap.get(key)
        .subscribe(value => {

          if (value) {
            resolve(value);
          }
          else {
            resolve("");
          }
        }, error => {

          resolve("");
        })
    })

  }

  Set(key: string, value: string) {
    this.storagemap.set(key, value).subscribe();
  }

  clear(): void {
    this.storagemap.clear().subscribe();
  }

  public DecryptCustome(text) {

    var key = CryptoJS.enc.Utf8.parse('8080808080808080');
    var iv = CryptoJS.enc.Utf8.parse('8080808080808080');
    var decrypted = CryptoJS.AES.decrypt(text, key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  public async presentLoading() {
    this.loader.show()
    return;
  }

  public async hideLoading() {
    this.loader.hide();
  }

  public ParseErrorAlert(title, button, _router: Router, httpRes) {

    this.parseError(httpRes).then((mes) => {


      title = title ? title : "Sorry";
      button = button ? button : "OK"
      this.translate.get([title, button]).subscribe(data => {


        this.showErrorAlert(data[title], mes, data[button], _router);
      })
    })
  }

  async showErrorAlert(title, message, button, _router: Router) {

    let resolveFunction: (confirm: string) => void;
    let promise = new Promise<string>(resolve => {
      resolveFunction = resolve;
    });
    if (this.alert) {
      await this.alert.dismiss();

    }


    if (this.loading == undefined) {
      await this.hideLoading();
    }

    this.alert = await Swal.fire({
      title: title,
      html: message,
      icon: "error",
      confirmButtonText: button,
      allowOutsideClick: false,
    }).then((result) => {

      if (message == "your session is expired ,please login again (401)") {

        this.config.backbuttonStateName = "LetsbeginPage";
        _router.navigate(['login']);
        resolveFunction("Logout");
      }




      else if (button == "Retry") {
        resolveFunction("Retry");
      }
      else if (button == "Update") {
        resolveFunction(button);
        return false;
      }
      else {
        resolveFunction(button);
      }
    });
    return promise;
  }

  public async showDoLaterAlert(_router: Router) {
    let resolveFunction1: (confirm: string) => void;
    let promise1 = new Promise<string>(resolve => {
      resolveFunction1 = resolve;
    });

    if (this.logOutAlert) {
      this.logOutAlert.hide();

    }


    if (this.loading == undefined) {
      await this.hideLoading();
      this.loading = null;
    }


    this.translate.get(["Cancel", "Logout", "YourProgressText", "YourProgress"]).subscribe(async data => {


      this.logOutAlert = await Swal.fire({
        title: data["YourProgress"],
        html: data["YourProgressText"],
        icon: "info",
        showCancelButton: data["Cancel"],
        confirmButtonText: data["Logout"],
        allowOutsideClick: false,
      }).then((result) => {

        if (result.isConfirmed) {
          resolveFunction1("Logout");
        }
        else if (result.isDismissed) {
          this.logOutAlert = undefined;
          resolveFunction1("Cancel");
        }
        else {
          resolveFunction1(data["YourProgressText"])
        }
      })
    });
    return promise1;
  }

  public async ShowBackToDashboard(_router: Router) {
    let resolveFunction1: (confirm: string) => void;
    let promise1 = new Promise<string>(resolve => {
      resolveFunction1 = resolve;
    });


    if (this.loading == undefined) {
      await this.hideLoading();
      this.loading = null;
    }


    this.translate.get(["Cancel", "Confirm", "backtodashboardtext", "backtodashboardheading"]).subscribe(async data => {


      this.logOutAlert = await Swal.fire({
        title: data["backtodashboardheading"],
        html: data["backtodashboardtext"],
        icon: "info",
        showCancelButton: data["Cancel"],
        confirmButtonText: data["Confirm"],
        allowOutsideClick: false,
      }).then((result) => {

        if (result.isConfirmed) {
          resolveFunction1("Confirm");
        }
        else if (result.isDismissed) {
          this.logOutAlert = undefined;
          resolveFunction1("Cancel");
        }
        else {
          resolveFunction1(data["backtodashboardtext"])
        }
      })
    });
    return promise1;
  }

  public async showLogoutAlert(_router: Router) {
    let resolveFunction: (confirm: string) => void;
    let promise = new Promise<string>(resolve => {
      resolveFunction = resolve;
    });

    if (this.logOutAlert) {
      this.logOutAlert.dismiss();

    }

    if (this.loading != undefined) {
      await this.hideLoading();
      this.loading = null;
    }

    this.translate.get(["Cancel", "Logout", "LogoutMsg", "Info"]).subscribe(async data => {
      this.logOutAlert = await Swal.fire({
        title: data["Info"],
        html: data["LogoutMsg"],
        icon: "info",
        showCancelButton: data["Cancel"],
        confirmButtonText: data["Logout"],
        allowOutsideClick: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.logOutAlert = undefined;
          this.auth.accessToken = "";
          this.clearLogout();
          _router.navigateByUrl("/login");
          resolveFunction(data["Logout"]);
        }
        if (result.isDismissed) {
          this.logOutAlert = undefined;
          resolveFunction("Cancel");
        }
        else {
          resolveFunction(data["LogoutMsg"])
        }
      })
    });
    return promise;
  }

  public clearLogout() {
    this.auth.accessToken = "";
    this.auth.ClearDetail();
  }

  public async showTwoButtonAlert(title, message, buttons: string[], _router: Router) {

    let resolveFunction: (confirm: string) => void;
    let promise = new Promise<string>(resolve => {
      resolveFunction = resolve;
    });
    if (this.alert) {
      this.alert.dismiss();

    }


    if (this.loading == undefined) {
      await this.hideLoading();
      this.loading = null;
    }


    this.alert = await Swal.fire({
      title: title,
      html: message,
      icon: "icon",
      showDenyButton: true,
      denyButtonText: buttons[0],
      confirmButtonText: buttons[1],
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isDenied) {
        this.alert = undefined;
        resolveFunction(buttons[0]);
      }
      else if (result.isConfirmed) {
        resolveFunction(buttons[1]);
      }
      else {
        resolveFunction(message);
      }
    });
    return promise;
  }

  public async showThreeButtonAlert(title, message, buttons: string[], _router: Router) {
    let resolveFunction: (confirm: string) => void;
    let promise = new Promise<string>(resolve => {
      resolveFunction = resolve;
    });

    if (this.alert) {
      this.alert.dismiss();

    }


    if (this.loading != undefined) {
      await this.hideLoading();
      this.loading = null;
    }



    this.alert = await Swal.fire({
      title: title,
      html: message,
      icon: "icon",
      confirmButtonText: buttons,
      allowOutsideClick: false,
    }).then((result) => {
      if (buttons[0]) {
        resolveFunction(buttons[0]);
      }
      else if (buttons[1]) {
        this.alert = undefined;
        resolveFunction(buttons[1]);
      }
      else if (buttons[2]) {
        this.alert = undefined;
        resolveFunction(buttons[2]);
      }
      else {
        resolveFunction(message);
      }
    });
    return promise;
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  async showSuccessAlert(title, message, button, _router: Router) {
    let resolveFunction: (confirm: string) => void;
    let promise = new Promise<string>(resolve => {
      resolveFunction = resolve;
    });
    if (this.alert) {
      await this.alert.dismiss();

    }


    if (this.loading == undefined) {
      await this.hideLoading();
    }

    this.alert = await Swal.fire({
      title: title,
      html: message,
      icon: "success",
      allowOutsideClick: false,
    }).then((result) => {

      if (message == "your session is expired ,please login again (401)") {

        this.config.backbuttonStateName = "LetsbeginPage";
        _router.navigate(['login']);
        resolveFunction("Logout");
      }
      else if (button == "Retry") {
        resolveFunction("Retry");
      }
      else if (button == "Update") {
        resolveFunction(button);
        return false;
      }
      else {
        resolveFunction(button);
      }
    });
    return promise;
  }

  public parseError(res) {
    return new Promise((resolve, reject) => {





      if (res.name) {
        if (res.name == "TimeoutError") {
          this.translate.get(["TimeoutError"]).subscribe(data => {



            resolve(data["TimeoutError"]);


          });
        }
      }
      if (res.statusText) {
        if (res.statusText == "Unknown Error") {
          this.translate.get(["00"]).subscribe(data => {



            resolve(data["00"]);


          });
        }
      }

      if (typeof (res) == "string") {
        this.translate.get([res]).subscribe(data => {
          resolve(data[res]);
        });
      }

      if (res.status) {

        if (res.status == 500) {

          this.translate.get(["500"]).subscribe(data => {
            resolve(data["500"]);
          });
        }

        if (res.status == 404) {

          this.translate.get(["404"]).subscribe(data => {
            resolve(data["404"]);
          });
        }

        if (res.status == 400) {





          if (res.error?.response?.token) {

            this.auth.accessToken = res.error?.response?.token;
            this.Set('token', this.auth.accessToken);

          }
        }

        if (res.status == 0) {

          this.translate.get(["00"]).subscribe(data => {
            resolve(data["00"]);
          });
        }

        if (res.status == 401) {
          this.translate.get(["unAuth"]).subscribe(data => {
            resolve(data["unAuth"]);
          });
        }
      }



      if (res.error) {

        if (res.error.response) {

          if (res.error.response.content?.length > 0) {



            if (Array.isArray(res.error.response.content)) {
              let result = "";
              res.error.response.content.forEach(key => {



                this.translate.get([key]).subscribe(data => {

                  result = result + data[key] + "<br/>";
                });
              })

              resolve(result);
            }

            else if (res.error.response.message) {

              this.translate.get([res.error.response.message]).subscribe(data => {






                let a = data[res.error.response.message.toString().trim()];
                resolve(a);
              });
            }
          }


          this.translate.get([res.error.response.message]).subscribe(data => {




            let a = data[res.error.response.message.toString().trim()];
            resolve(a);
          });
        }
      }

      else if (res.error?.errors) {
        let result = "";
        Object.keys(res.error.errors).forEach(key => {


          if (key == "token") {

          }
          else {
            res.error.errors[key].forEach(element => {

              this.translate.get([element]).subscribe(data => {


                result = result + data[element] + "<br/>";
              });
            });
          }
        })
        resolve(result);
      }
      else if (res.response?.code) {
        this.translate.get([res.response?.content]).subscribe(data => {






          let a = data[res.response?.content.toString().trim()];
          resolve(a);
        });
      }
      else {
      }
    })
  }

  async FinalEncrypt(data): Promise<any> {
    
    let obj = await this.EncryptPublicKey(data);
    let data1 = await this.EncryptWithPublicKey(obj.key);
    return { data: obj.data, data1 };
  }

  async EncryptPublicKey(text): Promise<any> {

    let js = JSON.stringify(text);
    let randomKey = await this.generateRandomKey();

    var key = CryptoJS.enc.Utf8.parse(randomKey);
    var iv = CryptoJS.enc.Utf8.parse(randomKey);

    var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(js), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    var a = encryptedlogin.toString();


    return { data: a, key: randomKey };


  }

  async EncryptWithPublicKey(key): Promise<string> {
    let publicKey: string = `-----BEGIN PUBLIC KEY-----
    MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDlOJu6TyygqxfWT7eLtGDwajtN
    FOb9I5XRb6khyfD1Yt3YiCgQWMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76
    xFxdU6jE0NQ+Z+zEdhUTooNRaY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4
    gwQco1KRMDSmXSMkDwIDAQAB
    -----END PUBLIC KEY-----`
    var encrypt = new JSEncrypt({});

    encrypt.setPublicKey(publicKey);

    var encrypted = encrypt.encrypt(key.toString().trim());

    return encrypted.toString();
  }

  async generateRandomKey(): Promise<string> {
    let randomKey = Math.floor(Math.random() * (9 * Math.pow(10, 16 - 1))) + Math.pow(10, 16 - 1);


    return randomKey.toString();

  }
}