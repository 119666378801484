<div class="custom-dropdown" #customDropdownList>
  <div class="custom-dropdown-backdrop" (click)="closeDropdown()"></div>
  <div class="selected-box" (click)="showDropList()">
    <img *ngIf="this.imgageStatus && this.selectedIcon" [src]="'assets/svg-flags/'+this.selectedIcon+'.svg'" width="20"
      height="20">
    <input type="text" readonly [value]="this.selectedValue" [placeholder]="defaultplaceholder" [ngClass]="ShowDropDown ? 'custom-select' : ''">
  </div>
  <div class="list-item-box">
    <input *ngIf="this.searchStatus" type="text" placeholder="Search" [(ngModel)]="this.filterTerm"
      [ngModelOptions]="{standalone: true}" class="p-l-r font-14">
    <div class="list-item-box-scroll custom-scrollbar">
      <div class="list-item" *ngFor="let a of this.dropDownData | filter:filterTerm" (click)="selectDropVal(a)">
        <img *ngIf="this.imgageStatus" [src]="'assets/svg-flags/'+a.iconClass+'.svg'" width="20" height="20">
        <div class="num">{{countryCodePlus}} {{a.displayValue}}</div>
      </div>
    </div>
  </div>
</div>