<app-header3></app-header3>

<div class="container abc-component mt-46">
    <div class="ng-row bg-2F5" justify-content-center>
        <div class="ng-col text-align-center mt-46 mb-46" col-8>

            <i class="icon-check-white-big"></i>

            <div class="font-24 color-white font-600 mt-20">
                {{"Yourapplicationhas" | translate}}
            </div>

            <div class="font-18 color-white font-300 mt-20 line-h-25">
                {{"ThankyouforapplyingQurbani" | translate}}
            </div>

        </div>
    </div>

    <div class="ng-row mt-20 mb-20" justify-content-center align-item-center>
        <div class="ng-col pd" col-5 col-md-11 col-sm-11>
            <div class="ng-row box-shadow br-12">
                <div class="ng-col pd border-bottom" col-12>
                    <div class="pd-10">
                        <div class="font-12 font-500 color-354">
                            {{"RefNumber" | translate}}
                        </div>
                        <div class="font-16 font-600 color-354 mt-5">
                            {{refeid}}
                        </div>
                    </div>
                </div>
                <div class="ng-col pd" col-12>
                    <div class="pd-10">
                        <div class="font-12 font-500 color-354">
                            {{"Date" | translate}}
                        </div>
                        <div class="font-16 font-600 color-354 mt-5">
                            {{this.TodayDate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-fab></app-fab>