import { Router } from '@angular/router';
import { AuthService, Balances, LinkAccountObject } from './../Services/auth.service';
import { CommonService } from './../Services/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { Guid } from "guid-typescript";
import { Products, ProductService } from '../Services/product.service';

@Component({
  selector: 'app-link-account-opening-form',
  templateUrl: './link-account-opening-form.component.html',
  styleUrls: ['./link-account-opening-form.component.scss']
})
export class LinkAccountOpeningFormComponent implements OnInit {

  LinkAccountOpeningForm: FormGroup;
  submitAttempt: boolean = false;
  productname: string;
  curren: any;
  object: { currencyId: string, saving: boolean, current: boolean }
  ListOfLinkAccount: LinkAccountObject[] = [];
  productSelection: object[] = [];
  productstoshow;
  accountlist;
  LinkAccount: any = [];
  selectedCurrency: string[] = [];
  AllBalance: [];
  selectedAccont = undefined;
  selectedAccountCurrency: string;
  SelectedAccountNature: string = undefined;
  selectedNature: string;
  SelectedProduct: string = undefined;
  TOA;
  NOA;

  constructor(
    public auth: AuthService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public commonP: CommonService,
    public errorKey: ErrorMessageKeyService,
    private _router: Router,
    private prod: ProductService
  ) {
    this.LinkAccountOpeningForm = this.formBuilder.group({
      fcBranchName: ['', Validators.compose([Validators.required])],
      DobdaySelect: ['', Validators.compose([Validators.required])],
      DobMonthSelect: ['', Validators.compose([Validators.required])],
      DobYearSelect: ['', Validators.compose([Validators.required])],
    });
  }


  async ngOnInit(): Promise<void> {
    setTimeout(async () => {
      this.commonP.presentLoading();
      this.auth.accessToken = await this.auth.Get('token');
      let c = await this.auth.Get("User");
      if (c) {
        this.auth.data = JSON.parse(c);
      }

      this.auth.BalanceInquiry(Guid.create().toString(), this.auth.accessToken).then((data: any) => {
        if (data?.response?.code == 1) {
          this.auth.AllBalance = data.response.content;
          this.commonP.hideLoading();
        }
        else {
          this.commonP.ParseErrorAlert('', '', this._router, data);
        }
      })

    }, 3000);

  }

  agree: boolean;
  changeAgree(event) {
    if (event.srcElement.checked) {
      this.agree = true;
    }
    else {
      this.agree = false;
    }
  }


  isValid() {
    if (this.agree) {
      if (this.selectedAccont) {
        if (this.productSelection.length > 0) {
          if (this.productSelection.length == this.ListOfLinkAccount.length) {
            return false
          }
        }
      }
    }
    return true
  }

  saveinfo() {
    var object = this.auth.LinkAccountRequestObj;

    object.applicationId = this.auth.data.onBoardingAccount.applicationId;
    object.basicAccount = this.selectedAccont;
    object.uniqueId = Guid.create().toString();
    object.ListOfLinkAccount = this.ListOfLinkAccount;
    object.accountNature = this.selectedNature;
  }


  Continue() {

    this.commonP.presentLoading();
    this.saveinfo();
    this.auth.SaveOtherLinkAccount(this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {
        this.commonP.hideLoading();
        this.translate.get(["Success", "RequestSubmit", "OK"]).subscribe((data: any) => {
          this.commonP.showSuccessAlert(data["Success"], data["RequestSubmit"], data["OK"], this._router).then((data) => {
            this._router.navigateByUrl("/CurrencyExchangeRate");
          });
        })
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert("", "", this._router, data);
      }
    })
  }

  getMessage(key, control) {
    let error = "";
    this.translate.get([key]).subscribe(data => {
      error = data[key];
    })
    return error;
  }

  labelLink(e) {
    e.preventDefault();
    window.open("https://www.dibpak.com/wp-content/uploads/2021/01/Dubai-Islamic-Bank-10-RDA-Terms-Conditions.pdf", '_blank');
  }


  ValueChange() {
    this.selectedAccountCurrency = this.auth.AllBalance.find(x => this.selectedAccont == x.accountNumber).currency;
    this.SelectedAccountNature = this.auth.AllBalance.find(x => this.selectedAccont == x.accountNumber).accountNature.toString().toLowerCase();
  }

  ProductChange() {
    this.productname = this.SelectedProduct
    this.LinkAccountProducts2();
  }


  LinkAccountProducts2() {
    this.productSelection = [];
    this.accountlist = undefined;
    this.productstoshow = undefined;
    this.ListOfLinkAccount = [];

    this.commonP.presentLoading();
    this.prod.GetLinkAccountProducts(this.auth.accessToken).then((res: any) => {
      if (res?.response?.code == 1) {

        this.LinkAccount = res.response.content.lst;
        this.LinkAccount.every(element => {
          var a = element.filter(x => x.shortCode == this.productname);
          if (a.length > 0) {
            this.accountlist = a;
            return false
          }
          else {
            return true
          }
        });
        this.productstoshow = [...new Set(this.accountlist.map(x => x.currencyId))];
        this.commonP.hideLoading();
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert('', '', this._router, res);
      }
    })
  }

  changeSelection2(e, data, i) {

    if (e.srcElement.checked) {
      var Matchinglist = this.accountlist.filter(x => x.currencyId == data);
      var typesofAccount = Matchinglist.map(c => c.typesOfAccount.toUpperCase());
      if (typesofAccount.length > 0) {
        if (typesofAccount.includes('SAVING') && typesofAccount.includes('CURRENT')) {
          this.productSelection.splice(i, 0, ({
            currencyId: Matchinglist[0].currencyId,
            current: true,
            saving: true
          }))
        }
        else {
          this.productSelection.splice(i, 0, ({
            currencyId: Matchinglist[0].currencyId,
            current: (typesofAccount.includes('CURRENT')) ? true : false,
            saving: (typesofAccount.includes('SAVING')) ? true : false
          }))
        }
      }
    }
    else {
      this.productSelection.splice(i, 1);
      var index = this.ListOfLinkAccount.findIndex(x => x.currency == data);
      if (index != -1) {
        this.ListOfLinkAccount.splice(index, 1);
      }
    }
  }

  changeNature(object, index) {

    var TOACurr = document.getElementById('TOAlabel-' + index) as HTMLInputElement;
    var TOASav = document.getElementById('TOAlabel2-' + index) as HTMLInputElement;
    var NOAJoint = document.getElementById('NOAlabel-' + index) as HTMLInputElement;
    var NOAIndividual = document.getElementById('NOAlabel2-' + index) as HTMLInputElement;

    if (object.saving && object.current) {
      if (TOACurr.checked || TOASav.checked) {
        if (this.ListOfLinkAccount.length < 1) {
          this.ListOfLinkAccount[0] = {
            currency: object.currencyId,
            accountType: TOACurr.checked ? "Current" : "Saving",
            accountNature: NOAJoint.checked ? "Joint" : "Individual"
          }
        }
        else {
          var alreadyexist = this.ListOfLinkAccount.filter(x => x.currency == object.currencyId)
          if (alreadyexist.length > 0) {
            var index1 = this.ListOfLinkAccount.indexOf(alreadyexist[0]);
            this.ListOfLinkAccount[index1] = {
              currency: object.currencyId,
              accountType: TOACurr.checked ? "Current" : "Saving",
              accountNature: NOAJoint.checked ? "Joint" : "Individual"
            }
          }
          else {
            this.ListOfLinkAccount.push({
              currency: object.currencyId,
              accountType: TOACurr.checked ? "Current" : "Saving",
              accountNature: NOAJoint.checked ? "Joint" : "Individual"
            })
          }
        }
      }
    }
    else {
      if (TOACurr.checked) {
        if (this.ListOfLinkAccount.length < 1) {
          this.ListOfLinkAccount[0] = {
            currency: object.currencyId,
            accountType: object.current ? "Current" : "Saving",
            accountNature: NOAJoint.checked ? "Joint" : "Individual"
          }
        }
        else {
          var alreadyexist = this.ListOfLinkAccount.filter(x => x.currency == object.currencyId)
          if (alreadyexist.length > 0) {
            var index1 = this.ListOfLinkAccount.indexOf(alreadyexist[0]);
            this.ListOfLinkAccount[index1] = {
              currency: object.currencyId,
              accountType: object.current ? "Current" : "Saving",
              accountNature: NOAJoint.checked ? "Joint" : "Individual"
            }
          }
          else {
            this.ListOfLinkAccount.push({
              currency: object.currencyId,
              accountType: object.current ? "Current" : "Saving",
              accountNature: NOAJoint.checked ? "Joint" : "Individual"
            })
          }
        }
      }
    }
  }

  changeType2(currency, index) {
    // 
    var NOAJoint = document.getElementById('NOAlabel-' + index) as HTMLInputElement;
    var NOAIndividual = document.getElementById('NOAlabel2-' + index) as HTMLInputElement;

    var index1 = this.ListOfLinkAccount.findIndex(x => x.currency == currency);
    if (index1 != -1) {
      this.ListOfLinkAccount.splice(index1, 1)
    }

    NOAJoint.checked = false;
    NOAIndividual.checked = false;
  }
}
