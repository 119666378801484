import { Component, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './../Services/common.service';
import { PasswordValidation } from './../Validators/PasswordValidators';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { AuthService } from './../Services/auth.service';
import { ConfigService } from '../Services/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { KycService } from './../Services/kyc.service';
import { Country, CountriesService } from './../Services/countries.service';
import { Guid } from "guid-typescript";
import { CaptchaComponent } from 'angular-captcha';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as $ from "jquery";
//import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  submitAttempt: boolean = false;
  registerForm: FormGroup;
  _email: string;
  _password: string;
  passwordType: string = "password";
  id: Guid;
  uniqueId: string;
  deviceInfo = null;
  returnUrl: string;
  IsResident : string;

  @ViewChild("eyeIcon") eyeIcon: ElementRef;
  @ViewChild("forpasswordstyle") PasswordText: ElementRef;
  @ViewChild("modal") Modal: ElementRef;
  @ViewChild("modal1") Modal1: ElementRef;
  @ViewChild(CaptchaComponent, { static: true }) captchaComponent: CaptchaComponent;
  @ViewChild("modal2") Modal2: ElementRef;

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    private kyc: KycService,
    private commonP: CommonService,
    public errorKey: ErrorMessageKeyService,
    public formBuilder: FormBuilder,
    public countries: CountriesService,
    private auth: AuthService,
    private config: ConfigService,
    private _router: Router,
    public Renderer2: Renderer2,
    private deviceService: DeviceDetectorService,
    //private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.registerForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')])],
      password: ['', Validators.compose([Validators.required])],
      username : [''],
      recaptcha:['', Validators.compose([Validators.required])]
    })
    this.epicFunction();
  }
  

  showPassword() {
    if (this.PasswordText.nativeElement.attributes[9].nodeValue == '-webkit-text-security: disc;') {
      this.PasswordText.nativeElement.attributes[9].nodeValue = '-webkit-text-security: none;'
      this.Renderer2.addClass(this.eyeIcon.nativeElement, "active");
    }
    else if (this.PasswordText.nativeElement.attributes[9].nodeValue == '-webkit-text-security: none;') {
      this.PasswordText.nativeElement.attributes[9].nodeValue = '-webkit-text-security: disc;'
      this.Renderer2.removeClass(this.eyeIcon.nativeElement, "active");
    }
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
  }

  public steps: string[] = [];
  public title: string = "";
  public text: string = "";

  onCaptchaResponse(e) {

    this.registerForm.get('recaptcha').setValue(e);
  }
  onCaptchaExpired() {
    this.registerForm.get('recaptcha').setValue('');
  }

  async ngOnInit(): Promise<void> {
    
    this.IsResident = '0';
    this.FormValidation();
    this.config.AllowedTypeArray = await this.commonP.Get('AllowedDocs');
    this.commonP.clear();
    this.commonP.Set('IsResident', '0');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.id = Guid.create();
    this.uniqueId = this.id.toString();

    const userAgent = window.navigator;

    this.auth.GEttep(this.IsResident).then((data: any) => {
      this.steps = data.steps;
      this.title = data.Title;
      this.text = data.Text;
    })

    this.auth.SaveCounterobject.Browser = this.deviceInfo.browser;
    this.auth.SaveCounterobject.DeviceType = this.deviceInfo.deviceType;
    this.commonP.GetIpAddress().subscribe((res: any) => {
      this.auth.SaveCounterobject.IpAddress = res.ip;
      this.auth.SaveCounterobject.PageName = 'LoginPage'
      this.auth.SaveCounterobject.UniqueId = Guid.create().toString();
      this.auth.SavePageCounter(this.auth.SaveCounterobject).then((data: any) => {
        if (data?.response?.code == 1) {
        }
      })
    },
      error => {
        this.auth.SaveCounterobject.IpAddress = "";
        this.auth.SaveCounterobject.PageName = 'LoginPage'
        this.auth.SaveCounterobject.UniqueId = this.uniqueId;
        this.auth.SavePageCounter(this.auth.SaveCounterobject).then((data: any) => {
          if (data?.response?.code == 1) {
          }
        })
      }
    );

    // this.JqueryFunc();
  }
  
  // JqueryFunc() {
  //   setTimeout(() => {
  //     this.commonP.hideLoading();
  //     $(function () {
  //       $("a[title ~= 'BotDetect']").removeAttr("style");
  //       $("a[title ~= 'BotDetect']").removeAttr("href");
  //       $("a[title ~= 'BotDetect']").css('visibility', 'hidden');
  //       $("img[class='BDC_CaptchaImage'").css('height', '50');
  //       $("img[class='BDC_CaptchaImage'").css('width', '250');
  //       $("input[id='CaptchaInputField'").css('text-trasform', 'none');
  //     });
  //   }, 2000);
  // }

  FormValidation() : Promise<string> {

    return new Promise((resolve, reject) => {

      if(this.IsResident == '0'){

        let EmailField = this.registerForm.controls['email'];
        EmailField.markAsUntouched();
        EmailField.setValidators(Validators.required);
        EmailField.updateValueAndValidity();

        resolve('success');
      }
      else{

        let UserNameField = this.registerForm.controls['username'];
        UserNameField.markAsUntouched();
        UserNameField.setValidators(Validators.required);
        UserNameField.updateValueAndValidity();

        resolve('success');
      }
    })
  }

  getMessage(key, control) {
    let error = "";
    this.translate.get([key]).subscribe(data => {
      error = data[key];
    })
    return error;
  }

  ngOnDestroy() {
    this.registerForm.reset();
  }

  isvalid() {
    var a = document.getElementById('CaptchaInputField') as HTMLInputElement;
    if (a.value) {
      return false
    }
    else {
      return true
    }
  }

  Signupfunc() {
    this.commonP.presentLoading();
    this.commonP.clear();
    this.commonP.Set('IsResident','0');
    this.commonP.Set('AllowedDocs', this.config.AllowedTypeArray);
    this._router.navigateByUrl("/register/LoginDetails");
  }

  isFormValid() {
    if (!this.registerForm.valid) {
      return true;
    } 
    else {
      return false;
    }
  }

  Login() {
    
    this.commonP.presentLoading();
    this.commonP.clear();
    // this.commonP.Set('IsResident', '0');
    // this.commonP.Set('AllowedDocs', this.config.AllowedTypeArray);

    // this.recaptchaV3Service.execute('submit').subscribe(token => {
      // this.auth.loginobjectV5.RecaptchaToken = "abc";
      // this.auth.loginobjectV5.Ip = this.auth.SaveCounterobject.IpAddress;

      let RecaptchaToken="";
        RecaptchaToken = this.registerForm.get('recaptcha').value;
      (RecaptchaToken);
      this.auth.loginobjectV5.RecaptchaToken = RecaptchaToken.toString();
      this.auth.loginobjectV5.Ip = this.auth.SaveCounterobject.IpAddress;

      this.Login1();
    // });
  }

  async Login1() {
    this.id = Guid.create();
    this.uniqueId = this.id.toString();

    if (!this.registerForm.valid) {
      this.submitAttempt = true;
      this.commonP.hideLoading();
    }
    else {

      this.SaveInfo().then(() => {

        this.auth.Login(this.IsResident).then(async (data: any) => {
          if (data?.response?.code == 1) {
  
            this.auth.data = data.response.content.l;
            this.auth.dataSec = data.response.content.lSec;
  
            this.commonP.Set('User', JSON.stringify(this.auth.data))
            if (this.auth.dataSec.emailId) {
              this.commonP.Set('UserSec', JSON.stringify(this.auth.dataSec))
            }
  
            this.auth.accessToken = await this.auth.Get('token');
            this.showRoute();
  
          }
          else {
            if (data?.error?.response?.content == "Err-BotDetected") {
              this.captchaComponent.reloadImage();
              // this.JqueryFunc();
              this.commonP.ParseErrorAlert("", "", this._router, data)
            }
            else if (data?.error?.total == 0) {
              this.commonP.hideLoading();
              this.commonP.ParseErrorAlert("", "", this._router, data);
            }
            if (data?.error?.response?.content == "Mobile") {
              this.commonP.hideLoading();
              this.commonP.ParseErrorAlert("", "", this._router, "You can't continue from web <br/> Please continue process from mobile where you started");
            }
            else if (data?.error?.response?.content == "Web") {
              this.commonP.hideLoading();
              this.commonP.ParseErrorAlert("", "", this._router, "You can't continue from mobile <br/> Please continue process from web");
            }
            else {
              this.commonP.hideLoading();
              this.commonP.ParseErrorAlert("", "", this._router, data)
            }
          }
        })
      });
    }
  }

  showRoute() {
    if (!this.auth.data.emailStatus) {
      this._router.navigateByUrl("/emailOTP");
      this.commonP.hideLoading();
    }
    else if (!this.auth.data.mobileStatus) {
      this._router.navigateByUrl("/mobileOTP");
      this.commonP.hideLoading();
    }
    else if (this.auth.dataSec.firstName != null) {
      if (!this.auth.dataSec.emailStatus) {
        this._router.navigateByUrl("/emailSecOTP");
        this.commonP.hideLoading();
      }
      else if (!this.auth.dataSec.mobileStatus) {
        this._router.navigateByUrl("/mobileSecOTP");
        this.commonP.hideLoading();
      }
      else {
        if (this.auth.data.newDevice) {
          this.translate.get(["Verify", "Cancel", "NewDeviceMSG"]).subscribe(async (data) => {
            this.commonP.showTwoButtonAlert("Device Change has been detected", data["NewDeviceMSG"], [data["Cancel"], data["Verify"]], this._router).then(async (data: any) => {
              if (data == "Verify") {
                this.auth.SendOTPByType(this.auth.accessToken, "SendDeviceChangedOTP").then(async (data: any) => {
                  if (data?.response?.code == 1) {
                    let navigationExtras: NavigationExtras = {
                      queryParams: {
                        type: "SendDeviceChangedOTP"
                      }
                    };
                    this._router.navigateByUrl("/mobileOTP", navigationExtras);
                    await this.commonP.hideLoading()
                  }
                  else {
                    this.commonP.ParseErrorAlert('', '', this._router, data);
                    await this.commonP.hideLoading();
                  }
                })
              }
              else {
                this.auth.ClearDetail();
              }
            })
          })
        }
        else {
          this.kyc.count = 0;
          if (this.auth.data.onBoardingAccount.accountNumber) {
            if (this.returnUrl != '/') {
              this._router.navigateByUrl(this.returnUrl);
            }
            else {
              if(this.IsResident == '1'){

                this._router.navigateByUrl("/register/SubmitSuccess");
              }
              else{

                this._router.navigateByUrl("/CurrencyExchangeRate");
              }
            }
          }
          else {
            this.kyc.GetKycStatus(this.auth.accessToken, this.auth.data.onBoardingAccount ? this.auth.data.onBoardingAccount.productId : 1).then(async (data: any) => {
              
              if (data?.response?.code == 1) {
                if (this.kyc.totalCount > 0) {
                  let nextscreen = this.kyc.getScreen(this.kyc.count);
                  if (nextscreen == "/register/AccountType") {
                    await this.commonP.hideLoading();
                    this.commonP.Set('User', JSON.stringify(this.auth.data));
                    this.commonP.Set('token', data.response.token);
                    this._router.navigateByUrl(nextscreen);
                  }
                  else {
                    await this.commonP.hideLoading();
                    this.commonP.Set('User', JSON.stringify(this.auth.data));
                    this.commonP.Set('token', data.response.token);
                    if (nextscreen == "/register/SubmitSuccess") {
                      this._router.navigateByUrl(nextscreen);
                    }
                    else {
                      this.commonP.hideLoading();
                      this._router.navigateByUrl(nextscreen);
                    }
                  }
                }
                else {
                  this.commonP.ParseErrorAlert('', '', this._router, data);
                }
              }
              else {
                this.commonP.ParseErrorAlert('', '', this._router, data);
              }
            })
          }
        }
      }
    }
    else {
      if (this.auth.data.newDevice) {
        this.commonP.hideLoading();
        this.translate.get(["Verify", "Cancel", "NewDeviceMSG"]).subscribe(async (data) => {
          this.commonP.showTwoButtonAlert("Device Change has been detected", data["NewDeviceMSG"], [data["Cancel"], data["Verify"]], this._router).then(async (data: any) => {
            if (data == "Verify") {
              await this.commonP.presentLoading();
              this.auth.SendOTPByType(this.auth.accessToken, "SendDeviceChangedOTP").then(async (data: any) => {

                if (data?.response?.code == 1) {
                  let navigationExtras: NavigationExtras = {
                    queryParams: {
                      type: "SendDeviceChangedOTP"
                    }
                  };
                  await this.commonP.hideLoading()
                  this._router.navigateByUrl("/mobileOTP", navigationExtras);
                } else {
                  await this.commonP.hideLoading();
                  this.commonP.ParseErrorAlert('', '', this._router, data);
                }
              })
            }
            else {
              this.auth.ClearDetail();
            }
          })
        })
      }
      else {

        this.kyc.count = 0;
        if (this.auth.data.onBoardingAccount.accountNumber) {
          if (this.returnUrl != '/') {
            this._router.navigateByUrl(this.returnUrl);
          } 
          else {
            if (this.IsResident == '1') {

              this._router.navigateByUrl("/register/SubmitSuccess");
            }
            else {

              this._router.navigateByUrl("/CurrencyExchangeRate");
            }          
          }
        }
        else {
          this.kyc.GetKycStatus(this.auth.accessToken, this.auth.data.onBoardingAccount ? this.auth.data.onBoardingAccount.productId : 1).then(async (data: any) => {
            
            if (data?.response?.code == 1) {
              if (this.kyc.totalCount > 0) {
                let nextscreen = this.kyc.getScreen(this.kyc.count);
                if (nextscreen == "/register/AccountType") {
                  await this.commonP.hideLoading();
                  this.commonP.Set('User', JSON.stringify(this.auth.data));
                  this.commonP.Set('token', data.response.token);
                  this._router.navigateByUrl(nextscreen);
                }
                else {
                  await this.commonP.hideLoading();
                  this.commonP.Set('User', JSON.stringify(this.auth.data));
                  this.commonP.Set('token', data.response.token);
                  if (nextscreen == "/register/SubmitSuccess") {
                    this._router.navigateByUrl(nextscreen);
                  }
                  else {
                    this.commonP.hideLoading();
                    this._router.navigateByUrl(nextscreen);
                  }
                }
              }
              else {
                this.commonP.ParseErrorAlert('', '', this._router, data);
              }
            }
            else {
              this.commonP.ParseErrorAlert('', '', this._router, data);
            }
          })
        }

      }
    }
  }





  SaveInfo() : Promise<string> {

    return new Promise((resolve, reject) =>{

      var object;
      if(this.IsResident == '1'){

        object = this.auth.LoginResidentObject;
        object.userName = this.registerForm.get('username').value;
        object.Password = this._password;
        object.UniqueId = this.uniqueId;
        object.RecaptchaToken = this.auth.loginobjectV5.RecaptchaToken;
        object.Ip = this.auth.loginobjectV5.Ip;

        resolve('success');
      }
      else{

        object = this.auth.loginobjectV5;
        object.Email = this._email;
        object.Password = this._password;
        object.UniqueId = this.uniqueId;

        resolve('success');
      }
    })
  }

  showPopup() {
    this.Renderer2.addClass(this.Modal1.nativeElement, "active");
  }

  hidePopup() {
    this.Renderer2.removeClass(this.Modal1.nativeElement, "active");
    this.captchaComponent.reloadImage();
    // this.JqueryFunc();
  }
}
