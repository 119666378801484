<header>
    <div class="ng-row height-100" align-item-center justify-content-between>

      <div class="ng-col hide-on-mob" col-2 col-md-3></div>

      <div class="ng-col" col-8 col-sm-12 col-md-6 col-lg-6>
        <div class="ng-row p-l-r" no-wrap col-space align-item-center justify-content-center>
          <div class="ng-col text-align-center" col-auto col-sm-4>
            <img src="../assets/images/logo.png" alt="" width="180" class="mt-10" (click)="backToDashboard()">
          </div>
          <div class="ng-col text-align-center" col-auto col-sm-4>
            <img src="../assets/images/{{this.headerLogo}}" alt="" class="mt-10" width="90">
          </div>
        </div>
      </div>

      <div class="ng-col p-l-r text-align-right not-apply top-bar" col-2 col-sm-12 col-md-3 col-lg-3 order-on-1>
        <div class="ng-row" align-item-center justify-content-right>

          <div class="ng-col" col-auto>
              <i class="icon-home link" (click)="CurrencyExchangeRate()"></i>
          </div>

          <div class="ng-col p-l-r" col-auto>
            <a href="mailto:Dibrda.inquiry@dibpak.com"><i class="icon-information"></i></a>
          </div>

          <div class="ng-col" col-auto (click)="LogOut()">
            <div class="font-16 font-600 color-357 link hide-on-mob">
              Log out
            </div>
            <i class="icon-logout hide-on-desktop"></i>
          </div>
          
        </div>
      </div>

    </div>
  </header>