import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'formatmoneypipe'
})
export class FormatmoneypipePipe implements PipeTransform {

  constructor(private currencyPipe:CurrencyPipe){
  
  }
    transform(value: string, ...args) {
      let rounding="1."+args+"-"+args;
      if(value){
            let a =this.currencyPipe.transform(value,"",false,rounding).substr(3);
            a=a.replace("D","-");
            return a;
      }else{
        return "";
      }
  
    }
  }