import { Component, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CaptchaComponent } from 'angular-captcha';
import { CommonService } from 'src/app/Services/common.service';
import { RemittanceTrackerService } from 'src/app/Services/remittance-tracker.service';

@Component({
  selector: 'app-remittance-tracker-mtcn',
  templateUrl: './remittance-tracker-mtcn.component.html',
  styleUrls: ['./remittance-tracker-mtcn.component.scss']
})
export class RemittanceTrackerMTCNComponent implements OnInit {

  @ViewChild(CaptchaComponent, { static: true }) captchaComponent: CaptchaComponent;
  _email
  mtcnno: string = '';
  bindedmtcn:string = '';
  error: any = '';

  status: string = '';
  isShown: boolean = true;
  isEnable: boolean = true;
  form: any;
  respobj: any = {};
  constructor(public translate: TranslateService,
    private fb: FormBuilder,
    private RemitService: RemittanceTrackerService,
    private route: Router,
    private loaderService: CommonService) {
    translate.setDefaultLang("en");
    translate.use("en");
    this.form = this.fb.group({
      mtcnno: new FormControl('', Validators.compose([Validators.required])),
      recaptcha: new FormControl('', Validators.compose([Validators.required]))
    });
  }

  ngOnInit(): void {
    this.isShown = true;
  }

  isFormValid() {
    if (!this.form.valid) {
      return true;
    }
    else {
      return false;
    }
  }
  reset() {
    debugger
    this.isShown = true;
    this.form.reset();
    window.location.reload();


  }
  onCaptchaResponse(e) {
    this.form.get('recaptcha').setValue(e);
  }
  onCaptchaExpired() {
    this.form.get('recaptcha').setValue('');
  }
  formPost() {
    this.isShown = false;
    debugger
    if (this.form.controls['mtcnno'].value != '') {
      this.mtcnno = this.form.controls['mtcnno'].value;
      var obj = {
        MTCN: this.mtcnno.toString()
      };
      this.loaderService.presentLoading();
      this.RemitService.RemittanceTracker(obj).then((data: any) => {
        debugger
        if (data?.response?.code == 1) {
          this.loaderService.hideLoading();
          if (data?.response?.content?.content?.responseCode == "00") {
            this.error= '';
            this.respobj = data?.response?.content?.content;
            this.bindedmtcn = this.form.controls['mtcnno'].value;
            if (data?.response?.content?.content?.status == "Successful") {
              this.status = "Success"
            }
            else if (data.response?.content?.content?.status == "Pending") {
              this.status = "Pending";
            }
            else {
              this.status = "Closed"
            }
          }
          else if (data?.response?.content?.content?.responseCode == "01") {
            this.respobj = {};
            this.error = "You’ve entered an incorrect MTCN number";
            this.isShown = true;
            this.bindedmtcn = '';
          }

        }
        else {

          this.loaderService.hideLoading();
          this.loaderService.ParseErrorAlert('', '', this.route, data);

        }


      });
    }
  }


}
export interface RemittanceTrackerObj {
  mtcnno: string;
}