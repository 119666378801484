<app-header></app-header>

<div class="container width-700 mt-46 pb-20">
  <div class="ng-row">
    <div class="ng-col" col-3 col-sm-12>
      <app-progress-bar-circle [counter]="counter" [current]="current" [max]="max"></app-progress-bar-circle>
    </div>
    <div class="ng-col pt-30 pb-30 pd-sm-30" col-7 col-sm-12>

      <div class="font-24 color-2f5 font-600 mt-5">
        {{"CRSdeclaration" | translate}}
      </div>

      <div class="font-14 color-354 font-400 mt-5">
        {{"Almostthere" | translate}}
      </div>

      <div class="font-12 font-500 color-354 mt-20 inside-link">
        {{"Visit" | translate}}
        <u class="label-link" (click)="labelLink2($event)">{{"OECD" | translate}}</u>
        {{"formoredetails" | translate}}
      </div>

      <div class="form-group mt-30">
        <button class="btn-dft-grey" (click)="showPopup()" *ngIf="this.fatcaserv.dataobject.CrsList.length<3"><i
            class="icon-plus"></i>
          {{"AddCountry" | translate}}</button>
      </div>

      <div class="ng-row mt-30" *ngFor="let data of this.fatcaserv.dataobject?.CrsList let i=index;">
        <div class="ng-col" col-6>
          <div class="font-14 color-a0a font-400 mt-5">
            {{"Country" | translate}}
          </div>
          <div class="font-14 color-354 font-400 mt-5">
            {{data.TaxCountryName}}
          </div>
        </div>
        <div class="ng-col" col-5>
          <div class="font-14 color-a0a font-400 mt-5">
            {{"TIN" | translate}}
          </div>
          <div class="font-14 color-354 font-400 mt-5 word-break">
            <ng-container *ngIf="data.TinNumber"> {{data.TinNumber}}</ng-container>
            <ng-container *ngIf="data.Reason"> {{data.Reason}}</ng-container>
            <ng-container *ngIf="data.ReasonExplantion"> {{data.ReasonExplantion}}</ng-container>
          </div>
        </div>
        <div class="ng-col" col-1>
          <i class="icon-circle-close link" (click)="remove(i)"></i>
        </div>
      </div>

      <div class="form-group mt-42 custom-checkbox label-checkbox">
        <input type="checkbox" (change)="checked($event)" value="" id="label-3">
        <label for="label-3" class="inside-link font-400">
          <span class="color-354">{{"Iconfirmedmy" | translate}}
            <u class="label-link color-6F2" (click)="labelLink($event)">{{"CRSdeclaration" | translate}}</u>
            {{"aboveistrue" | translate}}
          </span>
        </label>
      </div>

      <div class="form-group mt-30">
        <button class="btn-dft-white" [disabled]="isMainValid()" (click)="formpostdolater()">
          {{"SaveDoLater" | translate}}
        </button>
        <button class="btn-dft mt-15" [disabled]="isMainValid()" (click)="formpost()">{{"Continue" |
          translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="custom-modal full-width full-height" #modal>
  <div class="custom-modal-overlay"></div>
  <div class="custom-modal-canvas">
    <div class="custom-modal-close">
      <i class="icon-close-black" (click)="hidePopup()"></i>
    </div>
    <div class="custom-modal-canvas-scroller">

      <div class="ng-row mt-32" justify-content-center>
        <div class="ng-col" col-9 col-sm-12>
          <div class="font-24 font-600 color-354 line-h-26 mt-25">
            {{ "Pleasestatethe" | translate }}
          </div>
        </div>
      </div>

      <div class="ng-row mt-32" justify-content-center>
        <div class="ng-col" col-6 col-sm-12>

          <div class="form-group">
            <label>{{"Countryof" | translate}}</label>
            <app-drop-down-list dropDownImage="true" dropDownSearch="true" [selectedIcon]=''
              [selectedValue]='this.selectedCurrentCountry' [dropDownData]="this.dropdownlist"
              (itemselected)="onCountryChange($event)"></app-drop-down-list>
          </div>

          <div class="form-group mt-25" *ngIf="this.selectedCurrentCountry">
            <div class="ng-row">
              <div class="ng-col mb-15" col-12>
                <div class="font-16 font-600 color-2d3">{{"Doyouhave" | translate}} <span class="color-red">*</span>
                </div>
              </div>
              <div class="ng-col" col-auto>
                <div class="custom-checkbox">
                  <input type="radio" value="Yes" [(ngModel)]="radiobuttonValue" id="label-4" name="radioGroup2"
                    (change)="tinStatusChange()">
                  <label for="label-4" class="color-black pt-5 pl-35">
                    {{"Yes" | translate}}

                  </label>
                </div>
              </div>
              <div class="ng-col pl-15" col-auto>
                <div class="custom-checkbox">
                  <input type="radio" value="No" [(ngModel)]="radiobuttonValue" id="label-5" name="radioGroup2"
                    (change)="reasonStatusChange()">
                  <label for="label-5" class="color-black pt-5 pl-35">
                    {{"No" | translate}}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <form [formGroup]="newform">
            <div class="form-group mt-20" *ngIf="this.tinStatus == true">
              <label>{{"TaxIdentificationNumber" | translate}}</label>
              <input type="text" maxlength="15" formControlName="_tinNumber" [(ngModel)]="this.tinNumber"
                placeholder="Please enter">
              <ng-container item-content *ngFor="let validation of errorKey.validation_messages.taxNumber">
                <span class="error-label"
                  *ngIf="newform.controls._tinNumber.hasError(validation.type) &&(newform.controls._tinNumber.touched )">
                  {{ getMessage(validation.key,'tinNumber') }}
                </span>
              </ng-container>
            </div>

            <div class="form-group mt-20" *ngIf="this.reasonStatus == true">
              <div class="custom-radio-2 circle-check">
                <input type="radio" value="" id="radio1" name="radioButtons" (change)="radioStatusChange(1)">
                <label for="radio1">
                  <div class="font-16 font-600 color-354">
                    {{"ReasonA" | translate}}
                  </div>
                  <div class="font-12 font-400 color-354 mt-15 line-h-18">
                    {{"TheCountrywhere" | translate}}
                  </div>
                </label>
              </div>

              <div class="custom-radio-2 mt-15 circle-check">
                <input type="radio" value="" id="radio2" name="radioButtons" (change)="radioStatusChange(2)">
                <label for="radio2">
                  <div class="font-16 font-600 color-354">
                    {{"ReasonB" | translate}}
                  </div>
                  <div class="font-12 font-400 color-354 mt-15 line-h-18">
                    {{"TheAccountHolder" | translate}}
                  </div>

                  <div class="form-group mt-20" *ngIf="this.reasonBStatus">
                    <label class="not-on-this font-12">{{"Explainwhyunable" | translate}}</label>
                    <input formControlName="_reasonExplanation" minlength='5' maxlength='30'
                      [(ngModel)]="this.reasonExplanation" type="text" placeholder="Please enter" class="not-on-this">
                    <ng-container item-content *ngFor="let validation of errorKey.validation_messages.explanation">
                      <span class="error-label"
                        *ngIf="newform.controls._reasonExplanation.hasError(validation.type) &&(newform.controls._reasonExplanation.touched)">
                        {{ getMessage(validation.key,'termsCondition') }}
                      </span>
                    </ng-container>
                  </div>

                </label>
              </div>

              <div class="custom-radio-2 mt-15 circle-check">
                <input type="radio" value="" id="radio3" name="radioButtons" (change)="radioStatusChange(3)">
                <label for="radio3">
                  <div class="font-16 font-600 color-354">
                    {{"ReasonC" | translate}}
                  </div>
                  <div class="font-12 font-400 color-354 mt-15 line-h-18">
                    {{"TheTINis" | translate}}
                  </div>
                </label>

              </div>
            </div>
          </form>
          <div class="form-group mt-46 mb-25">
            <button class="btn-dft" [disabled]="isValid()" (click)="DataSelected()">{{"Continue" | translate}}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="custom-modal full-width full-height" #modal2>
  <div class="custom-modal-overlay" (click)="hidePopup2()"></div>
  <div class="custom-modal-canvas">
    <div class="custom-modal-close">
      <i class="icon-close-black" (click)="hidePopup2()"></i>
    </div>
    <div class="custom-modal-canvas-scroller">

      <div class="ng-row" justify-content-center>
        <div class="ng-col" col-11 col-sm-12>
          <div class="font-24 font-600 color-354 line-h-26 mt-25">
            <i class="icon-certificate"></i> <span class="p-l-r">{{ "CRSdeclaration" | translate }}</span>
          </div>

          <div class="font-14 font-400 color-354 line-h-26 mt-20">
            {{"Iunderstandthat" | translate}}
          </div>

          <div class="font-14 font-400 color-354 line-h-26 mt-20">
            {{"Iacknowledgethat" | translate}}
          </div>

          <div class="font-14 font-700 color-354 line-h-26 mt-20">
            {{"Certification" | translate}}
          </div>

          <div class="font-14 font-400 color-354 line-h-26 mt-5">
            {{"Icertifythat" | translate}}
          </div>

        </div>
      </div>

    </div>
  </div>
</div>

<div class="custom-modal full-width full-height-on-mob" #modal4>
  <div class="custom-modal-overlay" (click)="hidePopup3()"></div>
  <div class="custom-modal-canvas">
    <div class="custom-modal-close">
      <i class="icon-close-black" (click)="hidePopup3()"></i>
    </div>
    <div class="custom-modal-canvas-scroller">
      <div class="ng-row mt-32" justify-content-center>
        <div class="ng-col" col-9 col-sm-12>
          <div class="font-24 font-600 color-2f5 line-h-32">
            {{"AccountActivationPreference" | translate}}
          </div>
          <div class="font-14 font-400 color-354 line-h-26 mt-10">
            {{"Inordertoactivate" | translate}}
          </div>

          <div class="font-12 font-600 color-2D3 line-h-16 mt-20 mb-10">
            {{"SelectYourPreference" | translate}}
          </div>

          <div class="custom-select-box ng-row mb-15" align-item-center (click)="customSelectBoxBtn(1)"
            [ngClass]="customSelectBox == 1 ? 'active':''">
            <div class="ng-col" col-auto>
              <div class="icon-box">
                <i class="icon-mobile"></i>
              </div>
            </div>
            <div class="ng-col p-l-r" col-10 col-sm-9 col-xs-8>
              <div class="font-16 font-600 color-354 line-h-26">
                {{"Proceedtodownload" | translate}}

              </div>
            </div>
          </div>

          <div class="custom-select-box ng-row" align-item-center (click)="customSelectBoxBtn(2)"
            [ngClass]="customSelectBox == 2 ? 'active':''">
            <div class="ng-col" col-auto>
              <div class="icon-box">
                <i class="icon-dekstop"></i>
              </div>
            </div>
            <div class="ng-col p-l-r" col-10 col-sm-9 col-xs-8>
              <div class="font-16 font-600 color-354 line-h-26">
                {{"ProceedwithWebsite" | translate}}

              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="ng-row mt-25 mb-32" justify-content-center>
        <div class="ng-col" col-6 col-sm-12>
          <button class="btn-dft" [disabled]="isvalidbutton()" (click)="VideoCallPost()">
            {{"Proceed" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-fab></app-fab>