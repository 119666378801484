<app-header></app-header>
<div class="container abc-component mt-46">
    <div class="ng-row" justify-content-center>
        <div class="ng-col text-align-center mt-46 mb-46" col-9 col-sm-10>

            <i class="icon-error-circle"></i>

            <div class="font-24 color-2d3 font-600 mt-20">
                {{"Wewillbe" | translate}}
            </div>

            <div class="ng-row" justify-content-center>
                <div class="ng-col" col-11>
                    <div class="font-16 color-2d3 font-400 mt-15">
                        {{"Sorryforthe" | translate}}
                    </div>

                    <div class="font-16 color-2d3 font-400 mt-15">
                        {{"Youcanalways" | translate}}
                        <span class="color-2f5 font-600">

                            {{"dibrda"| translate}}
                        </span>
                        {{"incaseofany" | translate}}
                    </div>

                    <div class="font-16 color-2d3 font-400 mt-15">
                        {{"Thankyoufor" | translate}}
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>