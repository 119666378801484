import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BaseClassService } from './Interceptors/base-class.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StorageModule } from '@ngx-pwa/local-storage';
import { LoginComponent } from './login/login.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedComponentModule } from './SharedComponents/SharedComponent.module';
import { EmailOtpComponent } from './email-otp/email-otp.component';
import { MobileOtpComponent } from './mobile-otp/mobile-otp.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StarRatingModule } from 'angular-star-rating';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { IonicModule } from '@ionic/angular';
import { RatingModule } from 'ng-starrating';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { Error503Component } from './error503/error503.component';
import { CurrencyExchangeRateComponent } from './currency-exchange-rate/currency-exchange-rate.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { VerifyChangePasswordOtpComponent } from './verify-change-password-otp/verify-change-password-otp.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { WebcamModule } from 'ngx-webcam';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LinkAccountOpeningFormComponent } from './link-account-opening-form/link-account-opening-form.component';
import { RdaAdcServicesFormComponent } from './rda-adc-services-form/rda-adc-services-form.component';
import { ConversionApplicationFormComponent } from './conversion-application-form/conversion-application-form.component';
import { IslamicNayaPakistanCertificatesFormComponent } from './islamic-naya-pakistan-certificates-form/islamic-naya-pakistan-certificates-form.component';
import { AmountInwordsPipe } from './amount-inwords.pipe';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { LocalTransferComponent } from './Local-Transfer-form/local-transfer.component';
import { NayaPakistanEncashmentFormComponent } from './naya-pakistan-encashment-form/naya-pakistan-encashment-form.component';
import { CRSdeclarationComponent } from './crsdeclaration/crsdeclaration.component';
import { CDCTransferAmountRequestComponent } from './cdctransfer-amount-request/cdctransfer-amount-request.component';
import { RdastockMarketInvestmentRequestComponent } from './rdastock-market-investment-request/rdastock-market-investment-request.component';
import { EmailSecOTPComponent } from './email-sec-otp/email-sec-otp.component';
import { MobileSecOTPComponent } from './mobile-sec-otp/mobile-sec-otp.component';
import { FormatmoneypipePipe } from './formatmoneypipe.pipe';
import { DatePipe } from '@angular/common';
import { BotDetectCaptchaModule } from 'angular-captcha';
import * as APIURL from '../assets/APIURL.json';
import { QurbaniFormComponent } from './qurbani-form/qurbani-form.component';
import { QurbaniFormSuccessComponent } from './qurbani-form-success/qurbani-form-success.component';
import { InternationTransferRequestComponent } from './internation-transfer-request/internation-transfer-request.component';
import { ReCaptchaModule } from "angular-recaptcha3";
import { DiscrepancyPreviewScreenComponent } from './discrepancy-preview-screen/discrepancy-preview-screen.component';
import { DiscrepancyScreenComponent } from './discrepancy-screen/discrepancy-screen.component';
import { RemittanceTrackerModule } from './remittance-tracker/remittance-tracker.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EmailOtpComponent,
    MobileOtpComponent,
    Error503Component,
    CurrencyExchangeRateComponent,
    ChangePasswordComponent,
    VerifyChangePasswordOtpComponent,
    ResetPasswordComponent,
    LinkAccountOpeningFormComponent,
    RdaAdcServicesFormComponent,
    ConversionApplicationFormComponent,
    IslamicNayaPakistanCertificatesFormComponent,
    AmountInwordsPipe,
    FormatmoneypipePipe,
    LocalTransferComponent,
    NayaPakistanEncashmentFormComponent,
    CRSdeclarationComponent,
    CDCTransferAmountRequestComponent,
    RdastockMarketInvestmentRequestComponent,
    EmailSecOTPComponent,
    MobileSecOTPComponent,
    FormatmoneypipePipe,
    QurbaniFormComponent,
    QurbaniFormSuccessComponent,
    InternationTransferRequestComponent,
    DiscrepancyPreviewScreenComponent,
    DiscrepancyScreenComponent,
  ],
  imports: [
    IonicModule.forRoot(),
    ReCaptchaModule.forRoot({
      invisible: {
        sitekey: APIURL.SiteKey,
      },
      normal: {
        sitekey: APIURL.SiteKey,
      },
      language: "en",
    }),
    BotDetectCaptchaModule.forRoot({ captchaEndpoint: APIURL.URL + 'simple-captcha-endpoint.ashx' }),
    BrowserModule,
    StarRatingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    WebcamModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    StorageModule.forRoot({ IDBNoWrap: true }),
    SharedComponentModule,
    HttpClientModule,
    SelectDropDownModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    RatingModule,
    Ng2SearchPipeModule,
    RemittanceTrackerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: BaseClassService, multi: true
    },
    {
      provide: JWT_OPTIONS, useValue: JWT_OPTIONS
    },
    JwtHelperService,
    CurrencyPipe, DecimalPipe, AmountInwordsPipe, FormatmoneypipePipe, DatePipe

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
