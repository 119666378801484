import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpServiceService } from './http-service.service';

export interface SpecificFieldType{
  uniqueId: string,
  type: string,
}

export interface SpecificFieldTypes{
  uniqueId: string,
  type: string[],
}
export interface GetStaticData{
  uniqueId:string,
  type:string
}

@Injectable({
  providedIn: 'root'
})

export class ResidentialInformationService {
  public FieldType:string  = "ResidentialInformation/SpecificFieldTypeValue";
  public FieldTypes:string ="ResidentialInformation/GetListFieldValues";
  public GetStaticDataURL:string='ResidentialInformation/GetStaticHtmlData'

  constructor(  private http: HttpServiceService,
    private config: ConfigService,) { }

public FieldValue: SpecificFieldType = {
  uniqueId: "",
  type:  ""

}

public FieldValues:SpecificFieldTypes = {
  uniqueId: "",
  type: [
    ""
  ],
}
public GetStaticDataObject:GetStaticData={
  uniqueId:"",
  type:""
}

public GetFieldtype(object,token){
  return new Promise((resolve,reject)=>{
    this.http.postToCloseApi(this.config.server + this.FieldType, this.FieldValue ,token).subscribe((data:any)=>{
      resolve(data);
    },
    Error => {
      resolve(Error);
    })
  });
}


public GetFieldtypes(object,token){
  return new Promise((resolve,reject)=>{
    this.http.postToCloseApi(this.config.server + this.FieldTypes, this.FieldValues,token).subscribe((data:any)=>{
      resolve(data)
    },
    Error =>{
      resolve(Error)
    })
  });
}

public GetStaticData(token){
  console.log('this.GetStaticDataObject',this.GetStaticDataObject);
  return new Promise((resolve,reject)=>{
    this.http.postToCloseApi(this.config.server + this.GetStaticDataURL,this.GetStaticDataObject,token).subscribe((data:any)=>{
      resolve(data)
    },
    Error =>{
      resolve(Error)
    })
  });
}






}
