<app-header3></app-header3>

<div class="container mt-46">
  <div class="ng-row" justify-content-center>
    <div class="ng-col pt-30 pd-sm-30" col-8 col-sm-12>

      <div class="ng-row pb-30">
        <div class="ng-col">
          <div class="font-30 color-2f5 font-600 mt-5">
            {{"RoshanQurbani" | translate}}
          </div>

          <div class="form-group mt-30">
            <label>{{"AccountNumber" | translate}}</label>
            <select class="custom-select" (change)="ValueChange()" [(ngModel)]="selectedAccontfromAccount">
              <option hidden [ngValue]="undefined" disabled selected>{{"PleaseSelect"| translate}}</option>
              <option *ngFor="let data of this.auth.AllBalance" [ngValue]="data.accountNumber">{{data.accountNumber}}
              </option>
            </select>
          </div>

          <div class="font-16 color-354 font-700 mt-30" *ngIf="selectedAccontfromAccount">
            {{"AccountBalance" | translate}}
          </div>

          <div class="font-14 color-931 font-700 mt-5" *ngIf="selectedAccontfromAccount">
            {{selectedCurrency}}: {{AccountBalance}}
          </div>

          <div class="font-16 color-354 font-700 mt-30" *ngIf="this.rateToDisplay">
            {{"ConversionRate" | translate}}
          </div>

          <div class="font-14 color-931 font-700 mt-5" *ngIf="this.rateToDisplay && this.selectedCurrency">
            PKR: {{rateToDisplay}}
          </div>

          <div class="form-group mt-30">
            <label>{{"TypeofQurbani" | translate}}</label>
            <select class="custom-select" (change)="QurbaniTypeSelection()" [(ngModel)]="SelectedQurbaniType">
              <option hidden [ngValue]="undefined" disabled selected>{{"PleaseSelect"| translate}}</option>
              <option *ngFor="let data of this.QurbaniTypes" [ngValue]="data.qtId">{{data.name}}
              </option>
            </select>
          </div>

          <!-- Shahriyar -->
          <ng-container *ngFor="let a of this.QurbaniRatesChart">
            <ng-container>

              <ng-container>
                <div class="font-18 color-2f5 font-600 mt-25">
                  {{a.serviceProvider}}
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngFor="let b of a.data">
              <div class="currency-table-parent mt-15">
                <div class="currency-table">
                  <div class="currency-table-head bg-F6F pd">
                    <div class="ng-row" justify-content-center>
                      <div class="ng-col" col-3>
                        <div class="font-14 color-354 font-400 text-align-center">
                          {{"Servicetype" | translate}}
                        </div>
                      </div>
                      <div class="ng-col" col-3>
                        <div class="font-14 color-354 font-400 text-align-center">
                          <!-- {{"Varity" | translate}} -->
                          Label
                        </div>
                      </div>
                      <div class="ng-col" col-3>
                        <div class="font-14 color-354 font-400 text-align-center">
                          <!-- {{c.label}} -->
                          Variety
                        </div>
                      </div>
                      <div class="ng-col" col-3>
                        <div class="font-14 color-354 font-400 text-align-center">
                          Rate
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="currency-table-body p-l-r">
                    <div class="ng-row border-bottom pt-20 pb-15 currency-table-item" justify-content-center
                      align-item-center>
                      <div class="ng-col" col-3>
                        <div class="font-12 color-354 font-400 text-align-center">
                          {{b.serviceType}}
                        </div>
                      </div>
                      <div class="ng-col" col-3>
                        <div class="font-14 color-354 font-400 text-align-center">
                          {{b.label}}
                        </div>
                      </div>
                      <div class="ng-col" col-3>
                        <div class="font-14 color-354 font-400 text-align-center">
                          {{b.qurbaniType}}
                        </div>
                      </div>
                      <div class="ng-col" col-3>
                        <div class="font-14 color-354 font-400 text-align-center">
                          {{b.rate}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!-- Shahriyar -->

          <!-- <ng-container *ngIf="this.SelectedQurbaniType">
            <ng-container *ngFor="let a of this.QurbaniRatesChart; let o = index">

              <ng-container>
                <div class="font-18 color-2f5 font-600 mt-25">
                  {{a.name | titlecase }}
                </div>
              </ng-container>

              <ng-container>
                <div class="font-14 color-2d3 font-600">
                  {{a.rateLists[0].subText}}
                  <span *ngIf="a.rateLists[0].mainHeading == 'Meat One'">
                    <ng-container *ngFor="let b of a.rateLists[0].typeWise; let i = index">
                      <ng-container *ngIf="b.name == 'Delivery'">
                        <ng-container *ngFor="let c of b.data; let i = index">
                          <ng-container *ngIf="i == 0">
                            {{c.additionalRate}}
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </span>
                </div>
              </ng-container>

              <ng-container *ngIf="a.name == 'MEAT ONE'">
                <div class="currency-table-parent mt-15">
                  <div class="currency-table">
                    <div class="currency-table-head bg-F6F pd">
                      <div class="ng-row">

                        <ng-container *ngFor="let b of a.rateLists[0].typeWise; let i = index">

                          <ng-container *ngIf='i==0'>
                            <div class="ng-col" col-3>
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{"Servicetype" | translate}}
                              </div>
                            </div>

                            <div class="ng-col" col-3 *ngFor="let c of b.data">
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{c.label}}
                              </div>
                            </div>
                          </ng-container>

                        </ng-container>

                      </div>
                    </div>
                    <div class="currency-table-body p-l-r">
                      <ng-container *ngFor="let v of a.rateLists">
                        <ng-container *ngFor="let b of v.typeWise; let i = index">
                          <div class="ng-row border-bottom pt-20 pb-15 currency-table-item" align-item-center>
                            <div class="ng-col" col-3>
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{b.name}}
                              </div>
                            </div>

                            <ng-container *ngFor="let c of b.data">
                              <div class="ng-col" col-3>
                                <div class="font-14 color-354 font-400 text-align-center">
                                  {{c.rate}}
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>

                </div>
              </ng-container>

              <ng-container *ngIf="a.name == 'SHOUKAT KHANUM'">
                <div class="currency-table-parent mt-15">
                  <div class="currency-table">
                    <div class="currency-table-head bg-F6F pd">
                      <ng-container *ngFor="let b of a.rateLists[0].typeWise; let i = index">
                        <ng-container *ngIf='i==0'>
                          <div class="ng-row" justify-content-center>
                            <div class="ng-col" col-3>
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{"Servicetype" | translate}}
                              </div>
                            </div>
                            <div class="ng-col" col-3 *ngFor="let c of b.data">
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{c.label}}
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                    <div class="currency-table-body p-l-r">
                      <ng-container *ngFor="let v of a.rateLists">
                        <ng-container *ngFor="let b of v.typeWise; let i = index">
                          <div class="ng-row border-bottom pt-20 pb-15 currency-table-item" justify-content-center
                            align-item-center>
                            <div class="ng-col" col-3>
                              <div class="font-12 color-354 font-400 text-align-center">
                                {{b.name}}
                              </div>
                            </div>

                            <ng-container *ngFor="let c of b.data">
                              <div class="ng-col" col-3>
                                <div class="font-14 color-354 font-400 text-align-center">
                                  {{c.rate}}
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="a.name == 'CHHIPA'">
                <div class="currency-table-parent mt-15">
                  <div class="currency-table">
                    <div class="currency-table-head bg-F6F pd">
                      <div class="ng-row" justify-content-center>
                        <ng-container *ngFor="let b of a.rateLists[0].typeWise; let i = index">
                          <ng-container *ngIf='i==0'>
                            <div class="ng-col" col-3>
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{"Servicetype" | translate}}
                              </div>
                            </div>
                            <div class="ng-col" col-3>
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{"Varity" | translate}}
                              </div>
                            </div>
                            <div class="ng-col" col-3 *ngFor="let c of b.data">
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{c.label}}
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                    <div class="currency-table-body p-l-r">
                      <ng-container *ngFor="let v of a.rateLists">
                        <ng-container *ngFor="let b of v.typeWise; let i = index">
                          <div class="ng-row border-bottom pt-20 pb-15 currency-table-item" justify-content-center
                            align-item-center>
                            <div class="ng-col" col-3>
                              <div class="font-12 color-354 font-400 text-align-center">
                                {{b.name}}
                              </div>
                            </div>
                            <ng-container *ngFor="let c of b.data">
                              <div class="ng-col" col-3>
                                <div class="font-14 color-354 font-400 text-align-center">
                                  {{c.qtid}}
                                </div>
                              </div>
                              <div class="ng-col" col-3>
                                <div class="font-14 color-354 font-400 text-align-center">
                                  {{c.rate}}
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="a.name == 'BAIT US SALAM'">
                <div class="currency-table-parent mt-15">
                  <div class="currency-table">
                    <div class="currency-table-head bg-F6F pd">
                      <div class="ng-row" justify-content-center>
                        <ng-container *ngFor="let b of a.rateLists[0].typeWise; let i = index">
                          <ng-container *ngIf='i==0'>
                            <div class="ng-col" col-3>
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{"Servicetype" | translate}}
                              </div>
                            </div>
                            <div class="ng-col" col-3>
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{"Varity" | translate}}
                              </div>
                            </div>
                            <div class="ng-col" col-3 *ngFor="let c of b.data">
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{c.label}}
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                    <div class="currency-table-body p-l-r">
                      <ng-container *ngFor="let v of a.rateLists">
                        <ng-container *ngFor="let b of v.typeWise; let i = index">
                          <div class="ng-row border-bottom pt-20 pb-15 currency-table-item" justify-content-center
                            align-item-center>
                            <div class="ng-col" col-3>
                              <div class="font-12 color-354 font-400 text-align-center">
                                {{b.name}}
                              </div>
                            </div>
                            <ng-container *ngFor="let c of b.data">
                              <div class="ng-col" col-3>
                                <div class="font-14 color-354 font-400 text-align-center">
                                  {{c.qtid}}
                                </div>
                              </div>
                              <div class="ng-col" col-3>
                                <div class="font-14 color-354 font-400 text-align-center">
                                  {{c.rate}}
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="a.name == 'SAYLANI'">
                <div class="currency-table-parent mt-15">
                  <div class="currency-table">
                    <div class="currency-table-head bg-F6F pd">
                      <ng-container *ngFor="let b of a.rateLists[0].typeWise; let i = index">
                        <ng-container *ngIf='i==0'>
                          <div class="ng-row" justify-content-center>
                            <div class="ng-col" col-3>
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{"Servicetype" | translate}}
                              </div>
                            </div>
                            <div class="ng-col" col-3 *ngFor="let c of b.data">
                              <div class="font-14 color-354 font-400 text-align-center">
                                {{c.label}}
                              </div>
                            </div>
                          </div>



                        </ng-container>
                      </ng-container>
                    </div>
                    <div class="currency-table-body p-l-r">
                      <ng-container *ngFor="let v of a.rateLists">
                        <ng-container *ngFor="let b of v.typeWise; let i = index">
                          <div class="ng-row border-bottom pt-20 pb-15 currency-table-item" justify-content-center
                            align-item-center>
                            <div class="ng-col" col-3>
                              <div class="font-12 color-354 font-400 text-align-center">
                                {{b.name}}
                              </div>
                            </div>
                            <ng-container *ngFor="let c of b.data">
                              <div class="ng-col" col-3>
                                <div class="font-14 color-354 font-400 text-align-center">
                                  {{c.rate}}
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>

                </div>
              </ng-container>
            </ng-container>
          </ng-container> -->


          <div class="form-group mt-30">
            <label>{{"ServiceProvider"| translate}}</label>
            <select class="custom-select" (change)="change()" [(ngModel)]="SelectedSP">
              <option hidden [ngValue]="undefined" disabled selected>{{"PleaseSelect"| translate}}</option>
              <option *ngFor="let data of this.QurbaniServPro" [ngValue]="data.spid">{{data.serviceProvider}}
              </option>
            </select>
          </div>

          <div class="form-group mt-30" *ngIf="this.SelectedSP">
            <label>{{"ServiceType"| translate}}</label>
            <select class="custom-select" (change)="STChange()" [(ngModel)]="this.selectedST">
              <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
              <ng-container
                *ngIf="this.SelectedSP != 1102 && this.SelectedSP != 1103 && this.SelectedSP != 1104 && this.SelectedSP != 1105 && this.SelectedSP != 1106 && this.SelectedSP != 1017">
                <option [ngValue]="data" *ngFor="let data of this.servicetypelist">{{data}}</option>
              </ng-container>

              <ng-container
                *ngIf="this.SelectedSP == 1102 || this.SelectedSP == 1103 || this.SelectedSP == 1104 || this.SelectedSP == 1105 || this.SelectedSP == 1106 || this.SelectedSP == 1017">
                <option value="Charity">{{"Charity"| translate}}</option>
              </ng-container>
            </select>
          </div>
          <div class="form-group mt-30">
            <label> {{"Varity" | translate}}</label>
            <select class="custom-select" (change)="varitySelect()" [(ngModel)]="this.Varity">
              <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
              <option value="a.qurbaniType" *ngFor="let a of this.dropdownServiceTypeData.data">{{a.qurbaniType}}
              </option>
            </select>
          </div>

          <!-- Meat-One -->
          <!-- <ng-container *ngIf="this.SelectedSP == 1101">

            <div class="form-group mt-30">
              <label>{{"City"| translate}}</label>
              <select class="custom-select" (change)="cityselect()" [(ngModel)]="this.City">
                <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
                <option [ngValue]="data" *ngFor="let data of this.CityList">{{data}}</option>
              </select>
            </div>

            <ng-container *ngIf="(this.selectedST| lowercase) == 'collection'">

              <div class="form-group mt-30" *ngIf="this.City">
                <label>{{"Branch"| translate}}</label>
                <select class="custom-select" (change)="branchselect()" [(ngModel)]="this.Branch">
                  <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
                  <option [ngValue]="data.location" *ngFor="let data of this.BranchList">{{data.location}}</option>
                </select>
              </div>

              <div class="form-group mt-30" *ngIf="this.Branch">
                <label>{{"AddressofOutlet"| translate}}</label>
                <input disabled readonly [value]="(this.OutletAddress | titlecase)">
              </div>

            </ng-container>

            <ng-container *ngIf="(this.selectedST| lowercase) == 'delivery'">

              <div class="form-group ng-row mt-30 mb-25">
                <div class="ng-col" col-9>
                  <div class="font-16 font-600 color-354">
                    {{"Mailingaddressdifferent" | translate}}
                  </div>
                </div>
                <div class="ng-col text-align-right" col-3>
                  <ion-toggle mode="ios" (ionChange)="changeStatus($event)"></ion-toggle>
                </div>
              </div>

              <div class="form-group mt-30" *ngIf="this.MailAddressDiffer == true">
                <label>{{"MailingAddress" | translate}}</label>
                <input placeholder="Enter mailing address" minlength=3 maxlength=250 [(ngModel)]="this.DelMailAddress">
              </div>

            </ng-container>

            <ng-container *ngIf="(this.selectedST| lowercase) == 'charity'">
              <div class="form-group mt-30">
                <label>{{"DonationType" | translate}}</label>
                <select class="custom-select" [(ngModel)]="this.DonationType">
                  <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
                  <option value="Self">Self</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <ng-container *ngIf="this.DonationType == 'Other'">
                <div class="form-group mt-30">
                  <label>{{"Nameoftheperson" | translate}}</label>
                  <input type="text" value="" placeholder="Please enter" minlength="3" maxlength="250"
                    [(ngModel)]="this.otherName">
                </div>

                <div class="form-group mt-30">
                  <label>{{"Contact" | translate}}</label>
                  <input type="tel" value="" placeholder="Please enter" minlength="3" maxlength="250"
                    [(ngModel)]="this.otherContact">
                </div>

                <div class="form-group mt-30">
                  <label>{{"Address" | translate}}</label>
                  <input type="text" value="" placeholder="Please enter" minlength="3" maxlength="450"
                    [(ngModel)]="this.OtherAddress">
                </div>
              </ng-container>
            </ng-container>
          </ng-container>  -->

          <!-- Shoukat Khanum -->
          <ng-container *ngIf="this.SelectedSP == 1102">
            <ng-container *ngIf="(this.selectedST| lowercase) == 'charity'">
              <div class="form-group mt-30">
                <label>{{"DonationType" | translate}}</label>
                <select class="custom-select" [(ngModel)]="this.DonationType">
                  <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
                  <option value="Self">Self</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <ng-container *ngIf="this.DonationType == 'Other'">
                <div class="form-group mt-30">
                  <label>{{"Nameoftheperson" | translate}}</label>
                  <input type="text" value="" placeholder="Please enter" minlength="3" maxlength="250"
                    [(ngModel)]="this.otherName">
                </div>

                <div class="form-group mt-30">
                  <label>Contact</label>
                  <input type="tel" value="" placeholder="Please enter" minlength="3" maxlength="250"
                    [(ngModel)]="this.otherContact">
                </div>

                <div class="form-group mt-30">
                  <label>Address</label>
                  <input type="text" value="" placeholder="Please enter" minlength="3" maxlength="450"
                    [(ngModel)]="this.OtherAddress">
                </div>
              </ng-container>
            </ng-container>

          </ng-container>


          <!-- Saylani -->
          <ng-container *ngIf="this.SelectedSP == 1103">
            <div class="form-group mt-30">
              <label>Qurbani Day</label>
              <select class="custom-select" [(ngModel)]="this.QurbaniDay">
                <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
                <option value="Day 1">Day 1</option>
                <option value="Day 2">Day 2</option>
              </select>
            </div>

            <ng-container *ngIf="(this.selectedST| lowercase) == 'charity'">
              <div class="form-group mt-30">
                <label>Donation Type</label>
                <select class="custom-select" [(ngModel)]="this.DonationType">
                  <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
                  <option value="Self">Self</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <ng-container *ngIf="this.DonationType == 'Other'">
                <div class="form-group mt-30">
                  <label>Name of the person</label>
                  <input type="text" value="" placeholder="Please enter" minlength="3" maxlength="250"
                    [(ngModel)]="this.otherName">
                </div>

                <div class="form-group mt-30">
                  <label>Contact</label>
                  <input type="tel" value="" placeholder="Please enter" minlength="3" maxlength="250"
                    [(ngModel)]="this.otherContact">
                </div>

                <div class="form-group mt-30">
                  <label>Address</label>
                  <input type="text" value="" placeholder="Please enter" minlength="3" maxlength="450"
                    [(ngModel)]="this.OtherAddress">
                </div>
              </ng-container>
            </ng-container>


          </ng-container>

          <!-- Chhipa -->
          <ng-container *ngIf="this.SelectedSP == 1104">
            <ng-container *ngIf="(this.selectedST| lowercase) == 'charity'">
              <div class="form-group mt-30">
                <label>Donation Type</label>
                <select class="custom-select" [(ngModel)]="this.DonationType">
                  <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
                  <option value="Self">Self</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <ng-container *ngIf="this.DonationType == 'Other'">
                <div class="form-group mt-30">
                  <label>Name of the person</label>
                  <input type="text" value="" placeholder="Please enter" minlength="3" maxlength="250"
                    [(ngModel)]="this.otherName">
                </div>

                <div class="form-group mt-30">
                  <label>Contact</label>
                  <input type="tel" value="" placeholder="Please enter" minlength="3" maxlength="250"
                    [(ngModel)]="this.otherContact">
                </div>

                <div class="form-group mt-30">
                  <label>Address</label>
                  <input type="text" value="" placeholder="Please enter" minlength="3" maxlength="450"
                    [(ngModel)]="this.OtherAddress">
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- Bait us salam -->
          <ng-container *ngIf="this.SelectedSP == 1105">
            <div class="form-group mt-30">
              <label>Animal Quantity</label>
              <input type="tel" placeholder="Enter number of qurbani animals" (change)="AnimalQuantityChange()"
                maxlength="25" [(ngModel)]="this.AnimalQuantity">
            </div>

            <ng-container *ngIf="(this.selectedST| lowercase) == 'charity'">
              <div class="form-group mt-30">
                <label>Donation Type</label>
                <select class="custom-select" [(ngModel)]="this.DonationType">
                  <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
                  <option value="Self">Self</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <ng-container *ngIf="this.DonationType == 'Other'">
                <div class="form-group mt-30">
                  <label>Name of the person</label>
                  <input type="text" value="" placeholder="Please enter" minlength="3" maxlength="250"
                    [(ngModel)]="this.otherName">
                </div>

                <div class="form-group mt-30">
                  <label>Contact</label>
                  <input type="tel" value="" placeholder="Please enter" minlength="3" maxlength="250"
                    [(ngModel)]="this.otherContact">
                </div>

                <div class="form-group mt-30">
                  <label>Address</label>
                  <input type="text" value="" placeholder="Please enter" minlength="3" maxlength="450"
                    [(ngModel)]="this.OtherAddress">
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="this.SelectedQurbaniType == 501">

            <div class="form-group mt-30">
              <label>Number of shares</label>
              <select class="custom-select" (change)="NumberOfShareChange()" [(ngModel)]="this.NumberOfShare">
                <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
              </select>
            </div>

            <div class="form-group mt-10" *ngIf="this.NumberOfShare">
              <ng-container *ngFor="let a of this.NumberofsharesArray; let i = index">
                <input placeholder="Name of person {{a}}" (change)="ShareNames($event,i)">
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="this.SelectedQurbaniType == 502">
            <div class="form-group mt-30">
              <label>Name of Participants in Whole Cow Hissa</label>
              <ng-container *ngFor="let data of [1,2,3,4,5,6,7]; let i = index">
                <input placeholder="Name of Participant {{data}}" (change)="ShareNames($event,i)">
              </ng-container>
            </div>
          </ng-container>

          <div class="form-group mt-42 custom-checkbox" *ngIf="this.SelectedSP == 1103">
            <div class="ng-row">
              <div class="ng-col" col-10>
                <input type="checkbox" [(ngModel)]="this.agree" value="" id="label-0001">
                <label for="label-0001" class="pt-0 line-h-20">
                  {{"Iconsenttosaylani" | translate}}
                  <span class="label-link color-6F2" (click)="labelLink1($event)">
                    {{"Waqalatnama" | translate}}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <!-- Al-Khidmat -->
          <ng-container *ngIf="this.SelectedSP == 1106">
          </ng-container>
          <!-- Alamgir  -->
          <ng-container *ngIf="this.SelectedSP == 1017">
          </ng-container>
          <div class="form-group mt-30">
            <button class="btn-dft" [disabled]="isformvalid()" (click)="forward()">
              {{"Continue" | translate}}
            </button>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>

<app-fab></app-fab>