import { Router } from '@angular/router';
import { AuthService, Balances } from './../Services/auth.service';
import { CommonService } from './../Services/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { Guid } from "guid-typescript";
import { CountriesService, CityListModel, BranchModel } from './../Services/countries.service'
import { EFormServiceService } from './../Services/eform-service.service'

@Component({
  selector: 'app-rda-adc-services-form',
  templateUrl: './rda-adc-services-form.component.html',
  styleUrls: ['./rda-adc-services-form.component.scss']
})
export class RdaAdcServicesFormComponent implements OnInit {

  submitAttempt: boolean = false;
  selectedAccont = undefined;
  selectedAccountCurrency: string;

  RdaAdcServiceForm: FormGroup;
  id: Guid;
  uniqueId: string;
  CityList: CityListModel[];
  BranchList: BranchModel[];
  cityselected: number = null;
  BranchOptionvisible: boolean = false;
  count: number = 0;
  CityNull = null;

  PkrAccount: boolean = true;
  ForiegnAccount: boolean = true;

  constructor(
    public auth: AuthService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public common: CommonService,
    public errorKey: ErrorMessageKeyService,
    private _router: Router,
    private countries: CountriesService,
    private eformservice: EFormServiceService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');

    this.RdaAdcServiceForm = this.formBuilder.group({
      BasicAccNo: ['', Validators.compose([Validators.required])],
      DebitCard: [''],
      NameOnCard: ['', Validators.compose([Validators.pattern('[a-zA-Z ]*')])],
      RecieveCourier: ['', Validators.compose([Validators.required])],
      ChequeBookLeaves: [''],
      City: [""],
      Branch: [""],
    });
  }

  async ngOnInit(): Promise<void> {

    this.id = Guid.create();
    this.uniqueId = this.id.toString();
    this.count = 0;
    this.common.presentLoading();
    this.auth.accessToken = await this.auth.Get('token');
    this.auth.data = JSON.parse(await this.auth.Get("User"));
    this.auth.BalanceInquiry(Guid.create().toString(), this.auth.accessToken).then((data: any) => {

      if (data?.response?.code == 1) {
        this.auth.AllBalance = data.response.content;

        this.common.hideLoading();
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert('', '', this._router, data);
      }
    })
  }

  CourierOptionSelected() {
    this.id = Guid.create();
    this.uniqueId = this.id.toString();

    var value = this.RdaAdcServiceForm.controls['RecieveCourier'].value;

    if (value == "Branch For Self Pickup" && this.count == 0) {
      this.common.presentLoading();
      this.countries.GetBranches(this.uniqueId).then((data: any) => {
        if (data?.response?.code == 1) {
          this.CityList = data.response.content;
          this.count = 1;
          this.CityList.sort((a, b) => a.cityName.localeCompare(b.cityName));
          this.BranchOptionvisible = true;
          this.common.hideLoading();
        }
        else {
          this.common.hideLoading();
          this.common.ParseErrorAlert("", "", this._router, data);
        }
      })
    }
    else if (value == "Branch For Self Pickup" && this.count == 1) {
      this.CityNull = null;
      this.BranchOptionvisible = true;
      this.RdaAdcServiceForm.patchValue({ 'City': null });
      let con = this.RdaAdcServiceForm.controls['City'];
      let con1 = this.RdaAdcServiceForm.controls['Branch'];
      con.markAsUntouched;
      con.reset();
      con1.reset();
    }
    else {
      this.BranchOptionvisible = false;
      this.RdaAdcServiceForm.patchValue({ 'City': null });
      this.RdaAdcServiceForm.patchValue({ 'Branch': null });
      this.cityselected = undefined;
    }
  }

  NameChange() {

    if (this.RdaAdcServiceForm.get('NameOnCard').value) {
      this.MakeValidationforPKR();
    }
    else {
      this.agree = undefined;
      var check1 = document.getElementById('label-0001') as HTMLInputElement
      check1.checked = false;

      this.agree1 = undefined;
      var check2 = document.getElementById('label-0002') as HTMLInputElement
      check2.checked = false;

      this.DisableValidationforNonPKR();
    }
  }

  ValueChange() {

    this.selectedAccountCurrency = this.auth.AllBalance.find(x => this.selectedAccont == x.accountNumber).currency;


    if (this.selectedAccountCurrency == "PKR") {
      this.PkrAccount = true;
      this.ForiegnAccount = false;
    }
    else {
      this.PkrAccount = false;
      this.ForiegnAccount = true;
    }
  }

  MakeValidationforPKR() {
    this.common.presentLoading();
    let MandatoryFields = ['DebitCard', 'NameOnCard'];

    MandatoryFields.forEach(element => {
      let FormField = this.RdaAdcServiceForm.controls[element]
      FormField.setValidators([Validators.required]);
      FormField.updateValueAndValidity();
    });
    this.common.hideLoading();
  }

  DisableValidationforNonPKR() {
    this.common.presentLoading();
    let NonMandatoryFields = ['DebitCard', 'NameOnCard'];

    NonMandatoryFields.forEach(element => {
      let FormField = this.RdaAdcServiceForm.controls[element]
      FormField.setValue(undefined);
      FormField.clearAsyncValidators();
      FormField.clearValidators();
      FormField.markAsUntouched();
      FormField.updateValueAndValidity();
    });
    this.common.hideLoading();
  }

  getMessage(key, control) {
    let error = "";
    this.translate.get([key]).subscribe(data => {
      error = data[key];
    })
    return error;
  }

  CitySelectChangeMethod() {
    this.RdaAdcServiceForm.patchValue({ 'Branch': null });
    let con = this.RdaAdcServiceForm.controls['Branch'];
    con.markAsUntouched;
    con.reset();
    this.cityselected = this.RdaAdcServiceForm.get('City').value;


    for (var i = 0; i < this.CityList.length; i++) {
      if (this.CityList[i].cityId == this.cityselected) {
        this.BranchList = this.CityList[i].branches;
        this.BranchList.sort((a, b) => a.branchName.localeCompare(b.branchName));
        break;
      }
    }

    if (this.BranchList.length < 1) {
      this.cityselected = null;
    }
  }

  labelLink(e) {
    e.preventDefault();
    window.open("https://www.dibpak.com", '_blank');
  }

  labelLink3(e) {
    e.preventDefault();

  }

  isFormValid() {
    var c = this.RdaAdcServiceForm.controls['RecieveCourier'].value;
    var branch = this.RdaAdcServiceForm.controls['Branch'].value;
    var city = this.RdaAdcServiceForm.controls['City'].value;
    var name = this.RdaAdcServiceForm.controls['NameOnCard'].value;
    var chequebook = this.RdaAdcServiceForm.controls['ChequeBookLeaves'].value;
    var atm = this.RdaAdcServiceForm.controls['DebitCard'].value;

    if (!this.RdaAdcServiceForm.valid) {
      return true;
    }
    else {
      if (name) {
        if (this.agree1 && this.agree) {
          return false
        }
        else {
          return true
        }
      }
      if (c == "Branch For Self Pickup") {
        if (this.agree2) {
          if (branch == null || city == null) {
            return true;
          }
          if (!chequebook && !atm) {
            return true;
          }
        }
        else {
          return true;
        }
      }
      else {
        if (c) {
          return false
        }
        else {
          return true
        }
      }
    }
  }

  agree: boolean = false;
  changeAgree(event) {
    if (event.srcElement.checked) {
      this.agree = true;

    }
    else {
      this.agree = false;

    }
  }

  agree1: boolean = false;
  changeAgree1(event) {
    if (event.srcElement.checked) {
      this.agree1 = true;

    }
    else {
      this.agree1 = false;

    }
  }

  agree2: boolean = true;
  changeAgree2(event) {
    if (event.srcElement.checked) {
      this.agree2 = true;

    }
    else {
      this.agree2 = false;

    }
  }

  labelLink1(event) {
    event.preventDefault();
    window.open("https://www.dibpak.com/wp-content/uploads/2020/03/DIB-Terms-and-Conditions-DIB-Debit-Cards-Urdu.pdf", '_blank');
  }

  labelLink2(event) {
    event.preventDefault();
    window.open("https://www.dibpak.com/wp-content/uploads/2019/07/DIB-Terms-and-Conditions-DIB-Visa-Debit-Cards_June_2019.pdf", '_blank');
  }

  FormPost() {

    this.id = Guid.create();
    this.uniqueId = this.id.toString();


    this.common.presentLoading();

    this.saveinfo();

    if (!this.PkrAccount) {
      var a = this.eformservice.RdaAdcObject;
      a.DebitCard = '';
      a.NameForCard = '';
    }

    this.eformservice.SaveRdaAdcFormMethod(this.eformservice.RdaAdcObject, this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {
        this.common.hideLoading();
        this.translate.get(["Success", "RequestSubmit", "OK"]).subscribe((data: any) => {
          this.common.showSuccessAlert(data["Success"], data["RequestSubmit"], data["OK"], this._router).then((data) => {
            this._router.navigateByUrl("/CurrencyExchangeRate");
          });
        })
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert("", "", this._router, data);
      }
    })
  }

  saveinfo() {
    var object = this.eformservice.RdaAdcObject;

    object.ApplicationId = this.auth.data.onBoardingAccount.applicationId;
    object.BasicAccountNo = this.RdaAdcServiceForm.controls['BasicAccNo'].value;

    var a = this.RdaAdcServiceForm.controls['Branch'].value;
    if (a == undefined) {
      object.BranchForCourier = 0
    }
    else {
      object.BranchForCourier = a;
    }

    var b = this.RdaAdcServiceForm.controls['City'].value;
    if (b == undefined) {
      object.CityForCourier = 0
    }
    else {
      object.CityForCourier = b;
    }

    var a = this.RdaAdcServiceForm.controls['ChequeBookLeaves'].value;

    object.ChequeBookLeaves = a ? a : "";

    var b = this.RdaAdcServiceForm.controls['DebitCard'].value;

    object.DebitCard = b ? b : "";
    object.NameForCard = this.RdaAdcServiceForm.controls['NameOnCard'].value;
    object.RecieveCourier = this.RdaAdcServiceForm.controls['RecieveCourier'].value;
    object.UniqueId = this.uniqueId;
  }
}
