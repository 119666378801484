<app-header></app-header>

<div class="container email-component mt-46">
  <div class="ng-row height-100">
    <div class="ng-col" col-3 col-sm-12>
      <app-progress-bar-circle [counter]="counter" [current]="current" [max]="max" [total]="3">
      </app-progress-bar-circle>
    </div>
    <div class="ng-col pt-30 pd-sm-30" col-7 col-sm-12>

      <div class="ng-row height-100 pb-30" align-content-between>
        <div class="ng-col">
          <div class="font-30 color-2f5 font-600 mt-5">
            {{"ResetPassword" | translate}}
          </div>


          <form class="bg-f6f7" [formGroup]="RegistrationForm">
            <div class="form-group mt-30">
              <label>{{"CreateYourPassword" | translate}}</label>
              <input type="text" style="-webkit-text-security: disc;" autocomplete="new-password" value=""
                maxlength="15" formControlName="password" [(ngModel)]="_password" placeholder="Please enter">
              <div class="hint-text mt-10">

                <ul>
                  <li>{{'Pass1'| translate}}</li>
                  <li>{{'Pass2'| translate}}</li>
                  <li>{{'Pass'|translate}}</li>

                </ul>
              </div>

              <ng-container item-content *ngFor="let validation of errorKey.validation_messages.password">

                <span class="error-label"
                  *ngIf="RegistrationForm.controls.password.hasError(validation.type) &&(RegistrationForm.controls.password.touched ||submitAttempt)">
                  {{ getMessage(validation.key,'password') }}

                </span>
              </ng-container>
            </div>

            <div class="form-group mt-30">
              <label>{{"ReenterPassword" | translate}}</label>
              <input type="text" style="-webkit-text-security: disc;" autocomplete="new-confirmpassword" value=""
                maxlength="15" formControlName="confirmPassword" [(ngModel)]="_confirmPassword"
                placeholder="Please enter">
              <span item-content class="error-label"
                *ngIf="RegistrationForm.controls.confirmPassword.errors?.MatchPassword &&(RegistrationForm.controls.confirmPassword.touched ||submitAttempt)">
                {{ getMessage('PasswordNot','confirmPassword') }}
              </span>
            </div>
          </form>
        </div>

        <div class="ng-col mb-20">
          <div class="form-group mt-15">
            <button class="btn-dft" [disabled]="isFormValid()" (click)="Next()">
              {{"Submit" | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-fab></app-fab>