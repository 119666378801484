
import { Country } from './countries.service';
import { ConfigService } from './config.service';
import { HttpServiceService } from './http-service.service';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage'

export interface CurrencyRate {

  ccY_Sale: string;()
  ccY_Buy: string;
  rateBuy: string;
  rateSale: string;
  entryDate: string;
  rateForDisplay: string;
}

export interface DocObject {
  Docname: string
}

export interface RequestDocObject {
  DocName: string,
  DocType: string,
  TempDocId: string
}

export interface Balances {
  accountId: Number;
  productId: Number;
  acctClass: string;
  balance: string;
  accountTitle: string;
  currency: string;
  accountNumber: string;
  productName: string;
  isNpcProduct: boolean;
  iban: string;
  colorClass: string;
  isChequeBookProduct: boolean;
  isCdcAccount: boolean;
  cdcApplied: boolean;
  isDebitBlocked: boolean;
  isCharity: boolean
  accountNature: string;
}
export interface RegistrationModel {

  companyId: number;
  firstName: string;
  middleName: string;
  familyName: string;
  countryID: string;
  mobileNumber: string;
  dOB: string;
  gender: string;
  nationality: string;
  dOE: string;
  documentCode: string;
  documentNumber: string;
  residenceCountry: string;
  issuer: string;
  optionalData1: string;
  optionalData2: string;
  mrtdraw: string;
  frontCardImage: string;
  backCardImage: string;
  personFaceImage: string;
  fireBaseToken: string;
  platform: string;
  email: string;
  password: string;
  confirmPassword: string;
  fullName: string;
  issuedate: string;
  FatherName: string;
  CityId: number;
  BranchId: number;
  IsSpent: boolean;
  UniqueId: string;
  IsUSMobileNo: boolean;
  IsLifeTimeCnic: boolean;
  videocall: string;
  IsForeignTaxPayed: boolean;
  Referredby: string;
  IsReferred: boolean;
}

export interface RegistrationResidentModel {

  companyId: number;
  firstName: string;
  userName : string;
  middleName: string;
  familyName: string;
  countryID: string;
  mobileNumber: string;
  dOB: string;
  gender: string;
  nationality: string;
  dOE: string;
  documentCode: string;
  documentNumber: string;
  residenceCountry: string;
  issuer: string;
  optionalData1: string;
  optionalData2: string;
  mrtdraw: string;
  frontCardImage: string;
  backCardImage: string;
  personFaceImage: string;
  fireBaseToken: string;
  platform: string;
  email: string;
  password: string;
  confirmPassword: string;
  fullName: string;
  issuedate: string;
  FatherName: string;
  CityId: number;
  BranchId: number;
  UniqueId: string;
  IsUSMobileNo: boolean;
  IsLifeTimeCnic: boolean;
  videocall: string;
  Referredby: string;
  IsReferred: boolean;
}

export interface RegistrationSecModel {

  companyId: number;
  firstName: string;
  middleName: string;
  familyName: string;
  countryID: string;
  mobileNumber: string;
  dOB: string;
  gender: string;
  nationality: string;
  dOE: string;
  documentCode: string;
  documentNumber: string;
  residenceCountry: string;
  issuer: string;
  optionalData1: string;
  optionalData2: string;
  mrtdraw: string;
  frontCardImage: string;
  backCardImage: string;
  personFaceImage: string;
  fireBaseToken: string;
  platform: string;
  email: string;
  fullName: string;
  issuedate: string;
  FatherName: string;
  CityId: number;
  BranchId: number;
  IsSpent: boolean;
  UniqueId: string;
  IsUSMobileNo: boolean;
  IsLifeTimeCnic: boolean;
  videocall: string;
  IsForeignTaxPayed: boolean;
  PrimaryUserId: string;


}

export interface SecIdCardExpireModel {
  PrimaryUserId: string;
  CompanyId: number;
  issuedate: string;
  FirstName: string;
  MiddleName: string;
  FamilyName: string;
  CountryID: string;
  DOB: string;
  Gender: string;
  Nationality: string;
  DOE: string;
  IsLifeTimeCNIC: boolean;
  DocumentCode: string;
  DocumentNumber: string;
  Issuer: string;
  OptionalData1: string;
  OptionalData2: string;
  MrtDraw: string;
  FrontCardImage: string;
  BackCardImage: string;
  PersonFaceImage: string;
  Type: string;
  FullName: string;
  fireBaseToken: string;
}

export interface IdCardExpireModel {

  documentCode: string;
  frontCardImage: string;
  backCardImage: string;
  firstName: string;
  documentNumber: string;
  middleName: string;
  familyName: string;
  gender: string;
  dOB: string;
  dOE: string;
  issuer: string;
  issuedate: string;
  fullName: string;
  mrtDraw: string;
  optionalData1: string;
  optionalData2: string;
  fireBaseToken: string;
  personFaceImage: string;
  companyId: number;
  countryID: string;
  nationality: string;
  type: string;
  IsLifeTimeCnic: boolean;
}

export interface HashModel {
  IncomingObject: any,
  GeneratedHash: string;
}

export interface PageCounterModel {
  UniqueId: string;
  IpAddress: string;
  Browser: string;
  PageName: string;
  DeviceType: string;
  Country: string;
}



export interface LoginModel {
  Email: string;
  Password: string;
  UniqueId: string;
  CaptchaId: string;
  UserEnteredCaptchaCode: string;
}

export interface LoginModelV5 {
  Email: string;
  Password: string;
  UniqueId: string;
  RecaptchaToken : string;
  Ip: string;
}

export interface LoginResidentModel {
  userName: string;
  Password: string;
  UniqueId: string;
  RecaptchaToken: string;
  Ip: string;
}

export interface OnBoardingAccount {

  accountNumber: string;
  accountStatus: string;
  applicationId: string;
  callStatus: string;
  currency: string;
  debitBlockStatus: boolean;
  iban: string;
  productId: number;
}
export interface Detail {

  coRaddfld: string;
  cob: string;
  dob: Date;
  emailId: string;
  emailStatus: boolean;
  fatherName: string;
  feedbackResponse: boolean;
  firstBio: boolean;
  firstName: string;
  fullname: string;
  gender: string;
  idIssuer: string;
  isExisting: boolean;
  isForeignTaxPayed: boolean;
  IsIdCardExpire: boolean;
  isUSMobileNo: boolean;
  lastName: string;
  middleName: string;
  mobileNumber: string;
  mobileStatus: boolean;
  nationality: string;
  newDevice: boolean;
  occupation: string;
  onBoardingAccount: OnBoardingAccount;
  PassportNationality: string;
  PersonFaceImage: string;
  residenceCountry: string;
  tokenTimeOut: number;
  userId: string;
  videocall: string;
  faceImage: string;
  PassportExpiry: boolean;
  IsSpentDays: boolean;
}

export interface DetailSec {

  coRaddfld: string;
  cob: string;
  dob: Date;
  emailId: string;
  emailStatus: boolean;
  fatherName: string;
  feedbackResponse: boolean;
  firstBio: boolean;
  firstName: string;
  fullname: string;
  gender: string;
  idIssuer: string;
  isExisting: boolean;
  isForeignTaxPayed: boolean;
  IsIdCardExpire: boolean;
  isUSMobileNo: boolean;
  lastName: string;
  middleName: string;
  mobileNumber: string;
  mobileStatus: boolean;
  nationality: string;
  newDevice: boolean;
  occupation: string;
  onBoardingAccount: OnBoardingAccount;
  PassportNationality: string;
  PersonFaceImage: string;
  residenceCountry: string;
  tokenTimeOut: number;
  userId: string;
  videocall: string;
  faceImage: string;
  PassportExpiry: boolean;
  IsSpentDays: boolean;
}

export interface LinkAccountRequestModel {
  uniqueId: string,
  applicationId: string,
  basicAccount: string,
  accountNature: string,
  ListOfLinkAccount: LinkAccountObject[];
}

export interface LinkAccountObject { currency: string, accountType: string, accountNature: string };


export interface OBJ {
  variablee: number;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public platform: string = "w";
  public GetVersionUrl: string = "AppVersion/currentVersionPE";
  public RegisterURL: string = "Auth/Registerv4";
  public RegisterSecURL: string = "Auth/RegisterSecv3";
  public RegisterResidentURL: string = "Auth/RegisterResidentV1";
  public LoginURL: string = "Auth/LoginV5";
  public LoginResidentURL: string = "Auth/LoginResidentV1";
  public ResendEmailOTPURL: string = 'Auth/ResendOnboardingEmailOTP';
  public ResendEmailOTPSecURL: string = 'Auth/ResendOnboardingEmailOTPSec';
  public ChangeOnboardingEmail: string = "Auth/ChangeEmailV1";
  public ChangeOnboardingEmailSecURL: string = "Auth/ChangeEmailSecV1";
  public ChangeOnboardingMobile: string = "Auth/ChangeMobile";
  public VerifyOnboardingEmail: string = "Auth/verifyEmailOTP";
  public VerifyOnboardingEmailSecURL: string = "Auth/verifyEmailOTPSec";
  public VerifyOnboardingMobile: string = "Auth/verifyMobileOTP";
  public SendOnboardingMobileOTPURL: string = "Auth/SendOnboardingMobileOTP";
  public SendOTPByTypeURL: string = "Auth/sendotptoken";
  public VerifyOTPByTypeURL: string = "Auth/verifyOtpByType";
  public SaveTempImageUrl: string = "Auth/savetempImage";
  public SaveTempDocUrl: string = "Auth/savetempdoc";
  public IdCardSaveURL: string = "IdCard/SaveV3";
  public SecIdCardSaveURL: string = "IdCard/SaveV3Sec";
  public Savepagecounterurl: string = "Auth/SavePageCount";
  public residenceCountry: string;
  public accessToken: string;
  public checkemailURL: string = "Auth/checkEmail";
  public checkusernameURL: string = "Auth/checkUsername";
  public BalanceInqURL: string = "Auth/AccountBalanceInquiryV3Web";

  public LinkAccountURL: string = "EForm/SaveOtherAccountRequest";
  public LinkAccountrequestURL: string = "EForm/SaveOtherAccountRequestV2"
  public CheckOtherAccountReqURL: string = "Web/CheckOtherAccountReq"
  public FetchBrokerListURL: string = "Web/FetchBrokerList"
  public ConversionURL: string = "EForm/SaveConversionForm";
  public SaveNPCURL: string = "EForm/SaveNPCForm";

  ipaddressurl: string = ''
  public GetExchangeRateURL: string = "ExchangeRate/GetExchangeRate";
  public GetAllExchangeRateURL: string = "ExchangeRate/GetAllExchangeRate";

  public SendBackOfficeEmailURL: string = 'Auth/SendBackOfficeEmail';

  public SendForgotPOTPURL: string = "Auth/SendForgotPasswordOTP";
  public VerifyForgotPasswordOTPURL: string = "Auth/VerifyForgotPasswordOTP";
  public ChangePasswordURL: string = "Auth/ChangePassword";

  public CurrencyRate: CurrencyRate[] = [];

  public AllBalance: Balances[] = [];

  public obj: OBJ = {
    variablee: 0
  }

  public data: Detail = {
    faceImage: "",
    newDevice: false,
    onBoardingAccount: {
      iban: "",
      accountStatus: "",
      accountNumber: "",
      productId: 1,
      applicationId: '',
      currency: "",
      callStatus: "",
      debitBlockStatus: false
    },
    fullname: "",
    emailId: "",
    mobileNumber: "",
    mobileStatus: false,
    emailStatus: false,
    isExisting: false,
    userId: "",
    nationality: "",
    firstName: "",
    residenceCountry: "",
    lastName: "",
    gender: "",
    dob: new Date(),
    idIssuer: "",
    tokenTimeOut: null,
    PassportNationality: "",
    IsIdCardExpire: false,
    PersonFaceImage: "",
    middleName: "",
    coRaddfld: "",
    cob: "",
    fatherName: "",
    feedbackResponse: false,
    firstBio: false,
    isForeignTaxPayed: false,
    isUSMobileNo: false,
    occupation: "",
    videocall: "",
    PassportExpiry: false,
    IsSpentDays: false
  };

  public dataSec: DetailSec = {
    faceImage: "",
    newDevice: false,
    onBoardingAccount: {
      iban: "",
      accountStatus: "",
      accountNumber: "",
      productId: 1,
      applicationId: '',
      currency: "",
      callStatus: "",
      debitBlockStatus: false
    },
    fullname: "",
    emailId: "",
    mobileNumber: "",
    mobileStatus: false,
    emailStatus: false,
    isExisting: false,
    userId: "",
    nationality: "",
    firstName: "",
    residenceCountry: "",
    lastName: "",
    gender: "",
    dob: new Date(),
    idIssuer: "",
    tokenTimeOut: null,
    PassportNationality: "",
    IsIdCardExpire: false,
    PersonFaceImage: "",
    middleName: "",
    coRaddfld: "",
    cob: "",
    fatherName: "",
    feedbackResponse: false,
    firstBio: false,
    isForeignTaxPayed: false,
    isUSMobileNo: false,
    occupation: "",
    videocall: "",
    PassportExpiry: false,
    IsSpentDays: false
  };

  public LinkAccountRequestObj: LinkAccountRequestModel = {
    applicationId: '',
    ListOfLinkAccount: [],
    basicAccount: '',
    uniqueId: '',
    accountNature: ''
  }

  public docobject: DocObject;

  public RequestDocObject: RequestDocObject;

  public registrationObject: RegistrationModel = {
    backCardImage: "",
    familyName: "",
    fireBaseToken: "",
    firstName: "",
    frontCardImage: "",
    fullName: "",
    dOB: "",
    dOE: "",
    documentCode: "",
    documentNumber: "",
    email: "",
    residenceCountry: "",
    issuer: "",
    optionalData1: "",
    optionalData2: "",
    middleName: "",
    mobileNumber: "",
    mrtdraw: "",
    nationality: "",
    companyId: 1,
    confirmPassword: "",
    countryID: "",
    password: "",
    personFaceImage: "",
    platform: "",
    gender: "",
    issuedate: "",
    FatherName: "",
    CityId: null,
    BranchId: null,
    IsSpent: true,
    UniqueId: "",
    IsUSMobileNo: false,
    IsLifeTimeCnic: false,
    videocall: "",
    IsForeignTaxPayed: false,
    IsReferred: false,
    Referredby: ''
  };

  public RegistrationResidentObject: RegistrationResidentModel = {
    backCardImage: "",
    familyName: "",
    fireBaseToken: "",
    firstName: "",
    frontCardImage: "",
    fullName: "",
    dOB: "",
    dOE: "",
    documentCode: "",
    documentNumber: "",
    email: "",
    residenceCountry: "",
    issuer: "",
    optionalData1: "",
    optionalData2: "",
    middleName: "",
    mobileNumber: "",
    mrtdraw: "",
    nationality: "",
    companyId: 1,
    confirmPassword: "",
    countryID: "",
    password: "",
    personFaceImage: "",
    platform: "",
    gender: "",
    issuedate: "",
    FatherName: "",
    CityId: null,
    BranchId: null,
    UniqueId: "",
    IsUSMobileNo: false,
    IsLifeTimeCnic: false,
    videocall: "",
    IsReferred: false,
    Referredby: '',
    userName : ''
  };


  public registrationSecObject: RegistrationSecModel = {
    backCardImage: "",
    familyName: "",
    fireBaseToken: "",
    firstName: "",
    frontCardImage: "",
    fullName: "",
    dOB: "",
    dOE: "",
    documentCode: "",
    documentNumber: "",
    email: "",
    residenceCountry: "",
    issuer: "",
    optionalData1: "",
    optionalData2: "",
    middleName: "",
    mobileNumber: "",
    mrtdraw: "",
    nationality: "",
    companyId: 1,
    countryID: "",
    personFaceImage: "",
    platform: "",
    gender: "",
    issuedate: "",
    FatherName: "",
    CityId: null,
    BranchId: null,
    IsSpent: true,
    UniqueId: "",
    IsUSMobileNo: false,
    IsLifeTimeCnic: false,
    videocall: "",
    IsForeignTaxPayed: false,
    PrimaryUserId: ""
  };


  public SaveCounterobject: PageCounterModel = {
    Browser: '',
    DeviceType: '',
    IpAddress: '',
    PageName: '',
    UniqueId: '',
    Country: '',
  }

  public IdCardObject: IdCardExpireModel = {
    backCardImage: "",
    companyId: 1,
    countryID: "",
    dOB: "",
    dOE: "",
    documentCode: "",
    documentNumber: "",
    familyName: "",
    fireBaseToken: "",
    firstName: "",
    frontCardImage: "",
    fullName: "",
    gender: "",
    issuedate: "",
    issuer: "",
    middleName: "",
    mrtDraw: "",
    nationality: "",
    optionalData1: "",
    optionalData2: "",
    personFaceImage: "",
    type: "",
    IsLifeTimeCnic: false
  }

  public SecIdCardObject: SecIdCardExpireModel = {
    PrimaryUserId: "",
    CompanyId: 1,
    CountryID: "",
    DOB: "",
    DOE: "",
    DocumentCode: "",
    DocumentNumber: "",
    FamilyName: "",
    fireBaseToken: "",
    FirstName: "",
    FrontCardImage: "",
    BackCardImage: " ",
    FullName: "",
    Gender: "",
    issuedate: "",
    Issuer: "",
    MiddleName: "",
    MrtDraw: "",
    Nationality: "",
    OptionalData1: "",
    OptionalData2: "",
    PersonFaceImage: "",
    Type: "",
    IsLifeTimeCNIC: false
  }
  public loginobject: LoginModel = {
    Email: '',
    Password: '',
    UniqueId: '',
    CaptchaId: '',
    UserEnteredCaptchaCode: ''
  }

  public loginobjectV5: LoginModelV5 = {
    Email: '',
    Password: '',
    UniqueId: '',
    RecaptchaToken: '',
    Ip : '',
  }

  public LoginResidentObject : LoginResidentModel = {
    userName: '',
    Password: '',
    UniqueId: '',
    RecaptchaToken: '',
    Ip: '',
  }

  public selectedCountry: Country = {
    mask: '',
    mobileLength: 0,
    isActive: false,
    isBlink: false,
    isColorTest: false,
    isFeatured: false,
    isMobile: false,
    isStayAllowed: false,
    isoCode: '',
    isoCode3: '',
    countryName: '',
    createdDate: new Date(),
    crossAllowed: false,
    featureOrder: 0,
    flagClass: '',
    foriegnIssuerAllowed: false,
    foriegnNationalityAllowed: false,
    primaryIssuerAllowed: false,
    primaryNationalityAllowed: false,
    idLength: 9,
    mobileCode: '',
    mobileOperators: [],
    nationality: '',
    twoParts: false,
    mobileMaxLength: 0
  }

  constructor(
    private http: HttpServiceService,
    private config: ConfigService,
    private storagemap: StorageMap
  ) {
  }

  Get(key: string): Promise<any> {

    debugger

    return new Promise((resolve) => {
      this.storagemap.get(key)
        .subscribe(value => {


          if (value) {
            resolve(value);
          }
          else {
            resolve("");
          }
        }, error => {


          resolve("");
        })
    })

  }

  delete(Key: string): Promise<any> {
    return new Promise((resolve) => {
      this.storagemap.delete(Key);
    })
  }

  public ClearDetail() {
    this.data = {
      faceImage: "",
      newDevice: false,
      onBoardingAccount: {
        iban: "",
        accountStatus: "",
        accountNumber: "",
        productId: 1,
        applicationId: '',
        currency: "",
        callStatus: "",
        debitBlockStatus: false
      },
      fullname: "",
      emailId: "",
      mobileNumber: "",
      mobileStatus: false,
      emailStatus: false,
      isExisting: false,
      userId: "",
      nationality: "",
      firstName: "",
      residenceCountry: "",
      lastName: "",
      gender: "",
      dob: new Date(),
      idIssuer: "",
      tokenTimeOut: null,
      PassportNationality: "",
      IsIdCardExpire: false,
      PersonFaceImage: "",
      middleName: "",
      coRaddfld: "",
      cob: "",
      fatherName: "",
      feedbackResponse: false,
      firstBio: false,
      isForeignTaxPayed: false,
      isUSMobileNo: false,
      occupation: "",
      videocall: "",
      PassportExpiry: false,
      IsSpentDays: false
    };
  }

  public SaveTempImage(token, image) {

    if (Array.isArray(image)) {
      
      return new Promise((resolve, reject) => {
        this.http.postToCloseApi(
          this.config.server + this.SaveTempImageUrl,
          { Image: image[1], Type: image[3] }, token).subscribe((data: any) => {
            if (data.response) {
              if (data.response.code) {
                if (data.response.code == 1) {

                }
              }
            }
            resolve(data);
          },
            Error => {
              resolve(Error);
            })
      });
    }
    else {
      
      return new Promise((resolve, reject) => {
        this.http.postToCloseApi(
          this.config.server + this.SaveTempImageUrl,
          { Image: image, Type: 'JPEG/PNG' }, token).subscribe((data: any) => {
            if (data.response) {
              if (data.response.code) {
                if (data.response.code == 1) {

                }
              }
            }
            resolve(data);
          },
            Error => {
              resolve(Error);
            })
      });
    }
  }

  public saveTempimage2(token, image, type) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SaveTempImageUrl, { Image: image, Type: type }, token).subscribe((data: any) => {
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveTempDocs(token, image) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SaveTempDocUrl,
        { DocBased64: image }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public GEttep(IsResident) {
    return new Promise((resolve, reject) => {

      if(IsResident == '1'){

        this.http.getFromOpenApi("assets/ResidentSteps.json", "").subscribe((data: any) => {
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
      }
      else{

        this.http.getFromOpenApi("assets/Steps.json", "").subscribe((data: any) => {
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
      }
    });
  }

  public GetAPIUrl() {
    return new Promise((resolve, reject) => {
      this.http.getFromOpenApi(
        "assets/APIURL.json", "").subscribe((data: any) => {
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public GetDashboardText() {
    return new Promise((resolve, reject) => {
      this.http.getFromOpenApi(
        "assets/dashboardText.json", "").subscribe((data: any) => {
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }




  public ChangeEmailOnboarding(emailId, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.ChangeOnboardingEmail,
        { EmailId: emailId }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public ChangeEmailOnboardingSec(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.ChangeOnboardingEmailSecURL,
        { EmailId: object.emailid, SecUserId: object.SecUserId }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SavePageCounter(object) {
    return new Promise((resolve, reject) => {
      this.http.postToOpenApi(this.config.server + this.Savepagecounterurl, object).subscribe((data: any) => {
        if (data.response) {
          if (data.response.code) {
            if (data.response.code == 1) {
              this.data = data.response.content;
            }
          }
        }
        resolve(data);
      },
        Error => {
          resolve(Error);

        })
    })
  }

  public FetchBrokerListMethod(object) {
    return new Promise((resolve, reject) => {

      this.http.postToOpenApi(this.config.server + this.FetchBrokerListURL, { UniqueId: object }).subscribe((data: any) => {
        if (data?.response?.code == 1) {
          resolve(data);
        }
      },
        Error => {
          resolve(Error);
        })
    })
  }

  public CheckEmail(emailId, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.checkemailURL,
        { EmailId: emailId }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public CheckUserName(username, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.checkusernameURL, { Username: username }, token).subscribe((data: any) => {
        resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }


  public CheckOtherAccountMethod(token) {
    return new Promise((resolve, reject) => {

      this.http.postToCloseApi(this.config.server + this.CheckOtherAccountReqURL, { userId: this.data.userId }, token).subscribe((data: any) => {
        if (data?.response?.code == 1) {
          resolve(data);
        }
      },
        Error => {
          resolve(Error);
        })
    })
  }

  public ChangeMobileOnboarding(mobile, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.ChangeOnboardingMobile,
        { mobileNumber: mobile }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }


  public BalanceInquiry(uniquId, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.BalanceInqURL,
        { UniqueId: uniquId }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public LinkAccount(baiscAccount, ApplicationId, uniquId, Currency, AccounType, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.LinkAccountURL,
        { UniqueId: uniquId, ApplicationId: ApplicationId, Currency: Currency, BasicAccount: baiscAccount, AccountType: AccounType }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveOtherLinkAccount(token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.LinkAccountrequestURL, this.LinkAccountRequestObj, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public ConversionForm(ApplicationId, uniquId, DebitCurrency, DebitAccount, CreditCurrency, CreditAccount, ExRate, Amount, AmountInWords, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.ConversionURL,
        { UniqueId: uniquId, ApplicationId: ApplicationId, DebitCurrency: DebitCurrency, DebitAccount: DebitAccount, CreditCurrency: CreditCurrency, CreditAccount: CreditAccount, ExRate: ExRate, AmountInWord: AmountInWords, Amount: Amount }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }


  public SaveNPCFORM(ApplicationId, uniquId, DebitCurrency, DebitAccount, ExRate, Amount, Tenure, SelectedCertificate, numberOfCertificate, totlaamount, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SaveNPCURL,
        { TotalAmount: totlaamount, UniqueId: uniquId, ApplicationId: ApplicationId, DebitCurrency: DebitCurrency, DebitAccount: DebitAccount, ExRate: ExRate, Amount: Amount, NumberOfCertificates: numberOfCertificate, Tenure: Tenure, CertificateCurrency: SelectedCertificate }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public VerifyEmailOnboarding(otp, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.VerifyOnboardingEmail,
        { otpCode: otp }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public VerifyEmailOnboardingSec(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.VerifyOnboardingEmailSecURL,
        { otpCode: object.code, SecUserId: object.SecUserId }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public GetExchangeRate(token, uniqueId) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.GetExchangeRateURL,
        { UniqueId: uniqueId }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {
                this.CurrencyRate = data.response.content.pkrList;

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public GetAllRate(token, uniqueId) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.GetAllExchangeRateURL,
        { UniqueId: uniqueId }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {


              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public VerifyMobileOnboarding(otp, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.VerifyOnboardingMobile,
        { otpCode: otp }, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }


  public SendOnboardingMobileOTP(token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SendOnboardingMobileOTPURL, {}, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public ResendMobileOTP(token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.SendOnboardingMobileOTPURL, {}, token)
        .subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public ResendEmailOTP(token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.ResendEmailOTPURL, {}, token)
        .subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public ResendEmailOTPSec(SecUserId, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.ResendEmailOTPSecURL, { SecUserId: SecUserId }, token)
        .subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public clearRegistrationObject() {

    this.registrationObject = {
      backCardImage: "",
      familyName: "",
      fireBaseToken: "",
      firstName: "",
      frontCardImage: "",
      fullName: "",
      dOB: "",
      dOE: "",
      documentCode: "",
      documentNumber: "",
      email: "",
      residenceCountry: "",
      issuer: "",
      optionalData1: "",
      optionalData2: "",
      middleName: "",
      mobileNumber: "",
      mrtdraw: "",
      nationality: "",
      companyId: 1,
      confirmPassword: "",
      countryID: "",
      password: "",
      personFaceImage: "",
      platform: "",
      gender: "",
      issuedate: "",
      FatherName: "",
      CityId: null,
      BranchId: null,
      IsSpent: true,
      UniqueId: "",
      IsUSMobileNo: null,
      IsLifeTimeCnic: null,
      videocall: "",
      IsForeignTaxPayed: false,
      IsReferred: false,
      Referredby: ''
    };
  }

  public clearRegistrationSecObject() {

    this.registrationSecObject = {
      backCardImage: "",
      familyName: "",
      fireBaseToken: "",
      firstName: "",
      frontCardImage: "",
      fullName: "",
      dOB: "",
      dOE: "",
      documentCode: "",
      documentNumber: "",
      email: "",
      residenceCountry: "",
      issuer: "",
      optionalData1: "",
      optionalData2: "",
      middleName: "",
      mobileNumber: "",
      mrtdraw: "",
      nationality: "",
      companyId: 1,
      countryID: "",
      personFaceImage: "",
      platform: "",
      gender: "",
      issuedate: "",
      FatherName: "",
      CityId: null,
      BranchId: null,
      IsSpent: true,
      UniqueId: "",
      IsUSMobileNo: null,
      IsLifeTimeCnic: null,
      videocall: "",
      IsForeignTaxPayed: false,
      PrimaryUserId: "",


    };
  }


  public GetVersion() {

    return new Promise((resolve, reject) => {
      this.http.getFromOpenApi(
        this.config.server, this.GetVersionUrl + "?Platform=" + "A")
        .subscribe((data) => {

          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    })
  }

  public Login(IsResident) {

    // let hashobject: HashModel = {
    //   IncomingObject: object,
    //   GeneratedHash: ''
    // }
    return new Promise((resolve, reject) => {
      
      var object = IsResident == '1' ? this.LoginResidentObject : this.loginobjectV5;
      var Url = IsResident == '1' ? this.LoginResidentURL : this.LoginURL;

      this.http.postToOpenApi(this.config.server + Url, object).subscribe((data: any) => {
        if (data?.response?.code == 1) {
        }
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public Register(IsResident) {

    return new Promise((resolve, reject) => {

      var Object = IsResident == '1' ? this.RegistrationResidentObject : this.registrationObject;
      var Url = IsResident == '1' ? this.RegisterResidentURL : this.RegisterURL;

      this.http.postToOpenApi(this.config.server + Url, Object).subscribe((data: any) => {
        if (data?.response?.code == 1) {
          this.data = data.response.content;
          resolve(data);
        }
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public RegisterSec() {

    return new Promise((resolve, reject) => {
      this.http.postToOpenApi(this.config.server + this.RegisterSecURL, this.registrationSecObject).subscribe((data: any) => {
        if (data?.response?.code == 1) {
          this.data = data.response.content;
          resolve(data);
        }
      },
        Error => {
          resolve(Error);
        })
    });
  }


  public SendBackOfficeEmailFunction(token, UniqueId) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.SendBackOfficeEmailURL, { UniqueId: UniqueId }, token).subscribe((data: any) => {
        if (data.response) {
          if (data.response.code) {
            if (data.response.code == 1) {

            }
          }
        }
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    })
  }

  public SaveIdCard(token, object) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.IdCardSaveURL, object, token)
        .subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveSecIdCard(token, object) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.SecIdCardSaveURL, object, token)
        .subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }
  public SendOTPByType(token, type) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.SendOTPByTypeURL,
        { Type: type }, token)
        .subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SendForgotPasswordOTP(IdNumber, uniqueId) {
    return new Promise((resolve, reject) => {

      this.http.postToOpenApi(this.config.server + this.SendForgotPOTPURL, { idNumber: IdNumber, uniqueId: uniqueId }).subscribe((data: any) => {
        if (data.response) {
          if (data.response.code) {
            if (data.response.code == 1) {


            }
          }
        }
        resolve(data);

      }, Error => {
        resolve(Error);

      })
    });

  }
  public VerifyForgotPasswordOTP(IdNumber, uniqueId, otp) {
    return new Promise((resolve, reject) => {

      this.http.postToOpenApi(this.config.server + this.VerifyForgotPasswordOTPURL, { IdNumber: IdNumber, UniqueId: uniqueId, OTPCODE: otp }).subscribe((data: any) => {
        if (data.response) {
          if (data.response.code) {
            if (data.response.code == 1) {


            }
          }
        }
        resolve(data);

      }, Error => {
        resolve(Error);

      })
    });

  }
  public ChangePassword(IdNumber, uniqueId, otp, Password, ConfirmPassword) {
    return new Promise((resolve, reject) => {

      this.http.postToOpenApi(this.config.server + this.ChangePasswordURL, { IdNumber: IdNumber, UniqueId: uniqueId, OTPCODE: otp, Password: Password, ConfirmPassword: ConfirmPassword }).subscribe((data: any) => {
        if (data.response) {
          if (data.response.code) {
            if (data.response.code == 1) {


            }
          }
        }
        resolve(data);

      }, Error => {
        resolve(Error);

      })
    });

  }
  public VerifyOTPByType(token, type, otp) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.VerifyOTPByTypeURL,
        { Type: type, OTPCode: otp }, token)
        .subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  RegisterSession(token) {

    this.accessToken = token
  }
}
