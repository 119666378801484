<app-header3></app-header3>

<div class="container mt-46">
  <div class="ng-row" justify-content-center>
    <div class="ng-col pt-30 pd-sm-30" col-9 col-sm-12>

      <div class="ng-row pb-30">
        <div class="ng-col">
          <div class="font-30 color-2f5 font-600 mt-5">
            {{"NayaPakistanEncashmentForm" | translate}}
          </div>

          <form [formGroup]="NPEncashForm">

            <div class="form-group mt-30">
              <label>{{"BasicAccountNumber" | translate}}</label>
              <select class="custom-select" (change)="ValueChange()" [(ngModel)]="selectedAccont"
                formControlName="BasicAccNo">
                <option disabled hidden value="undefined" selected>Please select</option>
                <option *ngFor="let data of this.auth.AllBalance" [ngValue]="data.accountNumber">{{data.accountNumber}}
                </option>
              </select>
              <ng-container item-content *ngFor="let validation of errorKey.validation_messages.accountNo">
                <span class="error-label"
                  *ngIf="NPEncashForm.controls.BasicAccNo.hasError(validation.type) &&(NPEncashForm.controls.BasicAccNo.touched ||submitAttempt)">
                  {{ getMessage(validation.key,'accountNo') }}
                </span>
              </ng-container>
            </div>

            <div class="form-group mt-30">
              <label>{{"PreMatureencashment" | translate}}</label>
              <input type="text" placeholder="Please enter" maxlength="20" minlength="3"
                formControlName="PreMatureCertNo">
              <ng-container item-content *ngFor="let validation of errorKey.validation_messages.PreMatureCertNo">
                <span class="error-label"
                  *ngIf="NPEncashForm.controls.PreMatureCertNo.hasError(validation.type) &&(NPEncashForm.controls.PreMatureCertNo.touched ||submitAttempt)">
                  {{ getMessage(validation.key,'PreMatureCertNo') }}
                </span>
              </ng-container>
            </div>


            <div class="form-group mt-30">
              <label class="no-mark">{{"valuestartdate" | translate}}</label>
              <div class="ng-row mt-5" justify-content-between>
                <div class="ng-col" col-3 col-sm-12>
                  <div class="form-group">
                    <select [(ngModel)]="this.startdatedaySelect" id="startdatedayslist" name="startdatedayslist"
                      formControlName="startdatedaySelect" (change)="startdateSelectionchange()">
                      <option hidden [ngValue]=null disabled selected>Date</option>
                      <option [ngValue]=a *ngFor="let a of this.startdatedayslistArray">{{a}}</option>
                    </select>
                  </div>
                </div>
                <div class="ng-col p-l-r" col-6 col-sm-12>
                  <div class="form-group">
                    <select [(ngModel)]="this.startdateMonthSelect" id="startdatemonthlist" name="startdatemonthlist"
                      formControlName="startdateMonthSelect" (change)="startdateSelectionchange()">
                      <option hidden [ngValue]=null disabled selected>Month</option>
                      <option [ngValue]=a.value *ngFor="let a of this.startdatemonthlistArray">{{a.text}}</option>
                    </select>
                  </div>

                </div>
                <div class="ng-col" col-3 col-sm-12>
                  <div class="form-group">
                    <select [(ngModel)]="this.startdateYearSelect" formControlName="startdateYearSelect"
                      (change)="startdateSelectionchange()">
                      <option hidden [ngValue]=null disabled selected>Year</option>
                      <option [ngValue]=a *ngFor="let a of this.startdateyears">{{a}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <span class="error-label" *ngIf="this.startdateError">{{ "startdateerror" | translate}}</span>

            </div>

            <div class="form-group mt-30">
              <label class="no-mark">{{"maturitydate" | translate}}</label>
              <div class="ng-row mt-5" justify-content-between>
                <div class="ng-col" col-3 col-sm-12>
                  <div class="form-group">
                    <select [(ngModel)]="this.MaturityDatedaySelect" id="MaturityDatedayslist"
                      name="MaturityDatedayslist" formControlName="MaturityDatedaySelect"
                      (change)="MaturityDateSelectionchange()">
                      <option hidden [ngValue]=null disabled selected>Date</option>
                      <option [ngValue]=a *ngFor="let a of this.MaturityDatedayslistArray">{{a}}</option>
                    </select>
                  </div>

                </div>
                <div class="ng-col p-l-r" col-6 col-sm-12>
                  <div class="form-group">
                    <select [(ngModel)]="this.MaturityDateMonthSelect" id="MaturityDatemonthlist"
                      name="MaturityDatemonthlist" formControlName="MaturityDateMonthSelect"
                      (change)="MaturityDateSelectionchange()">
                      <option hidden [ngValue]=null disabled selected>Month</option>
                      <option [ngValue]=a.value *ngFor="let a of this.MaturityDatemonthlistArray">{{a.text}}</option>
                    </select>
                  </div>

                </div>
                <div class="ng-col" col-3 col-sm-12>
                  <div class="form-group">
                    <select [(ngModel)]="this.MaturityDateYearSelect" formControlName="MaturityDateYearSelect"
                      (change)="MaturityDateSelectionchange()">
                      <option hidden [ngValue]=null disabled selected>Year</option>
                      <option [ngValue]=a *ngFor="let a of this.MaturityDateyears">{{a}}</option>
                    </select>
                  </div>

                </div>
              </div>
              <span class="error-label" *ngIf="this.MaturityDateError">{{ "MaturityDateerror" | translate}}</span>
            </div>
          </form>



          <div class="form-group mt-30">
            <button class="btn-dft btn-auto" (click)="AddInCertificateList()" [disabled]='IsFormValid()'>Add</button>
            <div class="hint-text mt-10">
              <ul>
                <li>{{'CertificateAddHint'| translate}}</li>
              </ul>
            </div>
          </div>

          <div class="currency-table-parent mt-30" *ngIf="this.CertificatesArray.length > 0">
            <div class="currency-table">
              <div class="currency-table-head bg-F6F pd">
                <div class="ng-row" align-item-center>
                  <div class="ng-col" col-3>
                    <div class="font-14 color-354 font-400 text-align-center">
                      {{"Certificatenumber" | translate}}
                    </div>
                  </div>
                  <div class="ng-col" col-3>
                    <div class="font-14 color-354 font-400 text-align-center">
                      {{"valuestartdate" | translate}}
                    </div>
                  </div>
                  <div class="ng-col" col-3>
                    <div class="font-14 color-354 font-400 text-align-center">
                      {{"maturitydate" | translate}}
                    </div>
                  </div>
                  <div class="ng-col" col-3>

                  </div>
                </div>
              </div>
              <div class="currency-table-body p-l-r">
                <div class="ng-row border-bottom pt-20 pb-15 currency-table-item"
                  *ngFor="let a of this.CertificatesArray let i=index;" align-item-center>
                  <div class="ng-col" col-3>
                    <div class="font-12 color-354 font-400 text-align-center">
                      {{a.PreMatureCertNo}}
                    </div>
                  </div>
                  <div class="ng-col" col-3>
                    <div class="font-12 color-354 font-400 text-align-center">
                      {{(a.StartDate == "") ? '-' : a.StartDate}}
                    </div>
                  </div>
                  <div class="ng-col" col-3>
                    <div class="font-12 color-354 font-400 text-align-center">
                      {{(a.MaturityDate == "") ? '-' : a.MaturityDate}}
                    </div>
                  </div>
                  <div class="ng-col" col-3>
                    <button class="btn-dft btn-small" (click)="Remove(i)">Remove</button>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div class="font-14 color-354 font-400 mt-20">
            {{"ThisApplicationis" | translate}}
          </div>


          <div class="form-group mt-30">
            <button class="btn-dft" [disabled]="isFormValid()" (click)="FormPost()">
              {{"Continue" | translate}}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-fab></app-fab>