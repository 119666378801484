<app-header></app-header>

<div class="container email-component mt-46">
  <div class="ng-row height-100">
    <div class="ng-col" col-3 col-sm-12>
      <app-progress-bar-circle [counter]="counter" [current]="current" [max]="max" [total]="3">
      </app-progress-bar-circle>
    </div>
    <div class="ng-col pt-30 pd-sm-30" col-7 col-sm-12>

      <div class="ng-row height-100 pb-30" align-content-between>
        <div class="ng-col">
          <div class="font-30 color-2f5 font-600 mt-5">
            {{"OnetimePasscode" | translate}}
          </div>

          <div class="font-18 color-2f5 font-600 mt-15">
            {{"Pleaseentertheonetime" | translate}} {{this.email}} {{"oryourregistered" | translate}} + {{ numbertext }}
          </div>


          <form class="bg-f6f7" [formGroup]="registerForm">
            <div class="form-group mt-30">
              <label>{{"MobileVerificationCode" | translate}}</label>
              <input type="text" mask="999999" value="" [(ngModel)]="code" formControlName="otp"
                placeholder="6-digit code">
              <ng-container item-content *ngFor="let validation of errorKey.validation_messages.otp">
                <span class="error-label"
                  *ngIf="registerForm.controls.otp.hasError(validation.type) &&(registerForm.controls.otp.touched ||submitAttempt)">
                  {{ getMessage(validation.key,'otp') }}
                </span>
              </ng-container>
            </div>

          </form>

        </div>

        <div class="ng-col mb-20">
          <div class="form-group mt-15">
            <button class="btn-dft-white" disabled *ngIf="!this.resendOptbtnShow" (click)="SendAgain()">
              {{'Resendcodein' | translate}} {{timerexpire}}
            </button>
            <button class="btn-dft-white" *ngIf="this.resendOptbtnShow" (click)="SendAgain()">
              {{"Senditagain" | translate}}
            </button>
          </div>

          <div class="form-group mt-15">
            <button class="btn-dft" [disabled]="isFormValid()" (click)="Next()">
              {{"Continue" | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-fab></app-fab>