import { Component, Input, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from './../Services/auth.service';
import { CommonService } from './../Services/common.service';
import { ConfigService } from '../Services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { KycService } from './../Services/kyc.service';
import { CountriesService, Country } from '../Services/countries.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocationStrategy } from '@angular/common';
import { Guid } from 'guid-typescript';
import { PasswordValidation } from './../Validators/PasswordValidators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  RegistrationForm: FormGroup;
  _password: string = "";
  _confirmPassword: string = "";
  uniqueId: Guid;
  id: string;
  counter: string;
  current: string;
  max: string;
  submitAttempt: boolean = false;
  numbertext;
  idnumber;
  otp;

  constructor(
    private kyc: KycService,
    private country: CountriesService,
    public errorKey: ErrorMessageKeyService,
    private translate: TranslateService,
    private commonP: CommonService,
    private auth: AuthService,
    private config: ConfigService,
    private route: ActivatedRoute,
    private _router: Router,
    public formBuilder: FormBuilder,
    private location: LocationStrategy,
    public Renderer2: Renderer2,
    public countries: CountriesService,
  ) { }

  async ngOnInit(): Promise<void> {
    
    this.RegistrationForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$&+,:;=?@#|\'<>.^*()%!-]).{8,15}$')])],
      confirmPassword: ['', Validators.compose([Validators.required,])],
    }, { validator: PasswordValidation.MatchPassword })

    this.counter = "3";
    this.current = "3";
    this.max = "3";



    let a = await this.commonP.Get('MobileNumber');
    let d = await this.commonP.Get('Idnumber');
    let e = await this.commonP.Get('Random');
    if (a) {
      this.numbertext = a;
    }
    if (d) {
      this.idnumber = d;
    }
    if (e) {
      this.otp = e;
    }

  }

  isFormValid() {
    if (!this.RegistrationForm.valid) {
      return true;
    } else {
      false;
    }
  }

  getMessage(key, control) {

    let error = "";

    this.translate.get([key]).subscribe(data => {

      error = data[key];
    })
    return error;
  }

  Next() {
    this.uniqueId = Guid.create();
    this.id = this.uniqueId.toString();

    var passwordfld = this.RegistrationForm.get('password').value;
    var confirmpasswordfld = this.RegistrationForm.get('confirmPassword').value;

    this.commonP.presentLoading();

    this.auth.ChangePassword(this.idnumber, this.id, this.otp, passwordfld, confirmpasswordfld).then((data: any) => {
      if (data?.response?.code == 1) {
        this.commonP.hideLoading();
        this.translate.get(["Success", "Ok", "PasswordReset"]).subscribe((data) => {
          this.commonP.showSuccessAlert(data["Success"], data["PasswordReset"], data["Ok"], this._router).then((data: any) => {
            this._router.navigateByUrl('/login');
          });
        })
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert('', '', this._router, data);
      }
    })
  }
}
