import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, TemplateRef, Renderer2, AfterContentInit } from '@angular/core';
import * as _ from 'lodash';
import { Guid } from "guid-typescript";
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { CommonService } from '../../Services/common.service'
import { Router } from '@angular/router';
import { ConfigService } from '../../Services/config.service';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-attach-box3',
  templateUrl: './attach-box3.component.html',
  styleUrls: ['./attach-box3.component.scss']
})
export class AttachBox3Component implements OnInit {

  imageError: string = null;
  isImageSaved: boolean;
  cardImageBase64: string;
  image64: string[];
  imagebase64: string;
  identity1: number;
  uniqueId: Guid;
  id: string;
  imagesrc;
  cameraactive: boolean = false;
  localstream;
  imageviewer: boolean;
  AttachImage: boolean;
  id2: string;
  newunique: Guid;
  selectedfile: FileList;
  PdfSaved: boolean;
  AttachedPDFLink: string;
  pdfsrc = '';

  @Input() attachedHeading: string;
  @Input() CameraViewer: boolean = true;
  @Input() AsterikShow: boolean = false;
  @Input() attachedText: string = "UploadJPEG";
  @Input() ObjectName: string;
  @Input() PDFName: string;
  @Output() callParentFunction = new EventEmitter<any>();
  @Output() callAnotherFunction = new EventEmitter<any>();
  @ViewChild("modal") Modal: ElementRef;
  @ViewChild("imageid") imagetag: ElementRef;
  @ViewChild("imageid2") imagetag2: ElementRef;
  @ViewChild('takeinput', { static: false }) InputVar: ElementRef;
  @ViewChild('takeinput2', { static: false }) InputVar2: ElementRef;
  @ViewChild('pdfviewer') pdf: ElementRef;
  @ViewChild('viewlink', { static: false }) viewlink: ElementRef;
  @ViewChild("modal2") Modal2: ElementRef;
  @ViewChild("modal3") Modal3: ElementRef;
  @Input() IsSupportComponent : boolean = false; 

  @Input() closeButton: boolean = false;
  @Output() closeButtonFunction = new EventEmitter<any>();

  constructor(
    public renderer: Renderer2,
    public common: CommonService,
    public router: Router,
    private config: ConfigService,
  ) { }

  ngOnInit(): void {
    this.uniqueId = Guid.create();
    this.id = this.uniqueId.toString();

    this.newunique = Guid.create();
    this.id2 = this.newunique.toString();

    var classes = document.getElementsByClassName('ng2-pdf-viewer-container');
    for (var i = 0; i < classes.length; i++) {
      classes[i].setAttribute('style', 'display :block; height: 0px; width: 0px');
    }
  }

  closeClick() {
    this.closeButtonFunction.emit();
  }

  viewButtonLink(link) {
    window.open(this.AttachedPDFLink, '_blank');
  }

  async fileChangeEvent(fileInput: any) {

    this.common.presentLoading();
    
    if (this.ObjectName == "Headpic" || this.ObjectName == "Sign") {
      this.imageError = null;
      if (fileInput.target.files && fileInput.target.files[0]) {
        // Size Filter Bytes
        const max_size = 5000000;
        const allowed_types = ['image/png', 'image/jpeg'];
        const max_height = 2229;
        const max_width = 8386;

        if (fileInput.target.files[0].size > max_size) {
          this.imageError =
            'You have exceeded 5 MB. Please upload a valid format page.';
          this.common.hideLoading();
          return false;
        }

        if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
          this.imageError = 'Please upload a valid JPEG, PNG or JPG format. Max size: 5 MB';
          this.common.hideLoading();
          return false;
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
        
          const image = new Image();
          image.src = e.target.result;
          image.onload = rs => {
            const img_height = rs.currentTarget['height'];
            const img_width = rs.currentTarget['width'];

            if (img_height > max_height && img_width > max_width) {
              this.imageError =
                'Maximum dimentions allowed ' +
                max_height +
                '*' +
                max_width +
                'px';
              return false;
            }
            else {
              const imgBase64Path = e.target.result;
              this.cardImageBase64 = imgBase64Path;
              this.image64 = this.cardImageBase64.split(',');
              this.imagebase64 = this.image64[1];

              this.renderer.setAttribute(this.imagetag.nativeElement, 'src', this.image64[0] + ',' + this.image64[1]);
              this.AttachImage = true;
              this.isImageSaved = true;
              
              if(this.IsSupportComponent){
                
                var Url = URL.createObjectURL(fileInput.target.files[0]);
                var extension = this.image64[0].split(';')[0].split('/')[1].toString();
                var ARRAY = [Url,this.imagebase64,'Name',extension]
                this.callParentFunction.emit(ARRAY);
              }
              else{
                
                this.callParentFunction.emit(this.imagebase64);
              }
            }
          };
          image.onerror = error => {

            this.InputVar.nativeElement.value = "";
            this.cardImageBase64 = null;
            this.isImageSaved = false;
            this.PdfSaved = false;
            this.imagebase64 = null;
            this.imagebase64 = undefined;
            this.common.showErrorAlert("Sorry", "Corrupted File", "OK", this.router);
            this.common.hideLoading();
          }
        };
        reader.readAsDataURL(fileInput.target.files[0]);
      }
    }
    else {
      var AllowedArray = this.config.AllowedTypeArray.split(',');

      for (var i = 0; i < AllowedArray.length; i++) {
        if (AllowedArray[i] == "pdf") {
          AllowedArray[i] = 'application/pdf'
        }
        else {
          AllowedArray[i] = 'image/' + AllowedArray[i];
        }
      }

      this.imageError = null;
      if (fileInput.target.files && fileInput.target.files[0]) {
        // Size Filter Bytes
        const max_size = 5000000;
        const allowed_types = AllowedArray;
        const max_height = 2229;
        const max_width = 8386;

        if (fileInput.target.files[0].size > max_size) {
          this.imageError =
            'You have exceeded 5 MB. Please upload a valid format page.';
          this.common.hideLoading();
          return false;
        }

        if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
          this.imageError = 'Only PDF, JPEG and PNG formats are allowed.';
          this.common.hideLoading();
          return false;
        }

        const reader = new FileReader();
        reader.onload = async (e: any) => {
          
          this.pdfsrc = e.target.result;
          this.selectedfile = fileInput.target.files;
          var url = URL.createObjectURL(this.selectedfile[0]);
          var array = e.target.result.split(',');

          if (array[0] == "data:application/pdf;base64") {
            
            var a = array[0].split(';');
            var b = a[0].split('/');
            var extension = stringify(b[1]);

            setTimeout(() => {

              if (!this.pdferror) {

                this.imagebase64 = array[1];
                this.PdfSaved = true;

                var ARRAY: string[];
                ARRAY = [url, this.imagebase64, 'Name', extension];
                this.callParentFunction.emit(ARRAY);
                this.AttachedPDFLink = ARRAY[0];
                // this.common.hideLoading();
              }
              else {

                this.InputVar.nativeElement.value = "";
                this.cardImageBase64 = null;
                this.isImageSaved = false;
                this.PdfSaved = false;
                this.imagebase64 = null;
                this.imagebase64 = undefined;
                this.common.showErrorAlert("Sorry", "Corrupted File", "OK", this.router);
                this.common.hideLoading();
              }
            }, 1000);
          }
          else if (array[0] == "data:image/png;base64" || array[0] == "data:image/jpeg;base64") {

            var a = array[0].split(';');
            var b = a[0].split('/');
            var extension = stringify(b[1]);

            var image = new Image();
            image.src = e.target.result;

            image.onload = load => {

              this.imagebase64 = array[1];
              this.isImageSaved = true;
              this.AttachImage = true;
              this.renderer.setAttribute(this.imagetag.nativeElement, 'src', array[0] + ',' + array[1]);
              var ARRAY: string[];
              ARRAY = [url, this.imagebase64, 'Name', extension];
              this.callParentFunction.emit(ARRAY);
              // this.common.hideLoading();
            }
            image.onerror = error => {

              this.InputVar.nativeElement.value = "";
              this.cardImageBase64 = null;
              this.isImageSaved = false;
              this.PdfSaved = false;
              this.imagebase64 = null;
              this.imagebase64 = undefined;
              this.common.showErrorAlert("Sorry", "Corrupted File", "OK", this.router);
              this.common.hideLoading();
            }
          }
        };
        reader.readAsDataURL(fileInput.target.files[0]);
      }
    }
  }

  pdferror: boolean;
  err() {
    this.pdferror = true;
  }

  loaded() {
    this.pdferror = false;
  }

  removeImage() {
    this.InputVar.nativeElement.value = "";
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.PdfSaved = false;
    this.imagebase64 = null;

    this.callAnotherFunction.emit();
    this.renderer.removeClass(this.Modal.nativeElement, "active");
  }

  removeImage3() {
    this.renderer.setAttribute(this.imagetag2.nativeElement, 'src', "");

    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.PdfSaved = false;
    this.imagebase64 = null;

    this.callAnotherFunction.emit();
    this.renderer.removeClass(this.Modal3.nativeElement, "active");
  }

  removePdf(data) {

    this.InputVar.nativeElement.value = "";
    this.callAnotherFunction.emit(data);
    this.imagebase64 = null;
    this.PdfSaved = false;
  }

  hidePopup() {
    this.renderer.removeClass(this.Modal.nativeElement, "active");
  }

  hidePopup3() {
    this.renderer.removeClass(this.Modal3.nativeElement, "active");
    this.cameraactive = false;
    this.localstream.getTracks()[0].stop();
  }

  showPopup() {
    this.renderer.addClass(this.Modal.nativeElement, "active");
  }

  showPopup3() {
    if (this.AttachImage) {
      this.renderer.addClass(this.Modal.nativeElement, "active");

    } else {
      this.renderer.addClass(this.Modal3.nativeElement, "active");

    }
  }

  showPopup1() {
    this.cameraactive = true;
    this.checkCamera();
  }

  checkCamera() {
    var that = this;
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(function (stream) {
        that.localstream = stream;
        /* use the stream */

        that.cameraactive = true;
        that.renderer.addClass(that.Modal2.nativeElement, "active");
      })
      .catch(function (err) {
        /* handle the error */

        that.common.ParseErrorAlert('Sorry', 'OK', that.router, 'Your device does not support this feature');
        that.cameraactive = false;

      });
  }



  hidePopup1() {
    this.renderer.removeClass(this.Modal2.nativeElement, "active");
    this.cameraactive = false;
    this.localstream.getTracks()[0].stop();
  }


  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  triggerSnapshot(): void {
    this.trigger.next();
    this.imageviewer = true;
  }

  handleImage(webcamImage: WebcamImage): void {
    console.info('Saved webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.renderer.setAttribute(this.imagetag2.nativeElement, 'src', this.webcamImage.imageAsDataUrl);
    this.isImageSaved = true;
    this.AttachImage = false;
    this.callParentFunction.emit(this.webcamImage.imageAsBase64);
    this.hidePopup1();

  }

  public get triggerObservable(): Observable<void> {

    if (this.cameraactive) {
      return this.trigger.asObservable();
    }
  }

}

