import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { HeaderComponent } from "./header/header.component";
import { ProgressBarCircleComponent } from "./progress-bar-circle/progress-bar-circle.component";
import { RoundProgressModule } from "angular-svg-round-progressbar";
import { AttachBoxComponent } from "./attach-box/attach-box.component";
import { CircleIconComponent } from "./circle-icon/circle-icon.component";
import { CustomSelectComponent } from "./custom-select/custom-select.component";
import { AttachBox2Component } from "./attach-box2/attach-box2.component";
import { WebcamModule } from "ngx-webcam";
import { DropDownListComponent } from "./drop-down-list/drop-down-list.component";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
// import { PdfViewerModule } from "ng2-pdf-viewer";
import { Ng2SearchPipeModule } from "ng2-search-filter";

import { FormsModule } from "@angular/forms";
import { FabComponent } from "./fab/fab.component";
import { Header2Component } from "./header2/header2.component";
import { Header3Component } from "./header3/header3.component";
import { AttachBox3Component } from "./attach-box3/attach-box3.component";
import { Header4Component } from "./header4/header4.component";
import { Header5Component } from "./header5/header5.component";
import { AccordionComponent } from "./accordion/accordion.component";
import { FileUploaderComponent } from "./file-uploader/file-uploader.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "../../assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    HeaderComponent,
    ProgressBarCircleComponent,
    AttachBoxComponent,
    CircleIconComponent,
    CustomSelectComponent,
    AttachBox2Component,
    DropDownListComponent,
    Header2Component,
    FabComponent,
    Header3Component,
    AttachBox3Component,
    Header4Component,
    Header5Component,
    AccordionComponent,
    FileUploaderComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RoundProgressModule,
    WebcamModule,
    // PdfViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    Ng2SearchPipeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    HeaderComponent,
    ProgressBarCircleComponent,
    AttachBoxComponent,
    CircleIconComponent,
    CustomSelectComponent,
    AttachBox2Component,
    DropDownListComponent,
    Header2Component,
    FabComponent,
    Header3Component,
    AttachBox3Component,
    Header4Component,
    Header5Component,
    AccordionComponent,
    FileUploaderComponent,
  ],
})
export class SharedComponentModule {}
