<app-header3></app-header3>

<div class="container mt-46">
  <div class="ng-row">
    <div class="ng-col" col-3 col-sm-12>
    </div>
    <div class="ng-col pt-30 pd-sm-30" col-7 col-sm-12>

      <div class="ng-row pb-30">
        <div class="ng-col">
          <div class="font-30 color-2f5 font-600 mt-5">
            {{"LocalTran" | translate}}
          </div>


          <form [formGroup]="RemittanceForm">
            <div class="form-group mt-30">
              <label>{{"BasicAccountNumber" | translate}}</label>
              <select class="custom-select" (change)="ValueChange()" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="selectedAccontfromAccount">
                <option hidden [ngValue]="undefined" disabled selected>Please Select</option>
                <option *ngFor="let data of this.auth.AllBalance" [ngValue]="data.accountNumber">{{data.accountNumber}}
                </option>
              </select>
            </div>

            <div class="font-16 color-354 font-700 mt-30" *ngIf="selectedAccontfromAccount">
              {{"AccountBalance" | translate}}
            </div>

            <div class="font-14 color-931 font-700 mt-5" *ngIf="selectedAccontfromAccount">
              {{fromCurrency}}: {{AccountBalance}}
            </div>


            <div class="form-group mt-30" *ngIf="selectedAccontfromAccount">
              <div class="ng-row">
                <div class="ng-col mb-15" col-12>
                  <div class="font-16 font-600 color-2d3">{{"PleaseprocessmyLoc" | translate}}</div>
                </div>
                <div class="ng-col" col-12>
                  <div class="custom-checkbox">
                    <input type="radio" value="PO" id="label-1" name='radioGroup' [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="this.payorderselected" (change)="ChangeProcess('PO')">
                    <label for="label-1" class="color-black pt-5 pl-35">
                      {{"PaymentOrder" | translate}}
                    </label>
                  </div>
                </div>


                <div class="ng-col mt-10" col-12>
                  <div class="custom-checkbox">
                    <input type="radio" value="RTGS" id="label-4" name='radioGroup'
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="this.RTGSSelected"
                      (change)="ChangeProcess('RTGS')">
                    <label for="label-4" class="color-black pt-5 pl-35">
                      {{"RTGSPRISM" | translate}}
                    </label>
                  </div>
                </div>
              </div>
            </div>


            <div class="form-group mt-30"
              *ngIf='this.payorderselected && this.selectedAccontfromAccount || this.RTGSSelected && this.selectedAccontfromAccount'>
              <div class="ng-row">
                <div class="ng-col mb-15" col-12>
                  <div class="font-16 font-600 color-2d3">{{"RemittingCurrency" | translate}}<span class="color-red">
                      *</span></div>
                </div>
                <div class="ng-col" col-auto col-sm-5>
                  <div class="custom-checkbox">
                    <input type="radio" value="PKR" id="label-05" name='radioGroup1' (change)="ValueChange1()"
                      [(ngModel)]="selectedAcconttoAccount" [ngModelOptions]="{standalone: true}">
                    <label for="label-05" class="color-black pt-5 pl-35">
                      PKR
                    </label>
                  </div>
                </div>
              </div>
            </div>




            <div class="font-16 color-354 font-700 mt-30" *ngIf="toCurrency">
              {{"ConversionRate" | translate}}
            </div>
            <div class="font-14 color-931 font-700 mt-5" *ngIf="toCurrency && this.selectedAcconttoAccount">
              {{toCurrency}}: {{rateToDisplay}}
            </div>

            <div *ngIf="this.selectedAccontfromAccount && this.Processvalue">
              <div class="form-group mt-20">
                <label>{{"AmountinFigures" | translate}}</label>
                <input type="text" pattern="[0-9,.]*" (change)="AmountChange()" formControlName='Amountinfigures'
                  [(ngModel)]="amount" placeholder="Please enter">
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.Amountinfigures">
                  <span class="error-label"
                    *ngIf="RemittanceForm.controls.Amountinfigures.hasError(validation.type) &&(RemittanceForm.controls.Amountinfigures.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'Amountinfigures') }}
                  </span>
                </ng-container>
              </div>

              <div class="form-group mt-20" *ngIf="this.amount">
                <label class="no-mark">{{"AmountinWords" | translate}}</label>
                <label class="no-mark">{{amount| amountInwords }} {{fromCurrency}}</label>
                <span class="error-label" *ngIf="this.errorbalance">
                  Insuficient amount to be request
                </span>

              </div>

              <div class="form-group mt-20">
                <label>{{"RemittancePurpose" | translate}}</label>
                <select formControlName="RemittancePurpose" [(ngModel)]="RemittancePur"
                  (change)="RemittancePurpose()">
                  <option hidden value="" disabled selected>{{"PleaseSelect"| translate}}</option>
                  <option [ngValue]="a.labelEn" *ngFor="let a of this.PurposeRemitta ">{{a.labelEn}}
                  </option>
                </select>
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.RemittancePurpose">
                  <span class="error-label"
                    *ngIf="RemittanceForm.controls.RemittancePurpose.hasError(validation.type) &&(RemittanceForm.controls.RemittancePurpose.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'RemittancePurpose') }}
                  </span>
                </ng-container>
              </div>
              <div class="form-group mt-20" *ngIf="this.RemittancePur == 'Others'">
                <label>{{"OtherDes" | translate}}</label>
                <input type="text" formControlName='otherdes' [(ngModel)]="Otherdes" placeholder="Please enter">
                <ng-container item-content
                  *ngFor="let validation of errorKey.validation_messages.RemittanceOtherPurpose">
                  <span class="error-label"
                    *ngIf="RemittanceForm.controls.otherdes.hasError(validation.type) &&(RemittanceForm.controls.otherdes.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'otherdes') }}
                  </span>
                </ng-container>

              </div>
            </div>



            <div *ngIf='this.payorderselected && this.selectedAccontfromAccount'>

              <div class="font-24 color-2f5 font-600 mt-30">
                {{"BeneficiaryDetails" | translate}}
              </div>

              <div class="form-group mt-20">
                <label>{{"Name" | translate}}</label>
                <input type="text" placeholder="Enter enter" maxlength="35" minlength="3" formControlName="BenName">
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.BenName">
                  <span class="error-label"
                    *ngIf="RemittanceForm.controls.BenName.hasError(validation.type) &&(RemittanceForm.controls.BenName.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'BenName') }}
                  </span>
                </ng-container>
              </div>

              <div class="form-group mt-20">
                <label>{{"CNICPassport" | translate}}</label>
                <input type="text" placeholder="Enter enter" maxlength="15" minlength="7"
                  formControlName="CNICPassport">
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.CNICPassport">
                  <span class="error-label"
                    *ngIf="RemittanceForm.controls.CNICPassport.hasError(validation.type) &&(RemittanceForm.controls.CNICPassport.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'CNICPassport') }}
                  </span>
                </ng-container>
              </div>




              <div class="form-group mt-20">
                <label>{{"BeneficiaryAddress" | translate}}</label>
                <input type="text" placeholder="Enter enter" maxlength="30" minlength="3" formControlName="BenAddress">
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.BeneAddress">
                  <span class="error-label"
                    *ngIf="RemittanceForm.controls.BenAddress.hasError(validation.type) &&(RemittanceForm.controls.BenAddress.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'BenAddress') }}
                  </span>
                </ng-container>
              </div>

              <div class="form-group mt-20">
                <label>{{"Contact" | translate}}</label>
                <input type="tel" placeholder="Enter enter" maxlength="15" minlength="7" pattern="[0-9]*" formControlName="BenContact">
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.BenContact">
                  <span class="error-label"
                    *ngIf="RemittanceForm.controls.BenContact.hasError(validation.type) &&(RemittanceForm.controls.BenContact.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'BenContact') }}
                  </span>
                </ng-container>
              </div>

              <div class="form-group mt-42">
                <div class="ng-row">
                  <div class="ng-col mb-10" col-12>
                    <div class="font-16 font-600 color-2d3">{{"Payorderbehalf" | translate}}
                      <span class="color-red"> *</span>
                    </div>
                  </div>
                  <div class="ng-col" col-2 col-sm-3>
                    <div class="custom-checkbox">
                      <input type="radio" value="1" id="label-16" name='radioGroup4' (change)="payorderchange()"
                        [(ngModel)]="this.payoder" [ngModelOptions]="{standalone: true}">
                      <label for="label-16" class="color-black pt-5 pl-35">
                        {{"Yes" | translate}}
                      </label>
                    </div>
                  </div>
                  <div class="ng-col pl-15" col-2 col-sm-3>
                    <div class="custom-checkbox">
                      <input type="radio" value="0" id="label-17" name='radioGroup4' (change)="payorderchange()"
                        [(ngModel)]="this.payoder" [ngModelOptions]="{standalone: true}">
                      <label for="label-17" class="color-black pt-5 pl-35">
                        {{"No" | translate}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="this.payoderyes">
                <div class="form-group mt-30">
                  <label>{{"IHerebyauthorize" | translate}}</label>
                  <input type="text" placeholder="Please enter" maxlength="35" minlength="3"
                    formControlName="AuthorizeName">
                  <ng-container item-content *ngFor="let validation of errorKey.validation_messages.AuthorizeName">
                    <span class="error-label"
                      *ngIf="RemittanceForm.controls.AuthorizeName.hasError(validation.type) &&(RemittanceForm.controls.AuthorizeName.touched ||submitAttempt)">
                      {{ getMessage(validation.key,'AuthorizeName') }}
                    </span>
                  </ng-container>
                </div>

                <div class="form-group mt-30 input-between-text">
                  <label>{{"HavingCNICNo" | translate}}</label>
                  <input type="text" placeholder="Please enter" [mask]="this.cnicmask" maxlength="15" minlength="3"
                    formControlName="AuthorizeCnic">
                  <ng-container item-content *ngFor="let validation of errorKey.validation_messages.AuthorizeCnic">
                    <span class="error-label"
                      *ngIf="RemittanceForm.controls.AuthorizeCnic.hasError(validation.type) &&(RemittanceForm.controls.AuthorizeCnic.touched ||submitAttempt)">
                      {{ getMessage(validation.key,'AuthorizeCnic') }}
                    </span>
                  </ng-container>
                </div>

                <div class="font-16 color-354 font-600 mt-20">
                  {{"ToCollectthe" | translate}}
                </div>
              </div>

            </div>

            <div *ngIf='this.RTGSSelected && this.selectedAccontfromAccount'>
              <div class="font-24 color-2f5 font-600 mt-30">
                {{"BeneficiaryDetails" | translate}}
              </div>

              <div class="form-group mt-20">
                <label>{{"Name" | translate}}</label>
                <input type="text" placeholder="Please enter" maxlength="35" minlength="3" formControlName="BenName">
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.BenName">
                  <span class="error-label"
                    *ngIf="RemittanceForm.controls.BenName.hasError(validation.type) &&(RemittanceForm.controls.BenName.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'BenName') }}
                  </span>
                </ng-container>
              </div>

              <div class="form-group mt-20">
                <label>{{"BankName" | translate}}</label>
                <input type="text" placeholder="Please enter" maxlength="25" minlength="3" formControlName="BankName">
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.BankName">
                  <span class="error-label"
                    *ngIf="RemittanceForm.controls.BankName.hasError(validation.type) &&(RemittanceForm.controls.BankName.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'BankName') }}
                  </span>
                </ng-container>
              </div>


              <div class="form-group mt-20">
                <label>{{"BankAddress" | translate}}</label>
                <input type="text" placeholder="Please enter" maxlength="35" minlength="3"
                  formControlName="BenbankAddress">
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.BenBankAddress">
                  <span class="error-label"
                    *ngIf="RemittanceForm.controls.BenbankAddress.hasError(validation.type) &&(RemittanceForm.controls.BenbankAddress.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'BenbankAddress') }}
                  </span>
                </ng-container>
              </div>


              <div class="form-group mt-20">
                <label>{{"BeneficiarysAccount" | translate}}</label>
                <input type="text" placeholder="Please enter" maxlength="35" minlength="3" formControlName="BenAccount">
                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.BenAccount">
                  <span class="error-label"
                    *ngIf="RemittanceForm.controls.BenAccount.hasError(validation.type) &&(RemittanceForm.controls.BenAccount.touched ||submitAttempt)">
                    {{ getMessage(validation.key,'BenAccount') }}
                  </span>
                </ng-container>
              </div>

            </div>


            <div class="form-group mt-42 custom-checkbox">
              <div class="ng-row">
                <div class="ng-col" col-12>
                  <input type="checkbox" value="" id="label-0001" (change)="changeAgree($event)"
                    formControlName="TermsCondition">
                  <label for="label-0001" class="pt-0 line-h-20">
                    {{"IWehereby" | translate}}
                  </label>
                </div>
              </div>
            </div>
          </form>
          <div class="form-group mt-30">
            <button class="btn-dft" (click)="formpost()" [disabled]="IsFormValid()">
              {{"Continue" | translate}}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-fab></app-fab>