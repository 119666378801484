import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpServiceService } from './http-service.service';

export interface RacPordList {
  userId: string,
  applicationId: string,
}

@Injectable({
  providedIn: 'root'
})

export class WebService {

  public RacPordList: string = "Web/GetRacProdList";
  constructor(private http: HttpServiceService,
    private config: ConfigService,) { }

  public RProdList: RacPordList = {
    userId: "",
    applicationId: "",
  }

  GetRacProdList(object, token) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.RacPordList, this.RProdList, token).subscribe((data: any) => {
        resolve(data);
      },
        Error => {
          resolve(Error)
        })
    });
  }
}
