import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circle-icon',
  templateUrl: './circle-icon.component.html',
  styleUrls: ['./circle-icon.component.scss']
})
export class CircleIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
