import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error503',
  templateUrl: './error503.component.html',
  styleUrls: ['./error503.component.scss']
})
export class Error503Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
