import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, Renderer2 } from '@angular/core';
import { Guid } from "guid-typescript";
import * as _ from 'lodash';
import { ConfigService } from '../../Services/config.service';

@Component({
  selector: 'app-attach-box2',
  templateUrl: './attach-box2.component.html',
  styleUrls: ['./attach-box2.component.scss']
})

export class AttachBox2Component implements OnInit {

  imageError: string = null;
  FormPdfbase64: string;
  NameValue: string = "";
  PdfSaved: boolean;
  uniqueId: Guid;
  id: string;
  selectedfile: FileList;
  filename: string;
  id2: string;
  newunique: Guid;

  @Input() attached2Heading: string;
  @Input() attached2Text: string;
  @Input() attached2Link: string;
  @Input() description: string;
  @Input() PDFName: string;
  AttachedPDFLink: string;
  @Output() CallParentFunction = new EventEmitter<any>();
  @Output() CallAnotherFunction = new EventEmitter<any>();

  @ViewChild('takeinput', { static: false }) InputVar: ElementRef;
  @ViewChild('viewlink', { static: false }) viewlink: ElementRef;

  @Input() isButtonOnSide: boolean = false;

  @Input() attached2Text2: string;
  @Input() attached2Text3: string;

  constructor(
    public renderer: Renderer2,
    private config: ConfigService,
  ) { }

  ngOnInit(): void {
    this.uniqueId = Guid.create();
    this.id = this.uniqueId.toString();

    this.newunique = Guid.create();
    this.id2 = this.newunique.toString();


  }

  openButtonLink(link) {
    window.open(link, '_blank');
  }

  viewButtonLink(link) {

    window.open(this.AttachedPDFLink, '_blank');

  }

  CapturePDF(fileInput: any, Name) {

    var AllowedArray = this.config.AllowedTypeArray.split(',');

    for (var i = 0; i < AllowedArray.length; i++) {
      if (AllowedArray[i] == "pdf") {
        AllowedArray[i] = 'application/pdf'
      }
      else {
        AllowedArray[i] = 'image/' + AllowedArray[i];
      }
    }

    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {

      const max_size = 20971520;
      const allowed_types = AllowedArray;
      const max_height = 15200;
      const max_width = 25600;

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = 'Only PDF, JPEG and PNG formats are allowed.';
        return false;
      }

      const reader = new FileReader();

      reader.onload = (e: any) => {

        this.selectedfile = fileInput.target.files;
        var url = URL.createObjectURL(this.selectedfile[0]);
        var array = e.target.result.split(',');

        if (array[0] == "data:application/pdf;base64") {
          var a = array[0].split(';');
          var b = a[0].split('/');
          var extension = stringify(b[1]);
          this.FormPdfbase64 = array[1];

          this.PdfSaved = true;

          this.NameValue = Name;

          var ARRAY: string[];
          ARRAY = [url, this.FormPdfbase64, Name, extension];
          this.CallParentFunction.emit(ARRAY);
          this.AttachedPDFLink = ARRAY[0];

        }
        else if (array[0] == "data:image/png;base64" || array[0] == "data:image/jpeg;base64") {
          var a = array[0].split(';');
          var b = a[0].split('/');
          var extension = stringify(b[1]);
          this.FormPdfbase64 = array[1];

          this.PdfSaved = true;

          this.NameValue = Name;

          var ARRAY: string[];
          ARRAY = [url, this.FormPdfbase64, Name, extension];
          this.CallParentFunction.emit(ARRAY);
          this.AttachedPDFLink = ARRAY[0];
        }
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removePdf(data) {

    this.InputVar.nativeElement.value = "";
    this.CallAnotherFunction.emit(data);
    this.FormPdfbase64 = null;
    this.PdfSaved = false;
  }
}