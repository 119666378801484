<app-header3></app-header3>

<div class="container mt-46">
    <div class="ng-row">
        <div class="ng-col" col-3 col-sm-12>
        </div>
        <div class="ng-col pt-30 pd-sm-30" col-7 col-sm-12>
            <div class="ng-row pb-30">
                <div class="ng-col">
                    <div class="font-30 color-2f5 font-600 mt-5">
                        {{"InternationalTran" | translate}}
                    </div>
                    <form [formGroup]="InterNationalForm">
                        <div class="form-group mt-30">
                            <label>{{"BasicAccountNumber" | translate}}</label>
                            <select class="custom-select" (change)="ValueChange()" [ngModelOptions]="{standalone:true}"
                                [(ngModel)]="selectedAccontfromAccount">
                                <option hidden [ngValue]="undefined" disabled selected>{{"Pleaseselect"| translate}}
                                </option>
                                <option *ngFor="let data of this.auth.AllBalance" [ngValue]="data.accountNumber">
                                    {{data.accountNumber}}</option>
                            </select>
                        </div>

                        <div class="font-16 color-354 font-700 mt-30" *ngIf="selectedAccontfromAccount">
                            {{"AccountBalance" | translate}}
                        </div>

                        <div class="font-14 color-931 font-700 mt-5" *ngIf="selectedAccontfromAccount">
                            {{fromCurrency}}: {{AccountBalance}}
                        </div>

                        <div class="form-group mt-30" *ngIf="selectedAccontfromAccount">
                            <div class="ng-row">
                                <div class="ng-col mb-15" col-12>
                                    <div class="font-16 font-600 color-2d3">{{"PleaseprocessmyInt" | translate}}</div>
                                </div>
                                <div class="ng-col mt-10" col-12>
                                    <div class="custom-checkbox">
                                        <input type="radio" value="FTT" id="label-3" name='radioGroup'
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="this.FTTSelected"
                                            (change)="ChangeProcess('FTT')">
                                        <label for="label-3" class="color-black pt-5 pl-35">
                                            {{"ForeignTelegraphicTransferFTT" | translate}}
                                        </label>
                                    </div>
                                </div>
                                <div class="ng-col mt-10" col-12>
                                    <div class="custom-checkbox">
                                        <input type="radio" value="FDD" id="label-2" name='radioGroup'
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="this.FDDSelected"
                                            (change)="ChangeProcess('FDD')">
                                        <label for="label-2" class="color-black pt-5 pl-35">
                                            {{"ForeignDemandDraftFDD" | translate}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mt-30" *ngIf='this.FTTSelected && this.selectedAccontfromAccount'>
                            <div class="ng-row">
                                <div class="ng-col mb-15" col-12>
                                    <div class="font-16 font-600 color-2d3">{{"RemittingCurrency" | translate}}<span
                                            class="color-red"> *</span></div>
                                </div>

                                <div class="ng-col" col-auto col-sm-5>
                                    <div class="custom-checkbox">
                                        <input type="radio" value="USD" id="label-11" name='radioGroup3'
                                            (change)="ValueChange1()" [(ngModel)]="selectedAccounttoAccount"
                                            [ngModelOptions]="{standalone: true}">
                                        <label for="label-11" class="color-black pt-5 pl-35">
                                            USD
                                        </label>
                                    </div>
                                </div>
                                <div class="ng-col pl-15 pl-mob-0" col-auto col-sm-5>
                                    <div class="custom-checkbox">
                                        <input type="radio" value="GBP" id="label-12" name='radioGroup3'
                                            (change)="ValueChange1()" [(ngModel)]="selectedAccounttoAccount"
                                            [ngModelOptions]="{standalone: true}">
                                        <label for="label-12" class="color-black pt-5 pl-35">
                                            GBP
                                        </label>
                                    </div>
                                </div>
                                <div class="ng-col pl-15" col-auto col-sm-5>
                                    <div class="custom-checkbox">
                                        <input type="radio" value="EUR" id="label-13" name='radioGroup3'
                                            (change)="ValueChange1()" [(ngModel)]="selectedAccounttoAccount"
                                            [ngModelOptions]="{standalone: true}">
                                        <label for="label-13" class="color-black pt-5 pl-35">
                                            EURO
                                        </label>
                                    </div>
                                </div>
                                <div class="ng-col pl-15" col-auto col-sm-5>
                                    <div class="custom-checkbox">
                                        <input type="radio" value="AED" id="label-14" name='radioGroup3'
                                            (change)="ValueChange1()" [(ngModel)]="selectedAccounttoAccount"
                                            [ngModelOptions]="{standalone: true}">
                                        <label for="label-14" class="color-black pt-5 pl-35">
                                            AED
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="form-group mt-30" *ngIf='this.FDDSelected && this.selectedAccontfromAccount'>
                            <div class="ng-row">
                                <div class="ng-col mb-15" col-12>
                                    <div class="font-16 font-600 color-2d3">{{"RemittingCurrency" | translate}}<span
                                            class="color-red"> *</span></div>
                                </div>
                                <div class="ng-col" col-auto col-sm-5>
                                    <div class="custom-checkbox">
                                        <input type="radio" value="AED" id="label-06" name='radioGroup2'
                                            (change)="ValueChange1()" [(ngModel)]="selectedAccounttoAccount"
                                            [ngModelOptions]="{standalone: true}">
                                        <label for="label-06" class="color-black pt-5 pl-35">
                                            AED
                                        </label>
                                    </div>
                                </div>
                                <div class="ng-col pl-15" col-auto col-sm-5>
                                    <div class="custom-checkbox">
                                        <input type="radio" value="USD" id="label-07" name='radioGroup2'
                                            (change)="ValueChange1()" [(ngModel)]="selectedAccounttoAccount"
                                            [ngModelOptions]="{standalone: true}">
                                        <label for="label-07" class="color-black pt-5 pl-35">
                                            USD
                                        </label>
                                    </div>
                                </div>
                                <div class="ng-col pl-15 pl-mob-0" col-auto col-sm-5>
                                    <div class="custom-checkbox">
                                        <input type="radio" value="GBP" id="label-08" name='radioGroup2'
                                            (change)="ValueChange1()" [(ngModel)]="selectedAccounttoAccount"
                                            [ngModelOptions]="{standalone: true}">
                                        <label for="label-08" class="color-black pt-5 pl-35">
                                            GBP
                                        </label>
                                    </div>
                                </div>
                                <div class="ng-col pl-15" col-auto col-sm-5>
                                    <div class="custom-checkbox">
                                        <input type="radio" value="EUR" id="label-09" name='radioGroup2'
                                            (change)="ValueChange1()" [(ngModel)]="selectedAccounttoAccount"
                                            [ngModelOptions]="{standalone: true}">
                                        <label for="label-09" class="color-black pt-5 pl-35">
                                            EURO
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="font-16 color-354 font-700 mt-30" *ngIf="toCurrency">
                            {{"ConversionRate" | translate}}
                        </div>
                        <div class="font-14 color-931 font-700 mt-5"
                            *ngIf="toCurrency && this.selectedAccounttoAccount">
                            {{toCurrency}}: {{rateToDisplay}}
                        </div>


                        <div *ngIf="this.selectedAccontfromAccount && this.Processvalue">
                            <div class="form-group mt-20">
                                <label>{{"AmountinFigures" | translate}}</label>
                                <input type="text" pattern="[0-9,.]*" (change)="AmountChange()"
                                    formControlName='Amountinfigures' maxlength="15" [(ngModel)]="amount"
                                    placeholder="Please enter">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.Amountinfigures">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.Amountinfigures.hasError(validation.type) && (InterNationalForm.controls.Amountinfigures.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'Amountinfigures') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20" *ngIf="this.amount">
                                <label class="no-mark">{{"AmountinWords" | translate}}</label>
                                <label class="no-mark">{{amount| amountInwords }} {{fromCurrency}}</label>
                                <span class="error-label" *ngIf="this.errorbalance">
                                    Insuficient amount to be request
                                </span>

                            </div>


                            <div class="form-group mt-20">
                                <label>{{"RemittancePurpose" | translate}}</label>
                                <select formControlName="RemittancePurpose" [(ngModel)]="this.RemittancePur"
                                    (change)="RemittancePurpose()">
                                    <option hidden value="" disabled selected>{{"PleaseSelect"| translate}}</option>
                                    <option [ngValue]="a.labelEn" *ngFor="let a of this.PurposeRemitta ">{{a.labelEn}}
                                    </option>
                                </select>
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.RemittancePurpose">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.RemittancePurpose.hasError(validation.type) &&(InterNationalForm.controls.RemittancePurpose.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'RemittancePurpose') }}
                                    </span>
                                </ng-container>
                            </div>


                            <div class="form-group mt-20" *ngIf="this.RemittancePur == 'Others'">
                                <label>{{"OtherDes" | translate}}</label>
                                <input type="text" formControlName='otherdes' [(ngModel)]="Otherdes"
                                    placeholder="Please enter">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.RemittanceOtherPurpose">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.otherdes.hasError(validation.type) &&(InterNationalForm.controls.otherdes.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'otherdes') }}
                                    </span>
                                </ng-container>

                            </div>
                        </div>



                        <div *ngIf='this.FTTSelected && this.selectedAccontfromAccount'>

                            <div class="font-24 color-2f5 font-600 mt-30">
                                {{"BeneficiaryDetails" | translate}}
                            </div>


                            <div class="form-group mt-20">
                                <label>{{"BeneficiarysAccount" | translate}}</label>
                                <input type="text" placeholder="Please enter" maxlength="25" minlength="3"
                                    formControlName="BenAccount">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BenAccount">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BenAccount.hasError(validation.type) &&(InterNationalForm.controls.BenAccount.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BenAccount') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20">
                                <label>{{"Name" | translate}}</label>
                                <input type="text" placeholder="Please enter" maxlength="35" minlength="3"
                                    formControlName="BenName">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BenName">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BenName.hasError(validation.type) &&(InterNationalForm.controls.BenName.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BenName') }}
                                    </span>
                                </ng-container>
                            </div>


                            <div class="form-group mt-20">
                                <label>{{"BankName" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="25" minlength="3"
                                    formControlName="BankName">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BankName">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BankName.hasError(validation.type) &&(InterNationalForm.controls.BankName.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BankName') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20">
                                <label class="no-mark">{{"BankAddress" | translate}}</label>
                                <input type="text" placeholder="Please enter" maxlength="35" minlength="3"
                                    formControlName="BenbankAddress">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BenBankAddress">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BenbankAddress.hasError(validation.type) &&(InterNationalForm.controls.BenbankAddress.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BenbankAddress') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20">
                                <label class="no-mark">{{"SWIFTCodeSORTCode" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="11" minlength="3"
                                    formControlName="SwiftCode">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.SwiftCode">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.SwiftCode.hasError(validation.type) &&(InterNationalForm.controls.SwiftCode.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'SwiftCode') }}
                                    </span>
                                </ng-container>
                            </div>


                            <div class="form-group mt-20">
                                <label>{{"Relationshipwiththe" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="40" minlength="3"
                                    formControlName="Relationben">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.Relationben">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.Relationben.hasError(validation.type) &&(InterNationalForm.controls.Relationben.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'Relationben') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20">
                                <label>{{"BenTitle" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="35" minlength="3"
                                    formControlName="BenTitle">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BenTitle">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BenTitle.hasError(validation.type) &&(InterNationalForm.controls.BenTitle.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BenTitle') }}
                                    </span>
                                </ng-container>
                            </div>


                            <div class="form-group mt-20">
                                <label>{{"BeneficiaryAddress" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="35" minlength="3"
                                    formControlName="BeneAddress">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BeneAddress">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BeneAddress.hasError(validation.type) &&(InterNationalForm.controls.BeneAddress.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BeneAddress') }}
                                    </span>
                                </ng-container>
                            </div>


                            <div class="form-group mt-20">
                                <label class="no-mark">{{"BeneTelephone" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="13" pattern="[0-9]*" minlength="7" formControlName="BeneContact">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BeneContact">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BeneContact.hasError(validation.type) &&(InterNationalForm.controls.BeneContact.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BeneContact') }}
                                    </span>
                                </ng-container>
                            </div>



                            <div class="form-group mt-20">
                                <label>{{"DrawnonCityCountry" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="35" minlength="3"
                                    formControlName="DrawnCity">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.DrawnCity">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.DrawnCity.hasError(validation.type) &&(InterNationalForm.controls.DrawnCity.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'DrawnCity') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20">
                                <label class="no-mark">{{"BenBnkCodeType" | translate}}</label>
                                <select formControlName="BenBnkCodeType" [(ngModel)]="BBCodeType">
                                    <option hidden value="" disabled selected>{{"PleaseSelect"| translate}}</option>
                                    <option [ngValue]="a.labelEn" *ngFor="let a of this.BenBnkCodeType">{{a.labelEn}}
                                    </option>
                                </select>

                            </div>

                            <div class="form-group mt-20" *ngIf="BBCodeType">
                                <label class="no-mark">{{BBCodeType}}</label>
                                <input type="text" placeholder="Please Please" maxlength="25" minlength="3"
                                    formControlName="BenBnkCode">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BenBnkCode">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BenBnkCode.hasError(validation.type) &&(InterNationalForm.controls.BenBnkCode.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BenBnkCode') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20">
                                <label>{{"BeneIdentityType" | translate}}</label>
                                <select formControlName="BeneIdentityType" [(ngModel)]="BIdentityType">
                                    <option hidden value="" disabled selected>{{"PleaseSelect"| translate}}</option>
                                    <option [ngValue]="a.labelEn" *ngFor="let a of this.BeneIdentityType">{{a.labelEn}}
                                    </option>
                                </select>
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BeneIdentityType">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BeneIdentityType.hasError(validation.type) &&(InterNationalForm.controls.BeneIdentityType.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BeneIdentityType') }}
                                    </span>
                                </ng-container>
                            </div>


                            <div class="form-group mt-20" *ngIf="BIdentityType">
                                <label>{{BIdentityType}}</label>

                                <input *ngIf="BIdentityType.toUpperCase() != 'CNIC'" type="text" placeholder="Please Please" maxlength="13" minlength="7" formControlName="BeneIdentityNo">

                                <input *ngIf="BIdentityType.toUpperCase() == 'CNIC'" mask="XXXXX-XXXXXXX-X" type="text" placeholder="Please Please" maxlength="15" minlength="7" formControlName="BeneIdentityNo">

                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BeneIdentityNo">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BeneIdentityNo.hasError(validation.type) &&(InterNationalForm.controls.BeneIdentityNo.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BeneIdentityNo') }}
                                    </span>
                                </ng-container>
                            </div>


                            <div class="form-group mt-20">
                                <label>{{"CustomerIdentityType" | translate}}</label>
                                <select formControlName="CustomerIdentityType" [(ngModel)]="_CustomerIdentityType">
                                    <option hidden value="" disabled selected>{{"PleaseSelect"| translate}}</option>
                                    <option [ngValue]="a.labelEn" *ngFor="let a of this.CustomerIdentityType">
                                        {{a.labelEn}}
                                    </option>
                                </select>
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.CustomerIdentityType">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.CustomerIdentityType.hasError(validation.type) &&(InterNationalForm.controls.CustomerIdentityType.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'CustomerIdentityType') }}
                                    </span>
                                </ng-container>
                            </div>


                            <div class="form-group mt-20" *ngIf="_CustomerIdentityType">
                                <label>{{_CustomerIdentityType}}</label>

                                <input *ngIf="_CustomerIdentityType.toUpperCase() != 'CNIC'" type="text" placeholder="Please Please" maxlength="13" minlength="7" formControlName="CustomerIdentityNo">
                                
                                <input *ngIf="_CustomerIdentityType.toUpperCase() == 'CNIC'" mask="XXXXX-XXXXXXX-X" type="text" placeholder="Please Please" maxlength="15" minlength="7" formControlName="CustomerIdentityNo">

                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.CustomerIdentityNo">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.CustomerIdentityNo.hasError(validation.type) &&(InterNationalForm.controls.CustomerIdentityNo.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'CustomerIdentityNo') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="font-18 color-2f5 font-600 mt-30">
                                {{"IntermediaryBankoptional" | translate}}
                            </div>

                            <div class="form-group mt-20">
                                <label class="no-mark">{{"BankName" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="35" minlength="3"
                                    formControlName="InterBankNAme">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.InterBankNAme">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.InterBankNAme.hasError(validation.type) &&(InterNationalForm.controls.InterBankNAme.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'InterBankNAme') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20">
                                <label class="no-mark">{{"BankAddress" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="35" minlength="3"
                                    formControlName="InterBankAddress">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.InterBankAddress">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.InterBankAddress.hasError(validation.type) &&(InterNationalForm.controls.InterBankAddress.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'InterBankAddress') }}
                                    </span>
                                </ng-container>
                            </div>


                            <div class="form-group mt-20">
                                <label class="no-mark">{{"SWIFTCodeSORTCode" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="11" minlength="3"
                                    formControlName="InterSwiftCode">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.InterSwiftCode">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.InterSwiftCode.hasError(validation.type) &&(InterNationalForm.controls.InterSwiftCode.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'InterSwiftCode') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="font-18 color-2f5 font-600 mt-30">
                                {{"CORRESPONDENTBANK" | translate}}
                            </div>

                            <div class="form-group mt-20">
                                <label class="no-mark">{{"BankName" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="35" minlength="3"
                                    formControlName="BeneCorsBankNAme">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BeneCorressBankNAme">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BeneCorsBankNAme.hasError(validation.type) &&(InterNationalForm.controls.BeneCorsBankNAme.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'InterBankAddress') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20">
                                <label class="no-mark">{{"BankAddress" | translate}}</label>
                                <input type="text" placeholder="Please enter" maxlength="35" minlength="3"
                                    formControlName="BeneCorsBankAddress">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BeneCorsBankAddress">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.InterBankAddress.hasError(validation.type) &&(InterNationalForm.controls.InterBankAddress.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'InterBankAddress') }}
                                    </span>
                                </ng-container>
                            </div>


                            <div class="form-group mt-20">
                                <label class="no-mark">{{"SWIFTCodeSORTCode" | translate}}</label>
                                <input type="text" placeholder="Please enter" maxlength="11" minlength="3"
                                    formControlName="BeneCorsSwiftCode">
                            </div>

                        </div>

                        <div *ngIf='this.FDDSelected && this.selectedAccontfromAccount'>
                            <div class="font-24 color-2f5 font-600 mt-30">
                                {{"BeneficiaryDetails" | translate}}
                            </div>

                            <div class="form-group mt-20">
                                <label>{{"Name" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="35" minlength="3"
                                    formControlName="BenName">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BenName">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BenName.hasError(validation.type) &&(InterNationalForm.controls.BenName.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BenName') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20">
                                <label>{{"BeneficiaryAddress" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="35" minlength="3"
                                    formControlName="BeneAddress">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.BeneAddress">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BeneAddress.hasError(validation.type) &&(InterNationalForm.controls.BeneAddress.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BeneAddress') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20">
                                <label>{{"Contact" | translate}}</label>
                                <input type="text" placeholder="Please Please" maxlength="13" pattern="[0-9]*" minlength="7"
                                    formControlName="BeneContact">
                                <ng-container item-content *ngFor="let validation of errorKey.validation_messages.BeneContact">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.BeneContact.hasError(validation.type) &&(InterNationalForm.controls.BeneContact.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'BeneContact') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-20">
                                <label>{{"DrawnonCityCountry" | translate}}</label>
                                <input type="text" placeholder="Please enter" maxlength="35" minlength="3"
                                    formControlName="DrawnCity">
                                <ng-container item-content
                                    *ngFor="let validation of errorKey.validation_messages.DrawnCity">
                                    <span class="error-label"
                                        *ngIf="InterNationalForm.controls.DrawnCity.hasError(validation.type) &&(InterNationalForm.controls.DrawnCity.touched ||submitAttempt)">
                                        {{ getMessage(validation.key,'DrawnCity') }}
                                    </span>
                                </ng-container>
                            </div>

                            <div class="form-group mt-42">
                                <div class="ng-row">
                                    <div class="ng-col mb-10" col-12>
                                        <div class="font-16 font-600 color-2d3">{{"Payorderbehalf" | translate}}
                                            <span class="color-red"> *</span>
                                        </div>
                                    </div>
                                    <div class="ng-col" col-2 col-sm-3>
                                        <div class="custom-checkbox">
                                            <input type="radio" value="1" id="label-16" name='radioGroup5'
                                                (change)="payorderchange()" [(ngModel)]="this.payoder"
                                                [ngModelOptions]="{standalone: true}">
                                            <label for="label-16" class="color-black pt-5 pl-35">
                                                {{"Yes" | translate}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="ng-col pl-15" col-2 col-sm-3>
                                        <div class="custom-checkbox">
                                            <input type="radio" value="0" id="label-17" name='radioGroup5'
                                                (change)="payorderchange()" [(ngModel)]="this.payoder"
                                                [ngModelOptions]="{standalone: true}">
                                            <label for="label-17" class="color-black pt-5 pl-35">
                                                {{"No" | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="this.payoderyes">
                                <div class="form-group mt-30">
                                    <label class="no-mark">{{"IHerebyauthorize" | translate}}</label>
                                    <input type="text" placeholder="Please Please" maxlength="35" minlength="3"
                                        formControlName="AuthorizeName">
                                    <ng-container item-content
                                        *ngFor="let validation of errorKey.validation_messages.AuthorizeName">
                                        <span class="error-label"
                                            *ngIf="InterNationalForm.controls.AuthorizeName.hasError(validation.type) &&(InterNationalForm.controls.AuthorizeName.touched ||submitAttempt)">
                                            {{ getMessage(validation.key,'AuthorizeName') }}
                                        </span>
                                    </ng-container>
                                </div>

                                <div class="form-group mt-30 input-between-text">
                                    <label class="no-mark">{{"HavingCNICNo" | translate}}</label>
                                    <input type="text" placeholder="Please enter" [mask]="this.cnicmask" maxlength="15"
                                        minlength="3" formControlName="AuthorizeCnic">
                                    <ng-container item-content
                                        *ngFor="let validation of errorKey.validation_messages.AuthorizeCnic">
                                        <span class="error-label"
                                            *ngIf="InterNationalForm.controls.AuthorizeCnic.hasError(validation.type) &&(InterNationalForm.controls.AuthorizeCnic.touched ||submitAttempt)">
                                            {{ getMessage(validation.key,'AuthorizeCnic') }}
                                        </span>
                                    </ng-container>
                                </div>

                                <div class="font-16 color-354 font-600 mt-20">
                                    {{"ToCollectthe" | translate}}
                                </div>
                            </div>

                        </div>




                        <div class="form-group mt-42 custom-checkbox">
                            <div class="ng-row">
                                <div class="ng-col" col-12>
                                    <input type="checkbox" value="" id="label-0001" (change)="changeAgree($event)"
                                        formControlName="TermsCondition">
                                    <label for="label-0001" class="pt-0 line-h-20">
                                        {{"IWehereby" | translate}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>


                    <div class="form-group mt-30">
                        <button class="btn-dft" (click)="formpost()" [disabled]="IsFormValid()">
                            {{"Continue" | translate}}
                        </button>
                    </div>

                </div>
            </div>
        </div>

    </div>

</div>
<app-fab></app-fab>