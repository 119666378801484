import { CommonService } from './../../Services/common.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  constructor(
    public commonP: CommonService, 
    private _router: Router
  ) { }

  IsResident : string;
  async ngOnInit(): Promise<void> {

    this.IsResident = await this.commonP.Get('IsResident');
  }

  RemittanceTracker() {
    this._router.navigateByUrl('/RemittanceTracker/RemittanceTrackerMTCN');
  }
}
