<app-header3></app-header3>


<div class="container mt-46">
  <div class="ng-row" justify-content-center>
    <div class="ng-col pt-30 pd-sm-30" col-10 col-sm-12>

      <div class="ng-row pb-30">
        <div class="ng-col">
          <div class="font-30 color-2f5 font-600 mt-5">
            {{"IslamicNayaPakistan" | translate}}
          </div>


          <div class="form-group mt-30">
            <label>{{"AccountNumber" | translate}}</label>
            <select class="custom-select" (change)="ValueChange()" [(ngModel)]="selectedAccontfromAccount">
              <option hidden [ngValue]="undefined" disabled selected>{{"PleaseSelect"| translate}}</option>
              <option *ngFor="let data of this.auth.AllBalance" [ngValue]="data.accountNumber">{{data.accountNumber}}
              </option>

            </select>
          </div>

          <div class="font-16 color-354 font-700 mt-30" *ngIf="selectedAccontfromAccount">
            {{"AccountBalance" | translate}}
          </div>

          <div class="font-14 color-931 font-700 mt-5" *ngIf="selectedAccontfromAccount">
            {{selectedCurrency}}: {{AccountBalance}}
          </div>


          <div class="form-group mt-30" *ngIf="this.selectedAccontfromAccount && this.selectedCurrency">
            <div class="ng-row" align-item-bottom>
              <div class="ng-col mb-5" col-12>
                <label>{{"EnterDenomination" | translate}}</label>
              </div>
              <div class="ng-col" col-auto>
                <div class="font-16 font-600 color-2d3" [ngClass]="this.amount ? 'mb-5':''">{{this.selectedCurrency}}
                </div>
              </div>
              <div class="ng-col pl-10" col-4 col-sm-7>
                <input type="number" pattern="[0-9]*" (change)="AmountChange()" [(ngModel)]="amount"
                  placeholder="Please enter" [ngClass]="this.amount ? 'text-align-right':''">
              </div>
              <div class="ng-col" col-3>
                <div class="font-16 font-600 color-2d3" [ngClass]="this.amount ? 'mb-5':''"><span
                    *ngIf="this.selectedCurrency=='USD' || this.selectedCurrency=='GBP' || this.selectedCurrency=='EUR'">,000</span><span
                    *ngIf="this.selectedCurrency=='PKR'">0,000</span></div>
              </div>
            </div>


          </div>
          <div class="form-group mt-20" *ngIf="this.amountinwords">
            <label class="no-mark">{{"AmountinWords" | translate}}</label>
            <label class="no-mark">{{amountinwords| amountInwords }}{{selectedCurrency}}</label>

          </div>




          <div class="font-16 color-354 font-600 mt-30" *ngIf="this.selectedAccontfromAccount && this.selectedCurrency">
            {{"NumberofCertificates" | translate}}
          </div>
          <div class="custom-counter mt-10" *ngIf="this.selectedAccontfromAccount && this.selectedCurrency">
            <div [ngClass]="customCounter == 0 ? 'disabled btn-minus':'btn-minus'" class="btn-minus"
              (click)="btnMinus()"></div>
            <div class="center-counter">
              {{customCounter}}
            </div>
            <div [ngClass]="customCounter == 5 ? 'disabled btn-plus':'btn-plus'" class="btn-plus" (click)="btnPlus()">
            </div>
          </div>


          <div class="form-group mt-30" *ngIf="this.selectedAccontfromAccount && this.selectedCurrency">
            <label>{{"Tenure" | translate}}</label>
            <select class="custom-select" [(ngModel)]="tenor">
              <option disabled hidden value="undefined" selected>{{"PleaseSelect"| translate}}</option>
              <option>{{"3months"| translate}}</option>
              <option>{{"6months"| translate}}</option>
              <option>{{"12months"| translate}}</option>
              <option>{{"3years"| translate}}</option>
              <option>{{"5years"| translate}}</option>
            </select>
          </div>


          <div class="form-group mt-30"
            *ngIf="this.selectedAccontfromAccount && this.selectedCurrency && this.numberofcertificate>0">
            <div class="ng-row" align-item-bottom>
              <div class="ng-col" col-auto>
                <div class="font-16 font-600 color-2d3">Total</div>
              </div>
              <div class="ng-col pl-10" col-11 col-sm-9>
                <input type="number" readonly [(ngModel)]="totalValue" placeholder="0.00">
              </div>
            </div>
            <span class="error-label" *ngIf="this.errorbalance">{{"Insufficientbalance"| translate}}</span>

          </div>

          <div class="form-group mt-42 custom-checkbox" *ngIf="this.selectedAccontfromAccount && this.selectedCurrency">
            <div class="ng-row">
              <div class="ng-col" col-10>
                <input type="checkbox" (change)="changeAgree($event)" value="" id="label-0001">
                <label for="label-0001" class="pt-0 line-h-20">
                  {{"Iconsenttoinpc" | translate}}
                  <span class="label-link color-6F2" *ngIf="this.selectedCurrency=='PKR'" (click)="labelLink1($event)">
                    {{"terms&condition" | translate}}
                  </span>
                  <span class="label-link color-6F2" *ngIf="this.selectedCurrency=='USD'" (click)="labelLink2($event)">
                    {{"terms&condition" | translate}}
                  </span>
                  <span class="label-link color-6F2" *ngIf="this.selectedCurrency=='GBP'" (click)="labelLink3($event)">
                    {{"terms&condition" | translate}}
                  </span>
                  <span class="label-link color-6F2" *ngIf="this.selectedCurrency=='EUR'" (click)="labelLink4($event)">
                    {{"terms&condition" | translate}}
                  </span>
                </label>
              </div>
            </div>
          </div>


          <div class="form-group mt-30">
            <button class="btn-dft" [disabled]="IsValid()" (click)="Open()">
              {{"Submit" | translate}}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-fab></app-fab>