<app-header3></app-header3>

<div class="container mt-46">
    <div class="ng-row" justify-content-center>
        <div class="ng-col pt-30 pd-sm-30" col-7 col-sm-12 col-md-11>

            <div class="ng-row pb-30">
                <div class="ng-col">
                    <div class="font-30 color-2f5 font-600 mt-5">
                        {{"CDCTransferAmountRequest" | translate}}
                    </div>


                    <div class="ng-row" align-item-bottom>
                        <div class="ng-col" col-9 col-sm-8>
                            <div class="form-group mt-30">
                                <label>{{"Selectaccount" | translate}}</label>
                                <select class="custom-select" (change)="ValueChange()"
                                    [(ngModel)]="SelectedAccountNumber">
                                    <option hidden [ngValue]="undefined" disabled selected>Please Select</option>
                                    <option *ngFor="let data of this.auth.AllBalance" [ngValue]="data.accountNumber">
                                        {{data.accountNumber}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="ng-col p-l-r" col-3 col-sm-4>
                            <div class="font-16 color-354 font-400 mt-30">
                                (PKR Only)
                            </div>
                        </div>
                    </div>



                    <div class="font-12 color-354 font-400 mt-10 inside-link">
                        {{"PleaseselectyourPkr" | translate}}
                    </div>

                    <div class="font-16 color-354 font-700 mt-30" *ngIf="SelectedAccountNumber">
                        {{"AccountBalance" | translate}}
                    </div>

                    <div class="font-14 color-931 font-700 mt-5" *ngIf="SelectedAccountNumber">
                        {{toCurrency}}: {{AccountBalance}}
                    </div>


                    <div class="form-group mt-20" *ngIf="this.SelectedAccountNumber">
                        <label>{{"TransferAmount" | translate}}</label>
                        <input type="number" pattern="[0-9]*" (change)="AmountChange()" [(ngModel)]="this.amount"
                            placeholder="Please enter">
                    </div>


                    <div class="font-12 color-354 font-400 mt-10" *ngIf="this.SelectedAccountNumber">
                        {{"Pleaseentertheamount" | translate}}
                    </div>

                    <div class="form-group mt-20" *ngIf="this.amount">
                        <label class="no-mark">{{"AmountinWords" | translate}}</label>
                        <label class="no-mark">{{amount| amountInwords }}PKR</label>
                        <span class="error-label" *ngIf="this.errorbalance">
                            {{"Insuficientamounttoberequest" | translate}}
                        </span>

                    </div>



                    <div class="form-group mt-42 custom-checkbox">
                        <div class="ng-row">
                            <div class="ng-col" col-12>
                                <input type="checkbox" (change)="changeAgree($event)" value="" id="label-01">
                                <label for="label-01" class="pt-0 line-h-20">
                                    {{"Iherebyconsenttransferring" | translate}}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mt-30">
                        <button class="btn-dft" [disabled]="isvalid()" (click)="FormPost()">
                            {{"Submit" | translate}}
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<app-fab></app-fab>