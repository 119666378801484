import { Injectable } from '@angular/core';
import { HttpServiceService } from './http-service.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class DiscrepancyService {
  public ResolveDiscrepancyDocsPrimaryWeb='AdditionalDocs/ResolvePrimaryWebDiscrepancyV1';
  public getDiscrepayncPrimaryWebV1='AdditionalDocs/GetPrimaryWebDiscrepancyDocs';

  constructor(private http: HttpServiceService,
    private config: ConfigService) { }
    public resolveDiscrepancyDocsPrimaryWeb(token, object) {
      console.log('resolveDiscrepancyDocsPrimaryWebObject',object)
      return new Promise((resolve, reject) => {
        this.http.postToCloseApi(this.config.server + this.ResolveDiscrepancyDocsPrimaryWeb, object, token).subscribe((data: any) => {
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
      });
    }
  public GetDiscrepayncyPrimaryWebV1(token): Promise<unknown> {
    debugger
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.getDiscrepayncPrimaryWebV1  , { IsWeb: true }, token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }
  
}
