<app-header></app-header>

<div class="container login-component mt-46 pb-20">
  <div class="ng-row">
    <div class="ng-col" col-3 col-sm-12>
      <!-- <app-progress-bar-circle [counter]="counter" [current]="current" [max]="max"></app-progress-bar-circle> -->
    </div>
    <div class="ng-col pt-30 pb-30 pd-sm-30" col-7 col-sm-12>

      <div class="font-30 color-2f5 font-600 mt-5">
        {{"revalidatedocs" | translate}}
      </div>

      <div class="font-18 color-354 font-400 mt-5">
        {{"Pleaseuploadthenecessary" | translate}}
      </div>

      <div class="mt-46" *ngFor="let a of this.config; index as i">
        <div class="border border-color-e1e br-12 bg-white pd" *ngIf="a.listofadddoc.length > 1">
          <div class="ng-row p-l-r" justify-content-between>

            <div class="ng-col" col-12>
              <div class="font-18 font-600 color-354">
                {{a.listofadddoc[0].labelEn}}
                <span class="color-red" *ngIf="a.isMandatory"> *</span>
              </div>
            </div>

            <div class="ng-col" col-12 *ngIf='!this.SelectedOption'>
              <div class="form-group mt-15">
                <label class="no-mark">{{"DocumentType" | translate}}</label>
                <select [(ngModel)]="this.SelectedOption">
                  <option hidden [ngValue]="null" disabled selected>Select Document Type</option>
                  <option [ngValue]="b" *ngFor="let b of a.listofadddoc">{{b.documentType}}</option>
                </select>
              </div>
            </div>

            <div class="ng-col" col-7 col-sm-12 *ngIf='this.SelectedOption'>
              <div class="form-group mt-15">
                <label class="no-mark">{{"DocumentType" | translate}}</label>
                <select [(ngModel)]="this.SelectedOption">
                  <option hidden [ngValue]="null" disabled selected>Select Document Type</option>
                  <option [ngValue]="b" *ngFor="let b of a.listofadddoc">{{b.documentType}}</option>
                </select>
              </div>
            </div>

            <div class="ng-col" col-auto col-sm-12 *ngIf="this.SelectedOption">
              <div class="custom-file-uploader mt-25">

                <a *ngIf="this.CameraViewer && !isImageSaved && !PdfSaved" class="display-inline-block p-l-r"
                  (click)="showPopup1(a)"><i class="icon-camera"></i></a>

                <input type="file" id="i" #takeinput (change)="SavingImage($event,i,a)" />
                <label for=i *ngIf="!isImageSaved && !PdfSaved">Attach</label>
                <label *ngIf="isImageSaved && !this.CameraViewer" (click)="showPopup()" class="active">View</label>
                <label *ngIf="PdfSaved" #viewlink (click)="viewButtonLink(AttachedPDFLink)" class="active">View</label>
                <label *ngIf="isImageSaved && this.CameraViewer" (click)="showPopup3()" class="active">View</label>

              </div>
            </div>

            <div class="ng-col" col-12 [innerHTML]="a.listofadddoc[0].description" *ngIf=!this.imageError>
            </div>
            <div class="ng-col mt-20 " col-08 *ngIf=this.imageError>
              <div class=" font-14 color-red">
                {{ imageError | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="mt-10" *ngIf="a.listofadddoc.length == 1">
          <app-file-uploader [Heading]="a.listofadddoc[0].labelEn" [ObjectName]="a.listofadddoc[0].docId"
            [AsterikShow]=a.isMandatory [Text]="a.listofadddoc[0].description" IsResponseContainExtension="true"
            (callAnotherFunction)="RemoveFunction(a)" (change)="SavingImage($event,i,a)"
            [IsSupportComponent]=true>
          </app-file-uploader>
        </div>

      </div>

      <div class="form-group mt-46">
        <button class="btn-dft-white" [disabled]="isFormValid()" (click)="formpostdolater()">
          {{"SaveDoLater" | translate}}</button>
        <button class="btn-dft mt-15" (click)="formpost()" [disabled]="isFormValid()">{{"Continue" |
          translate}}</button>
      </div>
    </div>
  </div>
</div>



<div class="custom-modal auto-resize" #modal>
  <div class="custom-modal-overlay" (click)="hidePopup()"></div>
  <div class="custom-modal-canvas">

    <div class="custom-modal-canvas-scroller">
      <div>

      </div>


      <div class="custom-modal-img-box">
        <img id="ok" #imageid alt="" width="auto">
      </div>
    </div>
  </div>

  <div class="custom-modal-footer">
    <div class="ng-row" justify-content-center>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft-white" (click)="removeImage1()">
          {{'Delete' | translate}}
        </button>
      </div>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft" (click)="hidePopup()">
          {{'Close' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<app-fab></app-fab>

<div class="custom-modal auto-resize camera-view-modal" #modal3>
  <div class="custom-modal-overlay" (click)="hidePopup3()"></div>
  <div class="custom-modal-canvas">

    <div class="custom-modal-canvas-scroller">
      <div>

      </div>

      <div class="custom-modal-img-box">
        <img id="id" #imageid2 alt="" style="transform: scaleX(-1);" width="auto">
      </div>
    </div>
  </div>

  <div class="custom-modal-footer">
    <div class="ng-row" justify-content-center>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft-white" (click)="removeImage3()">
          {{'Delete' | translate}}
        </button>
      </div>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft" (click)="hidePopup3()">
          {{'Close' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="custom-modal auto-resize camera-modal" #modal2>
  <div class="custom-modal-overlay"></div>
  <div class="custom-modal-canvas p-0">
    <webcam *ngIf="this.cameraactive" mirrorImage="always" [imageQuality]="1" [height]="500" [width]="500"
      [trigger]="triggerObservable" (imageCapture)="handleImage($event)">
    </webcam>
  </div>
  <div class="custom-modal-footer">
    <div class="ng-row" justify-content-center>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft" (click)="hidePopup1()">
          {{'Close' | translate}}
        </button>
      </div>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft-white" (click)="triggerSnapshot();">
          {{'Capture' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>