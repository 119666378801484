<div class="border border-color-e1e br-12 bg-white pd attach-box">
  <div class="close-button" *ngIf="this.closeButton == true">
    <i class="icon-close-black link" (click)="closeClick()"></i>
  </div>
  <div class="ng-row p-l-r" justify-content-between align-item-center>
    <div class="ng-col" col-7 col-sm-12>
      <div class="font-18 font-600 color-354">
        {{attachedHeading | translate}} <span class="color-red" *ngIf='this.AsterikShow'>*</span>
      </div>
      <div class="font-16 font-400 color-354 mt-5" *ngIf="!this.imageError">
        {{attachedText | translate}}
      </div>

      <div class="font-16 font-400 color-red mt-5" *ngIf="this.imageError">
        {{imageError | translate}}
      </div>
    </div>
    <div class="ng-col mt-on-mob-15 mt-on-tab-15" col-auto col-sm-12>
      <div class="custom-file-uploader">
        <div class="ng-row" align-item-center>
          <div *ngIf="this.CameraViewer && !isImageSaved && !PdfSaved" class="ng-col pr-15" col-auto>
            <a class="display-inline-block" (click)="showPopup1()"><i class="icon-camera"></i></a>
          </div>
          <div class="ng-col" col-auto>
            <input type="file" [id]="id" [name]="'file' + id" #takeinput (change)="fileChangeEvent($event)" />
            <label for={{id}} *ngIf="!isImageSaved && !PdfSaved">Attach</label>
            <label *ngIf="isImageSaved && !this.CameraViewer" (click)="showPopup()" class="active">View</label>
            <label *ngIf="PdfSaved" #viewlink (click)="viewButtonLink(AttachedPDFLink)" class="active">View</label>
            <label *ngIf="isImageSaved && this.CameraViewer" (click)="showPopup3()" class="active">View</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<pdf-viewer [src]="pdfsrc" [render-text]="true" style="display: block;" (after-load-complete)="loaded()" (error)="err()"></pdf-viewer>

<div class="custom-modal auto-resize" #modal>
  <div class="custom-modal-overlay" (click)="hidePopup()"></div>
  <div class="custom-modal-canvas">

    <div class="custom-modal-canvas-scroller">
      <div>

      </div>

      <div class="custom-modal-img-box">
        <img [id]="id" #imageid alt="" width="auto">
      </div>
    </div>
  </div>

  <div class="custom-modal-footer">
    <div class="ng-row" justify-content-center>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft-white" (click)="removeImage()">
          {{'Delete' | translate}}
        </button>
      </div>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft" (click)="hidePopup()">
          {{'Close' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="custom-modal auto-resize camera-view-modal" #modal3>
  <div class="custom-modal-overlay" (click)="hidePopup3()"></div>
  <div class="custom-modal-canvas">

    <div class="custom-modal-canvas-scroller">
      <div>

      </div>

      <div class="custom-modal-img-box">
        <img [id]="id" #imageid2 alt="" style="transform: scaleX(-1);" width="auto">
      </div>
    </div>
  </div>

  <div class="custom-modal-footer">
    <div class="ng-row" justify-content-center>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft-white" (click)="removeImage3()">
          {{'Delete' | translate}}
        </button>
      </div>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft" (click)="hidePopup3()">
          {{'Close' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="custom-modal auto-resize camera-modal" #modal2>
  <div class="custom-modal-overlay"></div>
  <div class="custom-modal-canvas p-0">
    <webcam *ngIf="this.cameraactive" mirrorImage="always" [imageQuality]="1" [height]="500" [width]="500"
      [trigger]="triggerObservable" (imageCapture)="handleImage($event)">
    </webcam>
  </div>
  <div class="custom-modal-footer">
    <div class="ng-row" justify-content-center>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft" (click)="hidePopup1()">
          {{'Close' | translate}}
        </button>
      </div>
      <div class="ng-col pd" col-auto>
        <button class="btn-dft-white" (click)="triggerSnapshot();">
          {{'Capture' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>