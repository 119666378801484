import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {

  public language: string = "en";
  constructor() { }

  public TimeOut: number = 2000000;
  public BlinkIDAndroid: string = "";
  public BlinkIDIOS: string = "";
  public OtpResendTime: string = "";
  public captchaEndpoint: string = "";
  public server: string = undefined;
   // public server: string = "https://dibroshan.dibpak.com:8443/api/";

  public backbuttonStateName: string = ""
  public ServerVersion: string = "";
  public dateFormat: string = "DD MMMM YYYY"
  public Morning: string = "";
  public Afternoon: string = "";
  public Evening: string = "";
  public vidoeKey: string = "";
  public videoSecret: string = "";
  public AllowedTypeArray: string = "";
  public oecdsite: string = "";
  public ReferralCode:string = "";
  public RefCodeExpiry:string = "";

  
  GetGreeting() {

    let dt = new Date();
    let currentHour: string = dt.getHours().toString();
    currentHour = ("0" + currentHour).slice(-2);
    let currentMinutes: string = dt.getMinutes().toString();
    currentMinutes = ("0" + currentMinutes).slice(-2);

    if (currentHour + ":" + currentMinutes <= this.Morning) {
      return "Good Morning";
    }
    else if (currentHour + ":" + currentMinutes <= this.Afternoon) {
      return "Good Afternoon";
    }
    else if (currentHour + ":" + currentMinutes <= this.Evening) {
      return "Good Evening";
    }
    else {
      return "Good Night";
    }
  }
}
