import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, TemplateRef, Renderer2, AfterContentInit } from '@angular/core';
import * as _ from 'lodash';
import { Guid } from "guid-typescript";
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { CommonService } from '../../Services/common.service'
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-attach-box',
  templateUrl: './attach-box.component.html',
  styleUrls: ['./attach-box.component.scss']
})

export class AttachBoxComponent implements OnInit {

  imageError: string = null;
  isImageSaved: boolean;
  cardImageBase64: string;
  image64: string[];
  imagebase64: string;
  identity1: number;
  uniqueId: Guid;
  id: string;
  imagesrc;
  cameraactive: boolean = false;
  localstream;
  imageviewer: boolean;
  AttachImage: boolean;
  desktop: boolean;

  @Input() attachedHeading: string;
  @Input() CameraViewer: boolean = true;
  @Input() AsterikShow: boolean = false;
  @Input() attachedText: string = "UploadJPEG";
  @Output() callParentFunction = new EventEmitter<any>();
  @Output() callAnotherFunction = new EventEmitter<any>();
  @ViewChild("modal") Modal: ElementRef;
  @ViewChild("imageid") imagetag: ElementRef;
  @ViewChild("imageid2") imagetag2: ElementRef;
  @ViewChild('takeinput', { static: false }) InputVar: ElementRef;
  @ViewChild('takeinput2', { static: false }) InputVar2: ElementRef;
  @ViewChild("modal2") Modal2: ElementRef;
  @ViewChild("modal3") Modal3: ElementRef;

  @Input() isSimpleAttached: boolean = false;

  @Input() addMoreButton: boolean = false;

  constructor(
    public renderer: Renderer2,
    public common: CommonService,
    public router: Router,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.uniqueId = Guid.create();
    this.id = this.uniqueId.toString();

    if (this.deviceService.deviceType == 'desktop') {
      this.desktop = true;
    }
    else {
      this.desktop = false;
    }
  }

  fileChangeEvent(fileInput: any) {

    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {

      const max_size = 5000000;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 2229;
      const max_width = 8386;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'You have exceeded 5 MB. Please upload a valid format page.';

        return false;
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = 'Please upload a valid JPEG, PNG or JPG format. Max size: 5 MB';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];



          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              '*' +
              max_width +
              'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.image64 = this.cardImageBase64.split(',');
            this.imagebase64 = this.image64[1];

            this.renderer.setAttribute(this.imagetag.nativeElement, 'src', this.image64[0] + ',' + this.image64[1]);
            this.AttachImage = true;
            this.isImageSaved = true;
            this.callParentFunction.emit(this.imagebase64);
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeImage() {
    this.InputVar.nativeElement.value = "";
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.callAnotherFunction.emit();
    this.renderer.removeClass(this.Modal.nativeElement, "active");
  }

  removeImage3() {
    this.renderer.setAttribute(this.imagetag2.nativeElement, 'src', "");

    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.callAnotherFunction.emit();
    this.renderer.removeClass(this.Modal3.nativeElement, "active");
  }

  hidePopup() {
    this.renderer.removeClass(this.Modal.nativeElement, "active");
  }

  hidePopup3() {
    this.renderer.removeClass(this.Modal3.nativeElement, "active");
    this.cameraactive = false;
    this.localstream.getTracks()[0].stop();
  }

  showPopup() {
    this.renderer.addClass(this.Modal.nativeElement, "active");
  }

  showPopup3() {
    if (this.AttachImage) {
      this.renderer.addClass(this.Modal.nativeElement, "active");

    } else {
      this.renderer.addClass(this.Modal3.nativeElement, "active");

    }
  }

  showPopup1() {
    this.cameraactive = true;
    this.checkCamera();
  }

  checkCamera() {
    var that = this;
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(function (stream) {
        that.localstream = stream;
        /* use the stream */

        that.cameraactive = true;
        that.renderer.addClass(that.Modal2.nativeElement, "active");
      })
      .catch(function (err) {
        /* handle the error */

        that.common.ParseErrorAlert('Sorry', 'OK', that.router, 'Your device does not support this feature');
        that.cameraactive = false;

      });
  }



  hidePopup1() {
    this.renderer.removeClass(this.Modal2.nativeElement, "active");
    this.cameraactive = false;
    this.localstream.getTracks()[0].stop();
  }


  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  triggerSnapshot(): void {
    this.trigger.next();
    this.imageviewer = true;
  }

  handleImage(webcamImage: WebcamImage): void {
    console.info('Saved webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.renderer.setAttribute(this.imagetag2.nativeElement, 'src', this.webcamImage.imageAsDataUrl);
    this.isImageSaved = true;
    this.AttachImage = false;
    this.callParentFunction.emit(this.webcamImage.imageAsBase64);
    this.hidePopup1();

  }

  public get triggerObservable(): Observable<void> {

    if (this.cameraactive) {
      return this.trigger.asObservable();
    }
  }

}
