import { Auth2Guard } from './auth2.guard';
import { Auth3Guard } from './auth3.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailOtpComponent } from './email-otp/email-otp.component';
import { LoginComponent } from './login/login.component';
import { MobileOtpComponent } from './mobile-otp/mobile-otp.component';
import { AuthGuardService as AuthGuard } from './Services/auth-guard.service';
import { Error503Component } from './error503/error503.component';
import { CurrencyExchangeRateComponent } from './currency-exchange-rate/currency-exchange-rate.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { VerifyChangePasswordOtpComponent } from './verify-change-password-otp/verify-change-password-otp.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LinkAccountOpeningFormComponent } from './link-account-opening-form/link-account-opening-form.component';
import { RdaAdcServicesFormComponent } from './rda-adc-services-form/rda-adc-services-form.component';
import { ConversionApplicationFormComponent } from './conversion-application-form/conversion-application-form.component';
import { IslamicNayaPakistanCertificatesFormComponent } from './islamic-naya-pakistan-certificates-form/islamic-naya-pakistan-certificates-form.component';
import { LocalTransferComponent } from './Local-Transfer-form/local-transfer.component';
import { NayaPakistanEncashmentFormComponent } from './naya-pakistan-encashment-form/naya-pakistan-encashment-form.component';
import { CRSdeclarationComponent } from './crsdeclaration/crsdeclaration.component';
import { CDCTransferAmountRequestComponent } from './cdctransfer-amount-request/cdctransfer-amount-request.component';
import { RdastockMarketInvestmentRequestComponent } from './rdastock-market-investment-request/rdastock-market-investment-request.component'
import { EmailSecOTPComponent } from './email-sec-otp/email-sec-otp.component';
import { MobileSecOTPComponent } from './mobile-sec-otp/mobile-sec-otp.component';
import { QurbaniFormComponent } from './qurbani-form/qurbani-form.component';
import { QurbaniFormSuccessComponent } from './qurbani-form-success/qurbani-form-success.component';
import { InternationTransferRequestComponent } from './internation-transfer-request/internation-transfer-request.component';
import { DiscrepancyScreenComponent } from './discrepancy-screen/discrepancy-screen.component';
import { SubmitSuccessComponent } from './register/submit-success/submit-success.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/login', pathMatch: 'full'
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'change-password', component: ChangePasswordComponent
  },
  {
    path: 'DiscrepancyScreen', component: DiscrepancyScreenComponent
  },
  {
    path: 'verify-change-password-otp', component: VerifyChangePasswordOtpComponent, canActivate: [Auth3Guard]
  },
  {
    path: 'reset-password', component: ResetPasswordComponent, canActivate: [Auth3Guard]
  },
  {
    path: 'emailOTP', component: EmailOtpComponent, canActivate: [AuthGuard]
  },
  {
    path: 'mobileOTP', component: MobileOtpComponent, canActivate: [AuthGuard]
  },
  {
    path: 'emailSecOTP', component: EmailSecOTPComponent, canActivate: [AuthGuard]
  },
  {
    path: 'mobileSecOTP', component: MobileSecOTPComponent, canActivate: [AuthGuard]
  },

  // mobileSecOTP/referel
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(mod => mod.RegisterModule)
  },
  {
    path: 'SecondaryRegistration',
    loadChildren: () => import('./register-sec/register-sec.module').then(mod => mod.RegisterSecModule)
  },
  {
    path: 'RemittanceTracker',
    loadChildren: () => import('./remittance-tracker/remittance-tracker.module').then(mod => mod.RemittanceTrackerModule)
  },
  {
    path: 'error503', component: Error503Component
  },
  {
    path: 'CurrencyExchangeRate', component: CurrencyExchangeRateComponent, canActivate: [Auth2Guard]
  },
  {
    path: 'LinkAccountOpeningForm', component: LinkAccountOpeningFormComponent, canActivate: [Auth2Guard]
  },
  {
    path: 'RdaAdcServicesForm', component: RdaAdcServicesFormComponent
  },
  {
    path: 'ConversionApplicationForm', component: ConversionApplicationFormComponent
    , canActivate: [Auth2Guard]
  },
  {
    path: 'IslamicNayaPakistanCertificatesForm', component: IslamicNayaPakistanCertificatesFormComponent, canActivate: [Auth2Guard]
  },
  {
    path: 'LocalTransfer', component: LocalTransferComponent
    , canActivate: [Auth2Guard]
  },
  {
    path: 'InternationlTransfer', component: InternationTransferRequestComponent
    , canActivate: [Auth2Guard]
  },
  {
    path: 'NayaPakistanEncashmentForm', component: NayaPakistanEncashmentFormComponent
    , canActivate: [Auth2Guard]
  },
  {
    path: 'CRSdeclaration', component: CRSdeclarationComponent
  },
  {
    path: 'CDCTransferAmountRequest', component: CDCTransferAmountRequestComponent,
    canActivate: [Auth2Guard]
  },
  {
    path: 'RDAStockMarketInvestmentRequestForm', component: RdastockMarketInvestmentRequestComponent,
    canActivate: [Auth2Guard]
  },
  {
    path: 'auto-financing',
    loadChildren: () => import('./auto-financing/auto-financing.module').then(mod => mod.AutoFinancingModule)
  },
  {
    path: 'roshan-samaaji-khidmat',
    loadChildren: () => import('./roshan-samaaji-khidmat/roshan-samaaji-khidmat.module').then(mod => mod.RoshanSamaajiKhidmatModule)
  },

  {
    path: 'QurbaniForm', component: QurbaniFormComponent, canActivate: [Auth2Guard]
  },
  {
    path: 'QurbaniFormSuccess', component: QurbaniFormSuccessComponent, canActivate: [Auth2Guard]
  },
  {
    path: 'roshan-apna-ghar',
    loadChildren: () => import('./roshan-apna-ghar/roshan-apna-ghar.module').then(mod => mod.RoshanApnaGharModule)
  },
  {
    path: 'pension',
    loadChildren: () => import('./pension/pension.module').then(mod => mod.PensionModule)
  },
  { path: 'SubmitSuccess', component: SubmitSuccessComponent},
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
