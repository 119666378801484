import { ConfigService } from './config.service';
import { HttpServiceService } from './http-service.service';
import { Injectable } from '@angular/core';

export interface RdaAdcFormInterface {
  UniqueId: string;
  ApplicationId: string;
  BasicAccountNo: string;
  DebitCard: string;
  NameForCard: string;
  RecieveCourier: string;
  ChequeBookLeaves: string;
  CityForCourier: number;
  BranchForCourier: number;
}

export interface PayOrderInterface {
  uniqueId: string;
  applicationId: string;
  basicAccount: string;
  debitCurrency: string;
  creidtCurrency: string;
  exRate: string;
  amountInWord: string;
  amount: string;
  purposeRemittance: string;
  benName: string;
  cnic_Passport_NTN: string;
  benAddress: string;
  contact: string;
  authorizeOther: boolean;
  authPersonName: string;
  authPersonCnic: string,
  otherDescription: string
}

export interface FDDInterface {
  uniqueId: string,
  applicationId: string,
  debitCurrency: string,
  basicAccount: string,
  creidtCurrency: string,
  exRate: string,
  amountInWord: string,
  amount: string,
  purposeRemittance: string,
  benName: string,
  drawnCity_Country: string,
  benAddress: string,
  contact: string,
  authorizeOther: Boolean,
  authPersonName: string,
  authPersonCnic: string,
  otherDescription: string
}

export interface FTTInterface {
  applicationId: string,
  debitAccount: string,
  debitCurrency: string,
  creditCurrency: string,
  exRate: string,
  amountInWord: string,
  amountInDigits: string,
  purposeRemittance: string,
  othersDescription: string,
  benAccountIban: string,
  benBankName: string,
  benBankAddress: string,
  benSwiftCode: string,
  benRelation: string,
  benTitle: string,
  benAddress: string,
  benTelephone: string,
  interBankName: string,
  interBankAddress: string,
  interSwfitCode: string,
  benCorrBnkName: string,
  benCorrBnkAddr: string,
  benCorrBnkSwft: string,
  drawnCity_Coun: string,
  benBnkCodeType: string,
  benBankCode: string,
  beneIdentityType: string,
  beneIdentityNo: string
}

export interface RTGSInterface {
  uniqueId: string;
  applicationId: string;
  basicAccount: string;
  debitCurrency: string;
  creidtCurrency: string;
  exRate: string;
  amountInWord: string;
  amount: string;
  purposeRemittance: string;
  benName: string;
  benIban: string;
  benAddress: string;
  benBankName: string;
  otherDescription: string
}

export interface NPEncashCertificate {
  PreMatureCertNo: string;
  StartDate: string;
  MaturityDate: string;
}

export interface NPEncashInterface {
  UniqueId: string;
  currency: string;
  ApplicationId: string;
  BasicAccountNo: string;
  listofcertificates: NPEncashCertificate[];
}

export interface RDACapitalInterface {
  AccountNumber: string;
  Broker: string;
  ApplicationId: string;
}

export interface SaveCDCTransferInterface {
  AccountNumber: string;
  Amount: number;
  ApplicationId: string;
  AmountInWords: string;
  CDCNumber: string;
}

export interface SaveQurbaniRequestModel {
  UserId: string;
  DebitAccount: string;
  DebitCurr: string;
  CreditCurr: string;
  ExRate: Number;
  AmountInDigit: Number;
  AmountInWords: string;
  SPName: string;
  STName: string;
  QTName: string;
  QurbaniDay: string;
  AnimalQuantity: Number;
  CollectCity: string;
  CollectAddress: string;
  CollectLocation: string;
  DelMailAddress: string;
  QurbaniShare: Number;
  QurbaniShareNames: string;
  ChannelType: string;
  DonationType: string;
  DonationPerson: string;
  DonationAddress: string;
  DonationContact: string;
  LocalEq: Number
}
export interface SaveVpsRequestModel {
  uniqueId: string;
  nextofkin: string;
  relationship: string;
  fullname: string;
  cnicpassport: string;
  contactnumber: string;
  address: string;
  investmentamount: number;
  RetirementAge: string;
  userId: string;
  vlPensionScheme: string;
  answer1A: boolean;
  OneADetail: string;
  answer1B: boolean;
  OneBDetail: string;
  answer1C: boolean;
  OneCDetail: string;
  answer1D: boolean;
  OneDDetail: string;
  answer1E: boolean;
  OneEDetail: string;
  Answer1F: boolean;
  OneFDetail: string;
  Answer1G: boolean;
  OneGDetail: string;
  Answer1H: boolean;
  OneHDetail: string;
  Answer2A: boolean;
  TwoADetail: string;
  Answer2B: boolean;
  TwoBDetail: string;
  Answer3: boolean;
  ThreeDetail: string;
  Answer4A: boolean;
  FourADetail: string;
  Answer4B: boolean;
  FourBDetail: string;
  Answer4C: boolean;
  FourCDetail: string;
  Answer5: boolean;
  FiveDetail: string;
  Answer6: boolean;
  SixDetail: string;
  Answer7: boolean;
  SevenDetail: string;
  Answer8: boolean;
  EightDetail: string;
  Answer9: boolean;
  NineDetail: string;
  MonthlySaving: string;
  Occupation: string;
  RiskReturn: string;
  InvestmentObjective: string;
  KnowledgeofInvestment: string;
  InvestmentHorizon: string;
}

@Injectable({
  providedIn: 'root'
})

export class EFormServiceService {

  public SaveRdaAdcFormURL: string = "EForm/SaveRdaAdcServiceForm"
  public SaveNPEncashFormURL: string = "EForm/SaveNPEncashForm"
  public SavePayOrderURL: string = "EForm/SavePayOrderV1";
  public SaveFddURL: string = "EForm/SaveFDDRemittanceV1";
  public SaveFTTURL: string = "EForm/SaveFTTRemittanceV1";
  public SaveRTGSURL: string = "EForm/SaveRTGRequestV1";
  public SaveRDACapitalMarReqURL: string = "EForm/SaveRDACapitalMarReq";
  public SaveCDCTransferAmountReqURL: string = "EForm/SaveCDCTransferAmountReq";
  public GetQurbaniRatesURL: string = "EForm/GetQurbaniRates";
  public GetQurbaniFieldsURL: string = "EForm/GetQurbaniFields";
  public GetQurbaniConfigURL: string = "EForm/GetQurbaniConfig";
  public GetSpOutletsListURL: string = "EForm/GetSpOutletsList";
  public SaveQurbaniRequestURL: string = "EForm/SaveQurbaniRequest"
  public SaveVpsURL: string = "EForm/SaveVpsRequest"

  constructor(
    private http: HttpServiceService,
    private config: ConfigService,
  ) { }

  public CDCTransferObject: SaveCDCTransferInterface = {
    AccountNumber: "",
    Amount: 0,
    AmountInWords: '',
    ApplicationId: '',
    CDCNumber: ''
  }

  public savequrbanirequestmodel: SaveQurbaniRequestModel = {
    AmountInDigit: 0,
    AmountInWords: '',
    AnimalQuantity: 0,
    ChannelType: '',
    CollectAddress: "",
    CollectCity: '',
    CollectLocation: '',
    CreditCurr: '',
    DebitAccount: '',
    DebitCurr: '',
    DelMailAddress: '',
    DonationAddress: '',
    DonationContact: '',
    DonationPerson: '',
    DonationType: '',
    ExRate: 0,
    LocalEq: 0,
    QTName: '',
    QurbaniDay: '',
    QurbaniShare: 0,
    QurbaniShareNames: '',
    SPName: '',
    STName: '',
    UserId: '',

  }

  public RDACapitalObject: RDACapitalInterface = {
    AccountNumber: '',
    ApplicationId: '',
    Broker: ''
  }

  public RdaAdcObject: RdaAdcFormInterface = {
    ApplicationId: '',
    BasicAccountNo: '',
    BranchForCourier: 0,
    ChequeBookLeaves: '',
    CityForCourier: 0,
    DebitCard: '',
    NameForCard: '',
    RecieveCourier: '',
    UniqueId: ''
  }

  public payorderobject: PayOrderInterface = {
    amount: '',
    amountInWord: '',
    applicationId: '',
    authPersonCnic: '',
    authPersonName: '',
    authorizeOther: false,
    basicAccount: '',
    benAddress: '',
    benName: '',
    cnic_Passport_NTN: '',
    contact: '',
    creidtCurrency: '',
    debitCurrency: '',
    exRate: '',
    purposeRemittance: '',
    uniqueId: '',
    otherDescription: ''

  }

  public Fddobject: FDDInterface = {
    uniqueId: '',
    applicationId: '',
    debitCurrency: '',
    basicAccount: '',
    creidtCurrency: '',
    exRate: '',
    amountInWord: '',
    amount: '',
    purposeRemittance: '',
    benName: '',
    drawnCity_Country: '',
    benAddress: '',
    contact: '',
    authorizeOther: false,
    authPersonName: '',
    authPersonCnic: '',
    otherDescription: ''
  }

  public RTGSObject: RTGSInterface = {
    amount: '',
    amountInWord: '',
    applicationId: '',
    basicAccount: '',
    benAddress: '',
    benName: '',
    creidtCurrency: '',
    debitCurrency: '',
    exRate: '',
    purposeRemittance: '',
    uniqueId: '',
    benIban: '',
    benBankName: '',
    otherDescription: '',
  }

  public Fttobject: FTTInterface = {

    applicationId: '',
    debitAccount: '',
    debitCurrency: '',
    creditCurrency: '',
    exRate: '',
    amountInWord: '',
    amountInDigits: '',
    purposeRemittance: '',
    othersDescription: '',
    benAccountIban: '',
    benBankName: '',
    benBankAddress: '',
    benSwiftCode: '',
    benRelation: '',
    benTitle: '',
    benAddress: '',
    benTelephone: '',
    interBankName: '',
    interBankAddress: '',
    interSwfitCode: '',
    benCorrBnkName: '',
    benCorrBnkAddr: '',
    benCorrBnkSwft: '',
    drawnCity_Coun: '',
    benBnkCodeType: '',
    benBankCode: '',
    beneIdentityType: '',
    beneIdentityNo: ''
  }

  public NPEncashCertObject: NPEncashCertificate = {
    MaturityDate: '',
    PreMatureCertNo: '',
    StartDate: ''
  }

  public NpEncashObject: NPEncashInterface = {
    ApplicationId: '',
    UniqueId: '',
    BasicAccountNo: '',
    currency: '',
    listofcertificates: []
  }

  public SaveVpsObject: SaveVpsRequestModel = {
    uniqueId: '',
    nextofkin: '',
    relationship: '',
    fullname: '',
    contactnumber: '',
    address: '',
    investmentamount: 0,
    RetirementAge: '',
    cnicpassport: '',
    userId: '',
    vlPensionScheme: '',
    answer1A: false,
    OneADetail: '',
    answer1B: false,
    OneBDetail: '',
    answer1C: false,
    OneCDetail: '',
    answer1D: false,
    OneDDetail: '',
    answer1E: false,
    OneEDetail: '',
    Answer1F: false,
    OneFDetail: '',
    Answer1G: false,
    OneGDetail: '',
    Answer1H: false,
    OneHDetail: '',
    Answer2A: false,
    TwoADetail: '',
    Answer2B: false,
    TwoBDetail: "",
    Answer3: false,
    ThreeDetail: "",
    Answer4A: false,
    FourADetail: '',
    Answer4B: false,
    FourBDetail: '',
    Answer4C: false,
    FourCDetail: "",
    Answer5: false,
    FiveDetail: "",
    Answer6: false,
    SixDetail: "",
    Answer7: false,
    SevenDetail: "",
    Answer8: false,
    EightDetail: "",
    Answer9: false,
    NineDetail: "",
    MonthlySaving: "",
    Occupation: "",
    RiskReturn: "",
    InvestmentObjective: "",
    KnowledgeofInvestment: "",
    InvestmentHorizon: "",
  }
  public SaveRdaAdcFormMethod(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SaveRdaAdcFormURL, this.RdaAdcObject, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveNPEncashFormMethod(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SaveNPEncashFormURL, this.NpEncashObject, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public savepayordermethod(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SavePayOrderURL, this.payorderobject, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveFddFormMethod(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SaveFddURL, this.Fddobject, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveFTTFormMethod(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SaveFTTURL, this.Fttobject, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveRTGSFormMethod(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SaveRTGSURL, this.RTGSObject, token).subscribe((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

              }
            }
          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveRDACapitalMarReq(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SaveRDACapitalMarReqURL, this.RDACapitalObject, token).subscribe((data: any) => {
          if (data?.response?.code == 1) {

          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveCDCTransferAmountReq(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SaveCDCTransferAmountReqURL, this.CDCTransferObject, token).subscribe((data: any) => {
          if (data?.response?.code == 1) {

          }
          resolve(data);
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public GetQurbaniRates(QurbanitypeId, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.GetQurbaniRatesURL, { QurbaniTypeId: QurbanitypeId }, token).subscribe((data: any) => {
          if (data?.response?.code == 1) {
            resolve(data);
          }
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public GetQurbaniConfig(SPID, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.GetQurbaniConfigURL, { SPID: SPID }, token).subscribe((data: any) => {
          if (data?.response?.code == 1) {
            resolve(data);
          }
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public GetSpOutletsList(token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.GetSpOutletsListURL, {}, token).subscribe((data: any) => {
          if (data?.response?.code == 1) {
            resolve(data);
          }
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public SaveQurbaniRequest(object, token) {
debugger
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.SaveQurbaniRequestURL, this.savequrbanirequestmodel, token).subscribe((data: any) => {
          if (data?.response?.code == 1) {
            resolve(data);
          }
        },
          Error => {
            resolve(Error);
          })
    });
  }

  public GetQurbaniFields(token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(
        this.config.server + this.GetQurbaniFieldsURL, {}, token).subscribe((data: any) => {
          if (data?.response?.code == 1) {
            resolve(data);
          }
        },
          Error => {
            resolve(Error);
          })
    });
  }
  public SaveVpsRequest(token) {
    return new Promise((resolve, reject) => {
      console.log('Object', this.SaveVpsObject);
      this.http.postToCloseApi(this.config.server + this.SaveVpsURL, this.SaveVpsObject, token).subscribe((data: any) => {
        if (data?.response?.code == 1) {
          resolve(data);
        }
      },
        Error => {
          resolve(Error);

        })

    });



  }
}
