import { Component, Input, OnInit } from '@angular/core';
import {  AuthService} from '../../Services/auth.service'
@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {

  @Input("accordionIndex") accordionIndex:number;
  @Input("accordionHeading") accordionHeading:string;

  accordionStatus:number;

  constructor(public auth : AuthService) { }

  ngOnInit(): void {
  }

  showAccordion(num){
    if(num == this.auth.obj.variablee) {
      this.auth.obj.variablee = -1;
    } else {
      this.auth.obj.variablee = num;
      this.accordionStatus = num;
    }
  }

}
