<app-header></app-header>

<div class="container abc-component mt-46">
  <div class="ng-row bg-2F5" justify-content-center>
    <div class="ng-col text-align-center mt-46 mb-46" col-8>

      <i class="icon-check-white-big"></i>

      <div class="font-24 color-white font-600 mt-20">
        {{"Yourapplicationhasbeen" | translate}}
      </div>

      <div class="font-18 color-white font-300 mt-20">
        {{"ApplicationReferenceNumber" | translate}}
      </div>
      <div class="font-16 mt-15 color-white font-700">
        {{ Referencenumber }}
      </div>

    </div>
  </div>
  <div class="ng-row mt-15 mb-20" justify-content-center align-item-center>
    <div class="ng-col" col-8 col-md-11 col-sm-11 *ngIf="linkAccountReqEnable">
      <div class="form-group mt-30">
       
        <div class="ng-row" justify-content-center>
          <div class="ng-col mb-15" col-12>
            <div class="font-16 font-700 color-2d3 text-align-center">{{"Doyouwishto" | translate}}</div>
          </div>
          <div class="ng-col" col-auto>
            <div class="custom-checkbox">
              <input type="radio" value="Yes" id="label-001" name="checkboxGroup01">
              <label for="label-001" class="color-black pt-5 pl-35" (click)="AdditionalAccountStatusTure()">
                Yes
              </label>
            </div>
          </div>
          <div class="ng-col pl-15" col-auto>
            <div class="custom-checkbox">
              <input type="radio" value="No" id="label-002" name="checkboxGroup01"
                (click)="AdditionalAccountStatusFalse()">
              <label for="label-002" class="color-black pt-5 pl-35">
                No
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="this.additionalAccountStatus == true">
      <div class="ng-col mt-20" col-6 col-md-11 col-sm-11>
        <div class="form-group">

          <button class="btn-dft" (click)="showCheckBox()">{{"Wouldyoulike" | translate}}</button>
        </div>
      </div>

      <div class="ng-col" *ngIf="this.checkBoxStatus == true" col-8 col-md-11 col-sm-11>
        <div class="form-group mt-30">
          <div class="ng-row" justify-content-center>
            <div class="ng-col mb-15" col-12>
              <div class="font-16 font-600 color-2d3 text-align-center">{{"Selectthecurrency" | translate}}</div>
            </div>
            <div class="ng-col" col-auto *ngIf="this.auth.data.onBoardingAccount.currency!='PKR'">
              <div class="custom-checkbox">
                <input type="checkbox" value="PKR" (change)="changeSelection($event,'PKR',0)" id="label-1"
                  name="checkboxGroup">
                <label for="label-1" class="color-black pt-5 pl-35">
                  PKR
                </label>
              </div>
            </div>
            <div class="ng-col pl-15" col-auto *ngIf="this.auth.data.onBoardingAccount.currency!='USD'">
              <div class="custom-checkbox">
                <input type="checkbox" value="USD" (change)="changeSelection($event,'USD',1)" id="label-3"
                  name="checkboxGroup">
                <label for="label-3" class="color-black pt-5 pl-35">
                  USD
                </label>
              </div>
            </div>
            <div class="ng-col pl-15" col-auto>
              <div class="custom-checkbox">
                <input type="checkbox" value="GBP" (change)="changeSelection($event,'GBP',2)" id="label-4"
                  name="checkboxGroup">
                <label for="label-4" class="color-black pt-5 pl-35">
                  GBP
                </label>
              </div>
            </div>
            <div class="ng-col pl-15" col-auto>
              <div class="custom-checkbox">
                <input type="checkbox" value="EURO" (change)="changeSelection($event,'EURO',3)" id="label-5"
                  name="checkboxGroup">
                <label for="label-5" class="color-black pt-5 pl-35">
                  EURO
                </label>
              </div>
            </div>
            <div class="ng-col pl-15" col-auto>
              <div class="custom-checkbox">
                <input type="checkbox" value="AED" (change)="changeSelection($event,'AED',4)" id="label-6"
                  name="checkboxGroup">
                <label for="label-6" class="color-black pt-5 pl-35">
                  AED
                </label>
              </div>
            </div>




            <div class="ng-col mt-20" col-6 col-sm-12>
              <button class="btn-dft-white" [disabled]="isSubmitValid()" (click)="Submit()">{{"Submit" |
                translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="ng-col pd mt-15" col-10>
      <div class="font-16 color-354 font-400 text-align-center">
        {{"Youwillbereceivinganemailfrom" | translate}}
      </div>

      <div class="font-16 color-354 font-400 text-align-center mt-20">
        {{"PleasequoteyouraboveApplication" | translate}}
      </div>

      <div class="font-16 color-354 font-400 text-align-center mt-20">
        {{"Forfurtherinquiryplease" | translate}} 
        <a href="mailto:dibrda.inquiry@dibpak.com" class="color-354">dibrda.inquiry@dibpak.com</a>
      </div>

      <div class="font-16 color-354 font-400 text-align-center mt-20">
        <ng-container *ngIf="IsResident == '0'">
          {{"Foranycomplaintsregarding" | translate}} 
          <a href="mailto:dibrda.complaint@dibpak.com" class="color-354">dibrda.complaint@dibpak.com</a>
        </ng-container>
        <ng-container *ngIf="IsResident == '1'">
          {{"ForanycomplaintsregardingResident" | translate}}
          <a href="mailto:dibrda.complaint@dibpak.com" class="color-354">dibrda.complaint@dibpak.com</a>
        </ng-container>
      </div>

      <div class="font-23 color-354 font-600 text-align-center mt-20">
        {{"Thankyouforchoosingus" | translate}}
      </div>

      <div class="font-18 color-354 font-700 text-align-center mt-20">
        {{"DubaiIslamicBankPakistanLimited" | translate}}
        <div>{{"ThebetterwaytoBank" | translate}}</div>
      </div>
    </div>

    <div class="ng-col mt-15" col-12>
      <ng-container *ngIf=!this.feedbackcheck>
        <div class="ng-row" justify-content-center>
          <div class="ng-col" col-6 col-sm-12>
            <div class="form-group pd">
              <div>
                <button class="btn-dft" (click)="showPopup()" *ngIf=!this.feedbackcheck>{{"feedback" |
                  translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</div>

<div class="custom-modal rating-modal" #modalOne>
  <div class="custom-modal-overlay" (click)="hidePopup()"></div>
  <div class="custom-modal-canvas p-0">
    <div class="custom-modal-close">

    </div>
    <div class="custom-modal-canvas-scroller">

      <div class="font-18 font-600 color-2d3">
        {{"ShareYourFeedback" | translate}}
      </div>


      <div class="ng-row mt-20">
        <div class="ng-col" col-11>
          <div class="font-14 font-500 color-354">
            {{"Howsatisfiedareyou" | translate}}
          </div>
        </div>
      </div>

      <form [formGroup]="newform">
        <div class="form-group">
          <div class="start-rating-box mt-15 mb-15">
            <star-rating value="3" [totalstars]='5' checkedcolor="#F6D05B" uncheckedcolor="#EAEAEA" size="35px"
              readonly="false" (rate)="onRate($event)" space="between"></star-rating>
            <ng-container item-content *ngFor="let validation of errorKey.validation_messages.rating">
              <span class="error-label"
                *ngIf="newform.controls.Rating.hasError(validation.type) &&(newform.controls.Rating.touched ||submitAttempt)">
                {{ getMessage(validation.key,'rating') }}
              </span>
            </ng-container>
          </div>
        </div>

        <div class="form-group">
          <textarea placeholder="Comments (optional)" class="not-on-this" formControlName="Comment"></textarea>
        </div>
      </form>

      <div class="form-group mt-46">
        <button class="btn-dft" [disabled]="isFormValid()" (click)="formpost()">
          {{"Submit" | translate}}
        </button>
      </div>

    </div>
  </div>
</div>

<app-fab></app-fab>