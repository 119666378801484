<div class="ng-row min-height-100vh" justify-content-center align-content-between>
  <div class="ng-col" col-12>
    <app-header></app-header>
  </div>
  <form [formGroup]="form">
    <div class="ng-col" col-12>
      <div class="container login-component mt-15">
        <div class="ng-row">
          <div class="ng-col hide-on-mob" col-2>
            <div class="left-img"></div>
          </div>
          <div class="ng-col pt-20 p-l-r-sm-30" col-6 col-sm-12>
            <div class="ng-row height-100" justify-content-center>
              <div class="ng-col" col-10 col-sm-12>
                <div class="logo mt-15">
                </div>
                <div class="font-20 color-2f5 font-600 mt-10">
                  <ng-container>{{ "RemittanceTracker" | translate }}</ng-container>
                </div>

                <div class="font-12 color-354 font-400 mt-5">
                  {{"TransferRemittanceTracker" | translate}}
                </div>

                <div class="form-group mt-46">
                  <label>{{"MTCN" | translate}}</label>
                  <input type="text" onCopy="return false" maxlength="30" onDrag="return false" onDrop="return false"
                    onPaste="return false" placeholder="Please enter" formControlName="mtcnno">
                </div>

                <div class="font-12 color-718 font-400 mt-10" *ngIf="this.error === ''">
                  {{"Please10digit" | translate}}
                </div>
                <div class="font-12 color-718 font-400 mt-10" *ngIf="this.error !== ''">
                  <span style="color: red;">{{this.error}}</span>
                </div>

                <div class="mt-30 text-align-center">
                  <recaptcha class="display-inline-block" [size]="'normal'" formControlName="recaptcha" [hide]="false"
                    (captchaExpired)="onCaptchaExpired()" (captchaResponse)="onCaptchaResponse($event)">
                  </recaptcha>
                </div>

                <div class="form-group mt-46 mb-30">
                  <button class="btn-dft mt-46" (click)="formPost()" [disabled]="isFormValid()">{{"Submit" |
                    translate}}</button>
                  <button class="btn-dft-white mt-20" [disabled]="isFormValid()" (click)="reset()">{{"Reset" |
                    translate}}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="ng-col not-apply border-left p-20" col-4 col-sm-12>

            <!-- Empty State -->
            <div class="border br-12 p-15 h-100-60px" *ngIf="isShown">
              <div class="ng-row text-align-center height-100" align-content-center>
                <div class="ng-col" col-auto>
                  <i class="icon-remittance"></i>
                  <div class="font-12 color-2f5 font-400 mt-5">
                    {{"StartTracking" | translate}}
                  </div>
                </div>
              </div>
            </div>

            <!-- Remittance-area -->
            <div class="remittance-area" *ngIf="!isShown && this.status === 'Success'">
              <div class="remittance-status-box">
                <div class="header success">
                  <i class="icon-success-remittance"></i>
                  <!-- <i class="icon-pending-remittance"></i> -->
                  <!-- <i class="icon-close-remittance"></i> -->
                  <span>
                    {{this.respobj.status}}
                  </span>
                  <!-- <span> 
                  {{"Pending" | translate}}
                </span> -->
                  <!-- <span> 
                  {{"ClosedAccount" | translate}}
                </span> -->
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"RefID" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700 mt-10">
                    {{this.bindedmtcn}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"DisbursementDate" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.disbursementDate}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"TransferMode" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.bucket}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"BeneficiaryBank" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.beneficiaryBankName}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"TransactionMode" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    Direct Credit
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"RemitanceDate" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.remittanceDate}}
                  </div>
                </div>
                <div class="text-area border-bottom-none">
                  <div class="font-12 color-718 font-700">
                    {{"SendingInstitution" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.nameOfRemittingInstitution}}
                  </div>
                </div>
              </div>
            </div>

            <div class="remittance-area" *ngIf="!isShown && this.status === 'Pending'">
              <div class="remittance-status-box">
                <div class="header pending">
                  <!-- <i class="icon-success-remittance"></i> -->
                  <i class="icon-pending-remittance"></i>
                  <!-- <i class="icon-close-remittance"></i> -->
                  <span>
                    {{this.respobj.status}}
                  </span>
                  <!-- <span> 
                  {{"Pending" | translate}}
                </span> -->
                  <!-- <span> 
                  {{"ClosedAccount" | translate}}
                </span> -->
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"RefID" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700 mt-10">
                    {{this.bindedmtcn}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"DisbursementDate" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.disbursementDate}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"TransferMode" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.bucket}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"BeneficiaryBank" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.beneficiaryBankName}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"TransactionMode" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    Direct Credit
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"RemitanceDate" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.remittanceDate}}
                  </div>
                </div>
                <div class="text-area border-bottom-none">
                  <div class="font-12 color-718 font-700">
                    {{"SendingInstitution" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.nameOfRemittingInstitution}}
                  </div>
                </div>
              </div>
            </div>
            <div class="remittance-area" *ngIf="!isShown && this.status === 'Closed'">
              <div class="remittance-status-box">
                <div class="header close">
                  <!-- <i class="icon-success-remittance"></i> -->
                  <!-- <i class="icon-pending-remittance"></i> -->
                  <i class="icon-close-remittance"></i>
                  <span>
                    {{this.respobj.status}}
                  </span>
                  <!-- <span> 
                  {{"Pending" | translate}}
                </span> -->
                  <!-- <span> 
                  {{"ClosedAccount" | translate}}
                </span> -->
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"RefID" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700 mt-10">
                    {{this.bindedmtcn}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"DisbursementDate" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.disbursementDate}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"TransferMode" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.bucket}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"BeneficiaryBank" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.beneficiaryBankName}}
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"TransactionMode" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    Direct Credit
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-700">
                    {{"RemitanceDate" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.remittanceDate}}
                  </div>
                </div>
                <div class="text-area border-bottom-none">
                  <div class="font-12 color-718 font-700">
                    {{"SendingInstitution" | translate}}
                  </div>
                  <div class="font-12 color-354 mt-10 font-700">
                    {{this.respobj.nameOfRemittingInstitution}}
                  </div>
                </div>
              </div>
              <!-- <div class="remittance-status-box">
                <div class="header">
    
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-500">
                    {{"RefID" | translate}}
                  </div>
                  <div class="font-12 color-354 font-600 mt-5">
                    12345688
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-500">
                    {{"RefID" | translate}}
                  </div>
                  <div class="font-12 color-354 font-600 mt-5">
                    12345688
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-500">
                    {{"RefID" | translate}}
                  </div>
                  <div class="font-12 color-354 font-600 mt-5">
                    12345688
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-500">
                    {{"RefID" | translate}}
                  </div>
                  <div class="font-12 color-354 font-600 mt-5">
                    12345688
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-500">
                    {{"RefID" | translate}}
                  </div>
                  <div class="font-12 color-354 font-600 mt-5">
                    12345688
                  </div>
                </div>
                <div class="text-area">
                  <div class="font-12 color-718 font-500">
                    {{"RefID" | translate}}
                  </div>
                  <div class="font-12 color-354 font-600 mt-5">
                    12345688
                  </div>
                </div>
                <div class="text-area border-bottom-none">
                  <div class="font-12 color-718 font-500">
                    {{"RefID" | translate}}
                  </div>
                  <div class="font-12 color-354 font-600 mt-5">
                    12345688
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="ng-col" col-12>
    <div class="p-15 bg-white text-align-center">
      <div class="font-12 color-718 font-600">
        {{"TrackRemittancesPartners" | translate}}
      </div>
      <div class="ng-row" justify-content-center align-item-center col-space>
        <div class="ng-col mt-on-mob-15" col-auto col-sm-4>
          <img src="../../../assets/icons/icon-Dib-footer.svg" alt="">
        </div>
        <div class="ng-col mt-on-mob-15" col-auto col-sm-4>
          <img src="../../../assets/icons/icon-home-express-footer.svg" alt="">
        </div>
        <div class="ng-col mt-on-mob-15" col-auto col-sm-4>
          <img src="../../../assets/icons/icon-uremit-footer.svg" alt="">
        </div>
        <div class="ng-col mt-on-mob-15" col-auto col-sm-4>
          <img src="../../../assets/icons/icon-dytona-footer.svg" alt="">
        </div>
        <div class="ng-col mt-on-mob-15" col-auto col-sm-4>
          <img src="../../../assets/icons/icon-ria-footer.svg" alt="">
        </div>
        <div class="ng-col mt-on-mob-15" col-auto col-sm-4>
          <img src="../../../assets/icons/icon-remit-footer.svg" alt="">
        </div>
        <div class="ng-col mt-on-mob-15" col-auto col-sm-4>
          <img src="../../../assets/icons/icon-transfermate-footer.svg" alt="">
        </div>
        <div class="ng-col mt-on-mob-15" col-auto col-sm-4>
          <img src="../../../assets/icons/icon-ace-footer.svg" alt="">
        </div>
        <div class="ng-col mt-on-mob-15" col-auto col-sm-4>
          <img src="../../../assets/icons/icon-ime-footer.svg" alt="">
        </div>
        <div class="ng-col mt-on-mob-15" col-auto col-sm-4>
          <img src="../../../assets/icons/icon-speed-remit-footer.svg" alt="">
        </div>
        <div class="ng-col mt-on-mob-15" col-auto col-sm-4>
          <img src="../../../assets/icons/icon-western-union-footer.svg" alt="">
        </div>
      </div>

    </div>
  </div>
</div>

<app-fab></app-fab>