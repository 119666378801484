import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../Services/common.service';

@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.scss']
})
export class Header2Component implements OnInit {

  constructor(
    private commonP: CommonService,
    private _router: Router
  ) { }

  IsResident : string;
  async ngOnInit(): Promise<void> {
    
    this.IsResident = await this.commonP.Get('IsResident');
  }

  LogOut() {
    this.commonP.showLogoutAlert(this._router);
  }

  backToDashboard() {
    this._router.navigateByUrl('/CurrencyExchangeRate');
  }
}
