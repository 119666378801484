import { Injectable } from '@angular/core';
import { HttpServiceService } from './http-service.service';
import { ConfigService } from './config.service';
import { RequestDocObject } from './auth.service';

export interface FinanceDetails {
  TypeOfTransaction: string;
  TypeOfProduct: string;
  PropertyIdentification: string;
  ProjectName : string;
  IsOwnLand: boolean;
  City: string;
  Addressline1: string;
  Addressline2: string;
  PropertyType: string;
  PropertySize: string;
  Society_Area: string;
  BuildingName: string;
  CoveredArea: string;
  TokenAmount: string;
  OwnerCNIC: string;
  OwnerContact: string;
  OwnerEmail: string;
  OwnerFullName: string;
  ListOfPropertyDocs: RequestDocObject[];
}

export interface CoApplicantDetailModel {
  IsJointOwner: boolean;
  IsClubIncome: boolean;
  IsNRP: boolean;
  Name: string;
  CNIC: string;
  ExpiryDate: string;
  DOB: string;
  Relationship: string;
  Addressline1: string;
  Addressline2: string;
  ContactNo: string;
  Email: string;
}

export interface NomineeModel {
  Name: string;
  CNIC: string;
  AddressLine1: string;
  AddressLine2: string;
  MobileNo: string;
  Email: string;
  City: string;
  ContactName: string;
  RelationRda: string;
  TransactionDetail: string;
  NomineeType: string;
}

export interface CalculatorReqModel {
  Consent: boolean;
  IsLien: boolean;
  TransactionType: string;
  // DebitAccount: string;
  // DebitCurrency: string;
  // ExRate: string;
  PricingType: string;
  DesiredAmount: string;
  DesiredTenor: string;
  ProfitRate: string;
  MonthlyInstallment: string;
  FirstHalfMonthPay: string;
  SecondHalfMonthPay: string;
  NetIncome: string;
  FirstHalfProfitRate: string;
  SecondHalfProfitRate: string;
  // ConvertedAmount: string;
}

export interface EmployementDetailModel {
  applicantDetailId: string;
  coBorrowerDetailId: string;
  isSalaried: boolean;
  companyName: string;
  employeStatus: string;
  employmentType: string;
  otherEmploymentType: string;
  previousEmpName1: string;
  previousEmpName2: string;
  totalEmployment: string;
  profession: string;
  otherProfession: string;
  otherEntityType: string;
  businessName: string;
  businessNature: string;
  sector_Industry: string;
  durPresBusiness: string;
  totalYearsBusines: string;
  noOfEmployees: string;
  entityType: string;
  businessShare: string;
  annualTurnover: string;
  officeType: string;
  rentAmount: string;
  addOfBusLine1: string;
  addOfBusLine2: string
  officeContact: string;
  listofPropertyDocs: RequestDocObject[]
}

export interface IncomDetailModel {
  applicantDetailId: string;
  coBorrowerDetailId: string;
  isSalaried: boolean;
  netIncome: string;
  isOtherSource: boolean;
  otherIncome: string;
  otherSources: string;
  totalIncome: string;
  listofPropertyDocs: RequestDocObject[],
  isAddCoBorrower: boolean;
}

export interface CoBorrowerDetailsModel {
  // Coborrower: string;
  SegmentType: string;
  // OtherDescription: string;
  Name: string;
  CNIC: string;
  ExpiryDate: string;
  DOB: string;
  Relationship: string;
  Addressline1: string;
  Addressline2: string;
  ContactNo: string;
  Email: string;
}

@Injectable({
  providedIn: 'root'
})
export class RoshanApnaGharService {

  private SaveFinanceDetailsUrl: string = "RAG/SaveFinanceDetails";
  private KnowApnaGharJourneyURL: string = "RAG/KnowRAGJourney";
  private SaveCoApplicantDetailURL: string = "RAG/SaveCoApplicantDetails";
  private SaveApplicantDetailURL: string = "RAG/SaveApplicantDetails";
  private MarkApplicationCompleteUrl: string = "RAG/MarkApplicationCompleted";
  private GetGmssRatesURL: string = "RAG/GetGMSSRates";
  private SaveCalculatorReqUrl: string = "RAG/SaveCalculatorReq";
  private SaveIncomeDetailUrl: string = "RAG/SaveIncomeDetails";
  private SaveEmployementDetailsURL: string = "RAG/SaveEmployementDetails";
  private SaveCoBorrowerDetailURL: string = "RAG/SaveCoBorrowerDetails";
  private UpdateCalculatorReqURL: string = "RAG/UpdateCalculatorReq";
  private NomineeDetailURL : string = "RAG/SaveNomineeRequest";

  constructor(
    private http: HttpServiceService,
    private ConfigService: ConfigService
  ) { }

  public NomineeObject: NomineeModel = {
    AddressLine1: '',
    AddressLine2: '',
    CNIC: '',
    City: '',
    ContactName: '',
    Email: '',
    MobileNo: '',
    Name: '',
    NomineeType: '',
    RelationRda: '',
    TransactionDetail: ''
  }

  public financeObject: FinanceDetails = {
    Addressline1: '',
    Addressline2: '',
    BuildingName: '',
    City: '',
    CoveredArea: '',
    IsOwnLand: null,
    PropertyIdentification: '',
    ProjectName:'',
    ListOfPropertyDocs: [],
    PropertySize: '',
    PropertyType: '',
    Society_Area: '',
    TypeOfProduct: '',
    TypeOfTransaction: '',
    TokenAmount: '',
    OwnerCNIC: '',
    OwnerContact: '',
    OwnerEmail: '',
    OwnerFullName: ''
  };

  public CoBorrowerDetailObject: CoBorrowerDetailsModel = {
    // Coborrower: '',
    SegmentType: '',
    
    // OtherDescription: '',
    Addressline1: '',
    Addressline2: '',
    CNIC: '',
    ContactNo: '',
    DOB: '',
    Email: '',
    ExpiryDate: '',
    Name: '',
    Relationship: ''
  }

  public CoApplicantObject: CoApplicantDetailModel = {
    Addressline1: '',
    Addressline2: '',
    CNIC: '',
    ContactNo: '',
    DOB: '',
    Email: '',
    ExpiryDate: '',
    IsClubIncome: null,
    IsJointOwner: null,
    IsNRP: null,
    Name: '',
    Relationship: ''
  }

  public CalculatorObject: CalculatorReqModel = {
    Consent: null,
    // DebitAccount: '',
    // DebitCurrency: '',
    DesiredAmount: '',
    DesiredTenor: '',
    // ExRate: '',
    FirstHalfMonthPay: '',
    MonthlyInstallment: '',
    NetIncome: '',
    PricingType: '',
    ProfitRate: '',
    SecondHalfMonthPay: '',
    TransactionType: '',
    // ConvertedAmount: '',
    FirstHalfProfitRate: '',
    SecondHalfProfitRate: '',
    IsLien: null
  }

  public EmployementDetail: EmployementDetailModel = {
    applicantDetailId: "",
    coBorrowerDetailId: "",
    isSalaried: true,
    companyName: "",
    employeStatus: "",
    employmentType: "",
    otherEmploymentType: "",
    previousEmpName1: "",
    previousEmpName2: "",
    totalEmployment: "",
    profession: "",
    otherProfession: '',
    businessName: "",
    businessNature: "",
    sector_Industry: "",
    durPresBusiness: "",
    totalYearsBusines: "",
    noOfEmployees: "",
    entityType: "",
    otherEntityType: '',
    businessShare: "",
    annualTurnover: "",
    officeType: "",
    rentAmount: "",
    addOfBusLine1: "",
    addOfBusLine2: "",
    officeContact: "",
    listofPropertyDocs: []
  }


  public IncomDetail: IncomDetailModel = {
    applicantDetailId: "",
    coBorrowerDetailId: "",
    isSalaried: null,
    netIncome: "",
    isOtherSource: null,
    otherIncome: "",
    otherSources: "",
    totalIncome: "",
    listofPropertyDocs: [],
    isAddCoBorrower: null
  }

  public SaveFinanceDetailMethod(object, token) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.SaveFinanceDetailsUrl, object, token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public KnowApnaGharJourney(token) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.KnowApnaGharJourneyURL, {}, token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public SaveCoApplicantDetailMethod(object, token) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.SaveCoApplicantDetailURL, object, token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public SaveCoBorrowerDetailMethod(object, token) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.SaveCoBorrowerDetailURL, object, token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public SaveEmployementDetailsMethod(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.SaveEmployementDetailsURL, object, token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }


  public UpdateCalculatorReqMethod(id, conversioncurr, exrate, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.UpdateCalculatorReqURL, { Id: id, ConversionCurr: conversioncurr, ExRate: exrate }, token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public SaveApplicantDetailMethod(qualification, OtherDescription, segmentType, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.SaveApplicantDetailURL, { qualification: qualification, segmentType: segmentType, otherDescription: OtherDescription }, token).subscribe((data) => {

        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public SaveIncomeDetailMethod(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.SaveIncomeDetailUrl, this.IncomDetail, token).subscribe((data) => {
        // 
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public SaveNomineeDetailMethod(object, token) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.NomineeDetailURL, object , token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public MarkApplicationCompleteMethod(token) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.MarkApplicationCompleteUrl, {}, token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public GetGMSSRates(token) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.GetGmssRatesURL, {}, token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public SaveCalculatorReqMethod(object, token) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.ConfigService.server + this.SaveCalculatorReqUrl, object, token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }
}
