import { AmountInwordsPipe } from './../amount-inwords.pipe';
import { Router } from '@angular/router';
import { AuthService, Balances, CurrencyRate } from './../Services/auth.service';
import { CommonService } from './../Services/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { Guid } from "guid-typescript";
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { of } from 'rxjs';

@Component({
  selector: 'app-islamic-naya-pakistan-certificates-form',
  templateUrl: './islamic-naya-pakistan-certificates-form.component.html',
  styleUrls: ['./islamic-naya-pakistan-certificates-form.component.scss']
})
export class IslamicNayaPakistanCertificatesFormComponent implements OnInit {
  customCounter: number = 0;
  amountinwords;
  constructor(
    public currency: DecimalPipe,
    public auth: AuthService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public commonP: CommonService,
    public errorKey: ErrorMessageKeyService,
    private _router: Router,
    private AmountInwordsPipe: AmountInwordsPipe
  ) {
  }
  selectedAccontfromAccount;
  appliedRate;
  selectedCurrency;
  tenor: string;
  numberofcertificate: number = 0;
  totalValue: number;
  amount: number;
  AccountBalance: string;
  errorbalance: boolean;

  ValueChange() {
    this.appliedRate = "";
    this.amount = undefined;
    this.numberofcertificate = 0;
    this.customCounter = 0;
    this.totalValue = undefined;
    this.appliedRate = undefined;
    let a = this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).currency;
    this.AccountBalance = this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).balance;
    this.selectedCurrency = a;
    this.appliedRate = 1;
  }

  AllRate: CurrencyRate[] = [];
  async ngOnInit(): Promise<void> {
    setTimeout(async () => {
      await this.commonP.presentLoading();
      this.auth.accessToken = await this.auth.Get('token');
      this.auth.data = JSON.parse(await this.auth.Get("User"));
      this.auth.BalanceInquiry(Guid.create().toString(), this.auth.accessToken).then((data: any) => {

        if (data?.response?.code == 1) {
          this.auth.AllBalance = data.response.content;
          this.auth.AllBalance = this.auth.AllBalance.filter(x => x.currency == "PKR" || x.currency == "USD" || x.currency == "GBP" || x.currency == "EUR");

          this.auth.GetAllRate(this.auth.accessToken, Guid.create().toString()).then((data: any) => {
            if (data.response) {
              if (data.response.code) {
                if (data.response.code == 1) {

                  this.commonP.hideLoading();
                  this.AllRate = data.response.content.allRate;



                }
              } else {
                this.commonP.ParseErrorAlert('', '', this._router, data);

              }
            }
            else {
              this.commonP.ParseErrorAlert('', '', this._router, data);

            }


          });

        }
        else {
          this.commonP.ParseErrorAlert('', '', this._router, data);

        }
      })
    }, 3000);
  }

  labelLink1(e) {
    e.preventDefault();
    window.open("../../../assets/INPC-(PKR)-Mudarabah-Agreement.pdf", '_blank');
  }


  labelLink2(e) {
    e.preventDefault();
    window.open("../../../assets/INPC-(USD)-Mudarabah-Agreement.pdf", '_blank');
  }


  labelLink3(e) {
    e.preventDefault();
    window.open("https://www.dibpak.com/wp-content/uploads/2021/02/INPC-(GBP)-Mudarabah-Agreement-II.pdf", '_blank');
  }


  labelLink4(e) {
    e.preventDefault();
    window.open("https://www.dibpak.com/wp-content/uploads/2021/02/INPC-(EUR)-Mudarabah-Agreement-II.pdf", '_blank');
  }

  agree: boolean = false;
  changeAgree(event) {
    if (event.srcElement.checked) {
      this.agree = true;
    }
    else {
      this.agree = false;
    }
  }

  IsValid() {
    if (!this.errorbalance) {
      if (this.agree) {
        if (this.selectedAccontfromAccount && this.amount && this.numberofcertificate && this.tenor) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  AmountChange() {




    this.amount = Math.floor(this.amount);
    if (this.selectedCurrency == "USD") {
      this.totalValue = this.amount * this.numberofcertificate * 1000;
      var balance = Number(this.AccountBalance);
      if (this.totalValue > balance) {
        this.errorbalance = true;
      }
      else {
        this.errorbalance = false;
      }
      this.amountinwords = Math.floor(this.amount * 1000);
    }
    else if (this.selectedCurrency == "GBP") {
      this.totalValue = this.amount * this.numberofcertificate * 1000;
      var balance = Number(this.AccountBalance);
      if (this.totalValue > balance) {
        this.errorbalance = true;
      }
      else {
        this.errorbalance = false;
      }
      this.amountinwords = Math.floor(this.amount * 1000);
    }
    else if (this.selectedCurrency == "EUR") {
      this.totalValue = this.amount * this.numberofcertificate * 1000;
      var balance = Number(this.AccountBalance);
      if (this.totalValue > balance) {
        this.errorbalance = true;
      }
      else {
        this.errorbalance = false;
      }
      this.amountinwords = Math.floor(this.amount * 1000);
    }
    else {
      this.totalValue = this.amount * this.numberofcertificate * 10000;
      var balance = Number(this.AccountBalance);
      if (this.totalValue > balance) {
        this.errorbalance = true;
      }
      else {
        this.errorbalance = false;
      }
      this.amountinwords = Math.floor(this.amount * 10000);
    }
  }
  toCurrency: string;
  changeCurrency(event, currency) {

    this.amount = undefined;
    this.customCounter = 0;

    this.numberofcertificate = 0;
    this.totalValue = undefined;
    this.appliedRate = undefined;
    this.selectedCurrency = currency;
    let a = this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).currency;
    if (this.selectedCurrency == a) {
      this.appliedRate = 1.00;
    } else {
      if (this.AllRate) {
        let b = this.AllRate.find(x => x.ccY_Sale == a && x.ccY_Buy == this.selectedCurrency);
        if (b) {
          this.appliedRate = b.rateSale;
        } else {
          b = this.AllRate.find(x => x.ccY_Buy == a && x.ccY_Sale == this.selectedCurrency);
          if (b) {
            this.appliedRate = (1 / Number(b.rateBuy)).toFixed(4) + "";
          }
        }
      }
    }

  }
  btnMinus() {
    if (this.customCounter == 0) {
      this.numberofcertificate = 0;
      this.totalValue = 0;

      return "";


    } else {
      this.customCounter--;
      this.numberofcertificate = this.customCounter;
      if (this.selectedCurrency == "USD") {
        this.totalValue = this.numberofcertificate * this.amount * 1000;
        var balance = Number(this.AccountBalance);
        if (this.totalValue > balance) {
          this.errorbalance = true;
        }
        else {
          this.errorbalance = false;
        }
      }
      else if (this.selectedCurrency == "GBP") {
        this.totalValue = this.numberofcertificate * this.amount * 1000;
        var balance = Number(this.AccountBalance);
        if (this.totalValue > balance) {
          this.errorbalance = true;
        }
        else {
          this.errorbalance = false;
        }
      }
      else if (this.selectedCurrency == "EUR") {
        this.totalValue = this.numberofcertificate * this.amount * 1000;
        var balance = Number(this.AccountBalance);
        if (this.totalValue > balance) {
          this.errorbalance = true;
        }
        else {
          this.errorbalance = false;
        }
      }
      else {
        this.totalValue = this.numberofcertificate * this.amount * 10000;
        var balance = Number(this.AccountBalance);
        if (this.totalValue > balance) {
          this.errorbalance = true;
        }
        else {
          this.errorbalance = false;
        }
      }


    }


  }
  async Open() {
    if (this.selectedCurrency == "USD") {
      if (this.totalValue < 5000) {
        this.commonP.showErrorAlert("Error", "Minimum 5000 USD investment is required", "Ok", this._router);
      }
      else {
        this.DoOperation();
      }
    }
    else if (this.selectedCurrency == "GBP") {
      if (this.totalValue < 5000) {
        this.commonP.showErrorAlert("Error", "Minimum 5000 GBP investment is required", "Ok", this._router);
      }
      else {
        this.DoOperation();
      }
    }
    else if (this.selectedCurrency == "EUR") {
      if (this.totalValue < 5000) {
        this.commonP.showErrorAlert("Error", "Minimum 5000 EUR investment is required", "Ok", this._router);
      }
      else {
        this.DoOperation();
      }
    }
    else {
      if (this.totalValue < 100000) {
        this.commonP.showErrorAlert("Error", "Minimum 100,000 PKR investment is required", "Ok", this._router);

      } else {
        this.DoOperation();

      }
    }
  }
  async DoOperation() {
    await this.commonP.presentLoading();
    let _a;
    if (this.selectedCurrency == "USD") {
      _a = this.amount * 1000;

    }
    else if (this.selectedCurrency == "GBP") {
      _a = this.amount * 1000;

    }
    else if (this.selectedCurrency == "EUR") {
      _a = this.amount * 1000;

    }
    else {
      _a = this.amount * 10000;

    }

    let _amount = this.currency.transform(_a, "1.2-2").replace(/[^0-9.]/g, "");

    let debitCurrency = this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).currency;
    this.auth.SaveNPCFORM(this.auth.data.onBoardingAccount.applicationId, Guid.create().toString(), debitCurrency, this.selectedAccontfromAccount, this.appliedRate + "", _a + "", this.tenor, this.selectedCurrency, this.numberofcertificate, this.totalValue + "", this.auth.accessToken).then(async (data: any) => {
      if (data?.response?.code == 1) {
        await this.commonP.hideLoading();
        this.translate.get(["Success", "RequestSubmit", "OK"]).subscribe((data: any) => {
          this.commonP.showSuccessAlert(data["Success"], data["RequestSubmit"], data["OK"], this._router).then((data) => {
            this._router.navigateByUrl("/CurrencyExchangeRate");
          });
        })
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert("", "", this._router, data);
      }
    })

  }
  btnPlus() {
    if (this.customCounter == 5) {
      return "";
    } else {
      this.customCounter++;
      this.numberofcertificate = this.customCounter;
      if (this.selectedCurrency == "USD") {
        this.totalValue = this.numberofcertificate * this.amount * 1000;
        var balance = Number(this.AccountBalance);
        if (this.totalValue > balance) {
          this.errorbalance = true;
        }
        else {
          this.errorbalance = false;
        }
      }
      else if (this.selectedCurrency == "EUR") {
        this.totalValue = this.numberofcertificate * this.amount * 1000;
        var balance = Number(this.AccountBalance);
        if (this.totalValue > balance) {
          this.errorbalance = true;
        }
        else {
          this.errorbalance = false;
        }
      }
      else if (this.selectedCurrency == "GBP") {
        this.totalValue = this.numberofcertificate * this.amount * 1000;
        var balance = Number(this.AccountBalance);
        if (this.totalValue > balance) {
          this.errorbalance = true;
        }
        else {
          this.errorbalance = false;
        }
      }
      else {
        this.totalValue = this.numberofcertificate * this.amount * 10000;
        var balance = Number(this.AccountBalance);
        if (this.totalValue > balance) {
          this.errorbalance = true;
        }
        else {
          this.errorbalance = false;
        }

      }
    }




  }

}
