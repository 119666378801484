import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { SharedComponentModule } from '../SharedComponents/SharedComponent.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';

import * as APIURL from './../../assets/APIURL.json';

import { RemittanceTrackerRoutingModule } from './remittance-tracker-routing.module';
import { RemittanceTrackerMTCNComponent } from './remittance-tracker-mtcn/remittance-tracker-mtcn.component';

import { IonicModule } from '@ionic/angular';
import { RatingModule } from 'ng-starrating';
import { WebcamModule } from 'ngx-webcam';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ReCaptchaModule } from 'angular-recaptcha3';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './../../assets/i18n/', '.json');
};


@NgModule({
  declarations: [RemittanceTrackerMTCNComponent],
  imports: [
    CommonModule,
    ReCaptchaModule.forRoot({
      invisible: {
        sitekey: APIURL.SiteKey,
      },
      normal: {
        sitekey: APIURL.SiteKey,
      },
      language: "en",
    }),
    RemittanceTrackerRoutingModule,
    CommonModule,
    HttpClientModule,
    NgxSpinnerModule,
    WebcamModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentModule,
    NgxMaskModule.forRoot(),
    NgxMaskModule.forChild(),
    IonicModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    RatingModule,
    Ng2SearchPipeModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RemittanceTrackerModule { }
