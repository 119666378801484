import { Component, Input, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from './../Services/auth.service';
import { CommonService } from './../Services/common.service';
import { ConfigService } from '../Services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { KycService } from './../Services/kyc.service';
import { CountriesService, Country } from '../Services/countries.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocationStrategy } from '@angular/common';
import { Guid } from 'guid-typescript';
import { ValidationError } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-mobile-otp',
  templateUrl: './mobile-otp.component.html',
  styleUrls: ['./mobile-otp.component.scss']
})
export class MobileOtpComponent implements OnInit {

  filterTerm: string;

  counter: string;
  current: string;
  max: string;
  code: string;
  submitAttempt: boolean = false;
  public verify: string = '';
  backSubscription;
  timerexpire: string;
  timeoutHandle;
  type: string = ""
  mobileSuccess: boolean = false;
  resendOptbtnShow: boolean = false;
  loginResponse;
  maxNumber: number = 0;
  _number: string = "";
  mobilemaxlength: number;
  mobileminlength: number;
  selectedMobile: Country;
  IsUSMobileNo;
  mask;
  numberPlaceHolder: string = '';
  mobilelist;
  uniqueId: Guid;
  id: string;
  @Input() numbertext: string;
  registerForm: FormGroup;
  SecondForm: FormGroup;
  newmobile;
  mobilelistrender: boolean = false;
  IsResident : string ;

  @ViewChild("modal") Modal: ElementRef;
  @ViewChild("customDropdownList") customDropdownList: ElementRef;

  checkDropDownStatus: boolean = false;

  selectedDropNumber: string = "";

  selectedIcon: string;
  selectAssignNumber: string;

  constructor(
    private kyc: KycService,
    private country: CountriesService,
    public errorKey: ErrorMessageKeyService,
    private translate: TranslateService,
    private commonP: CommonService,
    private auth: AuthService,
    private config: ConfigService,
    private route: ActivatedRoute,
    private _router: Router,
    private formBuilder: FormBuilder,
    private location: LocationStrategy,
    public Renderer2: Renderer2,
    public countries: CountriesService,
  ) {

    //forbackbuttondisable
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });

    this.registerForm = this.formBuilder.group({
      otp: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
    })

    this.SecondForm = this.formBuilder.group({
      newmobile: ['', Validators.compose([Validators.required])],
    })

  }

  async ngOnInit(): Promise<void> {

    this.startTime();

    this.counter = "1";
    this.current = "4";
    this.max = "13";

    let a = await this.commonP.Get('User');
    this.IsResident = await this.commonP.Get('IsResident');
    this.auth.accessToken = await this.auth.Get('token');

    if (a) {
      this.auth.data = JSON.parse(a)
      this.numbertext = this.auth.data.mobileNumber;
      this.getNumber(this.numbertext);
      this.loginResponse = JSON.parse(await this.commonP.Get('User'));
      this.auth.data.onBoardingAccount.productId = this.loginResponse.onBoardingAccount.productId;
    }


    if (this.auth.data.emailStatus && this.auth.data.mobileStatus) {
      this.kyc.count = 0;
      this.kyc.GetKycStatus(this.auth.accessToken, this.auth.data.onBoardingAccount ? this.auth.data.onBoardingAccount.productId : 1).then((data: any) => {
        if (data?.response?.code == 1) {
          if (this.kyc.totalCount > 0) {
            let nextscreen = this.kyc.getScreen(this.kyc.count);
            this.commonP.hideLoading();
            this._router.navigateByUrl("/register/" + nextscreen);
          }
          else {
            this.commonP.hideLoading();
            this.commonP.ParseErrorAlert('', '', this._router, data);
          }
        }
        else {
          this.commonP.hideLoading();
          this.commonP.ParseErrorAlert('', '', this._router, data);
        }
      })
    }
    else if (this.auth.data.mobileStatus) {
      this.kyc.count = 0;
      this.kyc.GetKycStatus(this.auth.accessToken, this.auth.data.onBoardingAccount ? this.auth.data.onBoardingAccount.productId : 1).then((data: any) => {
        if (data?.response?.code == 1) {
          if (this.kyc.totalCount > 0) {
            let nextscreen = this.kyc.getScreen(this.kyc.count);
            this.commonP.hideLoading();
            this._router.navigateByUrl("/register/" + nextscreen);
          }
          else {
            this.commonP.hideLoading();
            this.commonP.ParseErrorAlert('', '', this._router, data);
          }
        }
        else {
          this.commonP.hideLoading();
          this.commonP.ParseErrorAlert('', '', this._router, data);
        }
      })
    }
  }


  getNumber(numbertext) {
    if (this.numbertext != null) {
      let a = new Array(numbertext.length - 4).join('*') + numbertext.substr(numbertext.length - 4);
      return a;
    }
  }

  startTime() {

    var str = "3:59";

    var array = str.split(":");
    this.countdown(parseInt(array[0]), parseInt(array[1]));
  }

  isFormValid() {
    if (!this.registerForm.valid) {
      return true;
    } else {
      false;
    }
  }

  isSecondFormValid() {
    var a = this.SecondForm.get('newmobile').value;

    if (!this.SecondForm.valid) {
      return true
    }
    else {
      false;
    }
  }

  clearAll() {
    this.verify = "";
  }

  ChangeMobilePost() {
    this.commonP.presentLoading();

    var mobile = this.SecondForm.get('newmobile').value;
    var fulnumber = this.selectAssignNumber + mobile;
    this.auth.ChangeMobileOnboarding(fulnumber, this.auth.accessToken).then((data: any) => {

      if (data?.response?.code == 1) {
        this.commonP.hideLoading();
        this.translate.get(["Success", "Ok", "ChangedMobileOTP"]).subscribe((data) => {
          this.commonP.showSuccessAlert(data["Success"], data["ChangedMobileOTP"], data["Ok"], this._router).then((data: any) => {
            this.hidePopup();
            this.auth.data.mobileNumber = mobile;
            this.commonP.Set('User', JSON.stringify(this.auth.data));
            this.numbertext = this.getNumber(this.auth.data.mobileNumber);
            clearInterval(this.timeoutHandle);
            this.startTime();
            this.resendOptbtnShow = false;
            this.clear();
          });
        })
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert("", "", this._router, data);
      }
    })
  }

  closeDropdown() {
    if(this.IsResident == '0'){

      this.Renderer2.removeClass(this.customDropdownList.nativeElement, "active");
      this.checkDropDownStatus = false;
      this.filterTerm = "";
    }
  }

  showDropList() {
    if(this.IsResident == '0'){
      
      if (this.checkDropDownStatus == true) {
        this.Renderer2.removeClass(this.customDropdownList.nativeElement, "active");
        this.checkDropDownStatus = false;
        this.filterTerm = "";
      } else {
        this.Renderer2.addClass(this.customDropdownList.nativeElement, "active");
        this.checkDropDownStatus = true;
        this.filterTerm = "";
      }
    }
  }

  selectDropVal(value) {
    this.selectedMobile = value;
    this.selectedDropNumber = '+' + value.mobileCode;
    this.selectedIcon = "assets/svg-flags/" + value.flagClass + ".svg";
    this.selectAssignNumber = value.mobileCode;
    this.Renderer2.removeClass(this.customDropdownList.nativeElement, "active");
    this.checkDropDownStatus = false;
    this.ChangeMobile(value);
  }

  ChangeMobile(selectvalue) {

    this._number = "";
    this.maxNumber = selectvalue.mobileMaxLength;
    this.mobileminlength = selectvalue.mobileLength;
    if (selectvalue.blinkCode == 56) {
      this.mobileminlength = this.mobileminlength - 1;
    }
    if (selectvalue.countryName == "United States") {
      this.IsUSMobileNo = true;
    }
    else {
      this.IsUSMobileNo = false;
    }
    this.mask = selectvalue.mask ? selectvalue.mask : new Array(this.maxNumber + 1).join('9');
    this.numberPlaceHolder = selectvalue.mask ? selectvalue.mask.replace(/9/g, "X") : new Array(this.maxNumber + 1).join('X');
    this._number = "";
  }

  countdown(minutes, seconds) {
    this.timeoutHandle;
    var that = this;
    function tick() {
      that.timerexpire = minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
      seconds--;
      if (seconds >= 0) {

        that.timeoutHandle = setTimeout(tick, 1000);
      } else {
        if (minutes >= 1) {

          setTimeout(function () {
            that.countdown(minutes - 1, 59);
          }, 1000);
        }
      }

      if (that.timerexpire == "0:00") {
        that.resendOptbtnShow = true;
      }
    }
    tick();
  }

  getMessage(key, control) {

    let error = "";
    this.translate.get([key]).subscribe(data => {
      if (control == "newmobile") {
        if (key == "MobileMin") {
          error = data[key];
          error = error.replace("{minLength}", this.mobileminlength.toString());
        }
        else if (key == "MobileMax") {
          error = data[key];
          error = error.replace("{maxLength}", this.mobilemaxlength.toString());
        }
        else {
          error = data[key];
        }
      }
      else {
        error = data[key];
      }
    })
    return error;
  }

  clear() {
    this.code = "";
    this.registerForm.reset();
  }

  async SendAgain() {
    await this.commonP.presentLoading();

    this.auth.ResendMobileOTP(this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {

        this.translate.get(["Success", "Ok", "ResendSuccess"]).subscribe((data) => {
          this.commonP.showSuccessAlert(data["Success"], data["ResendSuccess"], data["Ok"], this._router);
          this.startTime();
          this.resendOptbtnShow = false;
          this.clear();

        })
      } else {
        this.commonP.ParseErrorAlert("", "", this._router, data);

      }
    })
  }


  async Next() {
    await this.commonP.presentLoading();
    if (this.type) {
      this.auth.VerifyOTPByType(this.auth.accessToken, this.type, this.code).then(async (data: any) => {
        if (data?.response?.code == 1) {
          this.mobileSuccess = true;

          this.kyc.GetKycStatus(this.auth.accessToken, this.auth.data.onBoardingAccount ? this.auth.data.onBoardingAccount.productId : 1).then((data: any) => {
            if (data?.response?.code == 1) {

              if (this.kyc.totalCount > 0) {
                let nextscreen = this.kyc.getScreen(this.kyc.count);
                if (nextscreen == "VideoselePage") {
                }
                else {
                  if (nextscreen == "AccountType") {
                    this.commonP.hideLoading();
                    this._router.navigateByUrl("/register/AccountType");
                  } 
                  else {
                    this.commonP.hideLoading();
                    this._router.navigateByUrl("/register/" + nextscreen);
                  }
                }
              }
              else {
                this.commonP.hideLoading();
                this.commonP.ParseErrorAlert('', '', this._router, data);
              }

            }
            else {
              this.commonP.hideLoading();
              this.commonP.ParseErrorAlert('', '', this._router, data);
            }
          })
        } 
        else {
          this.commonP.hideLoading();
          this.commonP.ParseErrorAlert("", "", this._router, data);
          this.clearAll();
        }
      })
    } else {
      this.auth.VerifyMobileOnboarding(this.code, this.auth.accessToken).then(async (data: any) => {
        if (data?.response?.code == 1) {
          this.mobileSuccess = true;
          await this.commonP.hideLoading();
          this._router.navigateByUrl("/register/AccountType");

        } else {
          await this.commonP.hideLoading();
          this.commonP.ParseErrorAlert("", "", this._router, data);
          this.clearAll();

        }
      })
    }

  }


  showPopup() {

    this.uniqueId = Guid.create();
    this.id = this.uniqueId.toString();
    this.newmobile = 'something';
    this.Renderer2.addClass(this.Modal.nativeElement, "active");

    this.commonP.presentLoading();
    this.countries.GetAll(this.id).then((data: any) => {

      if (data?.response?.code == 1) {
        (data.response.content)
        if(this.IsResident == '1'){

          this.mobilelistrender = true;
          this.mobilelist = data.response.content.filter(x => x.isMobile == true).sort((a, b) => a.mobileCode.localeCompare(b.mobileCode));
          this.selectedIcon = "assets/svg-flags/" + this.mobilelist.find(x => x.mobileCode == "92").flagClass + ".svg";
          this.selectedDropNumber = this.mobilelist.find(x => x.mobileCode == "92").mobileCode;
          this.mobileminlength = this.mobilelist.find(x => x.mobileCode == "92").mobileLength;
          this.mask = this.mobilelist.find(x => x.mobileCode == "92").mask ? this.mobilelist.find(x => x.mobileCode == "92").mask : new Array(this.maxNumber + 1).join('9');
          this.numberPlaceHolder = this.mobilelist.find(x => x.mobileCode == "92").mask ? this.mobilelist.find(x => x.mobileCode == "92").mask.replace(/9/g, "X") : new Array(this.mobileminlength + 1).join('X');
          this.mobilemaxlength = this.mobilelist.find(x => x.mobileCode == "92").mobileMaxLength;
          this.selectDropVal(this.mobilelist.find(x => x.mobileCode == "92"));
        }
        else{

          this.mobilelistrender = true;
          this.mobilelist = data.response.content.filter(x => x.isMobile == true).sort((a, b) => a.mobileCode.localeCompare(b.mobileCode));
          this.selectedIcon = "assets/svg-flags/" + this.mobilelist[0].flagClass + ".svg";
          this.selectedDropNumber = this.mobilelist[0].mobileCode;
          this.mobileminlength = this.mobilelist[0].mobileLength;
          this.mask = this.mobilelist[0].mask ? this.mobilelist[0].mask : new Array(this.maxNumber + 1).join('9');
          this.numberPlaceHolder = this.mobilelist[0].mask ? this.mobilelist[0].mask.replace(/9/g, "X") : new Array(this.mobileminlength + 1).join('X');
          this.mobilemaxlength = this.mobilelist[0].mobileMaxLength;
        }

        this.commonP.hideLoading();
      }
      else {

        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert("", "", this._router, data);

      }

    })
  }

  hidePopup() {
    this.Renderer2.removeClass(this.Modal.nativeElement, "active");
    this.SecondForm.patchValue({ 'newmobile': '' });
    this.mobilelistrender = false;
    this.closeDropdown();
  }

}
