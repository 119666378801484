import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StarRatingComponent } from 'ng-starrating';
import { CommonService } from '../../Services/common.service'
import { Router } from '@angular/router'
import { ErrorMessageKeyService } from '../../Services/error-message-key.service';
import { KycService } from '../../Services/kyc.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from "../../Services/auth.service";
import { RatingService } from "../../Services/rating.service";
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-submit-success',
  templateUrl: './submit-success.component.html',
  styleUrls: ['./submit-success.component.scss']
})
export class SubmitSuccessComponent implements OnInit {

  newform: FormGroup;
  LoginResponse;
  submitAttempt: boolean = false;
  feedbackcheck: boolean;
  Referencenumber: string;
  id: Guid;
  uniqueId: string;

  checkBoxStatus: boolean = false;
  linkAccountReqEnable: boolean = false;
  additionalAccountStatus: boolean = false;

  @ViewChild("modalOne") modalOne: ElementRef;

  constructor(
    public translate: TranslateService,
    public renderer: Renderer2,
    public formbuilder: FormBuilder,
    public auth: AuthService,
    private common: CommonService,
    private _router: Router,
    public errorKey: ErrorMessageKeyService,
    public kyc: KycService,
    public rating: RatingService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }


  AdditionalAccountStatusTure() {
    this.additionalAccountStatus = true;
  }
  AdditionalAccountStatusFalse() {
    this.additionalAccountStatus = false;
  }

  selectedCurrency: string[] = ['', '', '', '', '', '', ''];
  changeSelection(event, Currency, index) {


    if (event.srcElement.checked) {

      this.selectedCurrency[index] = Currency;
      if (Currency == "PKR") {
        this.pkr = Currency;
      }
      if (Currency == "USD") {
        this.usd = Currency;
      }
      if (Currency == "GBP") {
        this.gbp = Currency;
      }
      if (Currency == "AED") {
        this.aed = Currency;
      }
      if (Currency == "EURO") {
        this.euro = Currency;
      }
      if (Currency == "SAR") {
        this.sar = Currency;
      }
      if (Currency == "CAD") {
        this.cad = Currency;
      }
    } else {
      this.selectedCurrency[index] = "";
      if (Currency == "PKR") {
        this.pkr = "";
      }
      if (Currency == "USD") {
        this.usd = "";
      }
      if (Currency == "GBP") {
        this.gbp = "";
      }
      if (Currency == "AED") {
        this.aed = "";
      }
      if (Currency == "EURO") {
        this.euro = "";
      }
      if (Currency == "SAR") {
        this.sar = "";
      }
      if (Currency == "CAD") {
        this.cad = "";
      }
    }
  }
  pkr: string = "";
  usd: string = "";
  gbp: string = "";
  aed: string = "";
  euro: string = "";
  sar: string = "";
  cad: string = "";
  IsResident : string;

  async Submit() {
    await this.common.presentLoading();

    this.auth.LinkAccount(this.auth.data.onBoardingAccount.accountNumber, this.auth.data.onBoardingAccount.applicationId, Guid.create().toString(), this.selectedCurrency.filter(i => [i].join(",").length > 0).join(","), "", this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {
        this.common.hideLoading();
        this.translate.get(["Success", "RequestSubmit", "OK"]).subscribe((data: any) => {
          this.common.showSuccessAlert(data["Success"], data["RequestSubmit"], data["OK"], this._router).then((data) => {
            window.location.reload();
          });


        })

      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert("", "", this._router, data);

      }
    })
  }

  isSubmitValid() {
    {
      if (this.pkr || this.usd || this.gbp || this.aed || this.euro || this.sar || this.cad) {
        return false;
      } else {
        return true;
      }
    }
  }

  async ngOnInit(): Promise<void> {

    this.id = Guid.create();
    this.uniqueId = this.id.toString();

    this.newform = this.formbuilder.group({
      Rating: ["", Validators.compose([Validators.required])],
      Comment: [""]
    })

    this.auth.accessToken = await this.auth.Get('token');
    this.IsResident = await this.common.Get('IsResident');

    var a = await this.common.Get('User');

    if (a) {
      this.LoginResponse = JSON.parse(a);
      this.auth.data = JSON.parse(a);
      this.Referencenumber = this.LoginResponse.onBoardingAccount.applicationId;
    }

    if (this.LoginResponse.feedbackResponse == true) {
      this.feedbackcheck = true;
    }

    this.kyc.count = 0;
    this.common.presentLoading();
    var object = JSON.parse(await this.common.Get('User'));
    this.auth.data.onBoardingAccount.productId = object.onBoardingAccount.productId;
    this.kyc.GetKycStatus(this.auth.accessToken, this.auth.data.onBoardingAccount ? this.auth.data.onBoardingAccount.productId : 1).then(async (data: any) => {
      if (data?.response?.code == 1) {
        if (this.kyc.totalCount > 0) {
          let nextscreen = this.kyc.getScreen(this.kyc.count);
          if (nextscreen == "/register/SubmitSuccess") {
            
            this.auth.SendBackOfficeEmailFunction(this.auth.accessToken, this.uniqueId).then((data: any) => {
              if (data?.response?.code == 1) {
                
                this.auth.CheckOtherAccountMethod(this.auth.accessToken).then((data: any) => {
                  if (data?.response?.code == 1) {
                    this.common.hideLoading();
                    if (data.response.content == "False") {
                      this.linkAccountReqEnable = true;
                    }
                    else {
                      this.linkAccountReqEnable = false;
                    }
                  }
                  else {
                    this.common.ParseErrorAlert("", "", this._router, data);
                  }
                })
              }
            });
          }
          else {
            this.common.hideLoading();
            this._router.navigateByUrl(nextscreen);
          }
        }
        else {
          this.common.hideLoading();
          this.common.ParseErrorAlert('', '', this._router, data);
        }
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert('', '', this._router, data);
      }
    })
  }

  showPopup() {
    this.renderer.addClass(this.modalOne.nativeElement, "active");
  }

  hidePopup() {
    this.renderer.removeClass(this.modalOne.nativeElement, "active");
  }

  showCheckBox() {
    if (this.checkBoxStatus == true) {
      this.checkBoxStatus = false;
    } else {
      this.checkBoxStatus = true;
    }
  }

  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    this.newform.patchValue({ 'Rating': $event.newValue })
  }

  formpost() {

    if (this.LoginResponse.feedbackResponse == false) {
      this.common.presentLoading();
      this.SaveInfo().then(() => {

        this.rating.SubmitResponse(this.auth.accessToken, this.rating.object).then((data: any) => {
          if (data?.response?.code == 1) {
            this.common.hideLoading();
            this.LoginResponse.feedbackResponse = true;
            this.common.Set('User', JSON.stringify(this.LoginResponse));
            this.renderer.removeClass(this.modalOne.nativeElement, "active");
            this.feedbackcheck = true;
          }
          else {
            this.common.hideLoading();
            this.common.ParseErrorAlert("", "", this._router, data);
          }
        })
      });
    }
  }
  gotoDiscrepancypage(){
    
     this._router.navigate(['/register/app-discrepancy-screen'])
  }
  getMessage(key, control) {
    let error = "";
    this.translate.get([key]).subscribe(data => {
      error = data[key];
    })
    return error;
  }

  isFormValid() {
    if (!this.newform.valid) {
      return true;
    } else {
      false;
    }
  }

  SaveInfo() : Promise<string> {

    return new Promise((resolve, reject) => {

      var object = this.rating.object;
  
      var commentvalue = this.newform.get('Comment').value;
      object.Comment = (commentvalue == null) ? "" : commentvalue;
  
      object.Rating = this.newform.get('Rating').value;

      resolve('success');
    })

  }
}
