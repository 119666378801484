import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { AdditionalFieldsModel, AdditionalDocs, AdditionalFieldsService } from '../Services/additional-fields.service';
import { AuthService } from '../Services/auth.service';
import { CommonService } from '../Services/common.service';
import { ConfigService } from '../Services/config.service';
import { ErrorMessageKeyService } from '../Services/error-message-key.service';
import { KycService } from '../Services/kyc.service';
import { DocList } from '../register/supporting-documents/supporting-documents.component';
import * as _ from 'lodash';
import { DiscrepancyService } from '../Services/discrepancy.service';
@Component({
  selector: 'app-discrepancy-screen',
  templateUrl: './discrepancy-screen.component.html',
  styleUrls: ['./discrepancy-screen.component.scss']
})
export class DiscrepancyScreenComponent implements OnInit {

  counter: string;
  current: string;
  max: string;
  config: AdditionalFieldsModel[] = [];
  DocsObject: AdditionalDocs[] = [];
  DocsObject2: DocList[] = [];
  DocsObjecttopost: DocList[] = [];
  submitAttempt: boolean = false;
  imageError: string = null;
  cardImageBase64: string;
  image64: string[];
  imagebase64: string;
  isImageSaved: boolean;
  Image;
  SelectedOption = null;
  Array: string[];
  Array2: string[];
  loginResponse;
  lableofarray;
  cameraactive: boolean = false;
  localstream;
  imageviewer: boolean;
  AttachImage: boolean;
  IsForiegnTaxPayed: boolean;
  cameraobject;
  selectedfile: FileList;
  PdfSaved: boolean;
  AttachedPDFLink: string;
  IsPOPReq: boolean;
  IsResident: string;
  AllowedArray;

  @ViewChild('takeinput', { static: false }) InputVar: ElementRef;
  @ViewChild("imageid") imagetag: ElementRef;
  @ViewChild("modal") Modal: ElementRef;
  @ViewChild("modal2") Modal2: ElementRef;
  @ViewChild("modal3") Modal3: ElementRef;
  @Input() CameraViewer: boolean = true;
  @ViewChild('takeinput2', { static: false }) InputVar2: ElementRef;
  @ViewChild("imageid2") imagetag2: ElementRef;

  constructor(
    public formbuilder: FormBuilder,
    public translate: TranslateService,
    public _adddoc: AdditionalFieldsService,
    private auth: AuthService,
    private common: CommonService,
    private discpService:DiscrepancyService,
    private _router: Router,
    public errorKey: ErrorMessageKeyService,
    private sanitizer: DomSanitizer,
    private kyc: KycService,
    public renderer: Renderer2,
    public configserv: ConfigService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  async ngOnInit(): Promise<void> {

    
    this.common.presentLoading();
    this.counter = "3";
    this.current = "11";
    this.max = "13";

    this.auth.accessToken = await this.auth.Get('token');
    this.auth.data = JSON.parse(await this.common.Get('User'));
    this.IsResident = await this.common.Get('IsResident');
    this.configserv.AllowedTypeArray = await this.common.Get('AllowedDocs');
    this.AllowedArray = this.AllowedArray = ["pdf,jpeg,png,jpg" ]//this.configserv.AllowedTypeArray.toUpperCase().split(',');

    this.loginResponse = this.auth.data;
    this.IsForiegnTaxPayed = this.auth.data.isForeignTaxPayed;
    this.auth.data.onBoardingAccount.productId = this.loginResponse.onBoardingAccount.productId;

    this.kyc.count = 0;
    this.kyc.GetKycStatus(this.auth.accessToken, this.auth.data.onBoardingAccount ? this.auth.data.onBoardingAccount.productId : 1).then((data: any) => {
      if (data?.response?.code == 1) {
        if (this.kyc.totalCount > 0) {

          
          let nextscreen = this.kyc.getScreen(this.kyc.count);
          if (nextscreen == "/register/appdiscrepancypreviewscreen") {
            debugger
            this.discpService.GetDiscrepayncyPrimaryWebV1(this.auth.accessToken).then((data: any) => {
              if (data?.response?.code == 1) {

                if (this.IsResident == '0') {
                  
                  if (this.IsForiegnTaxPayed) {
                    this.config = data.response.content.list.filter(x => x.listofadddoc[0].docId != 'ProofNRP');
                  }
                  else {
                    this.config = data.response.content.list.filter(x => x.listofadddoc[0].docId != 'ProofTax');
                  }
                }
                else {
                  data.response.content.list.forEach(element => {
                    if (element.listofadddoc[0].docId.toString().toUpperCase().includes('POP')) {
                      this.IsPOPReq = element.isMandatory ? true : false;
                    }
                  });
                  this.config = data.response.content.list;
                }
                this.DocsObject = data.response.content.list;
                this.common.hideLoading();
              }
              else {
                this.common.hideLoading();
                this.common.ParseErrorAlert("", "", this._router, data);
              }
            });
          }
          else {
            this.common.hideLoading();
            this._router.navigateByUrl(nextscreen);
          }
        }
        else {
          this.common.hideLoading();
          this.common.ParseErrorAlert('', '', this._router, data);
        }
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert('', '', this._router, data);
      }
    })
  }

  hidePopup() {
    this.renderer.removeClass(this.Modal.nativeElement, "active");
  }

  showPopup() {
    this.renderer.addClass(this.Modal.nativeElement, "active");
  }

  PostToTempImage(dataPara, identifier) {
    debugger
    this.common.presentLoading();
    return new Promise((resolve, reject) => {
      this.auth.saveTempimage2(this.auth.accessToken, dataPara[0], dataPara[1]).then((data: any) => {
        if (data?.response?.code == 1) {
          this.DocsObject2.push({
            docId: identifier.listofadddoc[0].docId,
            docType: identifier.listofadddoc[0].documentType,
            ImageId: data.response.content.id,
            Base64Type: dataPara[1]
          });

          this.common.hideLoading();
          debugger
          resolve(data);
        }
        else {
          this.common.hideLoading();
          this.common.ParseErrorAlert("", "", this._router, data);
        }
      },
        Error => {
          resolve(Error);
        })
    });
  }

  SaveImageToDocObject(data, identifier){
    this.DocsObject2.push({
      docId: identifier.listofadddoc[0].docId,
      docType: identifier.listofadddoc[0].documentType,
      ImageId: data[0],
      Base64Type: data[1]
    });
    debugger
  }

  viewButtonLink(link) {
    window.open(this.AttachedPDFLink, '_blank');
  }

  isFormValid() {
    return false
  //  return this.DocsObject
   
  }

  getMessage(key, control) {
    let error = "";
    this.translate.get([key]).subscribe(data => {
      error = data[key];
    })
    return error;
  }

  removeImage1() {
    for (var i = 0; i < this.DocsObject2.length; i++) {
      if (this.DocsObject2[i].docId.toString().toUpperCase().includes('POP')) {
        this.DocsObject2[i] = {
          Base64Type: "",
          ImageId: '',
          docId: '',
          docType: ''
        };
      }
    }
    this.InputVar.nativeElement.value = "";
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.renderer.removeClass(this.Modal.nativeElement, "active");
  }

  RemoveFunction(identifier) {
    for(var i=0; i<this.DocsObject2.length; i++){
      if(this.DocsObject2[i].docId == identifier.testDoc){
        this.DocsObject2[i] = {
          Base64Type : "",
          ImageId : '',
          docId : '',
          docType : ''
        };
      }
    }
  }

  removeImage3() {
    this.renderer.setAttribute(this.imagetag2.nativeElement, 'src', "");
    for (var i = 0; i < this.DocsObject2.length; i++) {
      if (this.DocsObject2[i].docId.toString().toUpperCase().includes('POP')) {
        this.DocsObject2[i] = {
          Base64Type: "",
          ImageId: '',
          docId: '',
          docType: ''
        };
      }
    }    
    this.InputVar.nativeElement.value = "";
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.renderer.removeClass(this.Modal3.nativeElement, "active");
  }

  hidePopup3() {
    this.renderer.removeClass(this.Modal3.nativeElement, "active");
    this.cameraactive = false;
    this.localstream.getTracks()[0].stop();
  }

  showPopup3() {
    if (this.AttachImage) {
      this.renderer.addClass(this.Modal.nativeElement, "active");

    } else {
      this.renderer.addClass(this.Modal3.nativeElement, "active");

    }
  }

  showPopup1(identifier) {
    this.cameraactive = true;
    this.checkCamera(identifier);
  }

  checkCamera(identifier) {
    var that = this;
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(function (stream) {
        that.localstream = stream;
        /* use the stream */
        that.cameraobject = identifier;
        that.cameraactive = true;
        that.renderer.addClass(that.Modal2.nativeElement, "active");
      })
      .catch(function (err) {
        /* handle the error */

        that.common.ParseErrorAlert('Sorry', 'OK', that._router, 'Your device does not support this feature');
        that.cameraactive = false;

      });
  }

  hidePopup1() {
    this.renderer.removeClass(this.Modal2.nativeElement, "active");
    this.cameraactive = false;
    this.localstream.getTracks()[0].stop();
  }

  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  triggerSnapshot(): void {
    this.trigger.next();
    this.imageviewer = true;
  }

  handleImage(webcamImage: WebcamImage): void {
    debugger
    this.webcamImage = webcamImage;
    this.renderer.setAttribute(this.imagetag2.nativeElement, 'src', this.webcamImage.imageAsDataUrl);
    this.isImageSaved = true;
    this.AttachImage = false;
    this.imagebase64 = this.webcamImage.imageAsBase64;

    // var url = this.webcamImage.imageAsDataUrl;
    var array = this.imagebase64.split(',');
    let extension = array[0].split(';')[0].split('/')[1].toString();
    var ARRAY = [this.imagebase64, extension];

    this.PostToTempImage(ARRAY, this.cameraobject).then(() => {

      this.hidePopup1();
    });
  }

  public get triggerObservable(): Observable<void> {

    if (this.cameraactive) {
      return this.trigger.asObservable();
    }
  }

  formpost() {
    this.common.presentLoading();
    this.discpService.resolveDiscrepancyDocsPrimaryWeb(this.auth.accessToken,
      { List: this.DocsObject2, ProductId: this.loginResponse.onBoardingAccount.productId }).then((data: any) => {
        if (data?.response?.code == 1) {
          this.kyc.count = 0;
          this.kyc.GetKycStatus(this.auth.accessToken, this.auth.data.onBoardingAccount ? this.auth.data.onBoardingAccount.productId : 1).then((data: any) => {
            if (data?.response?.code == 1) {
              if (this.kyc.totalCount > 0) {
                let nextscreen = this.kyc.getScreen(this.kyc.count);
                this._router.navigateByUrl(nextscreen);
                this.common.hideLoading();
              }
              else {
                this.common.hideLoading();
                this.common.ParseErrorAlert('', '', this._router, data);
              }
            }
            else {
              this.common.hideLoading();
              this.common.ParseErrorAlert('', '', this._router, data);
            }
          })
        }
        else {
          this.common.hideLoading();
          this.common.ParseErrorAlert("", "", this._router, data);
        }
      });
  }

  formpostdolater() {
    this.common.showDoLaterAlert(this._router).then((data) => {
      if (data == "Logout") {
        this.common.presentLoading();
        this._adddoc.SaveAdditionalDocs(this.auth.accessToken, { List: this.DocsObjecttopost, ProductId: this.loginResponse.onBoardingAccount.productId }).then((data: any) => {
          if (data?.response?.code == 1) {
            this.common.hideLoading();
            this.common.clear();
            this._router.navigateByUrl('/login');
          }
          else {
            this.common.hideLoading();
            this.common.ParseErrorAlert("", "", this._router, data);
          }
        });
      }
    });
  }

  SavingImage(fileInput: any, index, identifier) {
    
    
    let AllowedArray = this.AllowedArray;
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 5000000;
      let allowed_types = AllowedArray;
      const max_height = 2229;
      const max_width = 8386;
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is 5 MB';
        return false;
      }


      if (!_.includes(allowed_types, fileInput.target.files[0].type.split('/')[1].toString().toUpperCase())) {
        this.imageError = 'Only PDF, JPEG and PNG formats are allowed.';
        return false;
      }
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.selectedfile = fileInput.target.files;
        var url = URL.createObjectURL(this.selectedfile[0]);
        var array = e.target.result.split(',');

        if (array[0] == "data:application/pdf;base64") {
          let extension = array[0].split(';')[0].split('/')[1].toString();
          this.imagebase64 = array[1];
          let ARRAY = [this.imagebase64, extension];
          
          this.PostToTempImage(ARRAY, identifier).then(() => {
            
            this.PdfSaved = true;
            this.AttachedPDFLink = ARRAY[0];
          })
          // if (this.PdfSaved) {
          // }
        }
        else if (array[0] == "data:image/png;base64" || array[0] == "data:image/jpeg;base64") {

          let extension = array[0].split(';')[0].split('/')[1].toString();
          this.imagebase64 = array[1];
          let ARRAY = [this.imagebase64, extension];
          
          this.PostToTempImage(ARRAY, identifier).then(() => {
            
            this.isImageSaved = true;
            this.AttachImage = true;
            this.renderer.setAttribute(this.imagetag.nativeElement, 'src', array[0] + ',' + array[1]);
  
          })
          // if (this.isImageSaved) {
          // }
        }
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

}
