import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './Services/common.service'
import { ConfigService } from './Services/config.service';
import { AuthService } from './Services/auth.service'
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Dubai Islamic Bank';
  referralCode: string = this.config.ReferralCode;

  constructor(
    private translate: TranslateService,
    private commonP: CommonService,
    private config: ConfigService,
    private auth: AuthService,
    private datepipe: DatePipe,
    private _router: Router,
    private activatedRoute: ActivatedRoute

  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
  //V14 initial Push
  ngOnInit() {
    
    this.commonP.presentLoading();
    this.commonP.Set('Referby', undefined);
    this.commonP.Set('IsResident', '0');
    this.getversion();
  }

  getversion() {
    this.auth.GetVersion().then((res: any) => {
      if (res?.response?.code == 1) {
        
        let checkVersionData: any = JSON.parse(this.commonP.DecryptCustome(res.response.content));
        this.config.TimeOut = checkVersionData.dynamicObject.TimeOut;
        this.config.ServerVersion = checkVersionData.version;
        this.config.BlinkIDAndroid = checkVersionData.dynamicObject.BlinkIDAndroid;
        this.config.BlinkIDIOS = checkVersionData.dynamicObject.BlinkIDIOS;
        this.config.OtpResendTime = checkVersionData.dynamicObject.OtpResendTime;
        this.config.Morning = checkVersionData.dynamicObject.Morning;
        this.config.Evening = checkVersionData.dynamicObject.Evening;
        this.config.Afternoon = checkVersionData.dynamicObject.Afternoon;
        this.config.videoSecret = checkVersionData.dynamicObject.VideoSecret;
        this.config.vidoeKey = checkVersionData.dynamicObject.VideoKey;
        this.config.AllowedTypeArray = checkVersionData.dynamicObject.AllowedTypeDoc;
        this.config.oecdsite = checkVersionData.dynamicObject.oecdsite;
        this.config.ReferralCode = checkVersionData.dynamicObject.ReferralCode;
        this.referralCode = checkVersionData.dynamicObject.ReferralCode;
        this.config.RefCodeExpiry = checkVersionData.dynamicObject.RefCodeExpiry;

        this.commonP.Set('AllowedDocs', this.config.AllowedTypeArray);
        this.commonP.hideLoading();
      }
      else {
        if (res?.name == "TimeoutError") {
          this.translate.get(["Wewillbe", "WeRegrettheinconvenience", "Retry"]).subscribe(data => {
            this.commonP.showErrorAlert(data.Wewillbe, data.WeRegrettheinconvenience, data.Retry, this._router).then((data: any) => {
              window.location.reload();
            })
          })
          this.commonP.hideLoading();
        } else {
          this.commonP.hideLoading();
          this.commonP.ParseErrorAlert('', '', this._router, res);
        }
      }
    })
  }
}
