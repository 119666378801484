<div class="border border-color-e1e br-12 bg-white pd attach-box">
  <div class="ng-row p-l-r" justify-content-between>
    <ng-container *ngIf="this.isButtonOnSide == false">
      <div class="ng-col" col-12 col-sm-12>
        <div class="font-18 font-600 color-354">
          {{attached2Heading | translate}}

        </div>
        <div class="font-16 font-400 color-08A mt-5 link" (click)="openButtonLink(attached2Link)">
          {{attached2Text | translate}}

        </div>
      </div>

      <div class="ng-col mt-20" col-12 *ngIf="!this.imageError">
        <div class="font-12 font-400 color-354">
          {{description | translate}}

        </div>
      </div>

      <div class="ng-col mt-20" col-12 *ngIf="this.imageError">
        <div class="font-16 font-400 color-red">
          {{imageError | translate}}

        </div>
      </div>

      <div class="ng-col" col-auto>
        <div class="custom-file-uploader mt-5">
          <input type="file" [id]="id" [name]="'file' + id" #takeinput (change)="CapturePDF($event, PDFName)" />
          <label for={{id}} *ngIf="!PdfSaved">Attach</label>
          <label *ngIf="PdfSaved" #viewlink (click)="viewButtonLink(AttachedPDFLink)" class="active">View</label>

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="this.isButtonOnSide == true">
      <div class="ng-col" col-8 col-sm-12>
        <div class="font-18 font-600 color-354">
          {{attached2Heading | translate}}

        </div>
        <div class="font-16 font-400 color-08A mt-5 link" (click)="openButtonLink(attached2Link)">
          {{attached2Text | translate}}

        </div>
      </div>

      <div class="ng-col text-align-right" col-4>
        <div class="custom-file-uploader mt-5 display-inline-block">
          <input type="file" [id]="id" [name]="'file' + id" #takeinput (change)="CapturePDF($event, PDFName)" />
          <label for={{id}} *ngIf="!PdfSaved">Attach</label>
          <label *ngIf="PdfSaved" #viewlink (click)="viewButtonLink(AttachedPDFLink)" class="active">View</label>

        </div>
      </div>

      <div class="ng-col mt-20" col-12 *ngIf="!this.imageError">
        <div class="font-12 font-400 color-354">
          {{description | translate}}

        </div>
      </div>

      <div class="ng-col mt-20 mb-20" col-12 *ngIf="this.imageError">
        <div class="font-16 font-400 color-red">
          {{imageError | translate}}

        </div>
      </div>

      <div class="ng-col" col-12>
        <div class="font-14 font-400 color-354">
          {{attached2Text2 | translate}}
        </div>
        <div class="font-14 font-400 color-354 mt-5">
          {{attached2Text3 | translate}}
        </div>
      </div>

    </ng-container>
  </div>
</div>