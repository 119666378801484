import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'guid-typescript';
import { AuthService, Balances, CurrencyRate } from '../Services/auth.service';
import { CommonService } from '../Services/common.service';
import { EFormServiceService } from '../Services/eform-service.service';
import { ErrorMessageKeyService } from '../Services/error-message-key.service';
import { ResidentialInformationService } from '../Services/residential-information.service';
import { AmountInwordsPipe } from './../amount-inwords.pipe';

@Component({
  selector: 'app-internation-transfer-request',
  templateUrl: './internation-transfer-request.component.html',
  styleUrls: ['./internation-transfer-request.component.scss']
})
export class InternationTransferRequestComponent implements OnInit {
  
  InterNationalForm: FormGroup;
  id: Guid;
  uniqueId: string;
  AllRate: CurrencyRate[] = [];
  FDDSelected: string = '';
  FTTSelected: string = '';
  payoder = ''; 
  amount: number;
  RemittancePur: string = ''; 
  Otherdes: string = ''; 
  BBCodeType: string = ''; 
  BIdentityType: string = '';
  Processvalue = '';
  errorbalance: boolean = false;
  submitAttempt: boolean = false;
  BeneIdentityType: any;
  BenBnkCodeType: any;
  PurposeRemitta: any;
  CustomerIdentityType: any;
  cnicmask: string = "XXXXX-XXXXXXX-X";
  payoderyes: boolean = false;
  _CustomerIdentityType : string = "";

  constructor(
    public fb: FormBuilder, 
    public auth: AuthService,
    public translate: TranslateService, 
    private _router: Router,
    public common: CommonService, 
    public currency: DecimalPipe,
    private eformservice: EFormServiceService,
    public errorKey: ErrorMessageKeyService, 
    public resident: ResidentialInformationService,
    private AmountInwordsPipe: AmountInwordsPipe
  )
  {
    translate.setDefaultLang('en');
    translate.use('en');

    this.InterNationalForm = this.fb.group({
      Amountinfigures: ['', Validators.compose([Validators.required])],
      RemittancePurpose: ['', Validators.compose([Validators.required])],
      BenName: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*'), Validators.minLength(3), Validators.maxLength(35)])],
      BeneAddress: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      DrawnCity: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      TermsCondition: ['', Validators.compose([Validators.required])],
      BenAccount: [''],
      BankName: [''],
      BenbankAddress: [''],
      SwiftCode: [''],
      Relationben: [''],
      BenTitle: [''],
      BeneContact: [''],
      BenBnkCode: [''],
      BenBnkCodeType: [''],
      BeneIdentityType: [''],
      BeneIdentityNo: [''],
      otherdes: [''],
      InterSwiftCode: [''],
      InterBankNAme: [''],
      InterBankAddress: [''],
      BeneCorsBankAddress: [''],
      BeneCorsSwiftCode: [''],
      BeneCorsBankNAme: [''],
      AuthorizeName: [''],
      AuthorizeCnic: [''],
      CNICPassport: [''],
      Payorder: [''],
      CustomerIdentityType: [''],
      CustomerIdentityNo : ['']
    })
  }

  async ngOnInit(): Promise<void> {
    this.id = Guid.create();
    this.uniqueId = this.id.toString();
    this.common.presentLoading();
    this.auth.accessToken = await this.auth.Get('token');
    this.auth.BalanceInquiry(Guid.create().toString(), this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {
        this.auth.AllBalance = data.response.content;

        this.auth.GetAllRate(this.auth.accessToken, Guid.create().toString()).then((data: any) => {
          if (data.response) {
            if (data?.response?.code == 1) {
              this.common.hideLoading();
              this.AllRate = data.response.content.allRate
              this.FieldValues();

            }
            else {
              this.common.ParseErrorAlert('', '', this._router, data);
            }
          }
          else {
            this.common.ParseErrorAlert('', '', this._router, data);
          }
        });

      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert('', '', this._router, data)
      }
    })

  }

  getMessage(key, control) {
    let error = "";
    this.translate.get([key]).subscribe(data => {
      error = data[key];
    })
    return error;
  }

  selectedAccontfromAccount;
  selectedAccounttoAccount;
  ToAccountList: Balances[] = [];
  fromCurrency;
  appliedRate: string; 
  AccountBalance: string;

  ValueChange() {
    this.appliedRate = "";
    this.selectedAccounttoAccount = undefined;
    this.ToAccountList = this.auth.AllBalance.filter(x => x.accountNumber != this.selectedAccontfromAccount && (x.currency == 'PKR' || x.currency == "USD"));
    this.fromCurrency = this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).currency;
    this.AccountBalance = Number(Number(this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).balance).toFixed(2)).toLocaleString('en');
  }

  agree: boolean = false;
  changeAgree(event) {
    if (event.srcElement.checked) {
      this.agree = true;

    }
    else {
      this.agree = false;

    }
  }

  ChangeProcess(value) {

    this.toCurrency = undefined;
    this.Processvalue = value;
    this.selectedAccounttoAccount = '';
    this.amount = undefined;
    this.agree = false;
    this.payoder = '';
    
    let fields = ['Amountinfigures','RemittancePurpose', 'BenName', 'CNICPassport', 'BeneAddress', 'BeneContact', 'DrawnCity', 'BenAccount', 'BankName', 'SwiftCode', 'Relationben', 'InterSwiftCode', 'InterBankNAme', 'InterBankAddress', 'Payorder', 'CustomerIdentityType', 'CustomerIdentityNo'];
    this.common.AssignFieldsEmptyString(this.InterNationalForm, fields);

    if (value == 'FTT') {
      
      this.FDDSelected = '';

      let MandatoryFields = ['BenAccount', 'BankName', 'CustomerIdentityType', 'Relationben', 'BenTitle', 'BeneIdentityType', 'BeneIdentityNo', 'CustomerIdentityNo'];
      this.common.MarkFieldsMandatory(this.InterNationalForm, MandatoryFields);

      let NonMandatoryFields = ['BeneContact'];
      this.common.MarkFieldsNonMandatory(this.InterNationalForm, NonMandatoryFields);
    }

    else if(value == "FDD"){

      this.FTTSelected = '';

      let MandatoryFields = ['BeneContact'];
      this.common.MarkFieldsMandatory(this.InterNationalForm, MandatoryFields);

      let NonMandatoryFields = ['BenAccount', 'BankName', 'CustomerIdentityType', 'Relationben', 'BenTitle', 'BeneIdentityType', 'BeneIdentityNo', 'CustomerIdentityNo'];
      this.common.MarkFieldsNonMandatory(this.InterNationalForm, NonMandatoryFields);
    }
  }

  rateToDisplay;
  toCurrency: string;

  ValueChange1() {
    
    this.appliedRate = '';
    this.toCurrency = this.selectedAccounttoAccount;

    if (this.fromCurrency == this.toCurrency) {
      this.appliedRate = "1";
      this.rateToDisplay = "1";
    }
    else {
      if (this.AllRate) {
        let a = this.AllRate.find(x => x.ccY_Sale == this.fromCurrency && x.ccY_Buy == this.toCurrency);
        if (a) {
          this.appliedRate = a.rateBuy;
          this.rateToDisplay = a.rateBuy;
        } else {
          a = this.AllRate.find(x => x.ccY_Buy == this.fromCurrency && x.ccY_Sale == this.toCurrency);
          if (a) {
            this.appliedRate = (1 / Number(a.rateSale)).toFixed(4) + "";
            this.rateToDisplay = a.rateSale;
          }
        }
      }
    }
  }

  AmountChange() {
    this.amount = Math.floor(Number(this.amount.toString().replace(/[^0-9.]/g, "")));
    var balance = Number(this.AccountBalance.toString().replace(/[^0-9.]/g, "")) ;
    
    if (this.amount > balance) {
      this.errorbalance = true;
    }
    else {
      this.errorbalance = false;
    }
    
    var _A = this.amount;
    this.InterNationalForm.patchValue({'Amountinfigures': _A.toLocaleString('en')});
  }

  formpost() {
    this.common.presentLoading();
    if (this.FDDSelected) {
      this.saveinfofdd();
      this.eformservice.SaveFddFormMethod(this.eformservice.Fddobject, this.auth.accessToken).then((data: any) => {
        if (data?.response?.code == 1) {
          this.common.hideLoading();
          this.translate.get(["Success", "RequestSubmit", "OK"]).subscribe((data: any) => {
            this.common.showSuccessAlert(data["Success"], data["RequestSubmit"], data["OK"], this._router).then((data) => {
              this._router.navigateByUrl("/CurrencyExchangeRate");
            });
          })
        }
        else {
          this.common.hideLoading();
          this.common.ParseErrorAlert("", "", this._router, data);
        }
      })
    }
    else if (this.FTTSelected) {
      this.saveinfoftt();
      this.eformservice.SaveFTTFormMethod(this.eformservice.Fttobject, this.auth.accessToken).then((data: any) => {
        if (data?.response?.code == 1) {
          this.common.hideLoading();
          this.translate.get(["Success", "RequestSubmit", "OK"]).subscribe((data: any) => {
            this.common.showSuccessAlert(data["Success"], data["RequestSubmit"], data["OK"], this._router).then((data) => {
              this._router.navigateByUrl("/CurrencyExchangeRate");
            });
          })
        }
        else {
          this.common.hideLoading();
          this.common.ParseErrorAlert("", "", this._router, data);
        }
      })
    }


  }

  saveinfofdd() {

    this.id = Guid.create();
    this.uniqueId = this.id.toString();
    let _amount = this.currency.transform(Number(this.amount.toString().replace(/[^0-9]/g, "")), "1.2-2").replace(/[^0-9.]/g, "");
    var object = this.eformservice.Fddobject;
    object.amount = _amount.toString();
    object.amountInWord = this.AmountInwordsPipe.transform(Number(this.amount.toString().replace(/[^0-9]/g, ""))).toString() + " " + this.fromCurrency;
    object.applicationId = this.auth.data.onBoardingAccount.applicationId;
    object.authPersonCnic = this.InterNationalForm.controls['AuthorizeCnic'].value;
    object.authPersonName = this.InterNationalForm.controls['AuthorizeName'].value;
    object.authorizeOther = (this.payoder == "1") ? true : false;
    object.basicAccount = this.selectedAccontfromAccount;
    object.benAddress = this.InterNationalForm.controls['BeneAddress'].value;
    object.benName = this.InterNationalForm.controls['BenName'].value;
    object.drawnCity_Country = this.InterNationalForm.controls['DrawnCity'].value;
    var a = this.InterNationalForm.controls['BeneContact'].value;
    object.contact = a.toString();
    object.creidtCurrency = this.selectedAccounttoAccount;
    object.exRate = this.rateToDisplay;
    object.debitCurrency = this.fromCurrency;
    object.purposeRemittance = this.InterNationalForm.controls['RemittancePurpose'].value;
    object.otherDescription = this.InterNationalForm.controls['otherdes'].value ? this.InterNationalForm.controls['otherdes'].value : "";
    object.uniqueId = this.uniqueId;
  }

  saveinfoftt() {
    
    let _amount = this.currency.transform(Number(this.amount.toString().replace(/[^0-9]/g, "")), "1.2-2").replace(/[^0-9.]/g, "");
    var object = this.eformservice.Fttobject;

    object.applicationId = this.auth.data.onBoardingAccount.applicationId;
    object.debitAccount = this.selectedAccontfromAccount;
    object.debitCurrency = this.fromCurrency;
    object.creditCurrency = this.selectedAccounttoAccount;
    object.exRate = this.rateToDisplay;
    object.amountInDigits = _amount.toString();
    object.amountInWord = this.AmountInwordsPipe.transform(Number(this.amount.toString().replace(/[^0-9]/g, ""))).toString() + this.fromCurrency;
    object.purposeRemittance = this.InterNationalForm.controls['RemittancePurpose'].value;
    object.othersDescription = this.InterNationalForm.controls['otherdes'].value ? this.InterNationalForm.controls['otherdes'].value: "";
    object.benAccountIban = this.InterNationalForm.controls['BenAccount'].value;
    object.benBankName = this.InterNationalForm.controls['BankName'].value;
    object.benBankAddress = this.InterNationalForm.controls['BenbankAddress'].value;
    object.benSwiftCode = this.InterNationalForm.controls['SwiftCode'].value
    object.benRelation = this.InterNationalForm.controls['Relationben'].value;
    object.benTitle = this.InterNationalForm.controls['BenTitle'].value;
    object.benAddress = this.InterNationalForm.controls['BeneAddress'].value;
    object.benTelephone = this.InterNationalForm.controls['BeneContact'].value;
    var value1 = this.InterNationalForm.controls['InterBankNAme'].value;
    var value2 = this.InterNationalForm.controls['InterSwiftCode'].value;
    var value3 = this.InterNationalForm.controls['InterBankAddress'].value;
    object.interBankName = (value1) ? value1 : '';
    object.interSwfitCode = (value2) ? value2 : '';
    object.interBankAddress = (value3) ? value3 : '';
    var value4 = this.InterNationalForm.controls['BeneCorsBankNAme'].value;
    var value5 = this.InterNationalForm.controls['BeneCorsBankAddress'].value;
    var value6 = this.InterNationalForm.controls['BeneCorsSwiftCode'].value;
    object.benCorrBnkName = (value4) ? value4 : '';
    object.benCorrBnkAddr = (value5) ? value5 : '';
    object.benCorrBnkSwft = (value6) ? value6 : '';
    object.drawnCity_Coun = this.InterNationalForm.controls['DrawnCity'].value;
    object.benBnkCodeType = this.InterNationalForm.controls['BenBnkCodeType'].value;
    object.benBankCode = this.InterNationalForm.controls['BenBnkCode'].value;
    object.beneIdentityType = this.InterNationalForm.controls['BeneIdentityType'].value;
    object.beneIdentityNo = this.InterNationalForm.controls['BeneIdentityNo'].value;
  }

  IsFormValid() {
    var BenName = this.InterNationalForm.controls['BenName'].value;
    var BenAddress = this.InterNationalForm.controls['BeneAddress'].value;
    var BenContact = this.InterNationalForm.controls['BeneContact'].value;
    var DrawnCity = this.InterNationalForm.controls['DrawnCity'].value;
    var BenAccount = this.InterNationalForm.controls['BenAccount'].value;
    var BankName = this.InterNationalForm.controls['BankName'].value;
    var SwiftCode = this.InterNationalForm.controls['SwiftCode'].value;
    var Relationben = this.InterNationalForm.controls['Relationben'].value;
    var Payorder2 = this.InterNationalForm.controls['Payorder'].value;
    var Amountinfigures = this.InterNationalForm.controls['Amountinfigures'].value;
    if (!this.errorbalance) {
      if (this.agree && this.selectedAccounttoAccount) {
        if (!this.InterNationalForm.valid) {
          return true
        }
        else if (this.FDDSelected) {
          if (BenName && DrawnCity && BenAddress && BenContact && Amountinfigures) {
            if (Payorder2) {
              var authname = this.InterNationalForm.controls['AuthorizeName'].value;
              var authcnic = this.InterNationalForm.controls['AuthorizeCnic'].value
              if (authname != '' && authcnic != '') {
                return false;
              }
              else {
                return true;
              }

            }
            else {
              this.InterNationalForm.patchValue({ 'AuthorizeName': '' });
              this.InterNationalForm.patchValue({ 'AuthorizeCnic': '' });
              return false;
            }

          }
          else {
            return true;
          }
        }

        else if (this.FTTSelected) {
          if (BenAccount && BankName && Relationben && BenAddress && BenName && Amountinfigures) {
            return false;
          }
          else {
            return true;
          }
        }
        else {
          return false;
        }
      }
      else {
        return true
      }
    }
    else {
      return true
    }
  }

  RemittancePurpose(){
    this.Otherdes = undefined;
    this.InterNationalForm.patchValue({ 'otherdes': undefined});

    if (this.RemittancePur == 'Others') {
      let otherdes = this.InterNationalForm.controls['otherdes'];
      otherdes.markAsUntouched();
      otherdes.markAsDirty()
      otherdes.setValidators(Validators.compose([Validators.required]));
      otherdes.updateValueAndValidity();
    }
  }

  FieldValues() {
    this.common.presentLoading();
    let object = this.resident.FieldValues;
    this.id = Guid.create();
    object.uniqueId = this.id.toString();
    object.type = ['BeneIdentityType', 'BenBnkCodeType', 'CustomerIdentityType', 'PurposeRemittance']
    this.resident.GetFieldtypes(object, this.auth.accessToken).then((res: any) => {
      if (res?.response?.code == 1) {
        this.BeneIdentityType = res.response.content.list.BeneIdentityType;
        this.BenBnkCodeType = res.response.content.list.BenBnkCodeType;
        this.PurposeRemitta = res.response.content.list.PurposeRemittance;
        this.CustomerIdentityType = res.response.content.list.CustomerIdentityType;
        this.common.hideLoading();
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert('', '', this._router, res)
      }
    })
  }

  payorderchange() {
    var Payorder = this.InterNationalForm.controls['Payorder'].value;

    if (this.payoder == "1") {
      this.payoderyes = true;
      this.InterNationalForm.patchValue({ 'Payorder': true })
    }
    else if (this.payoder == "0") {
      this.payoderyes = false;
      this.InterNationalForm.patchValue({ 'Payorder': false })
      this.InterNationalForm.patchValue({ 'AuthorizeName': '' });
      this.InterNationalForm.patchValue({ 'AuthorizeCnic': '' });
    }
  }
}