import { AmountInwordsPipe } from '../amount-inwords.pipe';
import { Router } from '@angular/router';
import { AuthService, Balances, CurrencyRate } from '../Services/auth.service';
import { CommonService } from '../Services/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageKeyService } from '../Services/error-message-key.service';
import { Guid } from "guid-typescript";
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { EFormServiceService, PayOrderInterface } from '../Services/eform-service.service'
import { CountriesService, CityListModel, BranchModel } from '../Services/countries.service'
import { ResidentialInformationService } from '../Services/residential-information.service';


@Component({
  selector: 'app-local-transfer',
  templateUrl: './local-transfer.component.html',
  styleUrls: ['./local-transfer.component.scss']
})
export class LocalTransferComponent implements OnInit {


  id: Guid;
  uniqueId: string;
  RemittanceForm: FormGroup;
  selectedAccont = undefined;
  submitAttempt: boolean = false;
  selectedAccountCurrency: string;
  payorderselected: string = '';
  FDDSelected: string = '';
  FTTSelected: string = '';
  RTGSSelected: string = '';
  RemittancePur: string = ""; 
  Otherdes: String = "";
  Processvalue = '';
  payoder = '';
  cnicmask: string = "XXXXX-XXXXXXX-X";
  payoderyes: boolean = false;
  PurposeRemitta: any;

  constructor(
    public currency: DecimalPipe,
    public auth: AuthService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public common: CommonService,
    public errorKey: ErrorMessageKeyService,
    private _router: Router,
    private countries: CountriesService,
    private eformservice: EFormServiceService,
    private AmountInwordsPipe: AmountInwordsPipe,
    public resident: ResidentialInformationService,

  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    this.RemittanceForm = this.formBuilder.group({
      RemittancePurpose: ['', Validators.compose([Validators.required])],
      Amountinfigures: ['', Validators.compose([Validators.required])],
      BenName: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*')])],
      TermsCondition: ['', Validators.compose([Validators.required])],
      CNICPassport: [''],
      BenContact: [''],
      BenAddress: [''],
      BenbankAddress: [''],
      BankName: [''],
      BenAccount: [''],
      Payorder: [''],
      otherdes: [''],
      AuthorizeName: [''],
      AuthorizeCnic: [''],
    });

  }

  async ngOnInit(): Promise<void> {

    this.id = Guid.create();
    this.uniqueId = this.id.toString();
    this.common.presentLoading();
    this.auth.accessToken = await this.auth.Get('token');
    this.auth.data = JSON.parse(await this.auth.Get("User"));
    this.auth.BalanceInquiry(Guid.create().toString(), this.auth.accessToken).then((data: any) => {

      if (data?.response?.code == 1) {
        this.auth.AllBalance = data.response.content;

        this.auth.GetAllRate(this.auth.accessToken, Guid.create().toString()).then((data: any) => {
          if (data.response) {
            if (data.response.code) {
              if (data.response.code == 1) {

                this.common.hideLoading();
                this.AllRate = data.response.content.allRate;
                this.FieldValues();

              }
            } else {
              this.common.ParseErrorAlert('', '', this._router, data);

            }
          }
          else {
            this.common.ParseErrorAlert('', '', this._router, data);

          }
        });
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert('', '', this._router, data);
      }
    })
  }


  getMessage(key, control) {
    let error = "";
    this.translate.get([key]).subscribe(data => {
      error = data[key];
    })
    return error;
  }

  selectedAccontfromAccount;
  selectedAcconttoAccount;
  toAccountList: Balances[] = [];
  appliedRate: string;
  fromCurrency;
  amount: number;
  AccountBalance: string;
  errorbalance: boolean = false;
  AllRate: CurrencyRate[] = [];

  ValueChange() {
    this.appliedRate = "";
    this.selectedAcconttoAccount = undefined;
    this.toAccountList = this.auth.AllBalance.filter(x => x.accountNumber != this.selectedAccontfromAccount && (x.currency == "PKR" || x.currency == "USD"));
    this.fromCurrency = this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).currency;
    this.AccountBalance = Number(Number(this.auth.AllBalance.find(x => x.accountNumber == this.selectedAccontfromAccount).balance).toFixed(2)).toLocaleString('en');
  }

  AmountChange() {
    this.amount = Math.floor(Number(this.amount.toString().replace(/[^0-9.]/g, "")));
    var balance = Number(this.AccountBalance.toString().replace(/[^0-9.]/g, ""));

    if (this.amount > balance) {
      this.errorbalance = true;
    }
    else {
      this.errorbalance = false;
    }
    var _A = this.amount;
    this.RemittanceForm.patchValue({ 'Amountinfigures': _A.toLocaleString('en') });
  }

  toCurrency: string;
  rateToDisplay;

  ValueChange1() {

    this.appliedRate = "";
    this.toCurrency = this.selectedAcconttoAccount;
    
    if (this.toCurrency == this.fromCurrency) {
      this.appliedRate = "1";
      this.rateToDisplay = "1";
    } else {
      if (this.AllRate) {
        let a = this.AllRate.find(x => x.ccY_Sale == this.fromCurrency && x.ccY_Buy == this.toCurrency);
        if (a) {
          this.appliedRate = a.rateBuy;
          this.rateToDisplay = a.rateBuy;
        } else {
          a = this.AllRate.find(x => x.ccY_Buy == this.fromCurrency && x.ccY_Sale == this.toCurrency);
          if (a) {
            this.appliedRate = (1 / Number(a.rateSale)).toFixed(4) + "";
            this.rateToDisplay = a.rateSale;
          }
        }
      }
    }

  }

  ChangeProcess(value) {

    this.toCurrency = undefined;
    this.Processvalue = value;
    this.selectedAcconttoAccount = '';
    this.amount = undefined;
    this.payoder = '';
    
    if (value == 'PO') {
      this.RTGSSelected = '';

      let FieldsToEmpty = ['Amountinfigures','RemittancePurpose','Payorder','BankName', 'BenAddress','BenAccount','BenContact', 'BenName','CNICPassport'];
      this.common.AssignFieldsEmptyString(this.RemittanceForm,FieldsToEmpty);

      let FieldsToMandatory = ['CNICPassport', 'BenContact', 'BenAddress'];
      this.common.MarkFieldsMandatory(this.RemittanceForm, FieldsToMandatory);

      let NonMandatoryFields = ['BenbankAddress', 'BankName', 'BenAccount'];
      this.common.MarkFieldsNonMandatory(this.RemittanceForm, NonMandatoryFields);

    }

    else if (value == "RTGS"){

      this.payorderselected = '';

      let FieldsToEmpty = ['Amountinfigures','RemittancePurpose', 'BenName', 'BankName', 'BenbankAddress', 'BenAccount', 'CNICPassport', 'BenContact', 'BenAddress','Payorder'];
      this.common.AssignFieldsEmptyString(this.RemittanceForm, FieldsToEmpty);

      let MandatoryFields = ['BenbankAddress', 'BankName', 'BenAccount'];
      this.common.MarkFieldsMandatory(this.RemittanceForm, MandatoryFields);

      let NonMandatoryFields = ['CNICPassport', 'BenContact', 'BenAddress'];
      this.common.MarkFieldsNonMandatory(this.RemittanceForm, NonMandatoryFields);
    }
  }

  formpost() {
    this.common.presentLoading();
    if (this.payorderselected) {
      this.saveinfopayorder();
      
      this.eformservice.savepayordermethod(this.eformservice.payorderobject, this.auth.accessToken).then((data: any) => {
        if (data?.response?.code == 1) {
          this.common.hideLoading();
          
          this.translate.get(["Success", "RequestSubmit", "OK"]).subscribe((data: any) => {
            this.common.showSuccessAlert(data["Success"], data["RequestSubmit"], data["OK"], this._router).then((data) => {
              this._router.navigateByUrl("/CurrencyExchangeRate");
            });
          })
        }
        else {
          this.common.hideLoading();
          this.common.ParseErrorAlert("", "", this._router, data);
        }
      })
    }

    else if (this.RTGSSelected) {
      this.saveinfortgs();
      this.eformservice.SaveRTGSFormMethod(this.eformservice.RTGSObject, this.auth.accessToken).then((data: any) => {
        if (data?.response?.code == 1) {
          this.common.hideLoading();
          this.translate.get(["Success", "RequestSubmit", "OK"]).subscribe((data: any) => {
            this.common.showSuccessAlert(data["Success"], data["RequestSubmit"], data["OK"], this._router).then((data) => {
              this._router.navigateByUrl("/CurrencyExchangeRate");
            });
          })
        }
        else {
          this.common.hideLoading();
          this.common.ParseErrorAlert("", "", this._router, data);
        }
      })
    }

  }

  RemittancePurpose(){
    this.Otherdes = undefined;
    this.RemittanceForm.patchValue({'otherdes' : undefined});

    if (this.RemittancePur == 'Others') {
      let otherdes = this.RemittanceForm.controls['otherdes'];
      otherdes.markAsUntouched();
      otherdes.markAsDirty()
      otherdes.setValidators(Validators.compose([Validators.required]));
      otherdes.updateValueAndValidity();
    }
  }

  saveinfortgs() {
    let _amount = this.currency.transform(Number(this.amount.toString().replace(/[^0-9]/g, "")), "1.2-2").replace(/[^0-9.]/g, "");
    var object = this.eformservice.RTGSObject;
    object.amount = _amount.toString();
    object.amountInWord = this.AmountInwordsPipe.transform(Number(this.amount.toString().replace(/[^0-9]/g, ""))).toString() + " " + this.fromCurrency;
    object.applicationId = this.auth.data.onBoardingAccount.applicationId;
    object.basicAccount = this.selectedAccontfromAccount;
    object.benAddress = this.RemittanceForm.controls['BenbankAddress'].value;
    object.benName = this.RemittanceForm.controls['BenName'].value;
    object.creidtCurrency = this.selectedAcconttoAccount;
    object.exRate = this.rateToDisplay;
    object.purposeRemittance = this.RemittanceForm.controls['RemittancePurpose'].value;
    object.uniqueId = Guid.create().toString();
    object.debitCurrency = this.fromCurrency;
    object.benBankName = this.RemittanceForm.get('BankName').value;
    object.benIban = this.RemittanceForm.controls['BenAccount'].value;
    object.otherDescription = this.RemittanceForm.controls['otherdes'].value ? this.RemittanceForm.controls['otherdes'].value : "";
  }

  saveinfopayorder() {
    this.id = Guid.create();
    this.uniqueId = this.id.toString();

    var debitingcurrency = this.fromCurrency;
    let _amount = this.currency.transform(Number(this.amount.toString().replace(/[^0-9]/g, "")), "1.2-2").replace(/[^0-9.]/g, "");

    var object = this.eformservice.payorderobject;
    object.amount = _amount.toString();
    object.amountInWord = this.AmountInwordsPipe.transform(Number(this.amount.toString().replace(/[^0-9]/g, ""))).toString() + " " + this.fromCurrency;
    object.applicationId = this.auth.data.onBoardingAccount.applicationId;
    object.authPersonCnic = this.RemittanceForm.controls['AuthorizeCnic'].value;
    object.authPersonName = this.RemittanceForm.controls['AuthorizeName'].value;
    object.authorizeOther = (this.payoder == "1") ? true : false;
    object.basicAccount = this.selectedAccontfromAccount;
    object.benAddress = this.RemittanceForm.controls['BenAddress'].value;
    object.benName = this.RemittanceForm.controls['BenName'].value;
    object.cnic_Passport_NTN = this.RemittanceForm.controls['CNICPassport'].value;
    var oo = this.RemittanceForm.controls['BenContact'].value;
    object.contact = oo.toString();
    object.creidtCurrency = this.selectedAcconttoAccount;
    object.debitCurrency = debitingcurrency;
    object.exRate = this.rateToDisplay;
    object.purposeRemittance = this.RemittanceForm.controls['RemittancePurpose'].value;
    object.uniqueId = this.uniqueId;
    object.otherDescription = this.RemittanceForm.controls['otherdes'].value ? this.RemittanceForm.controls['otherdes'].value : "";
  }

  payorderchange() {
    var Payorder = this.RemittanceForm.controls['Payorder'].value;

    if (this.payoder == "1") {
      this.payoderyes = true;
      this.RemittanceForm.patchValue({ 'Payorder': true })
    }
    else if (this.payoder == "0") {
      this.payoderyes = false;
      this.RemittanceForm.patchValue({ 'Payorder': false })
      this.RemittanceForm.patchValue({ 'AuthorizeName': '' });
      this.RemittanceForm.patchValue({ 'AuthorizeCnic': '' });
    }
  }

  agree: boolean = false;
  changeAgree(event) {
    if (event.srcElement.checked) {
      this.agree = true;

    }
    else {
      this.agree = false;

    }
  }

  IsFormValid() {
    
    var BenName = this.RemittanceForm.controls['BenName'].value;
    var CNICPassport = this.RemittanceForm.controls['CNICPassport'].value;
    var BenAddress = this.RemittanceForm.controls['BenAddress'].value;
    var BenBnkAddress =this.RemittanceForm.controls['BenbankAddress'].value;
    var BenContact = this.RemittanceForm.controls['BenContact'].value;
    var BenAccount = this.RemittanceForm.controls['BenAccount'].value;
    var BankName = this.RemittanceForm.controls['BankName'].value;

    var Payorder2 = this.RemittanceForm.controls['Payorder'].value;
    var Amountinfigures = this.RemittanceForm.controls['Amountinfigures'].value;

    if (!this.errorbalance) {
      if (this.agree && this.selectedAcconttoAccount) {

        
        if (!this.RemittanceForm.valid) {
          
          return true
        }
        else if (this.payorderselected) {

          if (BenName && CNICPassport && BenAddress && BenContact && Amountinfigures) {

            if (Payorder2) {

              var authname = this.RemittanceForm.controls['AuthorizeName'].value;
              var authcnic = this.RemittanceForm.controls['AuthorizeCnic'].value;

              if (authname != '' && authcnic != '') {
                return false;
              }
              else {
                return true;
              }
            }
            else {
              this.RemittanceForm.patchValue({ 'AuthorizeName': '' });
              this.RemittanceForm.patchValue({ 'AuthorizeCnic': '' });
              return false;
            }

          }
          else {
            return true;
          }
        }

        else if (this.RTGSSelected) {
          
          if (BenName && BenAccount && BenBnkAddress && BankName && Amountinfigures) {
            
            return false;
          }
          else {
            return true;
          }
        }
      }
      else {
        return true
      }
    }
    else {
      return true
    }
  }

  FieldValues() {
    this.common.presentLoading();
    let object = this.resident.FieldValues;
    this.id = Guid.create();
    object.uniqueId = this.id.toString();
    object.type = ['BeneIdentityType', 'BenBnkCodeType', 'CustomerIdentityType', 'PurposeRemittance']
    this.resident.GetFieldtypes(object, this.auth.accessToken).then((res: any) => {
      if (res?.response?.code == 1) {
        // this.BeneIdentityType = res.response.content.list.BeneIdentityType;
        // this.BenBnkCodeType = res.response.content.list.BenBnkCodeType;
        this.PurposeRemitta = res.response.content.list.PurposeRemittance;
        // this.CustomerIdentityType = res.response.content.list.CustomerIdentityType;
        this.common.hideLoading();
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert('', '', this._router, res)
      }
    })
  }
}
