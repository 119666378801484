import { Router } from '@angular/router';
import { CommonService } from './../Services/common.service';
import { AuthService } from './../Services/auth.service';
import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Guid } from "guid-typescript";
import { WebService } from '../Services/web.service';
import { TranslateService } from '@ngx-translate/core';
import { RoshanApnaGharService } from '../Services/roshan-apna-ghar.service';

@Component({
  selector: 'app-currency-exchange-rate',
  templateUrl: './currency-exchange-rate.component.html',
  styleUrls: ['./currency-exchange-rate.component.scss']
})
export class CurrencyExchangeRateComponent implements OnInit {

  @ViewChild("box1", { static: true }) box1: ElementRef;

  @ViewChild("box2", { static: true }) box2: ElementRef;
  @ViewChild("modal2") Modal2: ElementRef;

  @ViewChild("modal3") Modal3: ElementRef;

  @ViewChild("modal4") Modal4: ElementRef;

  pkrlist;
  usdlist;
  ratelist;
  newlist;
  SelectFromValue = null;
  SelectToValue = null;
  RateFromArray = [];
  RateFromArrayNew = [];
  RateToArray = [];
  RateToArrayNew = [];
  FromValueSelected: boolean = false;
  CurrencyRate: string = "Select Currency First";
  Amount: number;
  convertedrate: string = null;
  formvalid: boolean = false;
  tocurrencyerror: boolean = false;

  sideBarTextShow: boolean = true;
  PPricingRate; PPricingtitle; BenchmarkRate; deliverTimetitle; deliverTime
  Benchmarktitle;

  constructor(public auth: AuthService, private commonP: CommonService, private _router: Router, public Renderer2: Renderer2,
    public translate: TranslateService, public web: WebService, private RAGService: RoshanApnaGharService) { }

  LogOut() {
    this.commonP.clearLogout();
    this.commonP.clear();
    this._router.navigateByUrl('/login');
  }

  async ngOnInit(): Promise<void> {
    this.commonP.presentLoading();

    this.auth.accessToken = await this.auth.Get('token');
    this.auth.data = JSON.parse(await this.auth.Get("User"));
    await this.auth.GetExchangeRate(this.auth.accessToken, Guid.create().toString()).then(async (data: any) => {
      if (data.response) {
        if (data.response.code) {
          if (data.response.code == 1) {

            this.pkrlist = data.response.content.pkrList;
            this.usdlist = data.response.content.usdList;
            this.ratelist = data.response.content.ratelist;
            for (var i = 0; i < this.ratelist.length; i++) {
              this.RateFromArray[i] = this.ratelist[i].from;
            }

            function foo(arr) {
              var a = [], b = [], prev;

              arr.sort();
              for (var i = 0; i < arr.length; i++) {
                if (arr[i] !== prev) {
                  a.push(arr[i]);
                  b.push(1);
                } else {
                  b[b.length - 1]++;
                }
                prev = arr[i];
              }

              return [a, b];
            }
            this.RateFromArrayNew = foo(this.RateFromArray);

            await this.auth.BalanceInquiry(Guid.create().toString(), this.auth.accessToken).then(async (data: any) => {

              if (data?.response?.code == 1) {
                this.auth.AllBalance = data.response.content;
                this.GetRacProdList();
              }
            })
          }
        }
        else {
          this.commonP.ParseErrorAlert('', '', this._router, data);
        }
      }
      else {
        this.commonP.ParseErrorAlert('', '', this._router, data);
      }
    });


    this.auth.GetDashboardText().then((data: any) => {
      if (data.htmlBoxShowStatus == true) {
        this.box1.nativeElement.removeAttribute('col-4', '');
        this.box1.nativeElement.setAttribute('col-3', '');

        this.box2.nativeElement.removeAttribute('col-8', '');
        this.box2.nativeElement.setAttribute('col-6', '');
      } else {
        this.box1.nativeElement.removeAttribute('col-3', '');
        this.box1.nativeElement.setAttribute('col-4', '');

        this.box2.nativeElement.removeAttribute('col-6', '');
        this.box2.nativeElement.setAttribute('col-8', '');
      }
      this.sideBarTextShow = data.htmlBoxShowStatus;
      document.getElementById("dashbord-sidebar-content").innerHTML = JSON.parse(JSON.stringify(data.htmlBox));
    });

  }


  ChangeEvent() {

    this.SelectToValue = undefined;
    this.CurrencyRate = "Select Currency First";
    this.FromValueSelected = true;
    this.newlist = this.ratelist.filter(x => x.from == this.SelectFromValue);
  }


  ForRouteNavigationWithLoader(Value) {

    this.commonP.presentLoading();
    if (Value == "QurbaniForm") {
      this._router.navigateByUrl("/QurbaniForm");
    }
    else if (Value == "RoshanApnaGhar") {
      this._router.navigateByUrl('/roshan-apna-ghar/RoshanApnaGharCalculator');
    }
    else if (Value == "own") {
      this._router.navigateByUrl('/roshan-apna-ghar/PropertyDetails');
    }
    else if (Value == "bank") {
      this._router.navigateByUrl('/roshan-apna-ghar/RoshanApnaGharCalculator');
    }
    else if (Value == "INPC") {
      this._router.navigateByUrl("/IslamicNayaPakistanCertificatesForm")
    }
    else if (Value == "LAR") {
      this._router.navigateByUrl("/LinkAccountOpeningForm")
    }
    else if (Value == "CR") {
      if (this.auth.AllBalance.find(x => x.currency != 'PKR' && x.currency != 'USD')) {
        if (this.auth.AllBalance.find(x => x.currency == 'PKR' || x.currency == 'USD')) {
          this._router.navigateByUrl("/ConversionApplicationForm");
        }
        else {
          this.commonP.showErrorAlert(
            "Sorry",
            "For currency conversion you must have atleast 2 accounts out of which atleast one of your accounts must be a PKR or USD account as currency conversion is available from PKR - FCY,  USD - FCY, FCY - PKR, FCY - USD. You can open a new link account to meet this criteria.",
            "OK",
            this._router
          );
        }
      }
      else {
        var PKR = this.auth.AllBalance.some(x => x.currency == 'PKR');
        var USD = this.auth.AllBalance.some(x => x.currency == "USD");

        if (PKR && USD) {
          this._router.navigateByUrl("/ConversionApplicationForm");
        }
        else {
          this.commonP.showErrorAlert(
            "Sorry",
            "For currency conversion you must have atleast 2 accounts out of which atleast one of your accounts must be a PKR or USD account as currency conversion is available from PKR - FCY,  USD - FCY, FCY - PKR, FCY - USD. You can open a new link account to meet this criteria.",
            "OK",
            this._router
          );
        }
      }
    }
    else if (Value == "RDAADC") {
      this._router.navigateByUrl("/RdaAdcServicesForm")
    }
    else if (Value == "LT") {
      this._router.navigateByUrl("/LocalTransfer")
    }
    else if (Value == "IT") {
      this._router.navigateByUrl("/InternationlTransfer")
    }
    else if (Value == "NPE") {
      this._router.navigateByUrl("/NayaPakistanEncashmentForm")
    }
    else if (Value == "AutoFinance") {
      this._router.navigateByUrl("/auto-financing/Calculator");
    }
    else if (Value == "Roshan") {
      this._router.navigateByUrl('/roshan-samaaji-khidmat/RoshanSamaajiKhidmat');
    }
    else if (Value == "RDAStock") {
      if (!this.auth.data.IsSpentDays && !this.auth.data.isForeignTaxPayed) {
        this._router.navigateByUrl("/RDAStockMarketInvestmentRequestForm")
      }
      else {
        this.commonP.showErrorAlert("Sorry", "At the moment CDC account services are only available for Non-Resident Pakistani's. You will be notified once this service is available for Resident Pakistani's also.", "OK", this._router);
      }
    }
    else if (Value == "CDC") {
      if (!this.auth.data.IsSpentDays && !this.auth.data.isForeignTaxPayed) {
        this._router.navigateByUrl("/CDCTransferAmountRequest")
      }
      else {
        this.commonP.showErrorAlert("Sorry", "At the moment CDC account services are only available for Non-Resident Pakistani's. You will be notified once this service is available for Resident Pakistani's also.", "OK", this._router);
      }
    }
  }

  rateForDisplay;
  ChangeEvent1() {
    let a = this.ratelist.find(x => x.from == this.SelectFromValue && x.to == this.SelectToValue);
    if (a) {
      this.CurrencyRate = a.rate;
      this.rateForDisplay = a.rateForDisplay;
    }

  }
  Vps() {

    this._router.navigateByUrl('/pension/InvestmentDetail');

  }
  Validation() {
    if (this.SelectFromValue != null && this.SelectToValue != null && this.Amount != null) {
      return false
    } else {
      this.formvalid = false;
      this.convertedrate = null;
      return true;
    }
  }

  Conversion() {
    if (this.SelectFromValue != null && this.SelectToValue != null && this.Amount != null) {
      var amountnumber = Number(this.CurrencyRate);
      var multiply = amountnumber * this.Amount;
      this.convertedrate = multiply.toFixed(2);
      this.formvalid = true;

    }
  }

  AmountChange() {
    this.convertedrate = null;
    this.formvalid = false;
  }


  labelLink(e) {
    e.preventDefault();
    window.open("../assets/Terms-and-Conditions-for-the-use-of-DIB-Visa-Debit-Cards-v1.6.pdf", '_blank');
  }

  labelLink2(e) {
    e.preventDefault();
    window.open("https://dibebank.dibpak.com/AmbitRetail/onlineRegistration", '_blank');
  }


  showPopup2() {
    this.Renderer2.addClass(this.Modal2.nativeElement, "active");
  }
  hidePopup2() {
    this.Renderer2.removeClass(this.Modal2.nativeElement, "active");
  }

  webinfo() {

    let object = this.web.RProdList;
    object.userId = this.auth.data.userId;
    object.applicationId = this.auth.data.onBoardingAccount.applicationId;

  }
  GetRacProdList() {

    this.commonP.presentLoading();
    this.web.GetRacProdList(this.webinfo(), this.auth.accessToken).then((res: any) => {
      if (res?.response?.code == 1) {
        let RacProdList = res.response.content;
        this.PPricingtitle = RacProdList.proposedPricingRate[0].title;
        this.PPricingRate = RacProdList.proposedPricingRate[0].rates;
        this.Benchmarktitle = RacProdList.latestBenchmarkRate[0].title;
        this.BenchmarkRate = RacProdList.latestBenchmarkRate[0].secondList;
        this.deliverTimetitle = RacProdList.deliveryTimelines[0].title;
        this.deliverTime = RacProdList.deliveryTimelines[0].subList;
        this.commonP.hideLoading();
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert('', '', this._router, res)
      }
    })
  }

  showPopup3() {
    this.commonP.presentLoading();
    this.RAGService.KnowApnaGharJourney(this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {
        // 
        if (data.response.content.routename == 'Popup') {
          this.Renderer2.addClass(this.Modal3.nativeElement, "active");
        }
        else {
          (data.response.content, 'knowRAG');
          if (data.response.content.alreadydata.length > 0) {

            if (data.response.content.alreadydata[0].object.transactionType == 'own' || data.response.content.alreadydata[0].object.typeOfTransaction == 'own') {
              this.commonP.Set('Transactiontype', 'own');
            }
            else if (data.response.content.alreadydata[0].object.transactionType == 'bank' || data.response.content.alreadydata[0].object.typeOfTransaction == 'bank') {
              this.commonP.Set('Transactiontype', 'bank');
            }

            if (data.response.content.alreadydata[0].object.pricingType != undefined) {
              if (data.response.content.alreadydata[0].object.isLien != undefined) {
                if (data.response.content.alreadydata[0].object.isLien == true) {
                  this.commonP.Set('Lien/Gmss', 'Lien')
                }
                else {
                  this.commonP.Set('Lien/Gmss', 'Nonlien')
                }
              }
              else if (data.response.content.alreadydata[0].isLien == null) {
                this.commonP.Set('Lien/Gmss', 'Gmss')
              }
            }
            else {
              this.commonP.Set('Lien/Gmss', null)
            }
          }
          // if (data.response.content.routename=="EmployeeDetails"){
          //   this._router.navigateByUrl('/roshan-apna-ghar/ApplicantDetail');
          // }
          //else{
          this._router.navigateByUrl('/roshan-apna-ghar/' + data.response.content.routename);
          // }

        }
        this.commonP.hideLoading();
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert('', '', this._router, data);
      }
    })
  }

  hidePopup3() {
    this.Renderer2.removeClass(this.Modal3.nativeElement, "active");
  }

  showPopup4() {
    this.Renderer2.addClass(this.Modal4.nativeElement, "active");
    this.hidePopup3();
  }
  hidePopup4() {
    this.Renderer2.removeClass(this.Modal4.nativeElement, "active");
  }

  typeOfTransactionStatus: string = undefined;
  radioTypeOfTransaction(val) {
    this.typeOfTransactionStatus = val;
    this.commonP.Set('Transactiontype', this.typeOfTransactionStatus);
  }

  Transactionbuttonvalid() {
    if (this.typeOfTransactionStatus) {
      return false
    }
    else {
      return true
    }
  }

}
