import { Router } from '@angular/router';
import { AuthService, Balances } from './../Services/auth.service';
import { CommonService } from './../Services/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { Guid } from "guid-typescript";
import { CountriesService, CityListModel, BranchModel } from './../Services/countries.service'
import { EFormServiceService, NPEncashCertificate } from './../Services/eform-service.service'
import * as moment from 'moment';

@Component({
  selector: 'app-naya-pakistan-encashment-form',
  templateUrl: './naya-pakistan-encashment-form.component.html',
  styleUrls: ['./naya-pakistan-encashment-form.component.scss']
})
export class NayaPakistanEncashmentFormComponent implements OnInit {

  id: Guid;
  uniqueId: string;
  NPEncashForm: FormGroup;
  selectedAccont = undefined;
  submitAttempt: boolean = false;
  selectedAccountCurrency: string;
  CertificatesArray: NPEncashCertificate[] = [];

  startdateminarray;
  startdatemaxarray;
  startdatedaySelect: number = null;
  startdateMonthSelect: number = null;
  startdateYearSelect: number = null;
  startdateIsFebSelect: boolean = false;
  startdateisyearleap: boolean = false;
  startdatedefaultSelect: boolean = true;
  startdatethirtySelect;
  startdate;
  startdatemin;
  startdatemax;
  startdateyears;
  startdatedayslistArray = [];
  startdatestring;
  startdateError;

  MaturityDateError;
  MaturityDateminarray;
  MaturityDatemaxarray;
  MaturityDatestring;
  MaturityDatedaySelect: number = null;
  MaturityDateMonthSelect: number = null;
  MaturityDateYearSelect: number = null;
  MaturityDateIsFebSelect: boolean = false;
  MaturityDateisyearleap: boolean = false;
  MaturityDatedefaultSelect: boolean = true;
  MaturityDatethirtySelect;
  MaturityDatedayslistArray = [];
  MaturityDateyears;
  MaturityDatesplit;
  MaturityDate;
  MaturityDatemin;
  MaturityDatemax;

  MaturityDatemonthlistArray = [{
    value: 1,
    text: "January"
  },
  {
    value: 2,
    text: "February"
  }, {
    value: 3,
    text: "March"
  }, {
    value: 4,
    text: "April"
  }, {
    value: 5,
    text: "May"
  }, {
    value: 6,
    text: "June"
  }, {
    value: 7,
    text: "July"
  }, {
    value: 8,
    text: "August"
  }, {
    value: 9,
    text: "September"
  }, {
    value: 10,
    text: "October"
  }, {
    value: 11,
    text: "November"
  }, {
    value: 12,
    text: "December"
  }];

  startdatemonthlistArray = [{
    value: 1,
    text: "January"
  },
  {
    value: 2,
    text: "February"
  }, {
    value: 3,
    text: "March"
  }, {
    value: 4,
    text: "April"
  }, {
    value: 5,
    text: "May"
  }, {
    value: 6,
    text: "June"
  }, {
    value: 7,
    text: "July"
  }, {
    value: 8,
    text: "August"
  }, {
    value: 9,
    text: "September"
  }, {
    value: 10,
    text: "October"
  }, {
    value: 11,
    text: "November"
  }, {
    value: 12,
    text: "December"
  }];

  constructor(
    public auth: AuthService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public common: CommonService,
    public errorKey: ErrorMessageKeyService,
    private _router: Router,
    private countries: CountriesService,
    private eformservice: EFormServiceService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');

    this.NPEncashForm = this.formBuilder.group({
      BasicAccNo: ['', Validators.compose([Validators.required])],
      PreMatureCertNo: ['', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])],
      StartDate: [''],
      MaturityDate: [''],
      startdatedaySelect: [''],
      startdateMonthSelect: [''],
      startdateYearSelect: [''],
      MaturityDatedaySelect: [''],
      MaturityDateMonthSelect: [''],
      MaturityDateYearSelect: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this.id = Guid.create();
    this.uniqueId = this.id.toString();
    this.common.presentLoading();
    this.auth.accessToken = await this.auth.Get('token');
    this.auth.data = JSON.parse(await this.auth.Get("User"));
    this.auth.BalanceInquiry(Guid.create().toString(), this.auth.accessToken).then((data: any) => {

      if (data?.response?.code == 1) {
        this.auth.AllBalance = data.response.content;

        this.common.hideLoading();
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert('', '', this._router, data);
      }
    })

    this.startdatemin = moment().add(-5, 'years').format('YYYY-MM-DD');
    this.startdatemax = moment().add(-1, 'days').format('YYYY-MM-DD');
    this.MaturityDatemin = moment().add(1, 'days').format('YYYY-MM-DD');
    this.MaturityDatemax = moment().add(12, 'years').format('YYYY-MM-DD');

    const startdateYEARS = () => {
      const years = []

      const dateStart = moment().add(-5, 'years')
      const dateEnd = moment().add(-1, 'days')
      while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'))
        dateStart.add(1, 'year')
      }
      return years
    }
    this.startdateyears = startdateYEARS();
    this.startdateyears.sort((one, two) => (one > two ? 1 : -1));

    const MaturityDateYEARS = () => {
      const years = []

      const dateStart = moment()
      const dateEnd = moment().add(12, 'y')
      while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'))
        dateStart.add(1, 'year')
      }
      return years
    }
    this.MaturityDateyears = MaturityDateYEARS();
    this.MaturityDateyears.sort((one, two) => (one > two ? -1 : 1));

    for (var i = 1; i <= 31; i++) {
      this.MaturityDatedayslistArray.push(i);
      this.startdatedayslistArray.push(i);

    }
  }

  leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }

  startdateSelectionchange() {


    const sb = document.querySelector('#startdatedayslist') as HTMLSelectElement;





    this.NPEncashForm.patchValue({ 'StartDate': null })


    if (this.startdateYearSelect != null) {
      var check = this.leapYear(this.startdateYearSelect)
    }

    if (this.startdatedaySelect != null && this.startdateMonthSelect != null && this.startdateYearSelect != null) {
      if (this.startdateMonthSelect.toString().length == 1) {
        var monthvalue = "0" + this.startdateMonthSelect
      }
      if (this.startdatedaySelect.toString().length == 1) {
        var startdatedaySelectvalue = "0" + this.startdatedaySelect;
      }
      if (monthvalue != null && startdatedaySelectvalue != null) {
        this.startdatestring = this.startdateYearSelect + "-" + monthvalue + "-" + startdatedaySelectvalue;
      } else if (monthvalue != null && startdatedaySelectvalue == null) {
        this.startdatestring = this.startdateYearSelect + "-" + monthvalue + "-" + this.startdatedaySelect;
      } else if (monthvalue == null && startdatedaySelectvalue != null) {
        this.startdatestring = this.startdateYearSelect + "-" + this.startdateMonthSelect + "-" + startdatedaySelectvalue;
      } else if (monthvalue == null && startdatedaySelectvalue == null) {
        this.startdatestring = this.startdateYearSelect + "-" + this.startdateMonthSelect + "-" + this.startdatedaySelect;
      }

      let a = this.startdatemin;




      if (this.startdatestring > this.startdatemax) {
        this.startdateError = true;
        this.NPEncashForm.patchValue({ 'StartDate': null });

      }
      else {
        this.startdateError = false;
        this.NPEncashForm.patchValue({ 'StartDate': this.startdatestring });
      }

    }

    if (this.startdateMonthSelect == 1 || this.startdateMonthSelect == 3 || this.startdateMonthSelect == 5 || this.startdateMonthSelect == 7 || this.startdateMonthSelect == 8 || this.startdateMonthSelect == 10 || this.startdateMonthSelect == 12) {

      if (!this.startdatedayslistArray.includes(28)) { this.startdatedayslistArray.push(28) }
      if (!this.startdatedayslistArray.includes(29)) { this.startdatedayslistArray.push(29) }
      if (!this.startdatedayslistArray.includes(30)) { this.startdatedayslistArray.push(30) }
      if (!this.startdatedayslistArray.includes(31)) { this.startdatedayslistArray.push(31) }

    }

    else if (this.startdateMonthSelect != 2 && this.startdateMonthSelect == 4 || this.startdateMonthSelect == 6 || this.startdateMonthSelect == 9 || this.startdateMonthSelect == 11) {

      if (!this.startdatedayslistArray.includes(28)) { this.startdatedayslistArray.push(28) }
      if (!this.startdatedayslistArray.includes(29)) { this.startdatedayslistArray.push(29) }
      if (!this.startdatedayslistArray.includes(30)) { this.startdatedayslistArray.push(30) }

      var lastindex = this.startdatedayslistArray.length - 1;
      if (this.startdatedayslistArray.includes(31)) { this.startdatedayslistArray.splice(lastindex, 1) }


      if (this.startdatedaySelect == 31) {
        this.startdatedaySelect = null;

      }

    }

    else if (this.startdateMonthSelect == 2) {
      if (this.startdateYearSelect != null) {
        if (check) {

          if (!this.startdatedayslistArray.includes(28)) { this.startdatedayslistArray.push(28) }
          if (!this.startdatedayslistArray.includes(29)) { this.startdatedayslistArray.push(29) }

          var lastindex = this.startdatedayslistArray.length - 1;
          if (this.startdatedayslistArray.includes(31)) { this.startdatedayslistArray.splice(lastindex, 1) }

          var lastindex = this.startdatedayslistArray.length - 1;
          if (this.startdatedayslistArray.includes(30)) { this.startdatedayslistArray.splice(lastindex, 1) }

          if (this.startdatedaySelect == 30 || this.startdatedaySelect == 31) {
            this.startdatedaySelect = null;

          }
        }
        else {
          if (!this.startdatedayslistArray.includes(28)) { this.startdatedayslistArray.push(28) }

          var lastindex = this.startdatedayslistArray.length - 1;
          if (this.startdatedayslistArray.includes(31)) { this.startdatedayslistArray.splice(lastindex, 1) }

          var lastindex = this.startdatedayslistArray.length - 1;
          if (this.startdatedayslistArray.includes(30)) { this.startdatedayslistArray.splice(lastindex, 1) }

          var lastindex = this.startdatedayslistArray.length - 1;
          if (this.startdatedayslistArray.includes(29)) { this.startdatedayslistArray.splice(lastindex, 1) }

          if (this.startdatedaySelect == 29 || this.startdatedaySelect == 30 || this.startdatedaySelect == 31) {
            this.startdatedaySelect = null;

          }
        }
      }
      else {
        if (!this.startdatedayslistArray.includes(28)) { this.startdatedayslistArray.push(28) }
        var lastindex = this.startdatedayslistArray.length - 1;
        if (this.startdatedayslistArray.includes(31)) { this.startdatedayslistArray.splice(lastindex, 1) }
        var lastindex = this.startdatedayslistArray.length - 1;
        if (this.startdatedayslistArray.includes(30)) { this.startdatedayslistArray.splice(lastindex, 1) }
        var lastindex = this.startdatedayslistArray.length - 1;
        if (this.startdatedayslistArray.includes(29)) { this.startdatedayslistArray.splice(lastindex, 1) }

        if (this.startdatedaySelect == 29 || this.startdatedaySelect == 30 || this.startdatedaySelect == 31) {
          this.startdatedaySelect = null;

        }
      }
    }
  }


  getMessage(key, control) {
    let error = "";
    this.translate.get([key]).subscribe(data => {
      error = data[key];
    })
    return error;
  }

  ValueChange() {

    this.selectedAccountCurrency = this.auth.AllBalance.find(x => this.selectedAccont == x.accountNumber).currency;

  }

  AddInCertificateList() {

    var certificate = this.NPEncashForm.controls['PreMatureCertNo'].value;
    var start = this.NPEncashForm.controls['StartDate'].value;
    var maturity = this.NPEncashForm.controls['MaturityDate'].value;

    var found = false;
    let a = this.CertificatesArray.find(x => x.PreMatureCertNo == certificate);
    if (a) {
      found = true;
    } else {
      found = false;
    }









    if (found == false) {
      var obj = {
        PreMatureCertNo: certificate,
        StartDate: (start == '' || start == null) ? '' : start,
        MaturityDate: (maturity == '' || maturity == null) ? '' : maturity
      }
      this.CertificatesArray.push(obj);
    }
    else {
      this.common.showErrorAlert("Error", "Ceritifcate with same number is already added", "Ok", this._router);
      return;

    }










  }


  MaturityDateSelectionchange() {

    const sb = document.querySelector('#MaturityDatedayslist') as HTMLSelectElement;





    this.NPEncashForm.patchValue({ 'MaturityDate': null })


    if (this.MaturityDateYearSelect != null) {
      var check = this.leapYear(this.MaturityDateYearSelect)
    }

    if (this.MaturityDatedaySelect != null && this.MaturityDateMonthSelect != null && this.MaturityDateYearSelect != null) {
      if (this.MaturityDateMonthSelect.toString().length == 1) {
        var monthvalue = "0" + this.MaturityDateMonthSelect
      }
      if (this.MaturityDatedaySelect.toString().length == 1) {
        var MaturityDatedaySelectvalue = "0" + this.MaturityDatedaySelect;
      }
      if (monthvalue != null && MaturityDatedaySelectvalue != null) {
        this.MaturityDatestring = this.MaturityDateYearSelect + "-" + monthvalue + "-" + MaturityDatedaySelectvalue;
      } else if (monthvalue != null && MaturityDatedaySelectvalue == null) {
        this.MaturityDatestring = this.MaturityDateYearSelect + "-" + monthvalue + "-" + this.MaturityDatedaySelect;
      } else if (monthvalue == null && MaturityDatedaySelectvalue != null) {
        this.MaturityDatestring = this.MaturityDateYearSelect + "-" + this.MaturityDateMonthSelect + "-" + MaturityDatedaySelectvalue;
      } else if (monthvalue == null && MaturityDatedaySelectvalue == null) {
        this.MaturityDatestring = this.MaturityDateYearSelect + "-" + this.MaturityDateMonthSelect + "-" + this.MaturityDatedaySelect;
      }
      if (this.MaturityDatestring! < this.MaturityDatemin) {
        this.MaturityDateError = true;
        this.NPEncashForm.patchValue({ 'MaturityDate': null });
      } else {
        this.MaturityDateError = false;
        this.NPEncashForm.patchValue({ 'MaturityDate': this.MaturityDatestring });
      }

    }

    if (this.MaturityDateMonthSelect == 1 || this.MaturityDateMonthSelect == 3 || this.MaturityDateMonthSelect == 5 || this.MaturityDateMonthSelect == 7 || this.MaturityDateMonthSelect == 8 || this.MaturityDateMonthSelect == 10 || this.MaturityDateMonthSelect == 12) {

      if (!this.MaturityDatedayslistArray.includes(28)) { this.MaturityDatedayslistArray.push(28) }
      if (!this.MaturityDatedayslistArray.includes(29)) { this.MaturityDatedayslistArray.push(29) }
      if (!this.MaturityDatedayslistArray.includes(30)) { this.MaturityDatedayslistArray.push(30) }
      if (!this.MaturityDatedayslistArray.includes(31)) { this.MaturityDatedayslistArray.push(31) }

    }

    else if (this.MaturityDateMonthSelect != 2 && this.MaturityDateMonthSelect == 4 || this.MaturityDateMonthSelect == 6 || this.MaturityDateMonthSelect == 9 || this.MaturityDateMonthSelect == 11) {

      if (!this.MaturityDatedayslistArray.includes(28)) { this.MaturityDatedayslistArray.push(28) }
      if (!this.MaturityDatedayslistArray.includes(29)) { this.MaturityDatedayslistArray.push(29) }
      if (!this.MaturityDatedayslistArray.includes(30)) { this.MaturityDatedayslistArray.push(30) }

      var lastindex = this.MaturityDatedayslistArray.length - 1;
      if (this.MaturityDatedayslistArray.includes(31)) { this.MaturityDatedayslistArray.splice(lastindex, 1) }


      if (this.MaturityDatedaySelect == 31) {
        this.MaturityDatedaySelect = null;

      }

    }

    else if (this.MaturityDateMonthSelect == 2) {
      if (this.MaturityDateYearSelect != null) {
        if (check) {

          if (!this.MaturityDatedayslistArray.includes(28)) { this.MaturityDatedayslistArray.push(28) }
          if (!this.MaturityDatedayslistArray.includes(29)) { this.MaturityDatedayslistArray.push(29) }

          var lastindex = this.MaturityDatedayslistArray.length - 1;
          if (this.MaturityDatedayslistArray.includes(31)) { this.MaturityDatedayslistArray.splice(lastindex, 1) }

          var lastindex = this.MaturityDatedayslistArray.length - 1;
          if (this.MaturityDatedayslistArray.includes(30)) { this.MaturityDatedayslistArray.splice(lastindex, 1) }

          if (this.MaturityDatedaySelect == 30 || this.MaturityDatedaySelect == 31) {
            this.MaturityDatedaySelect = null;

          }
        }
        else {
          if (!this.MaturityDatedayslistArray.includes(28)) { this.MaturityDatedayslistArray.push(28) }

          var lastindex = this.MaturityDatedayslistArray.length - 1;
          if (this.MaturityDatedayslistArray.includes(31)) { this.MaturityDatedayslistArray.splice(lastindex, 1) }

          var lastindex = this.MaturityDatedayslistArray.length - 1;
          if (this.MaturityDatedayslistArray.includes(30)) { this.MaturityDatedayslistArray.splice(lastindex, 1) }

          var lastindex = this.MaturityDatedayslistArray.length - 1;
          if (this.MaturityDatedayslistArray.includes(29)) { this.MaturityDatedayslistArray.splice(lastindex, 1) }

          if (this.MaturityDatedaySelect == 29 || this.MaturityDatedaySelect == 30 || this.MaturityDatedaySelect == 31) {
            this.MaturityDatedaySelect = null;

          }
        }
      }
      else {
        if (!this.MaturityDatedayslistArray.includes(28)) { this.MaturityDatedayslistArray.push(28) }
        var lastindex = this.MaturityDatedayslistArray.length - 1;
        if (this.MaturityDatedayslistArray.includes(31)) { this.MaturityDatedayslistArray.splice(lastindex, 1) }
        var lastindex = this.MaturityDatedayslistArray.length - 1;
        if (this.MaturityDatedayslistArray.includes(30)) { this.MaturityDatedayslistArray.splice(lastindex, 1) }
        var lastindex = this.MaturityDatedayslistArray.length - 1;
        if (this.MaturityDatedayslistArray.includes(29)) { this.MaturityDatedayslistArray.splice(lastindex, 1) }

        if (this.MaturityDatedaySelect == 29 || this.MaturityDatedaySelect == 30 || this.MaturityDatedaySelect == 31) {
          this.MaturityDatedaySelect = null;

        }
      }
    }
  }

  isFormValid() {
    if (!this.NPEncashForm.valid) {
      return true
    }
    else if (this.CertificatesArray.length == 0) {
      return true
    }
    else {
      return false
    }
  }

  FormPost() {
    this.id = Guid.create();
    this.uniqueId = this.id.toString();

    this.common.presentLoading();

    this.saveinfo();
    this.eformservice.SaveNPEncashFormMethod(this.eformservice.NpEncashObject, this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {
        this.common.hideLoading();
        this.translate.get(["Success", "RequestSubmit", "OK"]).subscribe((data: any) => {
          this.common.showSuccessAlert(data["Success"], data["RequestSubmit"], data["OK"], this._router).then((data) => {
            this._router.navigateByUrl("/CurrencyExchangeRate");
          });
        })
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert("", "", this._router, data);
      }
    })
  }

  saveinfo() {
    var obj = this.eformservice.NpEncashObject;
    obj.currency = this.selectedAccountCurrency;
    obj.BasicAccountNo = this.NPEncashForm.controls['BasicAccNo'].value;
    obj.listofcertificates = this.CertificatesArray;
    obj.ApplicationId = this.auth.data.onBoardingAccount.applicationId;
    obj.UniqueId = this.uniqueId;

  }
  Remove(i) {
    this.CertificatesArray.splice(i, 1)

  }
  IsFormValid() {
    if (this.NPEncashForm.valid) {
      return false;
    } else {
      return true;
    }
  }

}
