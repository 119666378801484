import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RemittanceTrackerMTCNComponent } from './remittance-tracker-mtcn/remittance-tracker-mtcn.component';

const routes: Routes = [
  {
    path: 'RemittanceTrackerMTCN', component: RemittanceTrackerMTCNComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RemittanceTrackerRoutingModule { }
