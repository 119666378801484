<app-header></app-header>

<div class="container email-component mt-46">
    <div class="ng-row height-100">
        <div class="ng-col" col-3 col-sm-12>
            <app-progress-bar-circle [counter]="counter" [current]="current" [max]="max" [total]="3">
            </app-progress-bar-circle>
        </div>
        <div class="ng-col pt-30 pd-sm-30" col-7 col-sm-12>

            <div class="ng-row height-100 pb-30" align-content-between>
                <div class="ng-col">
                    <div class="font-30 color-2f5 font-600 mt-5">
                        {{"ForgetPassword" | translate}}
                    </div>

                    <div class="font-16 color-2f5 font-400 mt-15">
                        {{"Pleaseenterthe" | translate}}
                    </div>

                    <form class="bg-f6f7" [formGroup]="registerForm">
                        <div class="form-group mt-30">
                            <label>{{"IDNumber" | translate}}</label>
                            <input type="text" formControlName="IDNumber" maxlength="15" minlength="13" value=""
                                [mask]="this.cardmaask" [placeholder]="this.cardPlaceHolder">
                            <ng-container item-content *ngFor="let validation of errorKey.validation_messages.IDNumber">
                                <span class="error-label"
                                    *ngIf="registerForm.controls.IDNumber.hasError(validation.type) &&(registerForm.controls.IDNumber.touched ||submitAttempt)">
                                    {{ getMessage(validation.key,'IDNumber') }}
                                </span>
                            </ng-container>
                        </div>
                    </form>
                </div>

                <div class="ng-col mb-20">

                    <div class="form-group mt-15">
                        <button class="btn-dft" [disabled]="isFormValid()" (click)="Next()">
                            {{"Continue" | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-fab></app-fab>