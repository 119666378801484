import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, Balances, CurrencyRate } from './../Services/auth.service';
import { CommonService } from './../Services/common.service';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageKeyService } from './../Services/error-message-key.service';
import { Guid } from "guid-typescript";
import { EFormServiceService } from '../Services/eform-service.service';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { AmountInwordsPipe } from './../amount-inwords.pipe';
import { KycService } from './../Services/kyc.service';

@Component({
  selector: 'app-cdctransfer-amount-request',
  templateUrl: './cdctransfer-amount-request.component.html',
  styleUrls: ['./cdctransfer-amount-request.component.scss']
})
export class CDCTransferAmountRequestComponent implements OnInit {

  agree: boolean = false;
  SelectedAccountNumber = undefined;
  amount: number;
  CDCAccountNumber = undefined;
  AccountBalance: string;
  toCurrency: string;
  errorbalance: boolean = false;

  constructor(
    public currency: DecimalPipe,
    public auth: AuthService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public commonP: CommonService,
    public errorKey: ErrorMessageKeyService,
    private _router: Router,
    private eform: EFormServiceService,
    private AmountInwordsPipe: AmountInwordsPipe,
    private Kyc: KycService
  ) { }

  async ngOnInit(): Promise<void> {
    setTimeout(async () => {
      this.commonP.presentLoading();
      this.auth.accessToken = await this.auth.Get('token');
      this.auth.data = JSON.parse(await this.auth.Get("User"));

      if (this.auth.data.IsSpentDays || this.auth.data.isForeignTaxPayed) {
        this._router.navigateByUrl("/CurrencyExchangeRate")
      }

      this.auth.BalanceInquiry(Guid.create().toString(), this.auth.accessToken).then((data: any) => {

        if (data?.response?.code == 1) {
          this.auth.AllBalance = data.response.content.filter(x => x.currency == 'PKR');


          this.commonP.hideLoading();













        }
        else {
          this.commonP.hideLoading();
          this.commonP.ParseErrorAlert('', '', this._router, data);
        }
      })
    }, 3000);
  }

  changeAgree(event) {
    if (event.srcElement.checked) {
      this.agree = true;
    }
    else {
      this.agree = false;
    }
  }

  AmountChange() {



    this.amount = Math.floor(this.amount);
    var balance = Number(this.AccountBalance);
    if (this.amount > balance) {
      this.errorbalance = true;
    }
    else {
      this.errorbalance = false;
    }
  }

  isvalid() {
    if (!this.errorbalance) {
      if (this.agree) {
        if (this.SelectedAccountNumber && this.amount) {
          return false;
        }
        return true;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  ValueChange() {
    var object = this.eform.CDCTransferObject;
    object.AccountNumber = this.SelectedAccountNumber;
    this.toCurrency = this.auth.AllBalance.find(x => x.accountNumber == this.SelectedAccountNumber).currency;
    this.AccountBalance = this.auth.AllBalance.find(x => x.accountNumber == this.SelectedAccountNumber).balance;
  }

  SaveInfo() {
    var object = this.eform.CDCTransferObject;
    let _amount = this.currency.transform(this.amount, "1.2-2").replace(/[^0-9.]/g, "");
    object.Amount = Number(_amount),
      object.ApplicationId = this.auth.data.onBoardingAccount.applicationId;
    object.AmountInWords = this.AmountInwordsPipe.transform(this.amount).toString();
    object.CDCNumber = "";
  }

  FormPost() {
    this.commonP.presentLoading();
    this.SaveInfo();
    this.eform.SaveCDCTransferAmountReq(this.eform.CDCTransferObject, this.auth.accessToken).then((data: any) => {
      if (data?.response?.code == 1) {
        this.commonP.hideLoading();
        this.translate.get(["Success", "CDCAmountSuccess", "OK"]).subscribe((data: any) => {
          this.commonP.showSuccessAlert(data["Success"], data["CDCAmountSuccess"], data["OK"], this._router).then((data) => {
            this._router.navigateByUrl("/CurrencyExchangeRate");
          });
        })
      }
      else {
        this.commonP.hideLoading();
        this.commonP.ParseErrorAlert("", "", this._router, data);
      }
    })
  }
}
