import { Component, Input, OnInit, ViewChild, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';

export interface list {

  displayValue: string;
  value: any;
  iconClass: string;

}

@Component({
  selector: 'app-drop-down-list',
  templateUrl: './drop-down-list.component.html',
  styleUrls: ['./drop-down-list.component.scss']
})
export class DropDownListComponent implements OnInit {

  filterTerm: string;
  @Input() defaultplaceholder = "Please select";
  imgageStatus: boolean = false;
  searchStatus: boolean = true;

  @Input('dropDownData') dropDownData: list[] = [];

  @Output() itemselected: EventEmitter<any> = new EventEmitter<any>();

  @Input('dropDownImage') dropDownImage: string;
  @Input('selectedValue') selectedValue: string;

  @Input('selectedIcon') selectedIcon: string;

  @ViewChild("customDropdownList") customDropdownList: ElementRef;

  @Input('dropDownSearch') dropDownSearch: string;

  checkDropDownStatus: boolean = false;

  selectedDropNumber: string = "";

  @Input('ShowDropDown') ShowDropDown : boolean = true;

  selectAssignNumber: string;

  @Input('countryCodePlus') countryCodePlus: string = "";


  constructor(
    public Renderer2: Renderer2,
  ) { }

  ngOnInit(): void {
    if (this.dropDownImage == "true") {
      this.imgageStatus = true;
    }
    if (this.dropDownSearch == "false") {
      this.searchStatus = false;
    }
    if (this.selectedValue) {
      this.selectedDropNumber = this.selectedValue;
    } else {
      this.selectedDropNumber = "";
    }
  }

  showDropList() {
    if(this.ShowDropDown){
      if (this.checkDropDownStatus == true) {
        this.Renderer2.removeClass(this.customDropdownList.nativeElement, "active");
        this.checkDropDownStatus = false;
        this.filterTerm = "";
      } else {
        this.Renderer2.addClass(this.customDropdownList.nativeElement, "active");
        this.checkDropDownStatus = true;
        this.filterTerm = "";
      }
    }
  }

  closeDropdown() {
    if(this.ShowDropDown){
      this.Renderer2.removeClass(this.customDropdownList.nativeElement, "active");
      this.checkDropDownStatus = false;
      this.filterTerm = "";
    }
  }

  selectDropVal(value) {










    this.Renderer2.removeClass(this.customDropdownList.nativeElement, "active");


    this.itemselected.emit(value);

    this.checkDropDownStatus = false;
  }

}
