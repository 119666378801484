import { CommonService } from './../Services/common.service';
import { AuthService } from './../Services/auth.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap, map } from 'rxjs/operators';
import { HttpServiceService } from '../Services/http-service.service';
import { debugOutputAstAsTypeScript } from '@angular/compiler';


@Injectable({
  providedIn: 'root'
})

export class BaseClassService implements HttpInterceptor {

  platform: string = "w";
  longitude;
  latitiude

  private _jsonURL = 'assets/APIURL.json';

  constructor(
    private injector: Injector,
    private auth: AuthService,
    private commonService: CommonService,
    private http: HttpServiceService
  ) {
    this.getlocationservice().then(resp => {
      this.longitude = JSON.stringify(resp.lng);
      this.latitiude = JSON.stringify(resp.lat);

    })
  }

  getlocationservice(): Promise<any> {
    return new Promise((resolve, rejects) => {

      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude })

      })
    })
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let http: HttpClient = this.injector.get(HttpClient);

    if (req.url.includes("undefined")) {
      return http.get(this._jsonURL).pipe(switchMap((response: any) => {

        let a = req.url;
        a = a.replace("undefined", response.URL);
        const newUrl = { url: a };
        const urlWithParams = { urlWithParams: a }
        req = Object.assign(req, newUrl);
        req = Object.assign(req, urlWithParams);
        if (req.method == "POST") {

          let a = req.body;
          console.log(a, 'a');
          let b = JSON.parse(a);
          console.log('b', b);
          b.baseClass = {
            deviceID: "string", "ChannelType": "Web", "Platform": "W", "MobileModel": "s"
            , "AppVersion": "0.0.1", "IP": "", "Longitude": "24.9815886", "Latitude": "24.9815886"
          };
          return from(this.commonService.FinalEncrypt(b)).pipe(switchMap(data => {
            const bod = { body: data };
            req = Object.assign(req, bod);
            console.log('req', req);

            return next.handle(req).pipe(
              map((event: HttpEvent<any>) => {

                if (event instanceof HttpResponse) {
                  if (event.body?.response?.token) {
                    this.auth.accessToken = event.body?.response?.token;
                    this.commonService.Set('token', this.auth.accessToken);

                  }
                }

                return event;
              }));
          }))

        }


        else {
          return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                if (event.body?.response?.token) {
                  this.auth.accessToken = event.body?.response?.token;
                  this.commonService.Set('token', this.auth.accessToken);
                }
              }
              return event;
            }));
        }


























































      }))
    }

    return next.handle(req);




  }





}