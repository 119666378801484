import { Injectable } from '@angular/core';
import { AuthService } from './Services/auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class Auth3Guard implements CanActivate {
  constructor(
    public auth: AuthService,
    public _router: Router
  ) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    

    let a = await this.auth.Get("Auth");
    debugger
    if (a) {
      return true;

    } else {
      
      this._router.navigateByUrl("/login");
      return false;
    }




  }



}
