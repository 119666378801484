import { ConfigService } from './../Services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FatcaCrsService } from './../Services/fatca-crs.service';
import { CountriesService } from './../Services/countries.service';
import { Router } from '@angular/router';
import { CommonService } from './../Services/common.service';
import { KycService } from './../Services/kyc.service';
import { AuthService } from './../Services/auth.service';
import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { list } from '../SharedComponents/drop-down-list/drop-down-list.component';
import { Guid } from "guid-typescript";
import { ErrorMessageKeyService } from '../Services/error-message-key.service';
import { VideoCallService } from '../Services/video-call.service';

@Component({
  selector: 'app-crsdeclaration',
  templateUrl: './crsdeclaration.component.html',
  styleUrls: ['./crsdeclaration.component.scss']
})
export class CRSdeclarationComponent implements OnInit {

  @ViewChild("modal") Modal: ElementRef;
  @ViewChild("modal2") Modal2: ElementRef;
  @ViewChild("modal4") Modal4: ElementRef;
  
  customSelectBox;
  counter: string;
  current: string;
  max: string;
  Userobj;
  selectedcountryValue: string = null;
  selectedCurrentCountry: string = '';
  dropdownlist: list[] = [];
  tinStatus: boolean = false;
  reasonStatus: boolean = false;
  reasonBStatus: boolean = false;
  newform: FormGroup;
  radioCheckStatus: null;
  radioCheckStatus2: null;
  radiobuttonValue: string = "";

  constructor(
    public Renderer2: Renderer2,
    public auth: AuthService,
    public kyc: KycService,
    public config: ConfigService,
    public common: CommonService,
    private _router: Router,
    public country: CountriesService,
    public fatcaserv: FatcaCrsService,
    public formbuilder: FormBuilder,
    public errorKey: ErrorMessageKeyService,
    public translate: TranslateService,
    public Videoservice: VideoCallService
  ) {
    this.newform = this.formbuilder.group({
      _tinNumber: [""],
      _reasonExplanation: [""]
    })
  }

  getMessage(key, control) {
    let error = "";
    this.translate.get([key]).subscribe(data => {
      error = data[key];
    })
    return error;
  }

  async ngOnInit(): Promise<void> {

    this.counter = "4";
    this.current = "4";
    this.max = "4";

    this.auth.accessToken = await this.auth.Get('token');
    var object = JSON.parse(await this.common.Get('User'));
    this.Userobj = JSON.parse(await this.common.Get('User'));
    let data = await this.common.Get('FatcaObject');

    if (!data) {
      this._router.navigateByUrl("/register/" + "CrsFatcaDeclaration");
    } else {
      this.fatcaserv.dataobject = JSON.parse(data);
    }
    this.auth.data.onBoardingAccount.productId = object.onBoardingAccount.productId;


    this.kyc.count = 0;
    await this.common.presentLoading();
    await this.kyc.GetKycStatus(this.auth.accessToken, this.auth.data.onBoardingAccount ? this.auth.data.onBoardingAccount.productId : 1).then((data: any) => {
      if (data?.response?.code == 1) {

        if (this.kyc.totalCount > 0) {
          let nextscreen = this.kyc.getScreen(this.kyc.count);

          if (nextscreen == "/register/CrsFatcaDeclaration") {
            this.country.GetAll(Guid.create().toString()).then(async (data: any) => {
              if (data?.response?.code == 1) {

                this.country.countries = data.response.content;
                this.dropdownlist = this.country.countries.map(x => ({ value: x, displayValue: x.countryName, iconClass: x.flagClass }));
                this.common.hideLoading();
              }
              else {
                this.common.hideLoading();
                this.common.ParseErrorAlert('', '', this._router, data);
              }
            })
          }
          else {
            this.common.hideLoading();
            this._router.navigateByUrl(nextscreen);
          }
        }
        else {
          this.common.hideLoading();
          this.common.ParseErrorAlert('', '', this._router, data);
        }
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert('', '', this._router, data);
      }
    })
  }

  labelLink(e) {
    e.preventDefault();
    this.showPopup2();
  }

  labelLink2(e) {
    e.preventDefault();
    window.open(this.config.oecdsite, '_blank');
  }

  showPopup() {
    this.isCurrentTinAvailable = '';
    this.currentTinNumber = "";
    this.tinNumber = "";
    this.reason = "";
    this.reasonExplanation = "";
    this.selectedCurrentCountry = "";
    this.selectedcountryValue = "";
    this.Renderer2.addClass(this.Modal.nativeElement, "active");
    this.radioCheckStatus = null;
    this.radioCheckStatus2 = null;
    this.radiobuttonValue = "";
    this.reasonStatus = false;
    this.tinStatus = false;
  }

  hidePopup() {
    this.Renderer2.removeClass(this.Modal.nativeElement, "active");
  }

  isCurrentTinAvailable: string = '';
  currentTinNumber: string = "";
  tinNumber: string = "";
  reason: string = "";
  reasonExplanation: string = "";

  onCountryChange(event) {
    this.selectedcountryValue = event.value.isoCode3;
    this.selectedCurrentCountry = event.displayValue;
  }

  HasCountry(event, hasCountry) {

  }

  tinStatusChange() {
    this.isCurrentTinAvailable = "Yes";

    this.reasonStatus = false;
    this.tinStatus = true;

    let con = this.newform.controls["_tinNumber"];
    con.clearValidators();
    con.reset();
    con.setValidators(Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(15), Validators.pattern('[a-zA-z0-9]*')]));
    con.markAsUntouched();
    con.updateValueAndValidity();
  }

  mainConfirmed: string = "";
  
  checked(event) {
    this.mainConfirmed = event.target.checked ? "true" : "false";
  }
  
  isMainValid() {

    if (this.mainConfirmed == "true") {
      if (this.fatcaserv.dataobject.CrsList.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }

  }

  reasonStatusChange() {
    this.isCurrentTinAvailable = "No";

    this.reasonStatus = true;
    this.tinStatus = false;

    let con = this.newform.controls["_tinNumber"];

    con.clearValidators();
    con.reset();
    con.setValidators(Validators.compose([]));
    con.markAsUntouched();
    con.updateValueAndValidity();
  }

  DataSelected() {
    this.fatcaserv.dataobject.CrsList.push({
      Reason: this.reason,
      ReasonExplantion: this.reasonExplanation,
      TaxCountry: this.selectedcountryValue,
      TaxCountryName: this.selectedCurrentCountry
      , TinAvailable: this.isCurrentTinAvailable, TinNumber: this.tinNumber
    })
    this.Renderer2.removeClass(this.Modal.nativeElement, "active");
  }

  formpost() {
    this.common.presentLoading();
    this.fatcaserv.CreatingFatcaCrsResponse(this.auth.accessToken, this.fatcaserv.dataobject).then((data: any) => {
      if (data?.response?.code == 1) {
        this.kyc.count = 0;
        this.kyc.GetKycStatus(this.auth.accessToken, this.auth.data.onBoardingAccount ? this.auth.data.onBoardingAccount.productId : 1).then( (data: any) => {
          if (data?.response?.code == 1) {

            if (this.kyc.totalCount > 0) {
              let nextscreen = this.kyc.getScreen(this.kyc.count);
              this.common.hideLoading();
              this._router.navigateByUrl(nextscreen);
            }
            else {
              this.common.hideLoading();
              this.common.ParseErrorAlert('', '', this._router, data);
            }
          }
          else {
            this.common.hideLoading();
            this.common.ParseErrorAlert('', '', this._router, data);
          }
        })
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert("", "", this._router, data);
      }
    })
  }

  formpostdolater() {
    this.common.showDoLaterAlert(this._router).then((data) => {
      if (data == "Logout") {
        this.common.presentLoading();
        this.fatcaserv.CreatingFatcaCrsResponse(
          this.auth.accessToken, this.fatcaserv.dataobject).then((data: any) => {
            if (data?.response?.code == 1) {
              this.common.hideLoading();
              this.common.clear();
              this._router.navigateByUrl('/login');
            }
            else {
              this.common.hideLoading();
              this.common.ParseErrorAlert("", "", this._router, data);
            }
          });
      }
    });
  }

  isValid() {
    if (!this.newform.valid) {
      return true;
    } else {
      if (this.selectedcountryValue) {
        if (this.isCurrentTinAvailable == "Yes") {
          if (this.tinNumber) {
            return false;
          } else {
            return true;
          }
        } else if (this.isCurrentTinAvailable == "No") {
          if (this.reason == "Reason A" || this.reason == "Reason C") {
            return false;
          } else if (this.reason == "Reason B") {
            if (this.reasonExplanation) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }

  }

  radioStatusChange(num) {
    this.reason = "";
    this.reasonExplanation = "";
    if (num == 1) {
      this.reason = "Reason A";

    } else if (num == 2) {
      this.reason = "Reason B";

    } else {
      this.reason = "Reason C";

    }
    if (num == 2) {
      this.reasonBStatus = true;
      let con = this.newform.controls["_reasonExplanation"];
      con.clearValidators();
      con.reset();
      con.setValidators(Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)]));
      con.markAsUntouched();

    } else {
      this.reasonBStatus = false;
      let con = this.newform.controls["_reasonExplanation"];
      con.clearValidators();
      con.reset();
      con.setValidators(Validators.compose([]));
      con.markAsUntouched();

    }
  }

  showPopup2() {
    this.Renderer2.addClass(this.Modal2.nativeElement, "active");
  }

  hidePopup2() {
    this.Renderer2.removeClass(this.Modal2.nativeElement, "active");
  }

  remove(index) {
    this.fatcaserv.dataobject.CrsList.splice(index, 1);
  }

  hidePopup3() {
    this.Renderer2.removeClass(this.Modal4.nativeElement, "active");
  }

  Selectedoption = undefined;
  customSelectBoxBtn(value) {
    this.customSelectBox = value;

    if (value == 1) {
      this.Selectedoption = "Yes"
    }
    else {
      this.Selectedoption = "No"
    }
  }

  isvalidbutton() {
    if (this.Selectedoption) {
      return false;
    }
    else {
      return true;
    }
  }

  VideoCallPost() {
    this.common.presentLoading();
    this.Videoservice.Videocallrequest(this.auth.accessToken, this.Selectedoption).then((data: any) => {
      if (data?.response?.code == 1) {
        this.common.hideLoading();
        this.Renderer2.removeClass(this.Modal4.nativeElement, "active");
      }
      else {
        this.common.hideLoading();
        this.common.ParseErrorAlert("", "", this._router, data);
      }
    })
  }

}
