import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpServiceService } from './http-service.service';

export interface AdditionalFieldsModel {

  ProductId: number;
  AccountId: number;
  AddressType: number;
  ResidenceAddressLine1: string;
  ResidenceAddressLine2: string;
  PurposeOfAccount: string;
  Pep: boolean;
  PepRelation: boolean;
  MonthlyIncome: string;
  Occupation: string;
  CompanyName: string;
  CountryOfBirth: string;
  CountryOfResidence: string;
  CityOfResidence: string;
  FrequencyStatements: string;
  MonthExpctCrditTrans: string;
  ExpctMonthCrditTrnovr: string;
  MotherMaidenName: string;
  MailingAddressLine1: string;
  MailingAddressLine2: string;
  CityOfBirth: string;
  StateOfResidence: string;
  PostCodeResidence: string;
  CountryOfMailAdd: string;
  StateOfMailAdd: string;
  CityOfMailAdd: string;
  PostCodeOfMailAdd: string;
  Remittance: string;
  formid: string;
  isDependentHousWife: boolean,
  changeConsent: boolean,
  sourceFundHousewife: string,
  beneficialOwnerConsent: boolean,
  dependentFundProvider: string,
  dependentRelAcctHolder: string,
  dependentResAddLine1: string,
  dependentResAddLine2: string,
  dependentCountry: string,
  dependentState: string,
  dependentCity: string,
  dependentPostcode: string,
  dependentIsMailAddSame: boolean,
  dependentMailAddLine1: string,
  dependentMailAddLine2: string,
  dependentMailCountry: string,
  dependentMailState: string,
  dependentMailCity: string,
  dependentMailPostCode: string,
  SpecifyIncome: string
}

export interface AdditionalFieldsResidentModel {
  
  formid: string;
  ProductId: number;
  AccountId: number;
  AddressType: number;
  MotherMaidenName: string;
  CountryOfBirth: string;
  CityOfBirth: string;
  LandlineNumber : string;
  MailingAddressLine1: string;
  MailingAddressLine2: string;
  PA_PostalCode_MA : string;
  PA_Country_MA : string;
  PA_City_MA : string;
  Occupation: string;
  ExpctMonthCrditTrnovr_DA : string;
  NameOfRemitter_ARDA : string;
  RelationWithRemitter_ARDA : string;
  Other_RelationWithRemitter_ARDA : string;
  PermanentAddressline1 : string;
  PermanentAddressline2 : string;
  PA_PostalCode : string;
  PA_Country : string;
  PA_City : string;
  AnyOtherNationality_DA : string;
  MonthlyIncome_DA : string;
}

export interface AdditionalFieldsModelSec {

  ProductId: number;
  AccountId: number;
  AddressType: number;
  ResidenceAddressLine1: string;
  ResidenceAddressLine2: string;
  PurposeOfAccount: string;
  Pep: boolean;
  PepRelation: boolean;
  MonthlyIncome: string;
  Occupation: string;
  CompanyName: string;
  CountryOfBirth: string;
  CountryOfResidence: string;
  CityOfResidence: string;
  isDependentHousWife: boolean,
  FrequencyStatements: string;
  MonthExpctCrditTrans: string;
  ExpctMonthCrditTrnovr: string;
  MotherMaidenName: string;
  MailingAddressLine1: string;
  MailingAddressLine2: string;
  CityOfBirth: string;
  ChangeConsent: boolean,
  StateOfResidence: string;
  PostCodeResidence: string;
  CountryOfMailAdd: string;
  StateOfMailAdd: string;
  CityOfMailAdd: string;
  SourceFundHousewife: string;
  BeneficialOwnerConsent: boolean;
  DependentPostcode: string;
  DependentFundProvider: string
  PostCodeOfMailAdd: string;
  DependentResAddLine1: string;
  DependentResAddLine2: string;
  DependentCountry: string;
  DependentRelAcctHolder: string;
  DependentIsMailAddSame: boolean;
  DependentMailCity: string;
  Remittance: string;
  DependentCity: string;
  DependentState: string;
  DependentMailAddLine1: string;
  DependentMailAddLine2: string;
  DependentMailCountry: string;
  DependentMailState: string,
  DependentMailPostCode: string,
  formid: string;
  SpecifyIncome: string
  SecUserId: string;
}

export interface AdditionalDocConfigModel {
  id: number;
  CompanyId: number;
  ProductId: number;
  Type: string;
  DocId: number;
  LabelEn: string;
  LabelAr: string;
  Description: string;
  DocumentType: string;
  OrderNo: string;
}

export interface HashModel {
  IncomingObject: any,
  GeneratedHash: string;
}

export interface AdditionalDocs {
  docId: number;
  documentType: string;
  image: string;

}

export interface listofadddocModel {
  companyId: number;
  description: string;
  docId: string;
  documentType: string;
  id: number;
  isActive: number;
  isWebOnly: boolean;
  labelAr: string;
  labelEn: string;
  mobileDescription: string;
  orderNo: number;
  productId: number;
  type: string;
  updatedDate: string;
}

@Injectable({
  providedIn: 'root'
})

export class AdditionalFieldsService {

  public Platform: string = "w";
  public SaveAdditionalResFldsURL: string = "AddFldResp/SaveAdditionalFieldsV3";
  public SaveAdditionalResFldsResidentURL: string = "AddFldResp/SaveResidentAdditionalFieldsV1";
  public SaveAdditionalResFldsSecURL: string = "AddFldResp/SaveAdditionalFieldsV3Sec";
  public GetAddDocURL: string = "AdditionalDocs/GetAddDocConfigV1"
  public SaveDocs: string = "AdditionalDocs/SaveAdditionalDocsForWebV2"
  public SaveDocsSec: string = "AdditionalDocs/SaveAdditionalDocsForWebV2Sec"

  public data: AdditionalFieldsModel = {
    ProductId: 1,
    AccountId: 1,
    AddressType: 1,
    CityOfBirth: '',
    CityOfMailAdd: '',
    CityOfResidence: '',
    CountryOfBirth: '',
    CountryOfMailAdd: '',
    CountryOfResidence: '',
    FrequencyStatements: '',
    MailingAddressLine1: '',
    MailingAddressLine2: '',
    Pep: false,
    PepRelation: false,
    PostCodeOfMailAdd: '',
    PostCodeResidence: '',
    PurposeOfAccount: '',
    Remittance: '',
    ResidenceAddressLine1: '',
    ResidenceAddressLine2: '',
    StateOfMailAdd: '',
    StateOfResidence: '',
    MotherMaidenName: "",
    Occupation: "",
    CompanyName: "",
    MonthlyIncome: "",
    MonthExpctCrditTrans: "",
    ExpctMonthCrditTrnovr: "",
    formid: "",
    beneficialOwnerConsent: null,
    changeConsent: null,
    dependentCity: "",
    dependentCountry: "",
    dependentFundProvider: "",
    dependentIsMailAddSame: null,
    dependentMailAddLine1: "",
    dependentMailAddLine2: "",
    dependentMailCity: "",
    dependentMailCountry: "",
    dependentMailPostCode: '',
    dependentMailState: '',
    dependentPostcode: '',
    dependentRelAcctHolder: '',
    dependentResAddLine1: '',
    dependentResAddLine2: '',
    dependentState: '',
    isDependentHousWife: null,
    sourceFundHousewife: '',
    SpecifyIncome: ""
  };

  public AdditionalFieldsResidentObj : AdditionalFieldsResidentModel = {
    
    ProductId: 1,
    AccountId: 1,
    AddressType: 1,
    CityOfBirth: '',
    CountryOfBirth: '',
    MailingAddressLine1: '',
    MailingAddressLine2: '',
    MotherMaidenName: "",
    Occupation: "",
    formid: "",
    AnyOtherNationality_DA : '',
    ExpctMonthCrditTrnovr_DA : '',
    LandlineNumber : '',
    NameOfRemitter_ARDA : '',
    RelationWithRemitter_ARDA : '',
    Other_RelationWithRemitter_ARDA : '',
    PA_City : '',
    PA_City_MA : '',
    PA_Country : '',
    PA_Country_MA : '',
    PA_PostalCode : '',
    PA_PostalCode_MA : '',
    PermanentAddressline1 : '',
    PermanentAddressline2 : '',
    MonthlyIncome_DA : ''
  };

  public dataSec: AdditionalFieldsModelSec = {
    ProductId: 1,
    AccountId: 1,
    AddressType: 1,
    CityOfBirth: '',
    CityOfMailAdd: '',
    CityOfResidence: '',
    CountryOfBirth: '',
    CountryOfMailAdd: '',
    CountryOfResidence: '',
    FrequencyStatements: '',
    MailingAddressLine1: '',
    MailingAddressLine2: '',
    Pep: false,
    PepRelation: false,
    PostCodeOfMailAdd: '',
    PostCodeResidence: '',
    PurposeOfAccount: '',
    Remittance: '',
    ResidenceAddressLine1: '',
    ResidenceAddressLine2: '',
    StateOfMailAdd: '',
    StateOfResidence: '',
    MotherMaidenName: "",
    Occupation: "",
    CompanyName: "",
    ChangeConsent: null,
    DependentCity: "",
    MonthlyIncome: "",
    MonthExpctCrditTrans: "",
    DependentIsMailAddSame: null,
    ExpctMonthCrditTrnovr: "",
    isDependentHousWife: null,
    BeneficialOwnerConsent: null,
    DependentResAddLine1: "",
    DependentResAddLine2: "",
    SourceFundHousewife: '',
    DependentFundProvider: "",
    DependentRelAcctHolder: "",
    DependentPostcode: "",
    DependentState: "",
    DependentCountry: "",
    DependentMailAddLine1: "",
    DependentMailAddLine2: "",
    DependentMailCountry: "",
    DependentMailState: "",
    DependentMailCity: "",
    DependentMailPostCode: "",
    SpecifyIncome: "",
    formid: "",
    SecUserId: '',
  };

  public DocsObject: AdditionalDocs = {
    docId: 0,
    documentType: '',
    image: '',
  }

  constructor(
    private http: HttpServiceService,
    private config: ConfigService,
  ) { }

  public SaveAdditionalFields(token, IsResident) {
    return new Promise((resolve, reject) => {

      var object = IsResident == '1' ? this.AdditionalFieldsResidentObj : this.data;
      var Url = IsResident == '1' ?  this.SaveAdditionalResFldsResidentURL : this.SaveAdditionalResFldsURL;
      
      this.http.postToCloseApi(this.config.server + Url , object, token).subscribe((data: any) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public SaveAdditionalFieldsSec(token, object) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.SaveAdditionalResFldsSecURL, object, token).subscribe((data: any) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public GetAddDocConfig(token): Promise<unknown> {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.GetAddDocURL, { IsWeb: true }, token).subscribe((data) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public SaveAdditionalDocs(token, object) {
    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.SaveDocs, object, token).subscribe((data: any) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }

  public SaveAdditionalDocsSec(token, object) {

    return new Promise((resolve, reject) => {
      this.http.postToCloseApi(this.config.server + this.SaveDocsSec, object, token).subscribe((data: any) => {
        resolve(data);
      },
        Error => {
          resolve(Error);
        })
    });
  }
}
